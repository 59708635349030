import { AnalyticsProvider } from '@praxis/component-analytics'
import { AuthProvider } from '@praxis/component-auth'
import { EnvProvider } from '@praxis/component-runtime-env'
import Layout from '../components/Layout/Layout'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { BrowserRouter as Router } from 'react-router-dom'
import UserSettingsContextProvider from '../contexts/UserSettingsContext'
import { getDomainName } from './Util'
import muiTheme from '../config/themeConfig'

const App = () => {
  return (
    <EnvProvider
      configPath={
        process.env.NODE_ENV === 'development'
          ? '/config.json'
          : '/app-environment'
      }
    >
      {({ env }) => {
        const isExternalDomain =
          getDomainName() !== env.auth.externalDomain ? false : true
        return (
          <MuiThemeProvider theme={muiTheme}>
            <AuthProvider
              clientId={env.auth.clientId}
              authorizationUrl={
                !isExternalDomain ? env.auth.host : env.auth.externalHost
              }
              logoutUrl={
                !isExternalDomain
                  ? env.auth.logoutHost
                  : env.auth.externalLogoutHost
              }
              isFullPageAuth
            >
              <Router>
                <AnalyticsProvider
                  eventManagerConfiguration={{
                    appName: env.analytics.appName,
                    apiKey: env.analytics.apiKey,
                    url: env.analytics.url,
                  }}
                  trackLocations
                >
                  <UserSettingsContextProvider>
                    <Layout props={env} isExternalDomain={isExternalDomain} />
                  </UserSettingsContextProvider>
                </AnalyticsProvider>
              </Router>
            </AuthProvider>
          </MuiThemeProvider>
        )
      }}
    </EnvProvider>
  )
}

export default App
