import axios from 'axios'

class SvcLostStolen {
  postDeviceLostStolenMoves(serialNumber, platform, config) {
    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey
    const apiUri = encodeURI(
      `${apiUriBase}/handhelds/${serialNumber}/lost_stolen_moves?key=${apiKey}`,
    )
    const headers = {
      'Content-Type': 'application/json',
    }
    return axios.post(
      apiUri,
      {
        serial_number: serialNumber,
        platform: platform,
      },
      headers,
    )
  }
}

export default SvcLostStolen
