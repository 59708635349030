import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import Dialog from '@material-ui/core/Dialog'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Good from '@material-ui/icons/CheckCircleTwoTone'
import IconButton from '@material-ui/core/IconButton'
import ListItem from '@material-ui/core/ListItem'
import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import Spinner from 'nicollet-react/es/components/Spinner/Spinner'
import SvcPrinters from '../../../services/svcPrinters'
import Tooltip from '@material-ui/core/Tooltip'
import { withAnalytics } from '@praxis/component-analytics'
import { withEnv } from '@praxis/component-runtime-env'
import withRouter from '../../withRouter'
import { withStyles } from '@material-ui/styles'

const styles = {
  icon: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
  submitButton: {
    float: 'left',
  },
  successButton: {
    float: 'left',
  },
  cancelButton: {
    float: 'left',
    marginLeft: 5,
  },
  dialog: {
    textAlign: 'center',
  },
  list: {
    isplay: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  spinner: {
    marginBottom: 15,
  },
  good: {
    fontSize: 40,
    color: 'green',
    paddingLeft: 5,
  },
}
export const toDoTypes = {
  'ZPL*': 'zplstar',
  PDF: 'pdf',
  EZPRINT: 'ezprint',
  ZPL: 'zpl',
}

class SetPrinterMode extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      snackBarOpen: false,
      snackBarMessageInfo: '',
      actionSuccess: false,
      loading: false,
    }
    this.svcPrinters = new SvcPrinters()
    this.goBack = this.goBack.bind(this)
  }
  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value })
  }
  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    this.setState({ snackBarOpen: false })
  }
  goBack() {
    this.props.router.navigate(0)
  }
  handleSubmit() {
    const {
      toDoAction,
      rowData: { ip_address, hostname, print_mode, mac_address, location_id }, //, serial_number
    } = this.props
    if (print_mode && toDoAction === toDoTypes[print_mode]) {
      this.setState({
        snackBarMessageInfo: 'WARNING - Already Set To ' + toDoAction,
        snackBarOpen: true,
      })
    } else {
      this.setState({ loading: true })
      this.svcPrinters
        .putPrinterUpdates(
          ip_address,
          hostname,
          toDoAction,
          mac_address,
          null,
          location_id,
          this.props.env,
        )
        .then((success) => {
          const eventData = {
            customMetrics: {
              metric1: 'SetPrintMode',
              metric2: toDoAction,
              metric3: success.status,
            },
            event: {
              type: 'apiSuccessSetPrintMode',
            },
          }
          this.props.trackEvent(eventData)
          if (success.status === 200) {
            this.setState({
              snackBarMessageInfo: 'Printer Mode Changed',
              snackBarOpen: true,
              actionSuccess: true,
            })
          }
        })
        .catch((error) => {
          const eventData = {
            customMetrics: {
              metric1: 'SetPrintMode',
              metric2: toDoAction,
              metric3: error.response.status,
            },
            event: {
              type: 'apiErrorSetPrintMode',
            },
          }
          this.props.trackEvent(eventData)
          if (
            Math.round(error.response.status / 100) === 5 ||
            Math.round(error.response.status / 100) === 4
          ) {
            if (
              error.response.data.message !== null ||
              error.response.data.message !== ''
            ) {
              this.setState({
                snackBarOpen: true,
                snackBarMessageInfo: error.response.data.message,
              })
            } else {
              this.setState({
                snackBarOpen: true,
                snackBarMessageInfo:
                  'ERROR - An error has occurred setting the printer settings',
              })
            }
          } else {
            this.setState({
              snackBarOpen: true,
              snackBarMessageInfo:
                'ERROR - An error has occurred setting the printer settings',
            })
          }
        })
        .finally(() => {
          this.setState({ loading: false })
        })
    }
  }

  render() {
    const {
      classes,
      toDoAction,
      rowData: { ip_address, hostname, role, mac_address },
    } = this.props
    const { snackBarMessageInfo, actionSuccess, loading } = this.state
    return (
      <Dialog
        fullWidth
        open={this.props.open}
        onClose={this.props.onClose}
        className={classes.dialog}
      >
        <DialogTitle>Confirm Change in Print Mode</DialogTitle>
        <DialogContentText>
          From: "{toDoTypes[role]}" to: "{toDoAction}" on: "{hostname}" with: "
          {mac_address}" at: "{ip_address}"
        </DialogContentText>
        <IconButton
          aria-label="Close"
          className={classes.icon}
          onClick={this.props.onClose}
        >
          <CloseIcon />
        </IconButton>
        <div>
          {loading ? (
            <Spinner className={classes.spinner} />
          ) : (
            <ListItem className={classes.list}>
              {!actionSuccess ? (
                <React.Fragment>
                  <Button
                    className={classes.submitButton}
                    variant="outlined"
                    onClick={() => this.handleSubmit()}
                  >
                    Confirm
                  </Button>
                  <Button
                    className={classes.cancelButton}
                    variant="outlined"
                    onClick={this.props.onClose}
                  >
                    CANCEL
                  </Button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Button
                    className={classes.successButton}
                    variant="outlined"
                    onClick={this.goBack}
                  >
                    BACK
                  </Button>
                  <Tooltip title="Success" placement="top">
                    <Good className={classes.good} />
                  </Tooltip>
                </React.Fragment>
              )}
            </ListItem>
          )}
        </div>
        <Snackbar
          open={this.state.snackBarOpen}
          autoHideDuration={5000}
          onClose={this.handleClose}
          message={snackBarMessageInfo}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.props.onClose}
            >
              <CloseIcon className={classes.CloseIcon} />
            </IconButton>,
          ]}
        />
      </Dialog>
    )
  }
}

export default withStyles(styles)(
  withRouter(withAnalytics()(withEnv()(SetPrinterMode))),
)
