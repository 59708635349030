import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'

import Devices from '../../images/devices.svg'
import IconButton from '@material-ui/core/IconButton'
import MUIDataTable from 'mui-datatables'
import Paper from '@material-ui/core/Paper'
import React from 'react'
import Spinner from 'nicollet-react/es/components/Spinner/Spinner'
import SvcMacApplications from '../../services/svcMacApplications'
import Tooltip from '@material-ui/core/Tooltip'
import queryString from 'query-string'
import { withAnalytics } from '@praxis/component-analytics'
import { withEnv } from '@praxis/component-runtime-env'
import withRouter from '../withRouter'
import { withStyles } from '@material-ui/styles'

class ApplicationsMac extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
    }
    this.SvcMacApplications = new SvcMacApplications()
    this.submission = {}
    this.resultsFlat = []
  }

  componentDidMount() {
    this.getData()
  }

  getData = () => {
    this.submission = queryString.parse(this.props.router.location.search)
    this.setState({ loading: true })
    this.SvcMacApplications.getApplications(
      this.props.env,
      Object.values(queryString.parse(this.props.router.location.search)),
    )
      .then((response) => {
        const eventData = {
          customMetrics: {
            metric1: 'ApplicationCounts',
            metric3: response.status,
          },
          event: {
            type: `apiSuccessApplicationCounts`,
          },
        }
        this.props.trackEvent(eventData)
        response.data.forEach((state) => {
          state.applications.forEach((applicationName) => {
            applicationName.versions.forEach((versionCount) => {
              this.resultsFlat.push({
                application_name: applicationName.application_name,

                version: versionCount.version,
                count: versionCount.count,
              })
            })
          })
        })
      })
      .catch((error) => {
        const eventData = {
          customMetrics: {
            metric1: 'ApplicationCounts',
            metric3: error.response.status,
          },
          event: {
            type: `apiErrorApplicationCounts`,
          },
        }
        this.props.trackEvent(eventData)
        console.log(error)
        window.location = window.location.origin + '/?no_results=true' //&search_type=' + searchType
      })
      .finally(() => {
        this.setState({
          loading: false,
        })
      })
  }
  render() {
    let title = this.submission.applications
      ? this.submission.applications + ' applications'
      : 'applications'
    return (
      <>
        {this.state.loading ? (
          <Spinner />
        ) : (
          <>
            {this.resultsFlat !== null && (
              <Paper elevation={0}>
                <MuiThemeProvider theme={this.getMuiTheme()}>
                  <MUIDataTable
                    title={title}
                    data={this.resultsFlat}
                    className={this.theme}
                    options={{
                      selectableRows: 'none',
                      rowHover: false,
                      filter: true,
                      print: false,
                      elevation: 4,
                      viewColumns: false,
                      filterType: 'multiselect',
                      rowsPerPage: 50,
                      rowsPerPageOptions: [10, 25, 50, 100, 250],
                      pagination: true,
                      search: true,
                      sort: true,
                      expandableRows: false,
                      expandableRowsHeader: false,
                    }}
                    columns={[
                      {
                        name: 'application_name',
                        label: 'Application Name',
                      },
                      {
                        name: 'version',
                        label: 'Version',
                      },
                      {
                        name: 'count',
                        label: 'Installed Count',
                        options: {
                          filter: false,
                          customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                              <>
                                {value}
                                <Tooltip
                                  title={
                                    'Click to see devices which have ' +
                                    tableMeta.rowData[0] +
                                    ' application of version ' +
                                    tableMeta.rowData[1]
                                  }
                                >
                                  <IconButton
                                    onClick={() => {
                                      window.location =
                                        window.location.origin +
                                        `/devices?classification_name=${this.props.router.location.search.substring(
                                          14,
                                        )}&insights.ci_installed_application.name=${encodeURIComponent(
                                          tableMeta.rowData[0],
                                        )}&insights.ci_installed_application.version=${encodeURIComponent(
                                          tableMeta.rowData[1],
                                        )}`
                                    }}
                                  >
                                    <Devices />
                                  </IconButton>
                                </Tooltip>
                              </>
                            )
                          },
                        },
                      },
                    ]}
                  />
                </MuiThemeProvider>
              </Paper>
            )}
          </>
        )}
      </>
    )
  }
  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTableSelectCell: {
          fixedHeaderCommon: {
            padding: '0px 0px 0px 0px',
            width: 50,
          },
        },
        MUIDataTableBodyCell: {
          root: {
            backgroundColor: '#eeeeee',
            borderRight: '1px dashed white',
            padding: 10,
            paddingLeft: 3,
            paddingRight: 0,
            paddingTop: 8,
            paddingBottom: 8,
            whiteSpace: 'nowrap',
          },
        },
        MUIDataTableHeadCell: {
          data: {
            fontSize: 14,
          },
        },
        MuiTableCell: {
          root: {
            paddingLeft: 4,
            paddingRight: 4,
            paddingTop: 4,
            paddingBottom: 4,
          },
        },
        MUIDataTableBodyRow: {
          root: {
            padding: 10,
            height: 50,
          },
        },
        MUIDataTableToolbar: {
          root: {
            backgroundColor: '#eeeeee',
            height: 10,
          },
          titleText: {
            fontSize: 20,
          },
          left: {
            flex: '100 0 auto',
          },
          filterPaper: {
            width: 500,
          },
        },
      },
    })
}

const styles = (theme) => ({
  honeyComb: {
    minHeight: `calc(100vh - 75px)`,
    backgroundImage:
      `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='24.5' ` +
      `viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%23888888' fill-opacity='0.50' ` +
      `fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 ` +
      `11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 ` +
      `0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
  },
  cell: {
    height: 10,
    verticalAlign: 'top',
    backgroundColor: '#fff',
  },
  row: {
    height: 10,
    verticalAlign: 'top',
    backgroundColor: '#fff',
  },
  listItem: {
    verticalAlign: 'top',
    height: 10,
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 5,
    border: 'none',
  },
  root: {
    verticalAlign: 'top',
    backgroundColor: '#eeeeee',
    padding: 'dense',
  },
})

export default withStyles(styles, { withTheme: true })(
  withRouter(withAnalytics()(withEnv()(ApplicationsMac))),
)
