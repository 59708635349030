import {
  Cell,
  Tooltip as ChartTooltip,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
} from 'recharts'

import Label from '@material-ui/icons/Label'
import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/styles'

const COLORS = [
  '#0097a7',
  '#c2185b',
  '#ffa000',
  '#ffce19',
  '#00796b',
  '#303f9f',
  '#7023b7',
  '#244224',
  '#784d41',
  '#5b5c58',
]

const DynamicPieChart = ({ field, data, onAggClick }) => {
  const getAggs = (data) => {
    const sortedData = Object.keys(data)
      .map((key) => ({ name: key, value: data[key] }))
      .sort((a, b) => b.value - a.value)
    return sortedData.slice(0, 10)
  }

  return (
    <div>
      <ResponsiveContainer width="100%" height={400}>
        <PieChart>
          <ChartTooltip content={CustomUtilTooltipPieChart} />
          <Pie
            data={getAggs(data)}
            cx="50%"
            cy="50%"
            innerRadius="65%"
            outerRadius="100%"
            dataKey="value"
            nameKey="name"
            cursor="pointer"
          >
            {getAggs(data).map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
                onClick={(e) => {
                  onAggClick(field, entry.name)
                }}
              />
            ))}
          </Pie>
          <Legend
            layout="horizontal"
            align="left"
            verticalAlign="bottom"
            content={
              <CustomLegendPieChart
                field={field}
                onAggClick={onAggClick}
                size={Object.keys(data).length}
              />
            }
            wrapperStyle={{
              maxHeight: '100px',
              minHeight: '100px',
              overflowY: 'auto',
              width: '100%',
              textAlign: 'center',
              paddingTop: '50px',
            }}
          />
        </PieChart>
      </ResponsiveContainer>
    </div>
  )
}

const CustomUtilTooltipPieChart = (props) => {
  return (
    props.active && (
      <Typography
        style={{
          display: 'flex',
          alignItems: 'center',
          fontSize: 12,
          fontWeight: 'medium',
          backgroundColor: '#666666',
          color: '#F7F7F7',
          borderRadius: 5,
          padding: '4px 8px',
          width: 'flex',
          height: '50px',
        }}
      >
        <Label
          style={{
            fontSize: 24,
            color: props.payload[0].payload.fill,
            paddingRight: 5,
          }}
        />
        {props.payload[0].payload.displayName !== undefined
          ? props.payload[0].payload.displayName +
            '\n(' +
            props.payload[0].name +
            ')' +
            ': ' +
            props.payload[0].value
          : props.payload[0].payload.name + ': ' + props.payload[0].value}
      </Typography>
    )
  )
}

const CustomLegendPieChart = (props) => {
  let size = props.size
  return props.payload.slice(0, size).map((row, index) => (
    <HtmlTooltip
      key={index}
      title={
        <React.Fragment>
          <Typography>
            click to drill through{' '}
            <span style={{ color: row.payload.fill, fontWeight: 'bold' }}>
              {row.payload.name.toLowerCase()}
            </span>{' '}
            {props.field}
          </Typography>
        </React.Fragment>
      }
    >
      <Typography
        variant="button"
        style={{
          display: 'flex',
          alignItems: 'center',
          fontSize: 14,
          marginRight: 15,
          cursor: 'pointer',
        }}
        onClick={(e) => {
          props.onAggClick(props.field, row.payload.name)
        }}
      >
        <Label
          style={{
            fontSize: 24,
            color: row.payload.fill,
            paddingRight: 5,
          }}
        />
        {(row.payload.name.length + row.payload.value.toString().length < 28
          ? row.payload.name
          : row.payload.name.slice(
              0,
              row.payload.name.length -
                (row.payload.name.length +
                  row.payload.value.toString().length -
                  24),
            ) + '... ') +
          ': ' +
          row.payload.value}
      </Typography>
    </HtmlTooltip>
  ))
}

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#ffffff',
    color: '#333333',
    maxWidth: 200,
    fontSize: 30,
    border: '1px solid #666666',
    borderRadius: 5,
  },
}))(Tooltip)

export default DynamicPieChart
