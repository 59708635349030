import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import FileSaver from 'file-saver'
import IconButton from '@material-ui/core/IconButton'
import PapaParse from 'papaparse'
import React from 'react'
import { withAnalytics } from '@praxis/component-analytics'
import { withEnv } from '@praxis/component-runtime-env'
import { withStyles } from '@material-ui/core/styles'
import svcHandhelds from '../../services/svcHandhelds'
import Spinner from 'nicollet-react/es/components/Spinner/Spinner'
import Snackbar from '@material-ui/core/Snackbar'
import Tooltip from '@material-ui/core/Tooltip'
import Good from '@material-ui/icons/CheckCircleTwoTone'

class CustomCheckOutDownloadDialog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      snackBarOpen: false,
      actionSuccess: false,
    }
    this.svcHandhelds = new svcHandhelds()
  }

  handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    this.setState({ snackBarOpen: false })
  }

  handleSubmit = () => {
    this.svcHandhelds
      .downloadCICOHandheldData(this.props.env)
      .then((response) => {
        this.setState({
          loading: true,
        })
        const dataString = PapaParse.unparse({
          fields: [
            'store_id',
            'device_locker_enabled',
            'device_locker_ring_enabled',
          ],
          data: response.data,
        })
        const blob = new Blob([dataString], {
          type: 'text/csv;charset=utf-8',
        })
        let fileName = 'mickra-checkout-download.csv'
        FileSaver.saveAs(blob, fileName)
        this.setState({
          actionSuccess: true,
          snackBarOpen: true,
          snackBarMessageInfo: 'Download Completed',
        })
      })
      .catch((error) => {
        console.log(error)
        this.setState({
          actionSuccess: false,
          loading: false,
          snackBarMessageInfo: 'CSV File Download Failed',
          snackBarOpen: true,
        })
      })
  }

  render() {
    const { classes, open, onClose } = this.props
    return (
      <Dialog open={open} onClose={onClose} className={classes.dialog}>
        <DialogTitle className={classes.dialog}>Check-Out Status</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Download Electronic CI/CO storewise information
          </DialogContentText>
          {this.state.loading === false ? (
            <Button variant="outlined" onClick={this.handleSubmit}>
              Download
            </Button>
          ) : !this.state.actionSuccess ? (
            <Spinner />
          ) : (
            <React.Fragment>
              <Button
                className={classes.successButton}
                variant="outlined"
                onClick={onClose}
              >
                CLOSE
              </Button>
              <Tooltip title="Success" placement="top">
                <Good className={classes.good} />
              </Tooltip>
            </React.Fragment>
          )}
        </DialogContent>
        <IconButton
          aria-label="Close"
          className={classes.dialogIcon}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        <Snackbar
          open={this.state.snackBarOpen}
          autoHideDuration={5000}
          onClose={this.handleSnackBarClose}
          message={this.state.snackBarMessageInfo}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleSnackBarClose}
            >
              <CloseIcon className={classes.CloseIcon} />
            </IconButton>,
          ]}
        />
      </Dialog>
    )
  }
}

const styles = {
  root: {
    justifyContent: 'space-around',
    marginTop: 5,
  },
  dialogIcon: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
  dialog: {
    textAlign: 'center',
    marginTop: 15,
  },
  good: {
    fontSize: 40,
    color: 'green',
    paddingLeft: 5,
  },
  successButton: {
    float: 'center',
    marginBottom: 25,
  },
  cancelButton: {
    float: 'left',
    marginLeft: 5,
  },
}

export default withStyles(styles)(
  withAnalytics()(withEnv()(CustomCheckOutDownloadDialog)),
)
