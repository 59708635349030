import React from 'react'
import axios from 'axios'
import queryString from 'query-string'

class SvcHandhelds extends React.Component {
  getHandhelds(config, submission) {
    const queryParameters = {
      handhelds: {
        serial_number: null,
        mac_address: null,
        model_name: null,
        operating_system_version: null,
        last_user: null,
        location_id: null,
        id: null,
      },
    }
    let queryParametersVar = queryParameters
    Object.entries(submission).forEach((param) => {
      if (queryParametersVar.handhelds.hasOwnProperty(param[0])) {
        queryParametersVar.handhelds[`${param[0]}`] = param[1]
      }
    })

    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey
    const endpointParams = queryString.stringify(queryParametersVar.handhelds, {
      sort: false,
      skipNull: true,
    })
    const apiUri = encodeURI(
      apiUriBase + `/handhelds?` + endpointParams + `&key=` + apiKey,
    )
    return axios.get(apiUri)
  }
  getHandheldDetail(config, submission) {
    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey
    const apiUri = encodeURI(
      apiUriBase +
        `/handhelds/` +
        submission.id +
        `/` +
        submission.type +
        `?key=` +
        apiKey,
    )
    return axios.get(apiUri)
  }
  getHandheldUtilizationDetails(locationId, config) {
    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey
    const apiUri = encodeURI(
      apiUriBase +
        `/handhelds/utilizations?location_id=` +
        locationId +
        `&key=` +
        apiKey,
    )
    return axios.get(apiUri)
  }
  putHandhheldLabel(deviceId, deviceLabel, config) {
    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey
    const apiUri = encodeURI(
      apiUriBase + `/devices/` + deviceId + `/labels?key=` + apiKey,
    )
    return axios.put(apiUri, {
      label: deviceLabel,
    })
  }
  postDeviceNotify(serialNumber, config) {
    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey
    const apiUri = encodeURI(
      `${apiUriBase}/handhelds/${serialNumber}/notifies?key=${apiKey}`,
    )
    const headers = {
      'Content-Type': 'application/json',
    }
    return axios.post(
      apiUri,
      {
        serial_number: serialNumber,
      },
      headers,
    )
  }
  deleteHandheld(id, config) {
    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey
    const apiUri = encodeURI(apiUriBase + `/handhelds/` + id + `?key=` + apiKey)
    return axios.delete(apiUri)
  }

  postTepEnrollment(props, state) {
    const apiUriBase = props.env.auth.apiUriBase
    const apiKey = props.env.auth.apiKey
    const apiUri = encodeURI(
      apiUriBase + `/enroll_devices/` + state.endpoint_param + `?key=` + apiKey,
    )
    const headers = {
      'Content-Type': 'application/json',
    }
    return axios.post(
      apiUri,
      {
        device_type: state.deviceType,
        email: props.auth.session.identity.mail,
        company: props.auth.session.identity.company_name,
        os_version: state.osVersion,
        manufacturer: state.manufacturer,
      },
      headers,
    )
  }

  quarantineHandheld(id, config) {
    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey
    const apiUri = encodeURI(
      apiUriBase + `/handhelds/` + id + `/quarantine?key=` + apiKey,
    )
    return axios.post(apiUri)
  }

  deleteRepairDevice(id, config) {
    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey
    const apiUri = encodeURI(
      apiUriBase + `/handhelds/` + id + `/repair?key=` + apiKey,
    )
    return axios.post(apiUri)
  }

  offlineHandheldDevice(id, config) {
    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey
    const apiUri = encodeURI(
      apiUriBase + `/handhelds/` + id + `/offline?key=` + apiKey,
    )
    return axios.post(apiUri)
  }

  markTepDeviceAsStolen(id, config) {
    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey
    const apiUri = encodeURI(
      apiUriBase + `/handhelds/` + id + `/stolen?key=` + apiKey,
    )
    return axios.post(apiUri)
  }

  getHandheldDeviceStateHistory(id, config) {
    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey
    const apiUri = encodeURI(
      apiUriBase + `/handhelds/` + id + `/state_history?key=` + apiKey,
    )
    return axios.get(apiUri)
  }

  getHandheldAcornPositions(config, id) {
    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey
    const apiUri = encodeURI(
      apiUriBase + `/handhelds/` + id + `/acorn_positions?key=` + apiKey,
    )
    return axios.get(apiUri)
  }

  updateDeviceOrgGroup(props, id, orgGroup, incidentNumber) {
    const apiUriBase = props.env.auth.apiUriBase
    const apiKey = props.env.auth.apiKey
    const apiUri = encodeURI(
      apiUriBase + `/devices/` + id + `/org_group?key=` + apiKey,
    )
    const headers = {
      'Content-Type': 'application/json',
    }
    return axios.put(
      apiUri,
      {
        new_org_group: orgGroup,
        incident: incidentNumber,
        source: props.auth.session.userInfo.lanId,
      },
      headers,
    )
  }

  downloadCICOHandheldData(config) {
    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey
    const apiUri = encodeURI(
      apiUriBase + `/handhelds/download/checkout_status?key=` + apiKey,
    )
    return axios.get(apiUri)
  }
}
export default SvcHandhelds
