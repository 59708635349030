import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'

import MUIDataTable from 'mui-datatables'
import React from 'react'
import Spinner from 'nicollet-react/es/components/Spinner/Spinner'
import svcEnrollmentStatus from '../../services/svcEnrollmentStatus'
import { withAnalytics } from '@praxis/component-analytics'
import { withEnv } from '@praxis/component-runtime-env'

class EnrollmentStatus extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      enrollmentStatusData: [],
      enrollmentStatusLoading: false,
      uniqueDateValues: [],
    }
    this.svcEnrollmentStatus = new svcEnrollmentStatus()
  }

  componentDidMount() {
    this.setState({
      enrollmentStatusLoading: true,
    })
    this.svcEnrollmentStatus
      .getEnrollmentStatusData(this.props.props.env)
      .then((response) => {
        const eventData = {
          customMetrics: {
            metric1: 'EnrollmentStatusData',
            metric3: response.status,
          },
          event: {
            type: `apiSuccessEnrollmentStatusData`,
          },
        }
        this.props.props.trackEvent(eventData)
        this.setState({ enrollmentStatusData: response.data })
        if (response.data.length > 0) {
          var dates = new Set()
          response.data.forEach((dict) =>
            dates.add(dict['enrollment_timestamp'].substring(0, 10)),
          )
          this.setState({ uniqueDateValues: [...dates] })
        }
      })
      .catch((error, response) => {
        const eventData = {
          customMetrics: {
            metric1: 'EnrollmentStatusData',
            metric3: error.response.status,
          },
          event: {
            type: `apiErrorEnrollmentStatusData`,
          },
        }
        this.props.props.trackEvent(eventData)
        console.log(error)
      })
      .finally(() => {
        this.setState({ enrollmentStatusLoading: false })
      })
  }
  render() {
    const options = {
      sortOrder: {
        name: 'enrollment_timestamp',
        direction: 'desc',
      },
      filterType: 'multiselect',
      selectableRows: 'none',
      rowsPerPage: 50,
      rowsPerPageOptions: [10, 25, 50, 100, 250],
      downloadOptions: {
        filterOptions: {
          useDisplayedColumnsOnly: true,
          useDisplayedRowsOnly: true,
        },
      },
      onSearchOpen: () => {
        const eventData = {
          customMetrics: {
            metric1: 'EnrollmentStatusDataGrid',
            metric3: 'Search Open',
          },
        }
        this.props.props.trackEvent(eventData)
      },
      onDownload: (buildHead, buildBody, columns, data) => {
        const eventData = {
          customMetrics: {
            metric1: 'EnrollmentStatusDataGrid',
            metric3: 'Data Download',
          },
        }
        this.props.props.trackEvent(eventData)
        return buildHead(columns) + buildBody(data)
      },
    }
    return (
      <>
        {this.state.enrollmentStatusLoading ? (
          <Spinner />
        ) : (
          <MuiThemeProvider theme={this.getMuiTheme()}>
            <MUIDataTable
              data={this.state.enrollmentStatusData}
              title="This report displays enrollment data for last 60 days."
              options={options}
              columns={[
                {
                  name: 'device_id',
                  label: 'Device ID',
                  options: {
                    filter: false,
                  },
                },
                {
                  name: 'company_name',
                  label: 'Company Name',
                  options: {
                    filter: true,
                  },
                },
                {
                  name: 'agent_version',
                  label: 'Agent Version',
                  options: {
                    filter: true,
                  },
                },
                {
                  name: 'enrollment_timestamp',
                  label: 'Device Enrolled On',
                  options: {
                    filter: true,
                    filterOptions: {
                      names: this.state.uniqueDateValues,
                      logic: (enrollment_timestamp, filters) => {
                        if (filters.length > 0) {
                          return !filters.includes(
                            enrollment_timestamp.substring(0, 10),
                          )
                        }
                        return true
                      },
                    },
                  },
                },
                {
                  name: 'device_type',
                  label: 'Device Type',
                  options: {
                    filter: true,
                  },
                },
              ]}
            />
          </MuiThemeProvider>
        )}
      </>
    )
  }
  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MuiTypography: {
          h6: {
            fontSize: 16,
          },
        },
        MUIDataTableHeadCell: {
          data: {
            fontSize: 16,
          },
          root: {
            fontSize: '500pt',
          },
        },
        MUIDataTableToolbar: {
          filterPaper: {
            width: 420,
          },
        },
      },
    })
}

export default withEnv()(withAnalytics()(EnrollmentStatus))
