import DeleteBTScanners from '../MenuActionItems/DeleteBTScanners'
import EditBTScannersLabel from '../MenuActionItems/EditBTScannersLabel'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import React from 'react'
import SetBTScannersLostStolen from '../MenuActionItems/SetBTScannersLostStolen'
import SettingsIcon from '@material-ui/icons/Settings'
import Tooltip from '@material-ui/core/Tooltip'
import btscannersDeviceTypes from '../deviceTypes/btscannersDeviceTypes'
import { withAuth } from '@praxis/component-auth'
import { withEnv } from '@praxis/component-runtime-env'

function MenuActionItem(props) {
  return (
    <MenuItem
      onClick={() => {
        props.onClose()
        props.onClick()
      }}
    >
      {props.label}
    </MenuItem>
  )
}

class BTScannersActionMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      anchorEl: null,
      showBTScannersLostStolenDialog: false,
      showBTScannersLabelDialog: false,
      showDeleteBTScannersDialog: false,
      menuDisabled: true,
      toDoAction: '',
    }
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget })
  }
  handleClose = () => {
    this.setState({ anchorEl: null })
  }
  handleMenu = () => {
    this.setState({ menuDisabled: false })
  }
  handleBTScannersLabelActionClick = (event) => {
    this.setState({ showBTScannersLabelDialog: true })
  }
  handleBTScannersLabelDialogClose = (event) => {
    this.setState({ showBTScannersLabelDialog: false })
  }
  handleBTScannersLostStolenClick = (event) => {
    this.setState({ showBTScannersLostStolenDialog: true })
  }
  handleBTScannersLostStolenClose = (event) => {
    this.setState({ showBTScannersLostStolenDialog: false })
  }
  handleDeleteBTScannersActionClick = () => {
    this.setState({ showDeleteBTScannersDialog: true })
  }
  handleDeleteBTScannersDialogClose = (event) => {
    this.setState({ showDeleteBTScannersDialog: false })
  }

  render() {
    const {
      rowData: { device_state },
    } = this.props
    const { anchorEl, menuDisabled } = this.state
    const device_type = 'bluetoothscanner'
    function hasAvailableAction(action) {
      return (
        btscannersDeviceTypes.hasOwnProperty(device_type) &&
        btscannersDeviceTypes[device_type].hasOwnProperty('availableActions') &&
        btscannersDeviceTypes[device_type].availableActions.includes(action)
      )
    }
    return (
      <>
        {!menuDisabled ? (
          <Tooltip title="Available Actions">
            <div>
              <IconButton disabled={false} onClick={this.handleClick}>
                <SettingsIcon />
              </IconButton>
            </div>
          </Tooltip>
        ) : (
          <Tooltip title="No Available Actions">
            <div>
              <IconButton disabled={true} onClick={this.handleClick}>
                <SettingsIcon />
              </IconButton>
            </div>
          </Tooltip>
        )}
        <Menu
          style={{ paddingTop: 0, paddingBottom: 0 }}
          disableAutoFocusItem
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {hasAvailableAction('btscannersLostStolen') &&
            (device_state === 'Online' || device_state === 'Offline') &&
            this.props.env.access.bluetoothScannerLostStolenMove.some((r) =>
              this.props.auth.session.userInfo.memberOf.includes(
                r.toUpperCase(),
              ),
            ) &&
            (menuDisabled && this.handleMenu(),
            (
              <MenuActionItem
                onClick={() => this.handleBTScannersLostStolenClick()}
                label={'Lost/Stolen'}
                onClose={() => {
                  this.handleClose()
                }}
              />
            ))}
          {hasAvailableAction('editBTScannersLabel') &&
            (menuDisabled && this.handleMenu(),
            (
              <MenuActionItem
                onClick={this.handleBTScannersLabelActionClick}
                label={'Edit Bluetooth Scanner Label'}
                onClose={() => {
                  this.handleClose()
                }}
              />
            ))}
          {hasAvailableAction('deleteBTScanners') &&
            this.props.env.access.deleteBTScannerGroup.some((r) =>
              this.props.auth.session.userInfo.memberOf.includes(
                r.toUpperCase(),
              ),
            ) &&
            (menuDisabled && this.handleMenu(),
            (
              <MenuActionItem
                onClick={() => this.handleDeleteBTScannersActionClick()}
                label={'Delete Bluetooth Scanner'}
                onClose={() => {
                  this.handleClose()
                }}
              />
            ))}
        </Menu>
        {this.state.showBTScannersLostStolenDialog && (
          <SetBTScannersLostStolen
            open={this.state.showBTScannersLostStolenDialog}
            onClose={() => {
              this.handleBTScannersLostStolenClose()
            }}
            {...this.props}
          />
        )}
        {this.state.showBTScannersLabelDialog && (
          <EditBTScannersLabel
            open={this.state.showBTScannersLabelDialog}
            onClose={() => {
              this.handleBTScannersLabelDialogClose()
            }}
            {...this.props}
          />
        )}
        {this.state.showDeleteBTScannersDialog && (
          <DeleteBTScanners
            open={this.state.showDeleteBTScannersDialog}
            onClose={() => {
              this.handleDeleteBTScannersDialogClose()
            }}
            {...this.props}
          />
        )}
      </>
    )
  }
}

export default withAuth(null)(withEnv()(BTScannersActionMenu))
