import { ExitToApp, Home, Person } from '@material-ui/icons'
import { ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core'

import Tooltip from '@material-ui/core/Tooltip'
import { redirectHandler } from '../../utilities/RedirectHandler'
import { snakeToFlat } from '../../utilities/SnakeToFlat'
import { useAuth } from '@praxis/component-auth'
import { useUserSettingsContext } from '../../contexts/UserSettingsContext'

export const UserMenu = ({ handleClose, anchorEl }) => {
  const auth = useAuth()
  const userSettings = useUserSettingsContext()
  const { logout } = auth

  if (!userSettings.data.isLoading) {
    return (
      <>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          style={{
            zIndex: '9999',
            top: '40px',
          }}
        >
          {userSettings.data.data.home.type !== null &&
          userSettings.data.data.home.value !== null ? (
            <Tooltip
              arrow
              placement="left"
              title={'Click to go to your saved home'}
            >
              <MenuItem
                divider
                onClick={() =>
                  redirectHandler(
                    userSettings.data.data.home.type,
                    userSettings.data.data.home.value,
                  )
                }
              >
                <ListItemIcon>
                  <Home fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  {snakeToFlat(userSettings.data.data.home.type)}
                  {': '}
                  {userSettings.data.data.home.value}
                </ListItemText>
              </MenuItem>
            </Tooltip>
          ) : (
            <MenuItem divider disabled>
              <ListItemIcon>
                <Home fontSize="small" />
              </ListItemIcon>
              <ListItemText>Not Set</ListItemText>
            </MenuItem>
          )}

          <Tooltip arrow placement="left" title={'Review your MICKRA profile'}>
            <MenuItem onClick={() => redirectHandler('profile', null)}>
              <ListItemIcon>
                <Person fontSize="small" />
              </ListItemIcon>
              <ListItemText>Profile</ListItemText>
            </MenuItem>
          </Tooltip>

          <Tooltip
            arrow
            placement="left"
            title={'Click to Log Out of MICKRA :('}
          >
            <MenuItem onClick={logout}>
              <ListItemIcon>
                <ExitToApp fontSize="small" />
              </ListItemIcon>
              <ListItemText>Logout</ListItemText>
            </MenuItem>
          </Tooltip>
        </Menu>
      </>
    )
  }
}
