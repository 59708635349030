import Bad from '@material-ui/icons/RemoveCircleOutlineTwoTone'
import Badge from '@material-ui/core/Badge'
import BatteryCharging20Icon from '@material-ui/icons/BatteryCharging20Outlined'
import BatteryGoodIcon from '@material-ui/icons/BatteryFullOutlined'
import BatteryNullIcon from '@material-ui/icons/BatteryUnknownOutlined'
import Good from '@material-ui/icons/CheckCircleTwoTone'
import Help from '@material-ui/icons/HelpOutline'
import ListItem from '@material-ui/core/ListItem'
import NotInterested from '@material-ui/icons/NotInterestedOutlined'
import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
// import UtilizationGoodIcon from '@material-ui/icons/AssessmentOutlined'
import handheldDeviceTypes from '../deviceTypes/handheldDeviceTypes'
import { withStyles } from '@material-ui/styles'

class DeviceHealthAirwatch extends React.Component {
  render() {
    const {
      classes,
      rowData: {
        department,
        device_state,
        // utilization,
        // platform,
        battery_status: { level },
        // management_system,
      },
    } = this.props
    return (
      <ListItem className={classes.listItem}>
        {handheldDeviceTypes.hasOwnProperty(department) &&
          handheldDeviceTypes[department].hasOwnProperty(
            'availableHeathItems',
          ) &&
          handheldDeviceTypes[department].availableHeathItems.includes(
            'batteryHealth',
          ) &&
          level === null &&
          (device_state === 'Active' || device_state === 'Online') && (
            <Tooltip title={'Battery Health - Level: Unknown'}>
              <Badge
                badgeContent={<Help className={classes.batteryNull} />}
                className={classes.badge}
              >
                <BatteryNullIcon className={classes.icon} />
              </Badge>
            </Tooltip>
          )}
        {handheldDeviceTypes.hasOwnProperty(department) &&
          handheldDeviceTypes[department].hasOwnProperty(
            'availableHeathItems',
          ) &&
          handheldDeviceTypes[department].availableHeathItems.includes(
            'batteryHealth',
          ) &&
          level !== null &&
          level < 20 &&
          (device_state === 'Active' || device_state === 'Online') && (
            <Tooltip title={'Battery Health - Level: ' + level + '%'}>
              <Badge
                badgeContent={<Bad className={classes.bad} />}
                className={classes.badge}
              >
                <BatteryCharging20Icon className={classes.icon} />
              </Badge>
            </Tooltip>
          )}
        {handheldDeviceTypes.hasOwnProperty(department) &&
          handheldDeviceTypes[department].hasOwnProperty(
            'availableHeathItems',
          ) &&
          handheldDeviceTypes[department].availableHeathItems.includes(
            'batteryHealth',
          ) &&
          (device_state === 'Missing' ||
            device_state === 'Offline' ||
            device_state === 'Lost') && (
            <Tooltip title={'Battery Health N/A for Offline/Lost Device'}>
              <Badge
                badgeContent={<NotInterested className={classes.batteryNull} />}
                className={classes.badge}
              >
                <BatteryNullIcon className={classes.icon} />
              </Badge>
            </Tooltip>
          )}
        {handheldDeviceTypes.hasOwnProperty(department) &&
          handheldDeviceTypes[department].hasOwnProperty(
            'availableHeathItems',
          ) &&
          handheldDeviceTypes[department].availableHeathItems.includes(
            'batteryHealth',
          ) &&
          level >= 20 &&
          (device_state === 'Active' || device_state === 'Online') && (
            <Tooltip title={'Battery Health - Level: ' + level + '%'}>
              <Badge
                badgeContent={<Good className={classes.good} />}
                className={classes.badge}
              >
                <BatteryGoodIcon className={classes.icon} />
              </Badge>
            </Tooltip>
          )}
        {/* add action item here */}
        {/* {deviceTypes.hasOwnProperty(department) &&
        deviceTypes[department].hasOwnProperty('availableHeathItems') &&
        deviceTypes[department].availableHeathItems.includes(
          'utilizationHealth'
        ) &&
        platform === 'Android' &&
        (device_state === 'Active' || device_state === 'Online') ? (
          utilization === 0 ? (
            <Tooltip
              title={'Utilization Health - Utilized: ' + utilization + '%'}
            >
              <Badge
                badgeContent={<Good className={classes.utilizationNull} />}
                className={classes.badge}
              >
                <UtilizationGoodIcon className={classes.icon} />
              </Badge>
            </Tooltip>
          ) : (
            <Tooltip
              title={'Utilization Health - Utilized: ' + utilization + '%'}
            >
              <Badge
                badgeContent={<Good className={classes.good} />}
                className={classes.badge}
              >
                <UtilizationGoodIcon className={classes.icon} />
              </Badge>
            </Tooltip>
          )
        ) : (
          <Tooltip
            title={'Utilization Health N/A for Offline or Apple Devices'}
          >
            <Badge
              badgeContent={<Good className={classes.utilizationNull} />}
              className={classes.badge}
            >
              <UtilizationGoodIcon className={classes.icon} />
            </Badge>
          </Tooltip>
        )} */}
      </ListItem>
    )
  }
}

const styles = (theme) => ({
  margin: {
    margin: theme.spacing(2),
  },
  error: {
    fontSize: 16,
    color: 'red',
  },
  good: {
    fontSize: 16,
    color: 'green',
  },
  batteryNull: {
    fontSize: 16,
    color: 'grey',
  },
  utilizationNull: {
    fontSize: 16,
    color: 'grey',
  },
  bad: {
    fontSize: 16,
    color: 'red',
  },
  warning: {
    fontSize: 16,
    fill: 'yellow',
    stroke: 'black',
    strokeWidth: '1',
  },
  badge: {
    margin: 4,
  },
  icon: {
    color: '#757575',
    fontSize: 16,
  },
  listItem: {
    paddingLeft: 0,
    paddingRight: 0,
  },
})

export default withStyles(styles, { withTheme: true })(DeviceHealthAirwatch)
