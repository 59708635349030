import Acorn from '../../../images/acorn_logo.svg'
import Divider from '@material-ui/core/Divider'
import React from 'react'
import SvcDevices from '../../../services/svcDevices'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import { withStyles } from '@material-ui/styles'

class TableDataTabletDetails extends React.Component {
  constructor(props) {
    super(props)
    this.svcDevices = new SvcDevices()
  }
  render() {
    const { classes, tabletDetails } = this.props
    const acorn_link = this.svcDevices.generateAcornLogLink(
      tabletDetails.serial_number,
    )
    const BatteryPluggedStateEnum = {
      0: 'BATTERY_PLUGGED_NONE',
      1: 'BATTERY_PLUGGED_AC',
      2: 'BATTERY_PLUGGED_USB',
      4: 'BATTERY_PLUGGED_WIRELESS',
    }
    return typeof tabletDetails !== 'undefined' ? (
      <>
        <Typography variant="h6" id="tableTitle">
          General
        </Typography>
        <Table className={classes.root}>
          <TableHead>
            <TableRow className={classes.cell}>
              <TableCell className={classes.cell}>Mac Address</TableCell>
              <TableCell className={classes.cell}>IP address</TableCell>
              <TableCell className={classes.cell}>OS Version</TableCell>
              <TableCell className={classes.cell}>Cert Expiration</TableCell>
              <TableCell className={classes.cell}>Platform</TableCell>
              <TableCell className={classes.cell}>Agent Version</TableCell>
              <TableCell className={classes.cell}>Build Number</TableCell>
              {tabletDetails.manufacturer_name &&
                tabletDetails.manufacturer_name !== 'Elo Touch Solutions' && (
                  <>
                    <TableCell className={classes.cell}>Last User</TableCell>
                    <TableCell className={classes.cell}>
                      Last User Type
                    </TableCell>
                    <TableCell className={classes.cell}>
                      Last User Event
                    </TableCell>
                  </>
                )}
              <TableCell className={classes.cell}>Logs</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={classes.cell}>
              <TableCell className={classes.cell}>
                {tabletDetails.mac_address && tabletDetails.mac_address}
              </TableCell>
              <TableCell className={classes.cell}>
                {tabletDetails.wifi_ip_address
                  ? tabletDetails.wifi_ip_address
                  : 'Not Available'}
              </TableCell>
              <TableCell className={classes.cell}>
                {tabletDetails.operating_system_version &&
                  tabletDetails.operating_system_version}
              </TableCell>
              <TableCell className={classes.cell}>
                {tabletDetails.certificate_expiration &&
                  tabletDetails.certificate_expiration}
              </TableCell>
              <TableCell className={classes.cell}>
                {tabletDetails.platform && tabletDetails.platform}
              </TableCell>
              <TableCell className={classes.cell}>
                {tabletDetails.agent_version && tabletDetails.agent_version}
              </TableCell>
              <TableCell className={classes.cell}>
                {tabletDetails.build_number && tabletDetails.build_number}
              </TableCell>
              {tabletDetails.manufacturer_name &&
                tabletDetails.manufacturer_name !== 'Elo Touch Solutions' && (
                  <>
                    <TableCell className={classes.cell}>
                      {tabletDetails.last_user.employee_id
                        ? tabletDetails.last_user.lan_id
                          ? tabletDetails.last_user.first_name +
                            ' ' +
                            tabletDetails.last_user.last_name +
                            ' - ' +
                            tabletDetails.last_user.lan_id +
                            ' (' +
                            tabletDetails.last_user.employee_id +
                            ')'
                          : tabletDetails.last_user.employee_id
                        : 'No Existing Record'}
                    </TableCell>
                    <TableCell>
                      {tabletDetails.last_user.employee_type
                        ? tabletDetails.last_user.employee_type +
                          ', ' +
                          tabletDetails.last_user.company
                        : 'No Existing Record'}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {tabletDetails.last_event}
                    </TableCell>
                  </>
                )}
              <TableCell className={classes.cell}>
                <a href={acorn_link} target="_blank" rel="noreferrer">
                  <Acorn />
                </a>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        {tabletDetails.manufacturer_name &&
          tabletDetails.manufacturer_name !== 'Elo Touch Solutions' && (
            <>
              <Divider style={{ backgroundColor: 'black' }} />
              <Typography variant="h6" id="tableTitle">
                Battery
              </Typography>
              <Table className={classes.root}>
                <TableHead>
                  <TableRow className={classes.cell}>
                    <TableCell className={classes.cell}>
                      Battery Level
                    </TableCell>
                    <TableCell className={classes.cell}>
                      Battery Health
                    </TableCell>
                    <TableCell className={classes.cell}>
                      Present\Rated Capacity
                    </TableCell>
                    <TableCell className={classes.cell}>Plugged</TableCell>
                    <TableCell className={classes.cell}>
                      Serial Number
                    </TableCell>
                    <TableCell className={classes.cell}>Part Number</TableCell>
                    <TableCell className={classes.cell}>
                      Manufactured Date
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow className={classes.cell}>
                    <TableCell className={classes.cell}>
                      {tabletDetails.battery_status &&
                      tabletDetails.battery_status.level &&
                      tabletDetails.battery_status.level &&
                      tabletDetails.device_state !== 'Lost'
                        ? tabletDetails.battery_status.level
                        : 'BATTERY_LEVEL_UNKNOWN'}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {tabletDetails.battery_status &&
                      tabletDetails.battery_status.battery_health_string &&
                      tabletDetails.battery_status.battery_health_string &&
                      tabletDetails.device_state !== 'Lost'
                        ? tabletDetails.battery_status.battery_health_string
                        : 'BATTERY_HEALTH_UNKNOWN'}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {tabletDetails.battery_status &&
                        tabletDetails.battery_status.present_capacity &&
                        tabletDetails.battery_status.present_capacity +
                          ' \\ ' +
                          tabletDetails.battery_status.rated_capacity}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {tabletDetails.battery_status &&
                        tabletDetails.battery_status.plugged &&
                        BatteryPluggedStateEnum[
                          tabletDetails.battery_status.plugged
                        ]}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {tabletDetails.battery_status &&
                        tabletDetails.battery_status.serial_number &&
                        tabletDetails.battery_status.serial_number}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {tabletDetails.battery_status &&
                        tabletDetails.battery_status.part_number &&
                        tabletDetails.battery_status.part_number}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {tabletDetails.battery_status &&
                        tabletDetails.battery_status.mfd &&
                        moment(tabletDetails.battery_status.mfd).format('LL')}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </>
          )}
        <Divider style={{ backgroundColor: 'black' }} />
        <Typography variant="h6" id="tableTitle">
          Bluetooth
        </Typography>
        <Table className={classes.root}>
          <TableHead>
            <TableRow className={classes.cell}>
              <TableCell className={classes.cell}>Is Discovering</TableCell>
              <TableCell className={classes.cell}>Is Enabled</TableCell>
              <TableCell className={classes.cell}>Mac Address</TableCell>
              <TableCell className={classes.cell}>Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={classes.cell}>
              <TableCell className={classes.cell}>
                {tabletDetails.bluetooth_status &&
                  tabletDetails.bluetooth_status.is_discovering}
              </TableCell>
              <TableCell className={classes.cell}>
                {tabletDetails.bluetooth_status &&
                  tabletDetails.bluetooth_status.is_enabled}
              </TableCell>
              <TableCell className={classes.cell}>
                {tabletDetails.bluetooth_status &&
                  tabletDetails.bluetooth_status.mac_address}
              </TableCell>
              <TableCell className={classes.cell}>
                {tabletDetails.bluetooth_status &&
                  tabletDetails.bluetooth_status.name}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        {tabletDetails.manufacturer_name &&
          tabletDetails.manufacturer_name !== 'Elo Touch Solutions' && (
            <>
              <Divider style={{ backgroundColor: 'black' }} />
              <Typography variant="h6" id="tableTitle">
                Bluetooth Pairing
              </Typography>
              <Table className={classes.root}>
                <TableHead>
                  <TableRow className={classes.cell}>
                    <TableCell className={classes.cell}>
                      Paired Device Name
                    </TableCell>
                    <TableCell className={classes.cell}>
                      Paired Device Mac Address
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tabletDetails.bluetooth_status &&
                    tabletDetails.bluetooth_status.paired_devices &&
                    tabletDetails.bluetooth_status.paired_devices.map(
                      (row, index) => {
                        return (
                          <TableRow className={classes.cell} key={index}>
                            <TableCell className={classes.listItem}>
                              {row.name && row.name}
                            </TableCell>
                            <TableCell className={classes.listItem}>
                              {row.mac_address && row.mac_address}
                            </TableCell>
                          </TableRow>
                        )
                      },
                    )}
                </TableBody>
              </Table>
            </>
          )}
      </>
    ) : (
      <div>Note, additional details are not available for this device.</div>
    )
  }
}

const styles = (theme) => ({
  root: {
    backgroundColor: '#eeeeee',
    padding: 'dense',
    fontSize: '40pt',
    verticalAlign: 'top',
  },
  cell: {
    height: 10,
    verticalAlign: 'top',
  },
  honeyComb: {
    minHeight: `calc(100vh - 75px)`,
    backgroundImage:
      `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='24.5' ` +
      `viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%23888888' fill-opacity='0.50' ` +
      `fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 ` +
      `11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 ` +
      `0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
  },
  listItem: {
    verticalAlign: 'top',
    height: 10,
    border: 'none',
  },
})

export default withStyles(styles, { withTheme: true })(TableDataTabletDetails)
