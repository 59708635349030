import Divider from '@material-ui/core/Divider'
import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/styles'

class TableDataI10iCore extends React.Component {
  render() {
    const { classes, i10iDetails } = this.props
    return typeof i10iDetails !== 'undefined' ? (
      <>
        <Typography variant="h6" id="tableTitle">
          Network Information
        </Typography>
        <Table className={classes.root}>
          <TableHead>
            <TableRow className={classes.cell}>
              <TableCell className={classes.cell}>Type</TableCell>
              <TableCell className={classes.cell}>IP Address</TableCell>
              <TableCell className={classes.cell}>MAC Address</TableCell>
              <TableCell className={classes.cell}>Interface Name</TableCell>
              <TableCell className={classes.cell}>Usage</TableCell>
              <TableCell className={classes.cell}>Provider ID</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {i10iDetails.network.map((value, index) => {
              return (
                <TableRow className={classes.cell} key={index}>
                  <TableCell className={classes.cell}>{value.type}</TableCell>
                  <TableCell className={classes.cell}>
                    {value.layer3addr}
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {value.layer2addr}
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {value.interface_name}
                  </TableCell>
                  <TableCell className={classes.cell}>{value.usage}</TableCell>
                  <TableCell className={classes.cell}>
                    {value.provider_id}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <Divider style={{ backgroundColor: 'black' }} />
        <Typography variant="h6" id="tableTitle">
          Power Information
        </Typography>
        <Table className={classes.root}>
          <TableHead>
            <TableRow className={classes.cell}>
              <TableCell className={classes.cell}>Type</TableCell>
              <TableCell className={classes.cell}>Source ID</TableCell>
              <TableCell className={classes.cell}>Outlet</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {i10iDetails.power.map((value, index) => {
              return (
                <TableRow className={classes.cell} key={index}>
                  <TableCell className={classes.cell}>{value.type}</TableCell>
                  <TableCell className={classes.cell}>
                    {value.source_id}
                  </TableCell>
                  <TableCell className={classes.cell}>{value.outlet}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <Divider style={{ backgroundColor: 'black' }} />
        <Typography variant="h6" id="tableTitle">
          Software Information
        </Typography>
        <Table className={classes.root}>
          <TableHead>
            <TableRow className={classes.cell}>
              <TableCell className={classes.cell}>Name</TableCell>
              <TableCell className={classes.cell}>Version</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {i10iDetails.compute_system_software.map((value, index) => {
              return (
                <TableRow className={classes.cell} key={index}>
                  <TableCell className={classes.cell}>
                    {value.software_name}
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {value.software_version}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <Divider style={{ backgroundColor: 'black' }} />
        <Typography variant="h6" id="tableTitle">
          Hardware Information
        </Typography>
        <Table className={classes.root}>
          <TableHead>
            <TableRow className={classes.cell}>
              <TableCell className={classes.cell}>CPU Manufacturer</TableCell>
              <TableCell className={classes.cell}>CPU Model</TableCell>
              <TableCell className={classes.cell}>CPU Sockets</TableCell>
              <TableCell className={classes.cell}>Memory Size</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={classes.cell}>
              <TableCell className={classes.cell}>
                {i10iDetails.hardware.firmware.cpu_manufacturer}
              </TableCell>
              <TableCell className={classes.cell}>
                {i10iDetails.hardware.firmware.cpu_model}
              </TableCell>
              <TableCell className={classes.cell}>
                {i10iDetails.hardware.firmware.cpu_sockets}
              </TableCell>
              <TableCell className={classes.cell}>
                {i10iDetails.hardware.firmware.memory_size}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Table className={classes.root}>
          <TableHead>
            <TableRow className={classes.cell}>
              <TableCell className={classes.cell}>Version</TableCell>
              <TableCell className={classes.cell}>Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {i10iDetails.hardware.firmware.map((value, index) => {
              return (
                <TableRow className={classes.cell} key={index}>
                  <TableCell className={classes.cell}>
                    {value.version}
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {value.description}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <Table className={classes.root}>
          <TableHead>
            <TableRow className={classes.cell}>
              <TableCell className={classes.cell}>Model</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {i10iDetails.hardware.memory_slot.map((value, index) => {
              return (
                <TableRow className={classes.cell} key={index}>
                  <TableCell className={classes.cell}>
                    {value.memory_model}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <Table className={classes.root}>
          <TableHead>
            <TableRow className={classes.cell}>
              <TableCell className={classes.cell}>Card</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {i10iDetails.hardware.pci_slot.map((value, index) => {
              return (
                <TableRow className={classes.cell} key={index}>
                  <TableCell className={classes.cell}>{value.card}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <Divider style={{ backgroundColor: 'black' }} />
        <Typography variant="h6" id="tableTitle">
          Location Information
        </Typography>
        <Table className={classes.root}>
          <TableHead>
            <TableRow className={classes.cell}>
              <TableCell className={classes.cell}>Grid X</TableCell>
              <TableCell className={classes.cell}>Grid Y</TableCell>
              <TableCell className={classes.cell}>Grid Z</TableCell>
              <TableCell className={classes.cell}>Rack ID</TableCell>
              <TableCell className={classes.cell}>RU Start</TableCell>
              <TableCell className={classes.cell}>RU End</TableCell>
              <TableCell className={classes.cell}>Floor ID</TableCell>
              <TableCell className={classes.cell}>Room</TableCell>
              <TableCell className={classes.cell}>Outdoor Grid X</TableCell>
              <TableCell className={classes.cell}>Outdoor Grid Y</TableCell>
              <TableCell className={classes.cell}>Outdoor Grid Z</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={classes.cell}>
              <TableCell className={classes.cell}>
                {i10iDetails.location.gridx}
              </TableCell>
              <TableCell className={classes.cell}>
                {i10iDetails.location.gridy}
              </TableCell>
              <TableCell className={classes.cell}>
                {i10iDetails.location.gridz}
              </TableCell>
              <TableCell className={classes.cell}>
                {i10iDetails.location.rackid}
              </TableCell>
              <TableCell className={classes.cell}>
                {i10iDetails.location.ru_start}
              </TableCell>
              <TableCell className={classes.cell}>
                {i10iDetails.location.ru_end}
              </TableCell>
              <TableCell className={classes.cell}>
                {i10iDetails.location.floor_id}
              </TableCell>
              <TableCell className={classes.cell}>
                {i10iDetails.location.room}
              </TableCell>
              <TableCell className={classes.cell}>
                {i10iDetails.location.outdoor_gridx}
              </TableCell>
              <TableCell className={classes.cell}>
                {i10iDetails.location.outdoor_gridy}
              </TableCell>
              <TableCell className={classes.cell}>
                {i10iDetails.location.outdoor_gridz}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Divider style={{ backgroundColor: 'black' }} />
        <Typography variant="h6" id="tableTitle">
          State Engine
        </Typography>
        <Table className={classes.root}>
          <TableHead>
            <TableRow className={classes.cell}>
              <TableCell className={classes.cell}>Actor</TableCell>
              <TableCell className={classes.cell}>Event Time</TableCell>
              <TableCell className={classes.cell}>Operational State</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={classes.cell}>
              <TableCell className={classes.cell}>
                {i10iDetails.state_engine.actor}
              </TableCell>
              <TableCell className={classes.cell}>
                {i10iDetails.state_engine.event_time}
              </TableCell>
              <TableCell className={classes.cell}>
                {i10iDetails.state_engine.operational_state}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Divider style={{ backgroundColor: 'black' }} />
      </>
    ) : (
      <div>Note, additional details are not available for this device.</div>
    )
  }
}

const styles = (theme) => ({
  root: {
    backgroundColor: '#eeeeee',
    padding: 'dense',
    fontSize: '40pt',
    verticalAlign: 'top',
  },
  cell: {
    height: 10,
    verticalAlign: 'top',
  },
  honeyComb: {
    minHeight: `calc(100vh - 75px)`,
    backgroundImage:
      `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='24.5' ` +
      `viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%23888888' fill-opacity='0.50' ` +
      `fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 ` +
      `11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 ` +
      `0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
  },
  listItem: {
    verticalAlign: 'top',
    height: 10,
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 5,
    border: 'none',
  },
})

export default withStyles(styles)(TableDataI10iCore)
