import {
  Bar,
  BarChart,
  CartesianGrid,
  Tooltip as ChartTooltip,
  Legend,
  Line,
  LineChart,
  XAxis,
  YAxis,
} from 'recharts'
import { Divider, Tab, TableBody, Tabs } from '@material-ui/core'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'

import { Button } from 'nicollet-react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import CloseIcon from '@material-ui/icons/Close'
import DateFnsUtils from '@date-io/date-fns'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import Label from '@material-ui/icons/Label'
import MUIDataTable from 'mui-datatables'
import React from 'react'
import SearchIcon from '@material-ui/icons/Search'
import Spinner from 'nicollet-react/es/components/Spinner/Spinner'
import Table from '@material-ui/core/Table'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Tooltip from '@material-ui/core/Tooltip'
import TopPanelDC from './TopPanelDC'
import TopPanelMobileHelp from './TopPanelHelp/TopPanelMobileHelp'
import Typography from '@material-ui/core/Typography'
import { fade } from '@material-ui/core/styles/colorManipulator'
import moment from 'moment'
import svcDevices from '../../../services/svcDevices'
import { withAnalytics } from '@praxis/component-analytics'
import { withStyles } from '@material-ui/core/styles'

function CustomTooltip() {
  return null
}

function LostStolenChartDialog({ open, onClose, storeLostStolenHistory }) {
  return (
    <React.Fragment>
      <Dialog fullWidth maxWidth="xl" open={open} onClose={onClose}>
        <DialogTitle>Lost & Stolen Details </DialogTitle>
        <IconButton
          aria-label="Close"
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        <MUIDataTable
          data={storeLostStolenHistory}
          options={{
            selectableRows: 'none',
            elevation: 0,
            print: false,
            pagination: true,
            rowsPerPage: 20,
            rowsPerPageOptions: [10, 20, 50],
            sortOrder: {
              name: 'lost_event_timestamp',
              direction: 'desc',
            },
            downloadOptions: {
              filterOptions: {
                useDisplayedColumnsOnly: true,
                useDisplayedRowsOnly: true,
              },
            },
          }}
          columns={[
            {
              name: 'serial_number',
              label: 'Serial Number',
            },
            {
              name: 'model',
              label: 'Model',
              options: {
                setCellProps: () => ({
                  style: {
                    maxWidth: '250px',
                  },
                }),
              },
            },
            {
              name: 'manufacturer',
              label: 'Manufacturer',
            },
            {
              name: 'device_type',
              label: 'Device Type',
            },
            {
              name: 'last_user',
              label: 'Last User',
              options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                  if (value.employee_id.length !== 0) {
                    const lastuser =
                      value.first_name +
                      ' ' +
                      value.last_name +
                      ' (' +
                      value.employee_id +
                      ')'
                    return lastuser
                  } else {
                    return null
                  }
                },
              },
            },
            {
              name: 'lost_user',
              label: 'User Marked Lost',
              options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                  if (
                    value &&
                    value.employee_id &&
                    value.employee_id.length !== 0
                  ) {
                    const lostuser =
                      value.first_name +
                      ' ' +
                      value.last_name +
                      ' (' +
                      value.employee_id +
                      ')'
                    return lostuser
                  } else {
                    return null
                  }
                },
              },
            },
            {
              name: 'updated_on',
              label: 'Updated On',
              options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                  return moment(value).format('llll')
                },
              },
            },
            {
              name: 'lost_event_timestamp',
              label: 'Date Marked Lost',
              options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                  return value != null ? moment(value).format('llll') : null
                },
              },
            },
            {
              name: 'state',
              label: 'Device State',
            },
          ]}
        />
      </Dialog>
    </React.Fragment>
  )
}

function UtilizationChartDialog({ open, onClose, storeUtilization, tabvalue }) {
  return (
    <React.Fragment>
      <Dialog fullWidth open={open} onClose={onClose}>
        <DialogTitle>
          {tabvalue === 0
            ? 'myDevice Utilization Details'
            : 'myCheckout Utilization Details'}{' '}
        </DialogTitle>
        <IconButton
          aria-label="Close"
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        <MUIDataTable
          data={storeUtilization}
          options={{
            selectableRows: 'none',
            elevation: 0,
            print: false,
            pagination: true,
          }}
          columns={[
            {
              name: 'date',
              label: 'Date',
            },
            {
              name: 'total_devices',
              label: 'Total Devices',
            },
            {
              name: 'max_utilized',
              label: 'Max Utilized',
            },
            {
              name: 'max_utilized_percentage',
              label: 'Max Utilized Percentage',
            },
          ]}
        />
      </Dialog>
    </React.Fragment>
  )
}

const styles = {
  paper: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    paddingLeft: '0',
    minWidth: '400',
  },
  dialog: {
    width: '100%',
    maxHeight: 435,
  },
  tableBorder: {
    borderBottom: 0,
    padding: '4px 4px 4px 4px',
  },
  cardContent: {
    paddingBottom: 0,
    paddingTop: 0,
    maxHeight: '155px',
    overflow: 'auto',
    marginBottom: 5,
  },
  cardContentLine: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  cardStyle: {
    textAlign: 'center',
    paddingRight: 0,
  },
  cardStyleUtil: {
    textAlign: 'center',
    paddingRight: 0,
    height: '305px',
    width: '450px',
  },
  listItemHeader: {
    textAlign: 'center',
    paddingLeft: 2,
    paddingRight: 2,
    paddingTop: 0,
    paddingBottom: 5,
  },
  listItem: {
    color: '#B85300',
    textAlign: 'center',
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
  listItem1: {
    color: 'white',
    textAlign: 'center',
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: 15,
    fontWeight: 500,
  },
  tableRow: {
    display: 'flex',
    height: 285,
    alignContent: 'center',
    justifyContent: 'space-evenly',
  },
  cell: {
    height: 10,
    padding: 'dense',
  },
  root: {
    padding: 'dense',
  },
  date: {
    marginLeft: 10,
    marginTop: -3,
    width: 170,
    color: 'blue',
  },
  searchTextbar: {
    paddingTop: 31.5,
    paddingBottom: 30,
    width: 50,
    borderBottom: '1px black',
  },
  submitButton: {
    marginTop: -5,
  },
  tab: {
    borderRight: '1px solid #666666',
    borderLeft: '1px solid #666666',
    borderTop: '1px solid #666666',
    borderBottom: '1px solid #666666',
    borderRadius: '5px 5px 0px 0px',
  },
  customTab: {
    minWidth: 'auto',
  },
  tabs: {
    backgroundColor: '#999999',
  },
  tabsProps: {
    backgroundColor: `${fade('#fff', 0.1)}`,
    borderBottom: '1px solid white',
  },
}

class TopPanel extends React.Component {
  _isMounted = false
  constructor(props) {
    super(props)
    this.state = {
      lostChartOpen: false,
      utilizationChartOpen: false,
      util: [],
      storeUtilization: [],
      lostStolenHistory: [],
      selected: 'location_id',
      searchText: this.props.location_id,
      startDate: moment(new Date(Date.now() - 1 * 24 * 60 * 60 * 1000)).format(
        'MM/DD/YYYY',
      ),
      endDate: moment(new Date(Date.now())).format('MM/DD/YYYY'),
      tabValue: 0,
    }
    this.svcDevices = new svcDevices()
    this.storeLocalMissingDevices = this.props.storeMissingDevices
    this.storeLocalBatteryIssues = this.props.storeBatteryIssues
    this.storeDeviceLockerdevices = this.props.storeDeviceLockerdevices
    this.storeDirectBootModeDevices = this.props.storeDirectBootModeDevices
    this.storeMPTCStatus = this.props.storeMPTCStatus
    this.filteredMyCheckout = this.props.filteredMyCheckout
    this.handleChartClick = this.handleChartClick.bind(this)
    this.lostStolenHistoryCounted = false
    this.handleOnClickTableCell = this.handleOnClickTableCell.bind(this)
    this.handleOnClickDeviceState = this.handleOnClickDeviceState.bind(this)
    this.handleOnClickDeviceModel = this.handleOnClickDeviceModel.bind(this)
    this.handleOnClickDeviceType = this.handleOnClickDeviceType.bind(this)
  }

  handleOnClickTableCell(key) {
    const eventData = {
      customInteraction: {
        key: `ElectronicCheckOutStatus`,
      },
    }
    this.props.trackEvent(eventData)
    const { onChangeValue } = this.props
    onChangeValue(key)
  }

  handleOnClickDBMode() {
    const { onDirectBootModeValue } = this.props
    onDirectBootModeValue()
  }

  handleOnClickmPTCDisconnected() {
    const { onMPTCDisconnectedClickHandler } = this.props
    onMPTCDisconnectedClickHandler()
  }

  handleOnClickDeviceState(state) {
    this.props.onFilterDeviceStateValue(state)
  }

  handleOnClickDeviceModel(model) {
    this.props.onFilterDeviceModelClickHandler(model.toUpperCase())
  }

  handleOnClickDeviceType(type) {
    this.props.onFilterDeviceTypeClickHandler(type)
  }

  handleChartClick(chart) {
    if (chart === 'lost') {
      this.setState({ lostChartOpen: !this.state.lostChartOpen })
      const eventData = {
        customInteraction: {
          key: `LostStolenWidgetHandheld`,
        },
      }
      this.props.trackEvent(eventData)
    } else if (chart === 'util') {
      this.setState({ utilizationChartOpen: !this.state.utilizationChartOpen })
      const eventData = {
        customInteraction: {
          key: `UtilizationWidgetLocation`,
        },
      }
      this.props.trackEvent(eventData)
    }
  }

  handleStartDate = (date) => {
    this.setState({
      startDate: moment(date).format('MM/DD/YYYY'),
    })
  }
  handleEndDate = (date) => {
    this.setState({
      endDate: moment(date).format('MM/DD/YYYY'),
    })
  }

  componentDidMount() {
    this.getUtilizationData()
  }

  getUtilizationData() {
    this._isMounted = true
    this.setState({ deviceCountsLoading: true })
    if (this.state.tabValue === 0) {
      this.svcDevices
        .getmyDeviceUtilization(this.props, this.state)
        .then((response) => {
          this.setState({
            storeUtilization: response.data.max_utilizations,
          })
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this._isMounted && this.setState({ deviceCountsLoading: false })
        })
    } else if (this.state.tabValue === 1) {
      this.svcDevices
        .getmyCheckoutUtilization(this.props, this.state)
        .then((response) => {
          this.setState({
            storeUtilization: response.data.max_utilizations,
          })
        })
        .catch((error, response) => {
          console.log(error)
        })
        .finally(() => {
          this._isMounted && this.setState({ deviceCountsLoading: false })
        })
    } else if (this.state.tabValue === 2) {
      this.svcDevices
        .getKioskUtilization(this.props, this.state)
        .then((response) => {
          this.setState({
            storeUtilization: response.data.max_utilizations,
          })
        })
        .catch((error, response) => {
          console.log(error)
        })
        .finally(() => {
          this._isMounted && this.setState({ deviceCountsLoading: false })
        })
    }
  }

  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTableBodyCell: {
          root: {
            backgroundColor: '#eeeeee',
            padding: 1,
            paddingLeft: 9,
          },
        },
        MUIDataTableHeadCell: {
          root: {
            backgroundColor: '#eeeeee',
            padding: 0,
            paddingLeft: 18,
          },
        },
        MUIDataTableHeadRow: {
          root: {
            height: 10,
          },
        },
        MUIDataTableBodyRow: {
          root: {
            backgroundColor: '#eeeeee',
            height: 10,
          },
        },
        MUIDataTableToolbar: {
          root: {
            height: 10,
          },
          titleText: {
            fontSize: 14,
          },
          left: {
            flex: '100 0 auto',
          },
        },
        MuiPaper: {
          root: {
            minWidth: '400',
            paddingLeft: '0',
          },
        },
        MuiTab: {
          root: { '&$selected': { backgroundColor: '#fff' } },
        },
      },
    })

  handleChangeTab = (event, tabValue) => {
    this.setState({ tabValue }, () => {
      this.getUtilizationData()
    })
  }

  render() {
    let tempCount = 0
    let devStatus = new Map([])
    let directBootModeCount = 0
    let mPTCDisconnectedCount = 0

    if (this.storeDeviceLockerdevices) {
      Object.entries(this.storeDeviceLockerdevices).map((row, index) => {
        if (
          row[1].device_state === 'Online' ||
          row[1].device_state === 'Offline' ||
          row[1].device_state === 'Bonded'
        ) {
          if (devStatus.has(row[1].device_event_state)) {
            tempCount = devStatus.get(row[1].device_event_state) + 1
            devStatus.set(row[1].device_event_state, tempCount)
          } else {
            devStatus.set(row[1].device_event_state, 1)
          }
        }
        return null
      })
      /* Requirement - missing in top
      Since, A map maintains the order of entry insertion (as per MDN docs), 
      making Missing categories as the last insertion, and later reversing the map.
      */
      if (devStatus.has('Use Expired')) {
        let count = devStatus.get('Use Expired')
        devStatus.delete('Use Expired')
        devStatus.set('Use Expired', count)
      }

      if (devStatus.has('No Check-Out')) {
        let count = devStatus.get('No Check-Out')
        devStatus.delete('No Check-Out')
        devStatus.set('No Check-Out', count)
      }
    }

    if (this.storeDirectBootModeDevices) {
      Object.entries(this.storeDirectBootModeDevices).map((row, index) => {
        if (row[1].direct_boot_mode === true) {
          directBootModeCount = directBootModeCount + 1
        }
        return directBootModeCount
      })
    }

    if (this.storeMPTCStatus) {
      Object.entries(this.storeMPTCStatus).map((row, index) => {
        if (
          (row[1].e355_pinpad_information.connection_status ===
            'DISCONNECTED') ===
          true
        ) {
          mPTCDisconnectedCount = mPTCDisconnectedCount + 1
        }
        return mPTCDisconnectedCount
      })
    }

    if (
      this.lostStolenHistoryCounted === false &&
      this.props.storeLostStolenHistory.length !== 0
    ) {
      // if no data, skip mapping over row data and just render zeros
      let lostStolenHistory = [
        {
          month: moment().format('MMMM'),
          count: 0,
          monthNumber: moment().format('YYYYMM'),
        },
        {
          month: moment().subtract(1, 'months').format('MMMM'),
          count: 0,
          monthNumber: moment().subtract(1, 'months').format('YYYYMM'),
        },
        {
          month: moment().subtract(2, 'months').format('MMMM'),
          count: 0,
          monthNumber: moment().subtract(2, 'months').format('YYYYMM'),
        },
        {
          month: moment().subtract(3, 'months').format('MMMM'),
          count: 0,
          monthNumber: moment().subtract(3, 'months').format('YYYYMM'),
        },
        {
          month: moment().subtract(4, 'months').format('MMMM'),
          count: 0,
          monthNumber: moment().subtract(4, 'months').format('YYYYMM'),
        },
        {
          month: moment().subtract(5, 'months').format('MMMM'),
          count: 0,
          monthNumber: moment().subtract(5, 'months').format('YYYYMM'),
        },
        {
          month: moment().subtract(6, 'months').format('MMMM'),
          count: 0,
          monthNumber: moment().subtract(6, 'months').format('YYYYMM'),
        },
        {
          month: moment().subtract(7, 'months').format('MMMM'),
          count: 0,
          monthNumber: moment().subtract(7, 'months').format('YYYYMM'),
        },
        {
          month: moment().subtract(8, 'months').format('MMMM'),
          count: 0,
          monthNumber: moment().subtract(8, 'months').format('YYYYMM'),
        },
        {
          month: moment().subtract(9, 'months').format('MMMM'),
          count: 0,
          monthNumber: moment().subtract(9, 'months').format('YYYYMM'),
        },
        {
          month: moment().subtract(10, 'months').format('MMMM'),
          count: 0,
          monthNumber: moment().subtract(10, 'months').format('YYYYMM'),
        },
        {
          month: moment().subtract(11, 'months').format('MMMM'),
          count: 0,
          monthNumber: moment().subtract(11, 'months').format('YYYYMM'),
        },
      ]
      if (!this.props.storeLostStolenHistory.includes('noData')) {
        this.props.storeLostStolenHistory.forEach((row) => {
          let colMonth = moment(row.lost_event_timestamp).format('YYYYMM')
          Object.values(lostStolenHistory).forEach((monthData) => {
            if (monthData.monthNumber === colMonth) {
              monthData.count++
            }
          })
        })
      }
      this.setState({ lostStolenHistory: lostStolenHistory })
      this.lostStolenHistoryCounted = true
    }
    let now = Date.now()
    let months = [
      moment().subtract(2, 'months').format('MMMM'),
      moment().subtract(1, 'months').format('MMMM'),
      moment().format('MMMM'),
    ]
    this.storeLocalMissingDevices.sort(function (a, b) {
      return a.last_seen < b.last_seen ? -1 : 1
    })
    this.storeLocalBatteryIssues.sort(function (a, b) {
      return parseInt(a.battery_level) < parseInt(b.battery_level) ? -1 : 1
    })
    const { classes } = this.props
    const DC = ['DC', 'DC-V', 'RGD']
    const StoreOffice = ['STR', 'STR-V', 'OFFICE', 'OSW']
    return (
      <React.Fragment>
        <Table>
          <TableHead>
            <TableRow className={classes.tableRow}>
              {/* DC only widgets */}
              {DC.includes(this.props.locationFullDetails.type) && (
                <>
                  <TableCell className={classes.tableBorder}>
                    <TopPanelDC
                      onChangeClick={this.handleOnClickDeviceState}
                      location_id={this.props.locationFullDetails.location_id}
                      field_name="device_state"
                      title="Device State"
                      classification_name="handheld,tablet"
                    />
                  </TableCell>
                  <TableCell className={classes.tableBorder}>
                    <TopPanelDC
                      // disabled click through on this widget to not conflict w left nav logic
                      onChangeClick={false}
                      location_id={this.props.locationFullDetails.location_id}
                      field_name="department"
                      title="Device Type"
                      classification_name="handheld,tablet"
                    />
                  </TableCell>
                  <TableCell className={classes.tableBorder}>
                    <TopPanelDC
                      onChangeClick={this.handleOnClickDeviceModel}
                      location_id={this.props.locationFullDetails.location_id}
                      field_name="model_name"
                      title="Models"
                      classification_name="handheld,tablet"
                    />
                  </TableCell>
                  <TableCell className={classes.tableBorder}>
                    <Card className={classes.cardStyle}>
                      <CardHeader
                        title={`device offline alerts: ${
                          this.storeLocalMissingDevices &&
                          this.storeLocalMissingDevices.length
                        }`}
                        titleTypographyProps={{
                          variant: 'button',
                          gutterBottom: false,
                          fontWeight: 'bold',
                          align: 'center',
                        }}
                      />
                      <CardContent className={classes.cardContent}>
                        <Table className={classes.root}>
                          <TableHead>
                            <TableRow className={classes.cell}>
                              <TableCell className={classes.listItemHeader}>
                                Device Label
                              </TableCell>
                              <TableCell className={classes.listItemHeader}>
                                Serial Number
                              </TableCell>
                              <TableCell className={classes.listItemHeader}>
                                Days Offline
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {Object.entries(this.storeLocalMissingDevices).map(
                              (row, index) => {
                                if (row[1].device_label) {
                                  var tempDevLabel = row[1].device_label
                                } else {
                                  tempDevLabel = 'Not Set'
                                }
                                return (
                                  <TableRow
                                    key={index}
                                    className={classes.cell}
                                  >
                                    <TableCell className={classes.listItem}>
                                      {tempDevLabel}
                                    </TableCell>
                                    <TableCell className={classes.listItem}>
                                      {row[1].serial_number}
                                    </TableCell>
                                    <TableCell className={classes.listItem}>
                                      {Math.abs(
                                        moment(row[1].last_seen)
                                          .add(0, 'days')
                                          .diff(now, 'days'),
                                      )}
                                    </TableCell>
                                  </TableRow>
                                )
                              },
                            )}
                          </TableBody>
                        </Table>
                      </CardContent>
                      <TopPanelMobileHelp widgetHelperText="mobileMovingToLost" />
                    </Card>
                    {/* </Tooltip> */}
                  </TableCell>
                </>
              )}
              {/* Store and HQ only widgets */}
              {StoreOffice.includes(this.props.locationFullDetails.type) && (
                <>
                  <TableCell className={classes.tableBorder}>
                    {devStatus.size > 0 && (
                      <Card className={classes.cardStyle}>
                        <CardHeader
                          title="Check-Out Status"
                          titleTypographyProps={{
                            variant: 'button',
                            gutterBottom: false,
                            fontWeight: 'bold',
                            align: 'center',
                          }}
                        />
                        <CardContent className={classes.cardContent}>
                          <Table className={classes.root}>
                            <TableHead>
                              <TableRow className={classes.cell}>
                                <TableCell className={classes.listItemHeader}>
                                  Status
                                </TableCell>
                                <TableCell className={classes.listItemHeader}>
                                  Count
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {Object.entries(
                                Object.fromEntries(devStatus.entries()),
                              )
                                .reverse()
                                .flatMap((row, index) => {
                                  return (
                                    <TableRow
                                      hover
                                      style={{
                                        cursor: 'pointer',
                                      }}
                                      key={index}
                                      className={classes.cell}
                                      onClick={() =>
                                        this.handleOnClickTableCell(row[0])
                                      }
                                    >
                                      <TableCell className={classes.listItem}>
                                        {row[0]}
                                      </TableCell>
                                      <TableCell className={classes.listItem}>
                                        {row[1]}
                                      </TableCell>
                                    </TableRow>
                                  )
                                })}
                            </TableBody>
                          </Table>
                        </CardContent>
                        <TopPanelMobileHelp widgetHelperText="electronicDeviceState" />
                      </Card>
                    )}
                  </TableCell>
                  {StoreOffice.includes(
                    this.props.locationFullDetails.type,
                  ) && (
                    <TableCell className={classes.tableBorder}>
                      {directBootModeCount > 0 && (
                        <Card className={classes.cardStyle}>
                          <CardHeader
                            title="Direct Boot Mode"
                            isActive={false}
                            titleTypographyProps={{
                              variant: 'button',
                              gutterBottom: false,
                              fontWeight: 'bold',
                              align: 'center',
                            }}
                          />
                          <Divider />
                          <CardContent className={classes.cardContent}>
                            <Button
                              style={{
                                backgroundColor: 'white',
                                width: '100%',
                                borderWidth: '0px',
                              }}
                              onClick={() => this.handleOnClickDBMode()}
                            >
                              <Typography
                                variant="h3"
                                style={{ color: 'black' }}
                              >
                                {directBootModeCount}
                              </Typography>
                            </Button>
                          </CardContent>
                          <TopPanelMobileHelp widgetHelperText="directbootmodetext" />
                        </Card>
                      )}
                    </TableCell>
                  )}
                  {StoreOffice.includes(this.props.locationFullDetails.type) &&
                    this.props.filteredMyCheckout && (
                      <TableCell className={classes.tableBorder}>
                        {mPTCDisconnectedCount > 0 && (
                          <Card className={classes.cardStyle}>
                            <CardHeader
                              title="mPTC Disconnected"
                              isActive={false}
                              titleTypographyProps={{
                                variant: 'button',
                                gutterBottom: false,
                                fontWeight: 'bold',
                                align: 'center',
                              }}
                            />
                            <Divider />
                            <CardContent className={classes.cardContent}>
                              <Button
                                style={{
                                  backgroundColor: 'white',
                                  width: '100%',
                                  borderWidth: '0px',
                                }}
                                onClick={() =>
                                  this.handleOnClickmPTCDisconnected()
                                }
                              >
                                <Typography
                                  variant="h3"
                                  style={{ color: 'black' }}
                                >
                                  {mPTCDisconnectedCount}
                                </Typography>
                              </Button>
                            </CardContent>
                            <TopPanelMobileHelp widgetHelperText="mPTCDisconnectedText" />
                          </Card>
                        )}
                      </TableCell>
                    )}
                  <TableCell className={classes.tableBorder}>
                    <Card className={classes.cardStyle}>
                      <CardHeader
                        title="Device battery alerts"
                        titleTypographyProps={{
                          variant: 'button',
                          gutterBottom: false,
                          fontWeight: 'bold',
                          align: 'center',
                        }}
                      />
                      <CardContent className={classes.cardContent}>
                        <Table className={classes.root}>
                          <TableHead>
                            <TableRow className={classes.cell}>
                              <TableCell className={classes.listItemHeader}>
                                Device Label
                              </TableCell>
                              <TableCell className={classes.listItemHeader}>
                                Serial Number
                              </TableCell>
                              <TableCell className={classes.listItemHeader}>
                                Battery Level
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {Object.entries(this.storeLocalBatteryIssues).map(
                              (row, index) => {
                                if (row[1].device_label) {
                                  var tempDevLabel = row[1].device_label
                                } else {
                                  tempDevLabel = 'Not Set'
                                }
                                return (
                                  <TableRow
                                    key={index}
                                    className={classes.cell}
                                  >
                                    <TableCell className={classes.listItem}>
                                      {tempDevLabel}
                                    </TableCell>
                                    <TableCell className={classes.listItem}>
                                      {row[1].serial_number}
                                    </TableCell>
                                    <TableCell className={classes.listItem}>
                                      {row[1].battery_level}%
                                    </TableCell>
                                  </TableRow>
                                )
                              },
                            )}
                          </TableBody>
                        </Table>
                      </CardContent>
                      <TopPanelMobileHelp widgetHelperText="mobileBattery" />
                    </Card>
                  </TableCell>
                  <TableCell className={classes.tableBorder}>
                    <Card className={classes.cardStyle}>
                      <CardHeader
                        title="device offline alerts"
                        titleTypographyProps={{
                          variant: 'button',
                          gutterBottom: false,
                          fontWeight: 'bold',
                          align: 'center',
                        }}
                      />
                      <CardContent className={classes.cardContent}>
                        <Table className={classes.root}>
                          <TableHead>
                            <TableRow className={classes.cell}>
                              <TableCell className={classes.listItemHeader}>
                                Device Label
                              </TableCell>
                              <TableCell className={classes.listItemHeader}>
                                Serial Number
                              </TableCell>
                              <TableCell className={classes.listItemHeader}>
                                Days Offline
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {Object.entries(this.storeLocalMissingDevices).map(
                              (row, index) => {
                                if (row[1].device_label) {
                                  var tempDevLabel = row[1].device_label
                                } else {
                                  tempDevLabel = 'Not Set'
                                }
                                return (
                                  <TableRow
                                    key={index}
                                    className={classes.cell}
                                  >
                                    <TableCell className={classes.listItem}>
                                      {tempDevLabel}
                                    </TableCell>
                                    <TableCell className={classes.listItem}>
                                      {row[1].serial_number}
                                    </TableCell>
                                    <TableCell className={classes.listItem}>
                                      {Math.abs(
                                        moment(row[1].last_seen)
                                          .add(0, 'days')
                                          .diff(now, 'days'),
                                      )}
                                    </TableCell>
                                  </TableRow>
                                )
                              },
                            )}
                          </TableBody>
                        </Table>
                      </CardContent>
                      <TopPanelMobileHelp widgetHelperText="mobileMovingToLost" />
                    </Card>
                    {/* </Tooltip> */}
                  </TableCell>
                  <TableCell className={classes.tableBorder}>
                    <Card className={classes.cardStyle}>
                      <CardHeader
                        title="Lost Stolen History"
                        titleTypographyProps={{
                          variant: 'button',
                          gutterBottom: false,
                          fontWeight: 'bold',
                          align: 'center',
                        }}
                      />
                      <CardContent className={classes.cardContentLine}>
                        {this.props.storeLostStolenHistory.length !== 0 &&
                        this.lostStolenHistoryCounted === true ? (
                          <React.Fragment>
                            <BarChart
                              style={{ cursor: 'pointer' }}
                              data={this.state.lostStolenHistory
                                .filter(
                                  (a) =>
                                    a.month === months[0] ||
                                    a.month === months[1] ||
                                    a.month === months[2],
                                )
                                .sort(function (a, b) {
                                  return parseInt(a.monthNumber) <
                                    parseInt(b.monthNumber)
                                    ? -1
                                    : 1
                                })}
                              width={300}
                              height={150}
                              margin={{
                                top: 15,
                                right: 0,
                                left: -35,
                                bottom: 10,
                              }}
                              onClick={() => this.handleChartClick('lost')}
                            >
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis dataKey="month" height={40} />
                              <YAxis type="number" ticks={[5, 10]} />
                              <Legend iconType="square" iconSize={10} />
                              <ChartTooltip
                                content={<CustomTooltip />}
                                isAnimationActive={false}
                                position={{ x: 200, y: -55 }}
                              />
                              <Bar
                                fill="#B85300"
                                isAnimationActive={false}
                                dataKey="count"
                                barSize={25}
                                name="Lost or Stolen"
                                label={{
                                  position: 'top',
                                  fontSize: '0.875rem',
                                  fontFamily: 'roboto',
                                  color: '#000000',
                                  fontWeight: 500,
                                }}
                              />
                            </BarChart>
                          </React.Fragment>
                        ) : (
                          <Typography
                            variant="caption"
                            className={classes.cardStyle}
                          >
                            No Data Available
                          </Typography>
                        )}
                      </CardContent>
                      <TopPanelMobileHelp widgetHelperText="mobileLostStolen" />
                    </Card>
                  </TableCell>
                  <TableCell className={classes.tableBorder}>
                    <Card className={classes.cardStyleUtil}>
                      <div>
                        <MuiThemeProvider theme={this.getMuiTheme()}>
                          <Tabs
                            value={this.state.tabValue}
                            onChange={this.handleChangeTab}
                            variant="fullWidth"
                            className={classes.tabs}
                            TabIndicatorProps={{ className: classes.tabsProps }}
                          >
                            <Tab
                              label="myDevice"
                              className={`${classes.tab} ${classes.customTab}`}
                            />
                            <Tab
                              label="myCheckout"
                              className={`${classes.tab} ${classes.customTab}`}
                            />
                            <Tab
                              label="servicekiosk"
                              className={`${classes.tab} ${classes.customTab}`}
                            />
                          </Tabs>
                        </MuiThemeProvider>
                      </div>
                      <CardContent className={classes.cardContentLine}>
                        {this.state.deviceCountsLoading ? (
                          <Spinner layout="selfCentering" />
                        ) : this.state.storeUtilization.length !== 0 ? (
                          <React.Fragment>
                            <LineChart
                              width={400}
                              height={150}
                              data={this.state.storeUtilization}
                              style={{
                                paddingTop: '10px',
                                marginBottom: '5px',
                              }}
                              margin={{
                                top: 0,
                                right: 0,
                                bottom: 20,
                                left: -40,
                              }}
                              onClick={() => this.handleChartClick('util')}
                              cursor="pointer"
                            >
                              <XAxis
                                dataKey="date"
                                height={20}
                                tick={{
                                  fontSize: '0.875rem',
                                  fontFamily: 'roboto',
                                  fill: '#000000',
                                  fontWeight: 500,
                                }}
                              />
                              <YAxis
                                type="number"
                                width={80}
                                domain={['auto', 'auto']}
                                tick={{
                                  fontSize: '0.875rem',
                                  fontFamily: 'roboto',
                                  fill: '#000000',
                                  fontWeight: 500,
                                }}
                              />
                              <Legend
                                iconType="square"
                                iconSize={10}
                                align="center"
                                verticalAlign="bottom"
                                wrapperStyle={{
                                  position: 'relative',
                                  marginTop: '-45px',
                                }}
                                content={
                                  <CustomLegendLineChart
                                    gridDetails={this.props.gridDetails}
                                  />
                                }
                              />
                              <ChartTooltip
                                content={CustomUtilTooltipLineChart}
                              />
                              <Line
                                type="monotone"
                                dataKey="total_devices"
                                stroke="#008300"
                                dot={false}
                                strokeWidth="3"
                                name={
                                  this.state.tabValue === 0
                                    ? 'Total myDevices'
                                    : this.state.tabValue === 1
                                      ? 'Total myCheckouts'
                                      : 'Total ServiceKiosks'
                                }
                              />
                              <Line
                                type="monotone"
                                dataKey="max_utilized"
                                stroke="#B85300"
                                dot={false}
                                strokeWidth="3"
                                name={
                                  this.state.tabValue === 0
                                    ? 'Max Utilized myDevices'
                                    : this.state.tabValue === 1
                                      ? 'Max Utilized myCheckouts'
                                      : 'Max Utilized ServiceKiosks'
                                }
                              />
                            </LineChart>
                          </React.Fragment>
                        ) : (
                          <Typography
                            variant="caption"
                            display="block"
                            className={classes.cardStyle}
                          >
                            No Data Available
                          </Typography>
                        )}
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              disableFuture
                              minDate={
                                this.state.tabValue === 0
                                  ? moment(
                                      new Date(
                                        Date.now() - 90 * 24 * 60 * 60 * 1000,
                                      ),
                                    ).format('MM/DD/YYYY')
                                  : moment(
                                      new Date(this.props.env.myCheckoutDate),
                                    ).format('MM/DD/YYYY')
                              }
                              margin="normal"
                              cursor="pointer"
                              label="SELECT A START DATE"
                              format="MM/dd/yyyy"
                              value={this.state.startDate}
                              onChange={(date) => this.handleStartDate(date)}
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                              }}
                              className={classes.date}
                            />
                            <KeyboardDatePicker
                              disableFuture
                              minDate={
                                this.state.tabValue === 0
                                  ? moment(
                                      new Date(
                                        Date.now() - 90 * 24 * 60 * 60 * 1000,
                                      ),
                                    ).format('MM/DD/YYYY')
                                  : moment(
                                      new Date(this.props.env.myCheckoutDate),
                                    ).format('MM/DD/YYYY')
                              }
                              margin="normal"
                              cursor="pointer"
                              label="SELECT A END DATE"
                              format="MM/dd/yyyy"
                              value={this.state.endDate}
                              onChange={(date) => this.handleEndDate(date)}
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                              }}
                              className={classes.date}
                            />
                          </MuiPickersUtilsProvider>
                          <Tooltip title="Search">
                            <IconButton
                              className={classes.submitButton}
                              onClick={() => this.getUtilizationData()}
                            >
                              <SearchIcon></SearchIcon>
                            </IconButton>
                          </Tooltip>
                        </div>
                      </CardContent>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <CardHeader
                          title="UTILIZATION"
                          titleTypographyProps={{
                            variant: 'button',
                            gutterBottom: false,
                            fontWeight: 'bold',
                            align: 'center',
                          }}
                        />{' '}
                        <TopPanelMobileHelp widgetHelperText="mobileUtilization" />
                      </div>
                    </Card>
                  </TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
        </Table>
        <MuiThemeProvider theme={this.getMuiTheme()}>
          {!this.props.storeLostStolenHistory.includes('noData') ? (
            <LostStolenChartDialog
              open={this.state.lostChartOpen}
              onClose={() => this.handleChartClick('lost')}
              storeLostStolenHistory={this.props.storeLostStolenHistory}
            />
          ) : (
            <LostStolenChartDialog
              open={this.state.lostChartOpen}
              onClose={() => this.handleChartClick('lost')}
              storeLostStolenHistory={[]}
            />
          )}
          <UtilizationChartDialog
            open={this.state.utilizationChartOpen}
            onClose={() => this.handleChartClick('util')}
            storeUtilization={this.state.storeUtilization}
            tabvalue={this.state.tabValue}
          />
        </MuiThemeProvider>
      </React.Fragment>
    )
  }
}

const CustomLegendLineChart = (props) => {
  return props.payload.map((row, index) => (
    <Typography
      key={index}
      variant="button"
      style={{
        alignItems: 'center',
        fontSize: '0.8rem',
        paddingLeft: '20px',
      }}
    >
      <Label
        style={{
          fontSize: 30,
          color: props.payload[index].color,
          paddingTop: '17px',
        }}
      />
      {row.value}
    </Typography>
  ))
}
const CustomUtilTooltipLineChart = (props) => {
  return (
    props.payload !== null &&
    props.payload.map(
      (row, index) =>
        props.active && (
          <Typography
            key={index}
            style={{
              display: 'flex',
              alignItems: 'center',
              fontSize: 12,
              fontWeight: 'medium',
              backgroundColor: '#666666',
              color: '#F7F7F7',
              borderRadius: 5,
              padding: '4px 8px',
              margin: 2,
            }}
          >
            <Label
              style={{
                fontSize: 24,
                color: row.stroke,
                paddingRight: 5,
              }}
            />
            {row.name + ': ' + row.value + ' (' + row.payload.date + ')'}
          </Typography>
        ),
    )
  )
}

export default withStyles(styles, { withTheme: true })(
  withAnalytics()(TopPanel),
)
