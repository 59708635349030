import React from 'react'
import axios from 'axios'
import queryString from 'query-string'

class SvcApplications extends React.Component {
  getApplications(config, submission) {
    let value
    Object.entries(submission).forEach((param) => {
      value = param[1]
    })
    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey
    var apiUri = encodeURI(
      apiUriBase +
        `/application_counts?classification_name=` +
        value +
        `&key=` +
        apiKey,
    )
    return axios.get(apiUri)
  }

  getApplicationStoreCounts(config, submission) {
    const queryParameters = {
      applications: {
        classification_name: null,
        'products.package_name': null,
        'products.version_name': null,
      },
    }
    Object.entries(submission).forEach((param) => {
      if (param[0] === 'classification_name') {
        Object.entries(config.deviceClassificationMap).forEach((row) => {
          if (param[1] === row[0]) {
            if (queryParameters.applications.hasOwnProperty(param[0])) {
              queryParameters.applications[`${param[0]}`] = row[1]
            }
          }
        })
      } else {
        queryParameters.applications[`${param[0]}`] = param[1]
      }
    })

    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey
    let endpointParams = queryString.stringify(
      Object.assign(queryParameters.applications, { key: apiKey }),
      {
        sort: false,
        skipNull: true,
      },
    )
    return axios.get(apiUriBase + `/application_store_counts?` + endpointParams)
  }
}
export default SvcApplications
