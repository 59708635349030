import {
  IconButton,
  Snackbar,
  SnackbarContent,
  Tooltip,
} from '@material-ui/core'
import { useEffect, useState } from 'react'

import CloseIcon from '@material-ui/icons/Close'
import Landing from '../Landing/Landing'
import { UserSettingsHome } from './UserSettingsHome'
import { redirectHandler } from '../../utilities/RedirectHandler'
import { useAuth } from '@praxis/component-auth'
import { useEnv } from '@praxis/component-runtime-env'
import { useUserSettingsContext } from '../../contexts/UserSettingsContext'
import { withStyles } from '@material-ui/styles'

const UserSettingsHomeLoader = ({ classes, homeOverride }) => {
  // if user_settings has no home key/value, render landing page
  // if home key/value exists, redirect to that location
  // if user clicks the global home (shield) override redirect to location and proceed to landing page
  const userSettings = useUserSettingsContext()
  const auth = useAuth()
  const env = useEnv()
  const [homeReminder, setHomeReminder] = useState(false)
  const [homeMessage, setHomeMessage] = useState()

  useEffect(() => {
    // prompt for user if they have a session and location
    // AND no home document, this will override the reminder if the user has ever set a home
    // AND homeReminder feature enabled in config for given user session location type (STR or DC)
    if (
      env.homeReminder.enabled &&
      auth.session.userInfo &&
      auth.session.userInfo.locationnumber &&
      auth.session.userInfo.locationtype &&
      env.homeReminder.enabledLocationTypes.includes(
        auth.session.userInfo.locationtype,
      ) &&
      !userSettings.data.isLoading &&
      userSettings.data.response.status === 404
    ) {
      setHomeMessage(
        `Hi, it looks like you are associated with location: T${auth.session.userInfo.locationnumber}. Click the home icon to set your default home location`,
      )
      setHomeReminder(true)
    }
  }, [userSettings, homeOverride, auth, env])

  useEffect(() => {
    // if no bypass effect from MICKRA home shield
    // if home type and value set, use that route w/ params
    if (
      !homeOverride &&
      !userSettings.data.isLoading &&
      userSettings.data.data.home &&
      userSettings.data.data.home.type &&
      userSettings.data.data.home.value
    ) {
      redirectHandler(
        userSettings.data.data.home.type,
        userSettings.data.data.home.value,
      )
    }
  }, [userSettings, homeOverride])

  return (
    <>
      <Landing />

      <Snackbar
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        open={homeReminder}
        onClose={() => setHomeReminder(false)}
        autoHideDuration={
          env.homeReminder.autoHideEnabled
            ? env.homeReminder.autoHideDurationMilliseconds
            : null
        }
        key={homeMessage}
      >
        <SnackbarContent
          className={classes.snackbarContent}
          action={
            <>
              <UserSettingsHome
                homeType="location_id"
                homeValue={auth.session.userInfo.locationnumber}
              />
              <Tooltip title="CLOSE">
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  onClick={() => setHomeReminder(false)}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </>
          }
          message={homeMessage}
        />
      </Snackbar>
    </>
  )
}

const styles = () => ({
  snackbarContent: {
    marginTop: 45,
    minWidth: 800,
    backgroundColor: '#EDB508',
    color: '#000000DE',
  },
})

export default withStyles(styles)(UserSettingsHomeLoader)
