import CustomDownloadDialog from '../CustomDownload/CustomDownloadDialog'
import FileDownloadButton from '@material-ui/icons/CloudDownload'
import IconButton from '@material-ui/core/IconButton'
import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import { withAnalytics } from '@praxis/component-analytics'
import { withEnv } from '@praxis/component-runtime-env'
import { withStyles } from '@material-ui/core/styles'

class CustomDownload extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showDialog: false,
    }
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    this.setState({ showDialog: false })
  }

  handleOnClick = (event) => {
    this.setState({ showDialog: true })
  }

  render() {
    return (
      <>
        <Tooltip title={'Download'}>
          <IconButton
            onClick={() => this.handleOnClick()}
            onClose={() => {
              this.handleClose()
            }}
          >
            <FileDownloadButton />
          </IconButton>
        </Tooltip>

        <CustomDownloadDialog
          open={this.state.showDialog}
          onClose={() => {
            this.handleClose()
          }}
          {...this.props}
        />
      </>
    )
  }
}

export default withStyles()(withAnalytics()(withEnv()(CustomDownload)))
