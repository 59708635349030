import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'

import Collapse from '@material-ui/core/Collapse'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import MUIDataTable from 'mui-datatables'
import Paper from '@material-ui/core/Paper'
import React from 'react'
import Spinner from 'nicollet-react/es/components/Spinner/Spinner'
import SvcPrinters from '../../../services/svcPrinters'
import Table from '@material-ui/core/Table'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableOptionsConfig from '../TableOptionsConfig/TableOptionsConfig'
import TableRow from '@material-ui/core/TableRow'
import TopPanelPrinters from '../TopPanel/TopPanelPrinters'
import Typography from '@material-ui/core/Typography'
import columns from '../TableColumnsConfig/TableColumnConfig'
import { withAnalytics } from '@praxis/component-analytics'
import { withEnv } from '@praxis/component-runtime-env'
import { withStyles } from '@material-ui/styles'

class DashboardPlatformsPrinter extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      storePrintersClean: [],
      storePrinters: [],
      storePrintersBatteryIssues: [],
      storeLostStolenHistory: [],
      storeHostnameRename: [],
      storeRenamePrinters: [],
      storePrintersLoading: false,
    }
    this.svcPrinters = new SvcPrinters()
  }

  componentDidMount() {
    this.setState({ storePrintersLoading: true })
    this.svcPrinters
      .getStorePrinters(this.props.locationId, this.props.env)
      .then((response) => {
        const eventData = {
          customMetrics: {
            metric1: 'Printer',
            metric3: response.status,
          },
          event: {
            type: `apiSuccessPrinter`,
          },
        }
        this.props.trackEvent(eventData)
        response.data.map((row, index) => {
          if (
            row.power_cycle_count &&
            row.power_cycle_count >= this.props.env.batteryCycleCountThreshold
          ) {
            this.state.storePrintersBatteryIssues.push({
              serial_number: row.serial_number,
              power_cycle_count: row.power_cycle_count,
              label: row.label,
              power_serial_number: row.power_serial_number,
            })
          }
          if (
            row.device_state === 'Lost' &&
            (row.department === 'HIP' || row.department === 'SFS')
          ) {
            this.state.storeLostStolenHistory.push({
              serial_number: row.serial_number,
              lost_event_user: row.lost_event_user,
              updated_on: row.updated_on,
              lost_event_timestamp: row.lost_event_timestamp,
            })
          }
          if (row.is_renamed === true) {
            this.state.storeHostnameRename.push({
              serial_number: row.serial_number,
              hostname: row.hostname,
            })
          }
          if (
            row.hostname.substring(0, 5) !==
            'T' + this.props.locationId.padStart(4, '0')
          ) {
            this.state.storeRenamePrinters.push({
              hostname: row.hostname,
              label: row.label,
            })
          }
          return null
        })
        this.setState({
          storePrinters: response.data,
          storePrintersLoading: false,
        })
      })
      .catch((error) => {
        const eventData = {
          customMetrics: {
            metric1: 'Printer',
            metric3: error.response.status,
          },
          event: {
            type: `apiErrorPrinter`,
          },
        }
        this.props.trackEvent(eventData)
        console.log('Unable to get response for Printers data.', error)
        this.setState({ storePrintersLoading: false })
      })
      .finally(() => {
        this.setState({ storePrintersLoading: false })
      })
  }
  render() {
    const { classes } = this.props
    return (
      <div>
        <Paper>
          <Table>
            <TableHead>
              <TableRow
                hover
                className={classes.tableRow}
                onClick={this.props.handleCollapsedRow}
              >
                {this.props.collapsedRow ? (
                  <TableCell className={classes.tableRow}>
                    <List dense>
                      <ListItem className={classes.tableRow}>
                        <Typography variant="h6" className={classes.metrics}>
                          Dashboard
                        </Typography>
                        <ExpandMoreIcon />
                      </ListItem>
                    </List>
                  </TableCell>
                ) : (
                  <TableCell className={classes.tableRow}>
                    <List dense>
                      <ListItem className={classes.tableRow}>
                        <Typography variant="h6" className={classes.metrics}>
                          Dashboard
                        </Typography>
                        <ExpandLessIcon />
                      </ListItem>
                    </List>
                  </TableCell>
                )}
                <TableCell />
                <TableCell />
                <TableCell />
              </TableRow>
            </TableHead>
          </Table>
        </Paper>
        <Collapse
          in={!this.props.collapsedRow}
          timeout="auto"
          unmountOnExit
          className={classes.honeyComb}
        >
          <TopPanelPrinters
            storePrintersBatteryIssues={this.state.storePrintersBatteryIssues}
            storeLostStolenHistory={this.state.storeLostStolenHistory}
            storeHostnameRename={this.state.storeHostnameRename}
            storePrinters={this.state.storePrinters}
            storeRenamePrinters={this.state.storeRenamePrinters}
          />
        </Collapse>
        {this.state.storePrintersLoading ? (
          <Spinner />
        ) : (
          <MuiThemeProvider theme={this.getMuiTheme()}>
            <MUIDataTable
              data={this.state.storePrinters}
              title={'Printers'}
              options={TableOptionsConfig(
                this.state.storePrinters,
                this.props.handleFiltersState,
                this.props.platform,
              )}
              columns={columns(
                {
                  filteredSFSPrinter: this.props.filterSFS,
                  filteredHIPPrinter: this.props.filterHIP,
                  filteredLexmarkPrinter: this.props.filterLexmark,
                },
                null,
                this.props.customFiltersPrinters,
                this.props.platform,
                this.props.env.access,
                this.props.locationId,
                null,
                this.props.env.batteryCycleCountThreshold,
              )}
            />
          </MuiThemeProvider>
        )}
      </div>
    )
  }
  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTableToolbar: {
          filterPaper: {
            width: 300,
          },
        },
        MuiTableCell: {
          root: {
            paddingTop: 4,
            paddingBottom: 4,
          },
        },
      },
    })
}

const styles = (theme) => ({
  honeyComb: {
    minHeight: `calc(100vh - 75px)`,
    overflow: 'auto',
    backgroundImage:
      `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='24.5' ` +
      `viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%23888888' fill-opacity='0.50' ` +
      `fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 ` +
      `11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 ` +
      `0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
  },
  tableRow: {
    cursor: 'cursor',
    width: 'flex',
    paddingLeft: 5,
    paddingTop: 0,
    paddingBottom: 0,
    height: 30,
  },
  metrics: {
    paddingLeft: 14,
  },
})
export default withStyles(styles, { withTheme: true })(
  withAnalytics()(withEnv()(DashboardPlatformsPrinter)),
)
