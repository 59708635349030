import axios from 'axios'
import { getEnvConfig } from '@praxis/component-runtime-env'

export const getUserSettings = async () => {
  const envConfig = await getEnvConfig(
    process.env.NODE_ENV === 'development'
      ? '/config.json'
      : '/app-environment',
  )

  const apiUriBase = envConfig.auth.apiUriBase
  const apiKey = envConfig.auth.apiKey
  const apiUri = encodeURI(
    apiUriBase + `/user_settings/current_user?key=${apiKey}`,
  )
  return axios.get(apiUri)
}

export const setUserSettingsHome = async (homeType, homeValue) => {
  const envConfig = await getEnvConfig(
    process.env.NODE_ENV === 'development'
      ? '/config.json'
      : '/app-environment',
  )

  const apiUriBase = envConfig.auth.apiUriBase
  const apiKey = envConfig.auth.apiKey
  const apiUri = encodeURI(
    apiUriBase + `/user_settings/current_user/home?key=${apiKey}`,
  )
  const headers = {
    'Content-Type': 'application/json',
  }

  return axios.put(apiUri, { type: homeType, value: homeValue }, headers)
}
