import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import CloseIcon from '@material-ui/icons/Close'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import FileSaver from 'file-saver'
import Good from '@material-ui/icons/CheckCircleTwoTone'
import IconButton from '@material-ui/core/IconButton'
import ListItem from '@material-ui/core/ListItem'
import PapaParse from 'papaparse'
import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import Spinner from 'nicollet-react/es/components/Spinner/Spinner'
import SvcDevicesDownload from '../../../services/svcDevicesDownload'
import Tooltip from '@material-ui/core/Tooltip'
import { withAnalytics } from '@praxis/component-analytics'
import { withEnv } from '@praxis/component-runtime-env'
import { withStyles } from '@material-ui/core/styles'

class CustomDownloadDialog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      actionSuccess: false,
      downloadIterations: null,
      loading: false,
      progress: 0,
      showDialog: false,
      snackBarMessageInfo: '',
      snackBarOpen: false,
    }
    this.svcDevicesDownload = new SvcDevicesDownload()
    this.count = 1
    this.devices = []
    this.devicesTransformed = []
  }

  handleDialogClose() {
    this.setState({
      actionSuccess: false,
      loading: false,
      progress: 0,
      snackBarOpen: false,
    })
    this.props.onClose()
  }

  handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    this.setState({ snackBarOpen: false })
  }

  handleProgress(progress, iterations) {
    const progressPercent = Math.ceil((progress / iterations) * 100, 100)
    this.setState({
      progress: progressPercent,
    })
  }

  handleSubmit(submission) {
    this.count = 1
    this.devices = []
    this.devicesTransformed = []
    this.setState({ loading: true })
    this.svcDevicesDownload
      .getDownloadsDevices(this.props.env, submission)
      .then((response) => {
        const eventData = {
          customMetrics: {
            metric1: 'Downloads Devices',
            metric3: response.status,
          },
          event: {
            type: `apiSuccessDownloadsDevicesInitial`,
          },
        }
        this.props.trackEvent(eventData)
        Object.assign(this.devices, response.data)
        // devices download page size needs to match the config on the API side
        var iterations = Math.ceil(
          parseInt(response.headers['total']) /
            this.props.env.devicesDownloadPageSize,
        )
        this.handleProgress(1, iterations)
        // fetch more data if necessary:
        if (this.count !== iterations) {
          this.handleIterations(
            this.count,
            iterations,
            response.headers['scroll-id'],
          )
        } else {
          this.handleConversion()
        }
      })
      .catch((error) => {
        console.log(error)
        const eventData = {
          customMetrics: {
            metric1: 'Downloads Devices',
            metric3: error.response.status,
          },
          event: {
            type: `apiErrorDownloadsDevicesInitial`,
          },
        }
        this.props.trackEvent(eventData)
        this.setState({
          actionSuccess: false,
          loading: false,
          snackBarMessageInfo: 'CSV File Download Failed',
          snackBarOpen: true,
        })
      })
  }

  handleIterations(count, iterations, scrollId) {
    this.svcDevicesDownload
      .getDevicesDownloadScroll(this.props.env, scrollId)
      .then((response) => {
        const eventData = {
          customMetrics: {
            metric1: 'Downloads Devices',
            metric3: response.status,
          },
          event: {
            type: `apiSuccessDownloadsDevicesIteration`,
          },
        }
        this.props.trackEvent(eventData)
        count++
        this.handleProgress(count, iterations)
        this.count++
        this.devices = this.devices.concat(response.data)
        if (this.count !== iterations) {
          this.handleIterations(
            this.count,
            iterations,
            response.headers['scroll-id'],
          )
        } else {
          this.handleConversion()
        }
      })
      .catch((error) => {
        console.log(error)
        const eventData = {
          customMetrics: {
            metric1: 'Downloads Devices',
            metric3: error.response.status,
          },
          event: {
            type: `apiErrorDownloadsDevicesIteration`,
          },
        }
        this.props.trackEvent(eventData)
        this.setState({
          actionSuccess: false,
          loading: false,
          snackBarMessageInfo: 'CSV File Download Failed',
          snackBarOpen: true,
        })
      })
  }

  flattenNestedObjects(devices) {
    // take object entries, iterate over
    // flatten, and stuff into new object
    let flattened = {}
    devices.forEach((item) => {
      Object.assign(flattened, item)
      Object.keys(item).forEach((key) => {
        const value = item[key]
        if (
          typeof value === 'object' &&
          value !== null &&
          !Array.isArray(value)
        ) {
          Object.assign(flattened, value)
        } else {
          flattened[key] = value
        }
      })
      this.devicesTransformed.push(flattened)
      flattened = {}
    })
  }

  handleConversion() {
    this.flattenNestedObjects(this.devices)
    const dataString = PapaParse.unparse({
      fields: [
        'classification_name',
        'id',
        'serial_number',
        'manufacturer_name',
        'model_name',
        'operating_system',
        'operating_system_version',
        'network_connection_name',
        'mac_address',
        'ip_address',
        'port_number',
        'ssid',
        'location_id',
        'region_id',
        'group_id',
        'district_id',
        'updated_on',
        'last_loggedin_user',
        'state',
      ],
      data: this.devicesTransformed,
    })
    const blob = new Blob([dataString], {
      type: 'text/csv;charset=utf-8',
    })
    let fileName = 'mickra-download.csv'
    FileSaver.saveAs(blob, fileName)
    this.setState({
      actionSuccess: true,
      loading: false,
      snackBarMessageInfo: 'CSV File Download Complete',
      snackBarOpen: true,
    })
  }

  render() {
    const { classes } = this.props
    let params = []
    Object.entries(this.props.submission).forEach((row) => {
      params.push(this.props.env.chipLabelSearchItems[row[0]] + ': ' + row[1])
    })
    return (
      <Dialog
        fullWidth
        maxWidth="sm"
        open={this.props.open}
        onClose={this.props.onClose}
      >
        <DialogTitle>
          Download Total of {this.props.totalCount} Devices with Search Criteria
        </DialogTitle>

        <IconButton
          aria-label="Close"
          className={classes.icon}
          onClick={() => this.handleDialogClose()}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <DialogContentText>Save Output to CSV:</DialogContentText>
          {params.map((data, index) => (
            <ListItem key={index}>
              <Chip label={data} />
            </ListItem>
          ))}
        </DialogContent>
        <div>
          {this.state.loading ? (
            <>
              <Spinner className={classes.spinner} />
              <DialogContentText align="center">
                {this.state.progress}%
              </DialogContentText>
            </>
          ) : (
            <ListItem className={classes.list}>
              {!this.state.actionSuccess ? (
                <React.Fragment>
                  <Button
                    className={classes.submitButton}
                    variant="outlined"
                    onClick={() => this.handleSubmit(this.props.submission)}
                  >
                    CONFIRM
                  </Button>
                  <Button
                    className={classes.cancelButton}
                    variant="outlined"
                    onClick={() => this.handleDialogClose()}
                  >
                    CANCEL
                  </Button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Button
                    className={classes.successButton}
                    variant="outlined"
                    onClick={() => this.handleDialogClose()}
                  >
                    CLOSE
                  </Button>
                  <Tooltip title="Success" placement="top">
                    <Good className={classes.good} />
                  </Tooltip>
                </React.Fragment>
              )}
            </ListItem>
          )}
        </div>
        <Snackbar
          open={this.state.snackBarOpen}
          autoHideDuration={5000}
          onClose={this.handleSnackBarClose}
          message={this.state.snackBarMessageInfo}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleSnackBarClose}
            >
              <CloseIcon className={classes.CloseIcon} />
            </IconButton>,
          ]}
        />
      </Dialog>
    )
  }
}

const styles = {
  icon: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
  searchTextbar: {
    minWidth: 200,
    width: '350px',
    paddingRight: '5px',
  },
  submitButton: {
    float: 'left',
  },
  cancelButton: {
    float: 'left',
    marginLeft: 5,
  },
  resetButton: {
    float: 'left',
    marginLeft: 5,
  },
  closeIcon: {
    fontSize: 20,
  },
  list: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  spinner: {
    marginBottom: 15,
  },
  good: {
    fontSize: 40,
    color: 'green',
    paddingLeft: 5,
  },
  successButton: {
    float: 'left',
  },
}

export default withStyles(styles)(
  withAnalytics()(withEnv()(CustomDownloadDialog)),
)
