import {
  Button,
  Chip,
  Dialog,
  DialogContentText,
  Divider,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core'
import { RemoveCircleOutlineRounded, TuneRounded } from '@material-ui/icons'
import { useEffect, useState } from 'react'

import { Alert } from '@material-ui/lab'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CloseIcon from '@material-ui/icons/Close'
import DateFnsUtils from '@date-io/date-fns'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import { KeyboardDateTimePicker } from '@material-ui/pickers'
import MuiDialogActions from '@material-ui/core/DialogActions'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import Tooltip from '@material-ui/core/Tooltip'
import moment from 'moment/moment'
import { redirectHandler } from '../../utilities/RedirectHandler'
import svcDevices from '../../services/svcDevices'
import { useUserSettingsContext } from '../../contexts/UserSettingsContext'
import { withAnalytics } from '@praxis/component-analytics'
import { withEnv } from '@praxis/component-runtime-env'
import withRouter from '../withRouter'
import { withStyles } from '@material-ui/core/styles'

let idCounter = 0
const createRow = (field, operator, chips, value) => {
  idCounter += 1
  if (field) {
    return {
      id: idCounter,
      fieldName: field,
      operatorValue: operator,
      chipsValue: chips,
      fieldValue: value,
    }
  } else {
    return {
      id: idCounter,
      fieldName: null,
      operatorValue: '=',
      chipsValue: [],
      fieldValue: '',
    }
  }
}

const AdvancedSearch = ({
  classes,
  props,
  isDialogOpen,
  setDialogOpen,
  trackEvent,
}) => {
  const { auth } = props
  const [suggestions, setSuggestions] = useState([])
  const [classification, setClassification] = useState('all')
  const [rows, setRows] = useState([])
  const [action, setAction] = useState(false)
  const [tempClass, settempClass] = useState('')
  const [helpDialog, setHelpDialog] = useState(false)
  const [svc] = useState(new svcDevices())
  const userSettings = useUserSettingsContext()

  useEffect(() => {
    svc
      .getAdvancedSearchMapping(auth)
      .then((respons) => {
        const response = [...respons.data]
        response.sort()
        setSuggestions(
          response.filter((row) => row.field_name !== 'classification_name'),
        )
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {})
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    handleRetain(suggestions)
    // eslint-disable-next-line
  }, [isDialogOpen])

  const handleRetain = (mapping) => {
    setRows([])
    setClassification('all')

    const isAdvancedSearch = localStorage.getItem('advanced_search')
    if (!isAdvancedSearch) {
      handleAddRow()
      return
    }
    let submission = JSON.parse(isAdvancedSearch)
    let countRows = 0

    Object.entries(submission).forEach((row) => {
      if (row[1].field_name === 'classification_name') {
        setClassification(row[1].field_value)
        handleClasssificationChange(row[1].field_value)
      } else {
        const field1 = mapping.find(
          (option) => option.field_name === row[1].field_name,
        )
        if (field1?.field_type === 'Date' || field1?.field_type === 'Boolean') {
          setRows((prevRows) => [
            ...prevRows,
            createRow(
              mapping.find((option) => option.field_name === row[1].field_name),
              row[1].operator,
              [],
              row[1].field_value,
            ),
          ])
        } else {
          setRows((prevRows) => [
            ...prevRows,
            createRow(
              mapping.find((option) => option.field_name === row[1].field_name),
              row[1].operator,
              row[1].field_value.split('|'),
              '',
            ),
          ])
        }
        countRows++
      }
    })

    if (countRows === 0) {
      handleAddRow()
      return
    }
  }

  const handleClose = () => {
    setDialogOpen(false)
  }
  const handleAddRow = () => {
    setRows((prevRows) => [...prevRows, createRow()])
  }
  const handleDeleteRow = (rowToDeleteIndex) => {
    if (rows.length === 1) {
      return
    }
    setRows(rows.filter((row) => row.id !== rowToDeleteIndex))
  }

  const handleFieldChange = (event, rowToUpdateIndex, value) => {
    setRows(
      rows.map((row) =>
        row.id === rowToUpdateIndex ? { ...row, fieldName: value } : row,
      ),
    )
  }

  const handleOperatorChange = (event, rowToUpdateIndex, value) => {
    setRows(
      rows.map((row) =>
        row.id === rowToUpdateIndex ? { ...row, operatorValue: value } : row,
      ),
    )
  }

  const handleValueChange = (newInputValue, rowToUpdateIndex) => {
    setRows(
      rows.map((row) =>
        row.id === rowToUpdateIndex
          ? { ...row, fieldValue: newInputValue }
          : row,
      ),
    )
  }

  const handleChipsChange = (event, rowToUpdateIndex) => {
    setRows(
      rows.map((row) =>
        row.id === rowToUpdateIndex ? { ...row, chipsValue: [] } : row,
      ),
    )
  }

  const handleChipDelete = (rowToUpdateIndex, chipToDelete) => {
    setRows(
      rows.map((row) =>
        row.id === rowToUpdateIndex
          ? {
              ...row,
              chipsValue: row.chipsValue.filter(
                (chip) => chip !== chipToDelete,
              ),
            }
          : row,
      ),
    )
  }

  const handleChipsAdd = (rowToUpdateIndex) => {
    setRows(
      rows.map((row) => {
        if (row.id === rowToUpdateIndex) {
          let valueArray = row.fieldValue?.split(/[\n|]/)
          let chipVal = row.chipsValue
          valueArray?.forEach((val) => {
            const value = val.trim()
            if (value !== '' && !chipVal.includes(value)) {
              chipVal.push(value)
            }
          })
          return { ...row, chipsValue: chipVal, fieldValue: '' }
        } else return row
      }),
    )
  }

  const handleKeyDown = (event, rowToUpdateIndex) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      handleChipsAdd(rowToUpdateIndex)
    }
    event.stopPropagation()
  }

  const handleReset = () => {
    setRows(() => [createRow()])
    localStorage.setItem('advanced_search', [])
  }

  const handleClasssificationChange = (value) => {
    setClassification(value)
    svc
      .getAdvancedSearchMapping(auth, value)
      .then((respons) => {
        const response = [...respons.data]
        response.sort()
        setSuggestions(
          response.filter((row) => row.field_name !== 'classification_name'),
        )
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {})
    setAction(false)
  }

  const handleSubmit = (event) => {
    handleClose()
    let query = []

    if (classification !== 'all' && classification !== null) {
      query.push({
        field_name: 'classification_name',
        field_value: classification,
        operator: '=',
      })
    }

    rows.forEach((row) => {
      if (row.fieldValue !== '') {
        handleChipsAdd(row.id)
      }

      if (
        row.fieldName !== null &&
        row.operatorValue !== null &&
        Array.isArray(row.chipsValue) &&
        row.chipsValue.length > 0
      ) {
        query.push({
          field_name: row.fieldName.field_name,
          field_value: row.chipsValue.join('|'),
          operator: row.operatorValue,
        })
      }
    })

    localStorage.setItem('advanced_search', JSON.stringify(query))

    let searchText = JSON.stringify(query)

    const eventData = {
      customInteraction: {
        key: 'AdvancedSearch',
        value: searchText,
      },
    }
    trackEvent(eventData)
    userSettings.updateRecent('advanced_search', {
      timestamp: moment.now(),
      query: query,
    })

    redirectHandler('advanced_search')
  }

  return (
    <>
      <IconButton
        color="inherit"
        onClick={() => {
          setDialogOpen(!isDialogOpen)
        }}
      >
        <TuneRounded style={{ width: 28, marginRight: 10 }} />
      </IconButton>
      <Dialog maxWidth="md" open={isDialogOpen} onClose={handleClose}>
        <DialogTitle onClose={handleClose}>
          {'Advanced Search '}
          <IconButton
            color="inherit"
            style={{ padding: '0px' }}
            onClick={() => {
              setHelpDialog(!helpDialog)
            }}
          >
            <HelpIcon />
          </IconButton>
          <Dialog
            maxWidth="md"
            open={helpDialog}
            onClose={() => setHelpDialog(false)}
          >
            <DialogTitle onClose={() => setHelpDialog(false)}>
              Advanced Search Help
            </DialogTitle>
            <DialogContent dividers="paper">
              <DialogContentText>
                <Typography>
                  <b>
                    <u>Supported Search Operations</u>:
                  </b>
                  <br />
                  &nbsp;- <b>Equal to (=) </b>: Find results that exactly match
                  your specified value.
                  <br />
                  &nbsp;- <b>Not Equal to (!=) </b>: Find results that do not
                  match your specified value.
                  <br />
                  &nbsp;- <b>Contains (contains)</b>: Find results that include
                  any of the specified words in your value.
                  <br />
                  &nbsp;- <b>Does Not Contain (!contains)</b>: Find results that
                  do not include any of the specified words in your value.
                  <br />
                  &nbsp;- <b>Less Than (&lt;)</b>: Find results with values less
                  than your specified value.
                  <br />
                  &nbsp;- <b>Greater Than (&gt;)</b>: Find results with values
                  greater than your specified value.
                  <br />
                  <br />
                  <b>Note</b>: Not all fields support all operators. Certain
                  fields may have limitations based on their data type and
                  content.
                  <br />
                  <br />
                  <b>
                    <u>Multiple Value Search</u>:
                  </b>
                  <br />
                  &nbsp;- To search a field with multiple values separate them
                  by '|' or use multiline
                  <br />
                  &nbsp;- Example: <br />
                  &nbsp;{' '}
                  <code>
                    field: model_name,
                    <br />
                    &nbsp;operator: =,
                    <br />
                    &nbsp;value: TC51|TC56|TC57
                    <br /> &nbsp;or
                    <br />
                    &nbsp;value: TC51
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TC56
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TC57
                  </code>
                  <br />
                  <br />
                  <b>
                    <u>Wildcard Search</u>:
                  </b>
                  <br />
                  &nbsp;- Use asterisk (*) for wildcard characters in values.
                  <br />
                  &nbsp;- Example:{' '}
                  <code>field: label, operator: !=, value: sbux*</code>
                  <br />
                  <br />
                  Explore different combinations to refine your search
                </Typography>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </DialogTitle>

        <DialogContent dividers>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              padding: '5px',
            }}
          >
            <div
              key="-1"
              style={{
                display: 'flex',
                marginBottom: '20px',
                padding: '10px',
                borderRadius: '5px',
              }}
            >
              <Typography
                variant="subtitle2"
                style={{
                  textAlign: 'center',
                  marginRight: '0px',
                  marginTop: '10px',
                }}
              >
                Device Classifications
              </Typography>
              <Autocomplete
                id="tags-filled"
                className={classes.valueClass}
                defaultValue={'all'}
                options={[
                  'all',
                  'handheld',
                  'tablet',
                  'mac',
                  'windows',
                  'printer',
                  'rfidhandheld',
                  'bluetoothscanner',
                ]}
                size="small"
                style={{ marginLeft: '35px' }}
                value={classification}
                onChange={(event, value) => {
                  settempClass(value)
                  if (
                    rows.findIndex(function count(row) {
                      return row.fieldName !== null
                    }) !== -1
                  ) {
                    setAction(true)
                  } else {
                    handleClasssificationChange(value)
                  }
                }}
                renderInput={(params) => {
                  return (
                    <TextField {...params} variant="outlined" size="small" />
                  )
                }}
              />
            </div>
          </div>
          <Divider style={{ marginBottom: '10px' }} />
          {rows.map((row, index) => {
            return (
              <div key={row.id} style={{ display: 'flex' }}>
                <Autocomplete
                  id={'autocomplete'}
                  label="Field type"
                  value={row.fieldName}
                  options={suggestions.sort(
                    (a, b) => -b.field_name.localeCompare(a.field_name),
                  )}
                  getOptionLabel={(option) => option.field_name}
                  onChange={(event, value) =>
                    handleFieldChange(event, row.id, value)
                  }
                  renderInput={(params) => (
                    <TextField
                      id={row.id + '-field'}
                      className={classes.fieldTextbar}
                      margin="dense"
                      variant="standard"
                      color="primary"
                      label="Enter field"
                      {...params}
                    />
                  )}
                />
                <TextField
                  className={classes.valueOperator}
                  select
                  id={row.id + '-op'}
                  value={row.operatorValue}
                  margin="dense"
                  variant="outlined"
                  color="primary"
                  onChange={(event, value) =>
                    handleOperatorChange(event, row.id, value.props.value)
                  }
                >
                  {row?.fieldName?.allowed_operations
                    .split(',')
                    .map((option, index) => (
                      <MenuItem key={index} dense value={option}>
                        {option}
                      </MenuItem>
                    ))}
                </TextField>
                {row.fieldName?.field_type === 'Date' ? (
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDateTimePicker
                      disableFuture
                      margin="normal"
                      cursor="pointer"
                      size="small"
                      label="Select a Date"
                      format="MM/dd/yyyy hh:mm:ss"
                      className={classes.valueTextbar}
                      style={{ marginTop: '1px' }}
                      value={row.fieldValue || null}
                      onChange={(d) =>
                        handleValueChange(
                          moment(d).format('MM/DD/YYYY hh:mm:ss'),
                          row.id,
                        )
                      }
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                ) : row.fieldName?.field_type === 'Boolean' ? (
                  <Autocomplete
                    id="tags-filled"
                    className={classes.valueTextbar}
                    options={['true', 'false']}
                    freeSolo
                    size="small"
                    limitTags="2"
                    value={row.fieldValue || null}
                    onChange={(event, newInputValue) => {
                      handleValueChange(newInputValue, row.id)
                    }}
                    inputValue={row.fieldValue || ''}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          multiline
                          variant="standard"
                          label="Enter value"
                          size="small"
                        />
                      )
                    }}
                  />
                ) : (
                  <Autocomplete
                    multiple
                    id="tags-filled"
                    className={classes.valueTextbar}
                    options={[]}
                    freeSolo
                    size="small"
                    limitTags="2"
                    value={row.chipsValue}
                    onChange={(event, newInputValue) => {
                      handleChipsChange(event, row.id)
                    }}
                    inputValue={row.fieldValue}
                    onInputChange={(event, newInputValue) => {
                      handleValueChange(newInputValue, row.id)
                    }}
                    renderInput={(params) => {
                      const { InputProps, ...restParams } = params
                      const { startAdornment, ...restInputProps } = InputProps
                      return (
                        <TextField
                          {...restParams}
                          multiline
                          variant="standard"
                          label="Enter value"
                          size="small"
                          onKeyDown={(event) => handleKeyDown(event, row.id)}
                          InputProps={{
                            ...restInputProps,
                            startAdornment: (
                              <div
                                style={{
                                  maxHeight: '60px',
                                  overflowY: 'auto',
                                }}
                              >
                                {startAdornment}
                              </div>
                            ),
                          }}
                        />
                      )
                    }}
                    renderTags={(value, getTagProps) =>
                      value.map((option, tagIndex) => (
                        <Chip
                          variant="outlined"
                          size="small"
                          label={option}
                          key={tagIndex}
                          onDelete={() => {
                            handleChipDelete(row.id, option)
                          }}
                        />
                      ))
                    }
                  />
                )}
                <Tooltip title="Remove">
                  <IconButton
                    color="primary"
                    onClick={() => {
                      handleDeleteRow(row.id)
                    }}
                  >
                    <RemoveCircleOutlineRounded
                      style={{ width: 18, marginRight: 5, marginTop: 12 }}
                    />
                  </IconButton>
                </Tooltip>
              </div>
            )
          })}
        </DialogContent>
        <DialogActions>
          {action === false ? (
            <>
              <div>
                <Button autoFocus onClick={handleReset} color="primary">
                  Reset
                </Button>
              </div>
              <div>
                <Button autoFocus onClick={handleAddRow} color="primary">
                  Add
                </Button>
                <Button
                  autoFocus
                  onClick={(event) => handleSubmit(event)}
                  color="primary"
                >
                  Search
                </Button>
              </div>
            </>
          ) : (
            <>
              <Alert
                severity="warning"
                style={{ width: '100%' }}
                action={
                  <>
                    <Button
                      autoFocus
                      onClick={() => {
                        setAction(false)
                      }}
                      color="primary"
                    >
                      Cancel
                    </Button>
                    <Button
                      autoFocus
                      onClick={() => {
                        handleClasssificationChange(tempClass)
                        handleReset()
                      }}
                      color="primary"
                    >
                      OK
                    </Button>
                  </>
                }
              >
                Changing classification_name will reset other choices, are you
                sure?
              </Alert>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  )
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  fieldTextbar: {
    padddingRight: 25,
    minWidth: 200,
    width: 375,
    borderBottom: '2px solid white',
    marginRight: 15,
  },
  valueTextbar: {
    minWidth: 200,
    width: 315,
    borderTop: '5px solid white',
    marginRight: 15,
  },
  valueOperator: {
    minWidth: 90,
    width: 115,
    borderTop: '6px solid white',
    marginRight: 15,
    textAlign: 'center',
  },
  valueClass: {
    minWidth: 200,
    width: 250,
    // paddingTop: '10px'
    // borderTop: '5px solid white',
  },
  // highlight: {
  //   border: '2px solid #cc0000',
  //   borderRadius: '8px',
  //   backgroundColor: '#cc0000ab',
  //   margin: '2px'
  // }
})

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    justifyContent: 'space-between',
  },
}))(MuiDialogActions)

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const HelpIcon = withStyles((theme) => ({
  root: {
    verticalAlign: 'middle',
    marginBottom: '5px',
  },
}))(HelpOutlineIcon)

export default withStyles(styles, { withTheme: true })(
  withRouter(withAnalytics()(withEnv()(AdvancedSearch))),
)
