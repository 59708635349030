import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import DeviceLastSeenFormat from '../DeviceLastSeenFormat/DeviceLastSeenFormat'
import Dialog from '@material-ui/core/Dialog'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import Good from '@material-ui/icons/CheckCircleTwoTone'
import IconButton from '@material-ui/core/IconButton'
import ListItem from '@material-ui/core/ListItem'
import MenuItem from '@material-ui/core/MenuItem'
import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import Spinner from 'nicollet-react/es/components/Spinner/Spinner'
import SvcCICOActions from '../../../services/svcCICOActions'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import { withAnalytics } from '@praxis/component-analytics'
import { withEnv } from '@praxis/component-runtime-env'
import withRouter from '../../withRouter'
import { withStyles } from '@material-ui/styles'
// import InfoIcon from '@material-ui/icons/Info'

class DeviceLevelOpacAction extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      snackBarOpen: false,
      snackBarMessageInfo: '',
      actionSuccess: false,
      loading: false,
      incidentNumber: '',
      helperText: 'Required',
      error: false,
      disableButton: true,
      inputOption: [],
      selectedOptions: new Map(),
      statusData: [],
    }
    this.svcCICOActions = new SvcCICOActions()
    this.goBack = this.goBack.bind(this)
  }

  componentDidMount() {
    const {
      rowData: { id },
    } = this.props
    if (this.props.data.opac_name === 'Last five statuses') {
      this.setState({
        loading: true,
      })
    }
    this.props.data.opac_name === 'Last five statuses' &&
      this.svcCICOActions
        .getOpacStatus(id, this.props.env)
        .then((success) => {
          const eventData = {
            customMetrics: {
              metric1: 'TepOpacsSuccess',
              metric3: success.status,
            },
            event: {
              type: 'apiSuccessTepOpacs',
            },
          }
          this.props.trackEvent(eventData)
          if (Math.round(success.status / 100) === 2) {
            this.setState({
              statusData: success.data,
              loading: false,
            })
          }
        })
        .catch((error) => {
          const eventData = {
            customMetrics: {
              metric1: 'TepOpacs',
              metric3: error.response.status,
            },
            event: {
              type: 'apiErrorTepOpacs',
            },
          }
          this.props.trackEvent(eventData)
          if (
            Math.round(error.response.status / 100) === 5 ||
            Math.round(error.response.status / 100) === 4
          ) {
            if (
              error.response.data.message !== null ||
              error.response.data.message !== ''
            ) {
              var obj = JSON.parse(error.response.data.message)
              if (obj.message !== null) {
                this.setState({
                  snackBarOpen: true,
                  snackBarMessageInfo: obj.message,
                })
              } else {
                this.setState({
                  snackBarOpen: true,
                  snackBarMessageInfo:
                    'ERROR - An error has occurred while fetching the status',
                })
              }
            } else {
              this.setState({
                snackBarOpen: true,
                snackBarMessageInfo:
                  'ERROR - An error has occurred while fetching the status',
              })
            }
          } else {
            this.setState({
              snackBarOpen: true,
              snackBarMessageInfo:
                'ERROR - An error has occurred while fetching the status',
            })
          }
        })
        .finally(() => {
          this.setState({ loading: false })
        })
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    this.setState({ snackBarOpen: false })
  }

  goBack() {
    this.props.router.navigate(0)
  }

  handleChangeInput = (name) => (event) => {
    var selectedOptionsTemp = this.state.selectedOptions.set(
      name,
      event.target.value,
    )
    var disabledTemp = true
    if (event.target.value === '') {
      selectedOptionsTemp.delete(name)
    }
    if (
      selectedOptionsTemp.size === this.props.data.required_parameters.length
    ) {
      disabledTemp = false
    }
    this.setState({
      selectedOptions: this.state.selectedOptions.set(name, event.target.value),
      disableButton: disabledTemp,
    })
  }

  handleSubmit() {
    const {
      data,
      rowData: { id },
    } = this.props
    const { selectedOptions } = this.state
    this.setState({ loading: true })
    this.svcCICOActions
      .postOpacAction(data, id, selectedOptions, this.props.env)
      .then((success) => {
        const eventData = {
          customMetrics: {
            metric1: 'TepOpacsSuccess',
            metric3: success.status,
          },
          event: {
            type: 'apiSuccessTepOpacs',
          },
        }
        this.props.trackEvent(eventData)
        if (Math.round(success.status / 100) === 2) {
          this.setState({
            snackBarMessageInfo: `Action completed for ${id}`,
            snackBarOpen: true,
            actionSuccess: true,
            loading: false,
          })
        }
      })
      .catch((error) => {
        const eventData = {
          customMetrics: {
            metric1: 'TepOpacs',
            metric3: error.response.status,
          },
          event: {
            type: 'apiErrorTepOpacs',
          },
        }
        this.props.trackEvent(eventData)
        if (
          Math.round(error.response.status / 100) === 5 ||
          Math.round(error.response.status / 100) === 4
        ) {
          if (
            error.response.data.message !== null ||
            error.response.data.message !== ''
          ) {
            let obj
            // let obj = error.response.data.message
            //   ? JSON.parse(error.response.data.message)
            //   : 'ERROR - An error has occurred while executing the action'
            if (obj !== null) {
              this.setState({
                snackBarOpen: true,
                snackBarMessageInfo:
                  'ERROR - An error has occurred while executing the action',
              })
            } else {
              this.setState({
                snackBarOpen: true,
                snackBarMessageInfo:
                  'ERROR - An error has occurred while executing the action',
              })
            }
          } else {
            this.setState({
              snackBarOpen: true,
              snackBarMessageInfo:
                'ERROR - An error has occurred while executing the action',
            })
          }
        } else {
          this.setState({
            snackBarOpen: true,
            snackBarMessageInfo: 'ERROR - An error has occurred while action',
          })
        }
      })
      .finally(() => {
        this.setState({ loading: false })
      })
  }

  render() {
    const {
      classes,
      rowData: { id, label },
    } = this.props
    const { snackBarMessageInfo, actionSuccess, loading, disableButton } =
      this.state

    return (
      <Dialog
        fullWidth
        open={this.props.open}
        onClose={this.props.onClose}
        className={classes.dialog}
        maxWidth={
          this.props.data.opac_name === 'Last five statuses' ? 'lg' : 'sm'
        }
      >
        {this.props.data.opac_name === 'Last five statuses' ? (
          <DialogTitle className={classes.dialogTitle}>
            Last five statuses
          </DialogTitle>
        ) : (
          <DialogTitle className={classes.dialogTitle}>
            Confirm Device Action -{' '}
            {this.props.data.friendly_name
              ? this.props.data.friendly_name
              : this.props.data.opac_name}
          </DialogTitle>
        )}
        {this.props.data.opac_name !== 'Last five statuses' ? (
          <DialogContentText>
            <div>
              {this.props.data.friendly_name
                ? this.props.data.friendly_name
                : this.props.data.opac_name}{' '}
              for Device with Serial Number: "{id}" and Label: "{label}"
              <br />
            </div>
            {this.props.data.required_parameters &&
              this.props.data.required_parameters.map((param) => (
                <div>
                  <FormControl
                    fullWidth
                    className={classes.margin}
                    variant="outlined"
                  >
                    <TextField
                      id={param.name}
                      select={param.allowed_values ? true : false}
                      label={param.friendly_name}
                      onChange={this.handleChangeInput(param.name)}
                      value={this.state.inputOption.id}
                      style={{ margin: 16 }}
                      size="small"
                      variant="outlined"
                    >
                      {param.allowed_values &&
                        param.allowed_values.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option.toString()}
                          </MenuItem>
                        ))}
                    </TextField>
                  </FormControl>
                </div>
              ))}
          </DialogContentText>
        ) : (
          <DialogContentText>
            <Table className={classes.root}>
              <TableHead>
                <TableRow className={classes.cell}>
                  <TableCell className={classes.listItemHeader}>
                    Action
                  </TableCell>
                  <TableCell className={classes.listItemHeader}>
                    Timestamp
                  </TableCell>
                  <TableCell className={classes.listItemHeader}>
                    Status
                  </TableCell>
                  <TableCell className={classes.listItemHeader}>
                    Message
                  </TableCell>
                  <TableCell className={classes.listItemHeader}>
                    Lan ID
                  </TableCell>
                  <TableCell className={classes.listItemHeader}>UUID</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(this.state.statusData)
                  .sort((a, b) => (a[1].timestamp < b[1].timestamp ? 1 : -1))
                  .map((row, index) => {
                    return (
                      <TableRow key={index} className={classes.cell}>
                        <TableCell className={classes.listItem}>
                          {row[1].friendly_name}
                        </TableCell>
                        <TableCell className={classes.listItem}>
                          <DeviceLastSeenFormat
                            lastSeenValue={row[1].timestamp}
                          />
                        </TableCell>
                        <TableCell className={classes.listItem}>
                          {row[1].status}
                        </TableCell>
                        <TableCell className={classes.listItem}>
                          {row[1].status_list[0].result &&
                            row[1].status_list[0].result[0].details &&
                            row[1].status_list[0].result[0].details[
                              row[1].status_list[0].result[0].details.length - 1
                            ]}
                        </TableCell>
                        <TableCell className={classes.listItemHeader}>
                          {row[1].lan_id}
                        </TableCell>
                        <TableCell className={classes.listItemHeader}>
                          {row[1].uuid}
                        </TableCell>
                      </TableRow>
                    )
                  })}
              </TableBody>
            </Table>
          </DialogContentText>
        )}
        <IconButton
          aria-label="Close"
          className={classes.icon}
          onClick={this.props.onClose}
        >
          <CloseIcon />
        </IconButton>
        <div>
          {loading ? (
            <Spinner className={classes.spinner} />
          ) : (
            <ListItem className={classes.list}>
              {!actionSuccess ? (
                this.props.data.opac_name !== 'Last five statuses' && (
                  <React.Fragment>
                    <Tooltip
                      title={
                        this.state.disableButton === true
                          ? 'Enter all required parameters'
                          : 'Confirm Action'
                      }
                      placement="top"
                    >
                      <Button
                        className={classes.submitButton}
                        variant="outlined"
                        onClick={() => this.handleSubmit()}
                        disabled={
                          this.props.data.required_parameters === undefined
                            ? false
                            : disableButton
                        }
                      >
                        Confirm
                      </Button>
                    </Tooltip>
                    <Button
                      className={classes.cancelButton}
                      variant="outlined"
                      onClick={this.props.onClose}
                    >
                      CANCEL
                    </Button>
                  </React.Fragment>
                )
              ) : (
                <React.Fragment>
                  <Button
                    className={classes.successButton}
                    variant="outlined"
                    onClick={this.goBack}
                  >
                    BACK
                  </Button>
                  <Tooltip title="Success" placement="top">
                    <Good className={classes.good} />
                  </Tooltip>
                </React.Fragment>
              )}
            </ListItem>
          )}
        </div>
        <Snackbar
          open={this.state.snackBarOpen}
          autoHideDuration={5000}
          onClose={this.handleClose}
          message={snackBarMessageInfo}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.props.onClose}
            >
              <CloseIcon className={classes.CloseIcon} />
            </IconButton>,
          ]}
        />
      </Dialog>
    )
  }
}

const styles = (theme) => ({
  icon: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
  submitButton: {
    float: 'left',
  },
  successButton: {
    float: 'left',
  },
  cancelButton: {
    float: 'left',
    marginLeft: 5,
  },
  dialog: {
    textAlign: 'center',
  },
  dialogTitle: {
    marginTop: 20,
  },
  list: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 20,
  },
  spinner: {
    left: '40%',
  },
  good: {
    fontSize: 40,
    color: 'green',
    paddingLeft: 5,
  },
  searchTextbar: {
    minWidth: 200,
    width: '350px',
    paddingRight: '5px',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '25ch',
  },
})

export default withStyles(styles)(
  withRouter(withAnalytics()(withEnv()(DeviceLevelOpacAction))),
)
