import React from 'react'
import axios from 'axios'
import l4CamelCase from '../components/Devices/I10iExports/l4CamelCase'

class SvcI10iDevices extends React.Component {
  toCamel = (s) => {
    return s.replace(/([-_][a-z])/gi, ($1) => {
      return $1.toUpperCase().replace('-', '').replace('_', '')
    })
  }

  toFriendly = (s) => {
    return s.replace(/([-_][a-z])/gi, ($1) => {
      return $1.toUpperCase().replace('_', ' ')
    })
  }

  getResource = (resource, config) => {
    return axios.get(
      `${config.auth.i10iBase}counts?by_technology_domain=${resource}&key=${config.auth.apiKey}`,
    )
  }

  getI10iCounts(config, endpoint) {
    return axios.get(
      `${config.auth.i10iBase}${endpoint}&key=${config.auth.apiKey}`,
    )
  }
  getSingleDevice(config, uniqueId) {
    const i10iOptions = config.auth.i10iOptions
    const i10iBase = config.auth.i10iBase
    const apiUri =
      i10iBase +
      `?by_device_family=${l4CamelCase.join(
        ',',
      )}&search={"terms":{"uniqueId.keyword":["${uniqueId}"]}}` +
      i10iOptions +
      '&key=' +
      config.auth.apiKey
    return axios.get(apiUri)
  }

  getDevicesByLocation(config, deviceTypeList, location) {
    const i10iOptions = config.auth.i10iOptions
    const i10iBase = config.auth.i10iBase
    let paddedLocation = String(location).padStart(4, 0)
    let locationParams = `"T${paddedLocation}","t${paddedLocation}","${paddedLocation}"`

    let apiUri =
      i10iBase +
      `?by_device_family=${deviceTypeList
        .map((d) => {
          return this.toCamel(d)
        })
        .join(',')}&` +
      `search={"terms":{"tnum.keyword":[${locationParams}]}}&` +
      i10iOptions +
      '&key=' +
      config.auth.apiKey

    return axios.get(apiUri)
  }

  checkQuery(params) {
    let returnval = false
    // eslint-disable-next-line
    for (const [key, value] of Object.entries(params)) {
      if (value !== null) {
        returnval = true
      }
    }

    return returnval
  }

  getDevicesElasticQuery(config, submission) {
    const i10iOptions = config.auth.i10iOptions
    const i10iBase = config.auth.i10iBase
    var devices = l4CamelCase.filter((item) => item !== 'printer').join(',')
    let terms = []
    let val, page, output

    const filters = {
      serialNumber: null,
      modelName: null,
      locationId: null,
      id: null,
      uniqueId: null,
      tcin: null,
      ipAddress: null,
      deviceType: null,
      macAddress: null,
    }

    Object.entries(submission).forEach((param) => {
      let paramZero = this.toCamel(param[0])
      if (filters.hasOwnProperty(paramZero)) {
        filters[`${paramZero}`] = param[1]
      }
    })

    for (const [key, value] of Object.entries(filters)) {
      if (String(key) === 'deviceType' && value !== null) {
        devices = value
      } else {
        if (String(key) === 'ipAddress' && value !== null) {
          val = String(`{"network.layer3addr.keyword":["${value}"]}`)
          terms.push(val)
        } else if (String(key) === 'macAddress' && value != null) {
          val = String(
            `{"network.layer2addr.keyword":["${String(value)
              .match(/.{1,2}/g)
              .join(':')}"]}`,
          )
          terms.push(val)
        } else if (value !== null) {
          val = String(`{"${key}.keyword":["${value}"]}`)
          terms.push(val)
        }
      }
    }
    output = terms.join(',')

    if (submission.page === 0 || submission.page === 'END') {
      page = ''
    } else {
      // eslint-disable-next-line
      page = '&page=' + String(submission.page).replace(/\"/g, '')
    }

    //truncate terms clause to fetch all if no terms are given
    let searchTerms, query
    terms.length === 0
      ? (searchTerms = '')
      : (searchTerms = `&search={"terms":${output}}`)

    if (submission.classification_name && !this.checkQuery(filters)) {
      if (submission.classification_name === 'EndUser/EndPoint') {
        query = '?by_device_family=register,scale,rfidHandheld'
      } else {
        query = `?by_technology_domain=${submission.classification_name}`
      }
      return axios.get(
        i10iBase +
          query +
          i10iOptions +
          '&per_page=' +
          submission.per_page +
          page +
          '&key=' +
          config.auth.apiKey,
      )
    } else {
      return axios.get(
        i10iBase +
          `?by_device_family=${devices}` +
          searchTerms +
          i10iOptions +
          '&per_page=' +
          submission.per_page +
          page +
          '&key=' +
          config.auth.apiKey,
      )
    }
  }
}

export default SvcI10iDevices
