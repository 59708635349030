import React, { useContext, useEffect, useState } from 'react'
import {
  getUserSettings,
  setUserSettingsHome,
} from '../services/svcUserSettings'

import { useAnalytics } from '@praxis/component-analytics'
import { useAuth } from '@praxis/component-auth'

const UserSettingsContext = React.createContext({})

function UserSettingsContextProvider({ children }) {
  const [data, setData] = useState({
    isLoading: true,
    data: [],
    response: {},
  })

  const recentItems = 'recent_items'
  const [recentData, setRecentData] = useState({
    advanced_search: [],
    location_id: [],
    search: [],
  })

  const { isAuthenticated, login } = useAuth()
  const { trackEvent } = useAnalytics()

  !isAuthenticated() && login({ redirect: window.location.href })

  useEffect(() => {
    localStorage.getItem(recentItems) === null &&
      localStorage.setItem(recentItems, JSON.stringify(recentData))

    // validate incoming change before pushing to state to prevent recursion
    JSON.stringify(recentData) !== localStorage.getItem(recentItems) &&
      setRecentData(JSON.parse(localStorage.getItem(recentItems)))
  }, [recentData])

  useEffect(() => {
    setData({ isLoading: true })
    getUserSettings()
      .then((response) => {
        const eventData = {
          customMetrics: {
            metric1: 'UserSettingsGet',
            metric3: response.status,
          },
          event: {
            type: `apiSuccessGetUserSettings`,
          },
        }
        trackEvent(eventData)
        setData({
          isLoading: false,
          data: response.data,
          response: response,
        })
      })
      .catch((error) => {
        const eventData = {
          customMetrics: {
            metric1: 'UserSettingsGet',
            metric3: error.response.status,
          },
          event: {
            type: `apiErrorGetUserSettings`,
          },
        }
        trackEvent(eventData)
        setData({
          isLoading: false,
          data: { home: { type: null, value: null } },
          response: error.response,
        })
      })
  }, [trackEvent])

  function updateHome(homeType, homeValue) {
    setData({ isLoading: true })
    setUserSettingsHome(homeType, homeValue)
      .then((response) => {
        const eventData = {
          customMetrics: {
            metric1: 'UserSettingsPut',
            metric3: response.status,
          },
          event: {
            type: `apiSuccessPutUserSettings`,
          },
        }
        trackEvent(eventData)
        setData({
          isLoading: false,
          data: response.data,
          response: response,
        })
      })
      .catch((error) => {
        console.log(error)
        const eventData = {
          customMetrics: {
            metric1: 'UserSettingsPut',
            metric3: error.response.status,
          },
          event: {
            type: `apiErrorPutUserSettings`,
          },
        }
        trackEvent(eventData)
        setData({
          isLoading: false,
          data: { home: { type: null, value: null } },
          response: error.response,
        })
      })
  }

  function updateRecent(recentItemType, recentItem) {
    setRecentData((prevState) => {
      if (prevState[recentItemType].indexOf(recentItem) === -1) {
        return prevState[recentItemType].unshift(recentItem)
      }
    })
    localStorage.setItem(recentItems, JSON.stringify(recentData))
  }

  function deleteRecentNested(recentItemType, recentItem) {
    setRecentData((prevState) => {
      const filteredList = prevState[recentItemType].filter(function (item) {
        return item.query !== recentItem.query
      })
      prevState[recentItemType] = filteredList
      return prevState
    })
    localStorage.setItem(recentItems, JSON.stringify(recentData))
  }

  function deleteRecent(recentItemType, recentItem) {
    setRecentData((prevState) => {
      const filteredList = prevState[recentItemType].filter(function (item) {
        return item !== recentItem
      })
      prevState[recentItemType] = filteredList
      return prevState
    })
    localStorage.setItem(recentItems, JSON.stringify(recentData))
  }

  function clearRecentCache() {
    localStorage.removeItem(recentItems)
    setRecentData({
      advanced_search: [],
      location_id: [],
      search: [],
    })
  }

  return (
    <UserSettingsContext.Provider
      updateHome={updateHome}
      value={{
        data,
        updateHome,
        recentData,
        updateRecent,
        deleteRecent,
        deleteRecentNested,
        clearRecentCache,
      }}
      loading={data.isLoading}
    >
      {children}
    </UserSettingsContext.Provider>
  )
}

export default UserSettingsContextProvider

export function useUserSettingsContext() {
  const context = useContext(UserSettingsContext)
  if (context === undefined) {
    throw new Error('Context must be used within a Provider')
  }
  return context
}
