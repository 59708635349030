import {
  Card,
  CardHeader,
  Divider,
  Grid,
  List,
  ListItem,
  Typography,
} from '@material-ui/core'
import { useEffect, useState } from 'react'

import { UserSettingsHome } from '../../UserSettings/UserSettingsHome'
import { useUserSettingsContext } from '../../../contexts/UserSettingsContext'
import { withStyles } from '@material-ui/styles'

const DashboardLocation = ({ classes, locationFullDetails }) => {
  const userSettings = useUserSettingsContext()
  const [locationSet, setLocation] = useState(false)

  useEffect(() => {
    if (!locationSet && locationFullDetails.length !== 0) {
      setLocation(true)
      userSettings.updateRecent(
        'location_id',
        locationFullDetails.location_id.padStart(4, 0),
      )
    }
  }, [userSettings, locationSet, locationFullDetails, setLocation])

  if (locationFullDetails.length !== 0) {
    const lFD = locationFullDetails
    const storeTitle = `T${lFD.location_id.padStart(4, 0)} ${lFD.name}`
    const model = lFD.format
    const storeRGD = `R:${lFD.region_id} G:${lFD.group_id} D:${lFD.district_id}`
    const phone = lFD.telephone_number
    const address = `${lFD.address[0].address_line1}, ${lFD.address[0].city}, ${
      lFD.address[0].region
    } ${lFD.address[0].postal_code.substring(0, 5)}`
    return (
      <div className={classes.div}>
        <Grid container spacing={1}>
          <Grid item xs={8}>
            <Card elevation={0} className={classes.header}>
              <CardHeader
                title={
                  <Typography className={classes.title}>
                    {storeTitle}
                  </Typography>
                }
                subheader={
                  locationFullDetails.type === 'STR' &&
                  `${storeRGD} - (${model})`
                }
                avatar={
                  <UserSettingsHome
                    homeType="location_id"
                    homeValue={lFD.location_id.padStart(4, 0)}
                  />
                }
              ></CardHeader>
            </Card>
          </Grid>
          <Grid
            item
            container
            xs={4}
            alignItems="center"
            justifyContent="flex-end"
          >
            <List dense disablePadding>
              <ListItem className={classes.details}>
                <Typography variant="subtitle2">{address}</Typography>
              </ListItem>
              <ListItem className={classes.details}>
                <Typography variant="subtitle2">
                  {lFD.telephone_number && phone}
                </Typography>
              </ListItem>
            </List>
          </Grid>
        </Grid>
        <Divider />
      </div>
    )
  } else {
    return (
      <div className={classes.div}>
        <Typography className={classes.padLeft}>
          Details are not available for this location
        </Typography>
      </div>
    )
  }
}

const styles = () => ({
  div: {
    backgroundColor: 'white',
  },
  title: {
    fontWeight: 'bold',
    fontSize: 20,
  },
  details: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: 0,
    paddingRight: 10,
    paddingBottom: 0,
  },
  padLeft: {
    paddingLeft: 23,
    fontWeight: 'bold',
    fontSize: 14,
  },
})

export default withStyles(styles, { withTheme: true })(DashboardLocation)
