class ValidateOrgGroup {
  ValidateOrgGroupPattern = (orgGroup) => {
    var regexString = /^\d{4}$/
    if (regexString.test(orgGroup)) {
      return false
    } else {
      return true
    }
  }

  ValidateIncidentNumberPattern = (incidentNumber) => {
    var regexString = /^INT\d{8}$/
    if (regexString.test(incidentNumber)) {
      return false
    } else {
      return true
    }
  }
}
export default ValidateOrgGroup
