import FileDownloadButton from '@material-ui/icons/CloudDownload'
import IconButton from '@material-ui/core/IconButton'
import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import { withAnalytics } from '@praxis/component-analytics'
import { withEnv } from '@praxis/component-runtime-env'
import { withStyles } from '@material-ui/core/styles'
import { CSVLink } from 'react-csv'
import SvcI10iDevices from '../../../services/svcI10iDevices'
import { CircularProgress } from '@material-ui/core'

class CustomDownloadI10i extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      showDialog: false,
      loading: false,
      loaded: false,
      csvData: '',
    }
    this.svci10iDevices = new SvcI10iDevices()
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    this.setState({ showDialog: false })
  }

  downLoadData = (page) => {
    const submission = this.props.submission
    submission.page = page
    submission.per_page = 3000

    this.svci10iDevices
      .getDevicesElasticQuery(this.props.config, submission)
      .then((response) => {
        let data = response.data
        let csvOutput = []

        let page = response.headers.rel

        //map through the entire array of objects ...
        data.map((object) => {
          //enumerate the keys of each object, check if the value points to an object .. if not, add it to csv output
          let tempObj = {}
          Object.keys(object).map((key) => {
            if (!(typeof object[key] === 'object')) {
              // push the key value pair if it is not an instance of object ...
              tempObj[key] = object[key]
            }
            return null
          })
          return csvOutput.push(tempObj)
        })
        this.setState({ data: this.state.data.concat(csvOutput) })
        page !== 'END'
          ? this.downLoadData(page)
          : this.setState({ loaded: true, loading: false })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  handleClick = () => {
    this.setState({ loading: true })
    this.downLoadData(0)
  }
  render() {
    return (
      <>
        {this.state.loading === true ? (
          <CircularProgress
            size={20}
            style={{ marginTop: 20, marginLeft: 15 }}
          ></CircularProgress>
        ) : (
          <Tooltip
            title={`Download ${this.props.totalCount} Result(s) ${
              this.props.totalCount > 3000 ? '-this may take some time' : ''
            }`}
          >
            <IconButton
              disabled={this.state.loaded || this.props.totalCount === 0}
              onClick={() => {
                this.handleClick()
              }}
            >
              <FileDownloadButton />
            </IconButton>
          </Tooltip>
        )}

        {this.state.loaded && (
          <>
            <CSVLink data={this.state.data} filename={'i10iInventory.csv'}>
              Download
            </CSVLink>
          </>
        )}
      </>
    )
  }
}

export default withStyles()(withAnalytics()(withEnv()(CustomDownloadI10i)))
