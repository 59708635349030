import CloseIcon from '@material-ui/icons/CloseOutlined'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import Grow from '@material-ui/core/Grow'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import MenuItem from '@material-ui/core/MenuItem'
import React from 'react'
import SearchIcon from '@material-ui/icons/Search'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import { Typography } from '@material-ui/core'
import ValidateMacIpAddress from '../../../utilities/ValidateMacIpAddress'
import { fade } from '@material-ui/core/styles/colorManipulator'
import queryString from 'query-string'
import { withAnalytics } from '@praxis/component-analytics'
import { withEnv } from '@praxis/component-runtime-env'
import { withStyles } from '@material-ui/core/styles'

const defaultSearchStyles = (theme) => ({
  main: {
    display: 'flex',
    flex: '1 0 auto',
  },
  searchText: {
    flex: '0.8 0',
  },
  clearIcon: {
    '&:hover': {
      color: theme.palette.error.main,
    },
  },
  searchTextbar: {
    padddingRight: 25,
    minWidth: 375,
    width: 375,
    borderBottom: '2px solid #666',
    marginRight: 25,
  },
  dropDown: {
    marginLeft: 20,
    minWidth: 250,
    width: 250,
    marginRight: 25,
  },
  submitButton: {
    color: '#666',
    '&:hover, &.Mui-focusVisible': {
      backgroundColor: `${fade('#666', 0.75)}`,
      color: '#fff',
    },
  },
})

class CustomSearch extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      label: 'Enter a Device ID',
      dropDownLabel: 'ID',
      selected: 'id',
      searchText: '',
      error: false,
    }
    this.validateMacIpAddress = new ValidateMacIpAddress()
  }
  handleTextChange = (event) => {
    this.props.onSearch(event.target.value)
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onKeyDown, false)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown, false)
  }

  onKeyDown = (event) => {
    if (event.keyCode === 27) {
      this.props.onHide()
    }
  }

  redirect = () => {
    var searchText = this.state.searchText
    if (this.state.selected === 'mac_address') {
      if (searchText.match(/:|-/g)) {
        searchText = this.state.searchText.replace(/:|-/g, '')
      }
    }
    if (window.location.search.includes(this.state.selected)) {
      delete this.props.submission[this.state.selected]
    }

    let redirectParams = queryString.stringify(
      Object.assign(
        {
          [this.state.selected]: searchText,
        },
        this.props.submission,
      ),
      {
        sort: false,
        skipNull: true,
      },
    )
    window.location = window.location.origin + `/devices?${redirectParams}`
  }

  handleSubmit(event) {
    const eventData = {
      customInteraction: {
        key: 'CustomSearchbar',
        value: this.state.selected,
      },
    }
    this.props.trackEvent(eventData)
    this.redirect()
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    })
    if (this.state.selected === 'mac_address') {
      this.setState({
        error: this.validateMacIpAddress.validateMacAddress(event.target.value),
        errorLabel:
          this.props.env.devicesSearchItems[this.state.selected].error_label,
      })
    }
    if (this.state.selected === 'ip_address') {
      this.setState({
        error: this.validateMacIpAddress.validateIpAddress(event.target.value),
        errorLabel:
          this.props.env.devicesSearchItems[this.state.selected].error_label,
      })
    }
  }

  handleClick = (event) => {
    this.setState({
      label: event.target.value.search_label,
      selected: event.target.value.search_param,
      dropDownLabel: event.target.value.chip_label,
      error: false,
      searchText: '',
    })
  }

  selectOnOff = () => {
    // turns select on if object contains a search_suggestions array and is selected
    const res = Object.entries(this.props.env.devicesSearchItems).filter(
      (f) =>
        f[0] === this.state.selected &&
        f[1].hasOwnProperty('search_suggestions'),
    )
    return res.length > 0
  }

  render() {
    const { classes } = this.props

    return (
      <Grow appear in={true} timeout={300}>
        <div className={classes.main} ref={(el) => (this.rootRef = el)}>
          <TextField
            className={classes.dropDown}
            select
            onChange={this.handleClick}
            margin="dense"
            variant="standard"
            color="primary"
            SelectProps={{
              value: this.state.dropDownLabel,
              disableUnderline: true,
              renderValue: (value) => value,
              SelectDisplayProps: {
                style: {
                  color: '#666',
                  borderBottom: '2px solid #666',
                },
              },
            }}
            label={
              <Typography variant="button" style={{ color: '#666' }}>
                Refine Search Results
              </Typography>
            }
          >
            {Object.entries(this.props.env.devicesSearchItems).map(
              (row, index) => {
                return (
                  row[1].hasOwnProperty('custom_search') && (
                    <MenuItem key={index} value={row[1]}>
                      {row[1].chip_label}
                    </MenuItem>
                  )
                )
              },
            )}
          </TextField>
          <TextField
            value={this.state.searchText}
            onChange={this.handleChange('searchText')}
            InputProps={{
              disableUnderline: true,
              endAdornment: this.state.error ? (
                <InputAdornment position="end">
                  <Tooltip title="Enter a valid Mac Address">
                    <ErrorOutlineIcon style={{ color: '#666' }} />
                  </Tooltip>
                </InputAdornment>
              ) : (
                <InputAdornment position="end">
                  <Tooltip title="Refine Search">
                    <IconButton
                      className={classes.submitButton}
                      variant="outlined"
                      disabled={this.state.error}
                      onClick={(event) => this.handleSubmit(event)}
                    >
                      <SearchIcon></SearchIcon>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Close Search">
                    <IconButton
                      className={classes.submitButton}
                      variant="outlined"
                      onClick={this.props.onHide}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            className={classes.searchTextbar}
            margin="dense"
            variant="standard"
            label={
              this.state.error ? (
                <Typography variant="button" style={{ color: '#666' }}>
                  {this.state.errorLabel}
                </Typography>
              ) : (
                <Typography variant="button" style={{ color: '#666' }}>
                  {this.state.label}
                </Typography>
              )
            }
            error={this.state.error}
            select={this.selectOnOff()}
            SelectProps={{
              classes: {
                icon: classes.icon,
              },
              disableUnderline: true,
              SelectDisplayProps: {},
            }}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter') {
                this.handleSubmit()
                ev.preventDefault()
              }
            }}
          >
            {/* this is unused for now */}
            {this.state.selected === 'management_system' &&
              this.props.env.devicesSearchItems.management_system.search_suggestions
                .sort()
                .map((option, index) => (
                  <MenuItem key={index} dense value={option}>
                    {option}
                  </MenuItem>
                ))}
          </TextField>
        </div>
      </Grow>
    )
  }
}

export default withStyles(defaultSearchStyles, { name: 'CustomSearchRender' })(
  withAnalytics()(withEnv()(CustomSearch)),
)
