export const btscannersDeviceTypes = {
  bluetoothscanner: {
    device_classification: 'bluetoothscanner',
    platform: 'bluetoothscanner',
    itemIcon: '',
    itemText: 'bluetoothscanner',
    itemFilter: 'filterBluetoothScanner',
    availableActions: [
      'btscannersLostStolen',
      'editBTScannersLabel',
      'deleteBTScanners',
    ],
    availableHeathItems: [],
  },
}

export default btscannersDeviceTypes
