// import ComputerActionsMenu from '../DeviceActionMenu/ComputerActionMenu'
import DeviceLastSeenFormat from '../DeviceLastSeenFormat/DeviceLastSeenFormat'
import Tooltip from '@material-ui/core/Tooltip'
// import { computerFilterMap } from '../deviceFilterMap/computerFilterMap'

export function computercolumns(filterList, customFiltersComputers, platform) {
  if (platform === 'windows' || platform === 'mac' || platform === 'computer') {
    // var arrComputer = []
    // Object.entries(filterList).flatMap((item) => {
    //   if (item[1] === true) {
    //     arrComputer.push(computerFilterMap[item[0]])
    //   }
    // })
    // customFiltersComputers.map((item) => arrComputer.push(item))
    return [
      {
        name: 'computer_name',
        label: 'Computer Name',
        options: {
          filter: false,
        },
      },
      {
        name: 'id',
        label: 'ID',
        options: {
          filter: false,
        },
      },
      {
        name: 'model_name',
        label: 'Model',
        options: {
          filter: true,
        },
      },
      {
        name: 'logged_in_user',
        label: 'User',
        options: {
          filter: false,
        },
      },
      {
        name: 'operating_system',
        label: 'OS Name',
        options: {
          filter: true,
        },
      },
      {
        name: 'location_id',
        label: 'Location',
        options: {
          filter: false,
        },
      },
      {
        name: 'updated_on',
        label: 'Updated On',
        options: {
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return value ? (
              <Tooltip title={value}>
                <div>
                  <DeviceLastSeenFormat lastSeenValue={value} />
                </div>
              </Tooltip>
            ) : (
              'N/A'
            )
          },
        },
      },
      // {
      //   name: 'id',
      //   label: 'Actions',
      //   options: {
      //     filter: false,
      //     sort: false,
      //     customBodyRender: (value, tableMeta, updateValue) => {
      //       const rowData = new ComputerRowData(tableMeta.rowData)
      //       return <ComputerActionsMenu rowData={rowData} />
      //     },
      //   },
      // },
    ]
  }
}

export class ComputerRowData {
  id
  model_name
  logged_in_user
  operating_system
  location_id
  updated_on

  constructor(rowData) {
    if (rowData !== undefined) {
      this.id = rowData[1]
      this.model_name = rowData[2]
      this.logged_in_user = rowData[3]
      this.operating_system = rowData[4]
      this.location_id = rowData[5]
      this.updated_on = rowData[6]
    }
  }
}

export default computercolumns
