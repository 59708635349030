import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'

import Devices from '../../../images/devices.svg'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import MUIDataTable from 'mui-datatables'
import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/styles'

class TableDataApplications extends React.Component {
  render() {
    return (
      <>
        <MuiThemeProvider theme={this.getMuiTheme()}>
          <MUIDataTable
            data={this.props.storeCounts}
            title={`Installed Locations Count: ${this.props.totalStoresCount}`}
            options={{
              selectableRows: 'none',
              rowHover: false,
              search: true,
              pagination: true,
              filter: true,
              print: true,
              download: true,
              elevation: 0,
              viewColumns: false,
              rowsPerPage: 50,
              rowsPerPageOptions: [10, 25, 50, 100, 250],
            }}
            columns={[
              {
                name: 'location',
                label: 'Location',
                options: {
                  filter: true,
                  sort: false,
                },
              },
              {
                name: 'count',
                label: 'Installed Count',
                options: {
                  filter: false,
                  sort: false,
                  customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                      value &&
                      value !== 'null' && (
                        <>
                          {value}
                          <Tooltip
                            title={`Click to see the device list of the application in devices page`}
                          >
                            <IconButton
                              onClick={() => {
                                window.location =
                                  window.location.origin +
                                  '/devices?classification_name=' +
                                  this.props.classification_name +
                                  '&products.name=' +
                                  encodeURIComponent(this.props.rowData[0]) +
                                  '&products.version_name=' +
                                  encodeURIComponent(this.props.rowData[1]) +
                                  '&device_state=Online|Offline|Lost|Enrolled|Bonded|Quarantined|Repair|Deleted|Blocked|Converted|Stolen' +
                                  '&location_id=' +
                                  tableMeta.rowData[0]
                              }}
                            >
                              <Devices />
                            </IconButton>
                          </Tooltip>
                        </>
                      )
                    )
                  },
                },
              },
            ]}
          />
        </MuiThemeProvider>
        <Divider style={{ backgroundColor: 'black' }} />
      </>
    )
  }
  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTableBodyRow: {
          root: {
            backgroundColor: '#eeeeee',
            textAlign: 'center',
          },
        },
        MUIDataTableHeadRow: {
          root: {
            height: 24,
          },
        },
        MUIDataTableBodyCell: {
          root: {
            padding: 0,
          },
        },
        MUIDataTableHeadCell: {
          root: {
            padding: 0,
          },
        },
        MUIDataTableToolbar: {
          titleText: {
            fontSize: 15,
          },
        },
      },
    })
}

const styles = (theme) => ({
  root: {
    backgroundColor: '#eeeeee',
    padding: 'dense',
    fontSize: '40pt',
    verticalAlign: 'top',
  },
  cell: {
    height: 10,
    verticalAlign: 'top',
  },
  honeyComb: {
    minHeight: `calc(100vh - 75px)`,
    backgroundImage:
      `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='24.5' ` +
      `viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%23888888' fill-opacity='0.50' ` +
      `fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 ` +
      `11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 ` +
      `0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
  },
  listItem: {
    verticalAlign: 'top',
    height: 10,
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 5,
    border: 'none',
  },
})

export default withStyles(styles)(TableDataApplications)
