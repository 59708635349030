import {
  AccountTree,
  AddShoppingCart,
  CloudCircle,
  Computer,
  DeviceHub,
  Dns,
  EvStation,
  LibraryMusic,
  Lock,
  Phone,
  PhoneAndroid,
  Power,
  PowerInput,
  Print,
  Radio,
  Router,
  Scanner,
  SettingsEthernet,
  SettingsInputComposite,
  Storage,
  StorageOutlined,
  SupervisorAccount,
  TabletAndroid,
  TouchApp,
  VpnKey,
} from '@material-ui/icons'

import RingScanners from '../../images/ringScanner.svg'
import Scale from '../../images/scaleLanding.svg'

export const landingNavigationConfig = {
  NavigationGroups: {
    'Camera access control': {
      enabled: true,
      locationType: ['all', 'store'],
      startExpanded: false,
      stateName: 'cameraAccessControl',
      NavigationItems: {
        'Door Access Control': {
          enabled: true,
          locationType: ['all', 'store'],
          tabValue: 24,
          icon: <Lock />,
        },
      },
    },
    Compute: {
      enabled: true,
      locationType: ['all', 'store'],
      startExpanded: false,
      stateName: 'compute',
      NavigationItems: {
        'Baremetal Server': {
          enabled: true,
          locationType: ['all', 'store'],
          tabValue: 13,
          icon: <Storage />,
        },
        'Server Chassis': {
          enabled: true,
          locationType: ['all', 'store'],
          tabValue: 14,
          icon: <StorageOutlined />,
        },
      },
    },
    'Enduser/Endpoint': {
      enabled: true,
      locationType: ['all', 'store', 'dc', 'hq'],
      startExpanded: true,
      stateName: 'enduserEndpoint',
      NavigationItems: {
        Handheld: {
          enabled: true,
          locationType: ['all', 'store', 'dc'],
          tabValue: 1,
          icon: <PhoneAndroid />,
        },
        Tablet: {
          enabled: true,
          locationType: ['all', 'store', 'dc'],
          tabValue: 2,
          icon: <TabletAndroid />,
        },
        'Bluetooth Scanner': {
          enabled: true,
          locationType: ['all', 'store'],
          tabValue: 3,
          icon: <RingScanners />,
        },
        // Camera: {
        //   enabled: true,
        //   locationType: ['all', 'store'],
        //   tabValue: 4,
        //   icon: <Videocam />,
        // },
        Printer: {
          enabled: true,
          locationType: ['all', 'store'],
          tabValue: 5,
          icon: <Print />,
        },
        Computer: {
          enabled: true,
          locationType: ['all', 'store', 'hq', 'dc'],
          tabValue: 6,
          icon: <Computer />,
        },
        Register: {
          enabled: true,
          locationType: ['all', 'store'],
          tabValue: 15,
          icon: <AddShoppingCart />,
        },
        'Retail Scale': {
          enabled: true,
          locationType: ['all', 'store'],
          tabValue: 17,
          icon: <Scale />,
        },
        'RFID handheld': {
          enabled: true,
          locationType: ['all', 'store'],
          tabValue: 25,
          icon: <Scanner />,
        },
      },
    },
    Network: {
      enabled: true,
      locationType: ['all', 'store'],
      startExpanded: false,
      stateName: 'network',
      NavigationItems: {
        'Network Access Point': {
          enabled: true,
          locationType: ['all', 'store'],
          tabValue: 18,
          icon: <DeviceHub />,
        },
        'Network Chassis': {
          enabled: true,
          locationType: ['all', 'store'],
          tabValue: 19,
          icon: <Dns />,
        },
        'Network Gateway': {
          enabled: true,
          locationType: ['all', 'store'],
          tabValue: 20,
          icon: <VpnKey />,
        },
        'Network Management': {
          enabled: true,
          locationType: ['all', 'store'],
          tabValue: 23,
          icon: <SupervisorAccount />,
        },
        'Network Router': {
          enabled: true,
          locationType: ['all', 'store'],
          tabValue: 22,
          icon: <Router />,
        },
        'Network Switch': {
          enabled: true,
          locationType: ['all', 'store'],
          tabValue: 21,
          icon: <SettingsInputComposite />,
        },
        'Network Traffic Management': {
          enabled: true,
          locationType: ['all', 'store'],
          tabValue: 28,
          icon: <SettingsEthernet />,
        },
      },
    },
    Power: {
      enabled: true,
      locationType: ['all', 'store'],
      startExpanded: false,
      stateName: 'power',
      NavigationItems: {
        ATS: {
          enabled: true,
          locationType: ['all', 'store'],
          tabValue: 8,
          icon: <AccountTree />,
        },
        Generator: {
          enabled: true,
          locationType: ['all', 'store'],
          tabValue: 10,
          icon: <EvStation />,
        },
        PDU: {
          enabled: true,
          locationType: ['all', 'store'],
          tabValue: 9,
          icon: <PowerInput />,
        },
        UPS: {
          enabled: true,
          locationType: ['all', 'store'],
          tabValue: 7,
          icon: <Power />,
        },
      },
    },
    'Property Management': {
      enabled: true,
      locationType: ['all', 'store'],
      startExpanded: false,
      stateName: 'propertyManagement',
      NavigationItems: {
        'Submetering Controls': {
          enabled: true,
          locationType: ['all', 'store'],
          tabValue: 29,
          icon: <TouchApp />,
        },
      },
    },
    'Voice and Collaboration': {
      enabled: true,
      locationType: ['all', 'store'],
      startExpanded: false,
      stateName: 'voiceCollab',
      NavigationItems: {
        Broadcast: {
          enabled: true,
          locationType: ['all', 'store'],
          tabValue: 30,
          icon: <Radio />,
        },
        'Digital Media Player': {
          enabled: true,
          locationType: ['all', 'store'],
          tabValue: 12,
          icon: <LibraryMusic />,
        },
        Phone: {
          enabled: true,
          locationType: ['all', 'store'],
          tabValue: 27,
          icon: <Phone />,
        },
        'Voice Gateway': {
          enabled: true,
          locationType: ['all', 'store'],
          tabValue: 11,
          icon: <CloudCircle />,
        },
      },
    },
  },
}

export default landingNavigationConfig
