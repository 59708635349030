import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'

import Collapse from '@material-ui/core/Collapse'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import HandheldsColumnConfig from '../TableColumnsConfig/HandheldsColumnConfig'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Paper from '@material-ui/core/Paper'
import React from 'react'
import Spinner from 'nicollet-react/es/components/Spinner/Spinner'
import Table from '@material-ui/core/Table'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TopPanel from '../TopPanel/TopPanel'
import Typography from '@material-ui/core/Typography'
import axios from 'axios'
import moment from 'moment'
import svcHandhelds from '../../../services/svcHandhelds'
import svcTablets from '../../../services/svcTablets'
import { withAnalytics } from '@praxis/component-analytics'
import { withEnv } from '@praxis/component-runtime-env'
import { withStyles } from '@material-ui/styles'

class DashboardPlatformsAndroid extends React.Component {
  _isMounted = false
  constructor(props) {
    super(props)
    this.state = {
      handhelds: [],
      tablets: [],
      handheldsLoading: false,
      tabletsLoading: false,
      handheldsUtilizationLoading: false,
      storeMissingDevices: [],
      storeActiveDevices: [],
      storeBatteryIssues: [],
      storeDeviceLockerdevices: [],
      storeDirectBootModeDevices: [],
      storeLostStolenHistory: [],
      storeMPTCStatus: [],
      filterValue: [],
      filterDBModeValue: [],
      filterMPTCDisconnectedValue: [],
      filterDeviceState: [],
      filterDeviceModel: [],
      filterDeviceType: [],
    }
    this.svcHandhelds = new svcHandhelds()
    this.svcTablets = new svcTablets()
    this.onChangeValueHandler = this.onChangeValueHandler.bind(this)
    this.onDirectBootModeClickHandler =
      this.onDirectBootModeClickHandler.bind(this)
    this.onFilterDeviceStateClickHandler =
      this.onFilterDeviceStateClickHandler.bind(this)
    this.onFilterDeviceModelClickHandler =
      this.onFilterDeviceModelClickHandler.bind(this)
    this.onFilterDeviceTypeClickHandler =
      this.onFilterDeviceTypeClickHandler.bind(this)
    this.onMPTCDisconnectedClickHandler =
      this.onMPTCDisconnectedClickHandler.bind(this)
    this.onFilterChange = this.onFilterChange.bind(this)
  }

  onChangeValueHandler = (val) => {
    /* filterStates is to add states for 
    Electronic CI/CO states while filtering */
    let temp = []
    let stateList = []
    if (!this.state.filterValue) {
      temp.push(val)
      stateList.push('Online')
      stateList.push('Offline')
      stateList.push('Bonded')
    } else if (!this.state.filterValue.includes(val)) {
      temp.push(val)
      stateList.push('Online')
      stateList.push('Offline')
      stateList.push('Bonded')
    } else {
      temp.pop()
    }
    this.setState({
      filterDeviceState: stateList,
      filterValue: temp,
      filterDBModeValue: null,
    })
  }

  onDirectBootModeClickHandler = () => {
    let filterDBModeValuetemp = []
    filterDBModeValuetemp.push('TRUE')
    this.setState({
      filterDBModeValue: filterDBModeValuetemp,
    })
  }
  onMPTCDisconnectedClickHandler = () => {
    let filterMPTCDisconnectedTemp = []
    filterMPTCDisconnectedTemp.push('TRUE')
    this.setState({
      filterMPTCDisconnectedValue: filterMPTCDisconnectedTemp,
    })
  }
  onFilterDeviceStateClickHandler = (state) => {
    this.setState({
      filterDeviceState: [state],
    })
  }
  onFilterDeviceModelClickHandler = (model) => {
    this.setState({
      filterDeviceModel: [model],
    })
  }

  onFilterDeviceTypeClickHandler = (type) => {
    this.setState({
      filterDeviceType: [type],
    })
  }

  onFilterChange = (columnName, filterChanged) => {
    columnName === 'model_name' &&
      this.setState({
        filterDeviceModel: filterChanged,
      })
    columnName === 'device_state' &&
      this.setState({
        filterDeviceState: filterChanged,
      })
    columnName === 'department' &&
      this.setState({
        filterDeviceType: filterChanged,
      })
    columnName === 'device_event_state' &&
      this.setState({
        filterValue: filterChanged,
      })
    columnName === 'direct_boot_mode' &&
      this.setState({
        filterDBModeValue: filterChanged,
      })
    columnName === 'e355_pinpad_information' &&
      this.setState({
        filterMPTCDisconnectedValue: filterChanged,
      })
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  componentDidMount() {
    this._isMounted = true
    this.setState({
      handheldsUtilizationLoading: true,
      handheldsLoading: true,
      tabletsLoading: true,
    })
    let start = moment().format()
    let end = moment()
      .subtract(`${this.props.env.lostStolenCountdown}`, 'days')
      .format()
    const submission = {
      location_id: this.props.locationId,
    }
    this._isMounted &&
      axios
        .all([
          this.svcHandhelds
            .getHandhelds(this.props.env, submission)
            .catch((err) => null),
          this.svcTablets
            .getTablets(this.props.env, submission)
            .catch((err) => null),
        ])
        .then((values) => {
          values.forEach((row) => {
            row &&
              row.data.forEach((row2) => {
                if (
                  row2.device_state === 'Lost' ||
                  row2.device_state === 'Stolen'
                ) {
                  this.state.storeLostStolenHistory.push({
                    serial_number: row2.serial_number,
                    model: row2.model_name,
                    manufacturer: row2.manufacturer_name,
                    device_type: row2.department,
                    last_user: row2.last_user,
                    lost_user: row2.lost_user,
                    updated_on: row2.updated_on,
                    lost_event_timestamp: row2.lost_event_timestamp,
                    state: row2.device_state,
                  })
                }
              })
          })
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this._isMounted && this.setState({ handheldsLoading: false })
        })
    this._isMounted &&
      this.svcHandhelds
        .getHandhelds(this.props.env, submission)
        .then((response) => {
          const eventData = {
            customMetrics: {
              metric1: 'Handheld',
              metric3: response.status,
            },
            event: {
              type: `apiSuccessHandheld`,
            },
          }
          this.props.trackEvent(eventData)
          response.data.map((row, index) => {
            if (
              row.device_state === 'Missing' ||
              row.device_state === 'Offline'
            ) {
              this.state.storeMissingDevices.push({
                serial_number: row.serial_number,
                device_label: row.label,
                last_seen: row.updated_on,
              })
            } else if (
              (row.device_state === 'Active' ||
                row.device_state === 'Online') &&
              (row.department === 'myDevice' || row.department === 'myCheckout')
            ) {
              this.state.storeActiveDevices.push({
                serial_number: row.serial_number,
                device_label: row.label,
                last_seen: row.updated_on,
              })
            }
            if (
              row.battery_status &&
              row.device_state === 'Online' &&
              row.battery_status.level !== null &&
              row.battery_status.level < 20
            ) {
              this.state.storeBatteryIssues.push({
                serial_number: row.serial_number,
                device_label: row.label,
                battery_level: row.battery_status.level,
              })
            }
            if (row.device_event_state) {
              this.state.storeDeviceLockerdevices.push({
                device_state: row.device_state,
                serial_number: row.serial_number,
                device_label: row.label,
                device_event_state: row.device_event_state,
              })
            }
            if (row.direct_boot_mode) {
              this.state.storeDirectBootModeDevices.push({
                direct_boot_mode: row.direct_boot_mode,
                serial_number: row.serial_number,
                device_state: row.device_state,
              })
            }
            if (row.e355_pinpad_information) {
              this.state.storeMPTCStatus.push({
                e355_pinpad_information: row.e355_pinpad_information,
                serial_number: row.serial_number,
                device_state: row.device_state,
              })
            }
            return null
          })
          this._isMounted &&
            this.setState({
              handhelds: response.data,
            })
        })
        .catch((error) => {
          if (error && error.response) {
            const eventData = {
              customMetrics: {
                metric1: 'Handheld',
                metric3: error.response.status,
              },
              event: {
                type: `apiErrorHandheld`,
              },
            }
            this.props.trackEvent(eventData)
          }
        })
        .finally(() => {
          this._isMounted && this.setState({ handheldsLoading: false })
        })
    this._isMounted &&
      this.svcTablets
        .getTablets(this.props.env, submission)
        .then((response) => {
          const eventData = {
            customMetrics: {
              metric1: 'Tablet',
              metric3: response.status,
            },
            event: {
              type: `apiSuccessTablet`,
            },
          }
          this.props.trackEvent(eventData)
          this._isMounted &&
            response.data.map((row, index) => {
              if (
                row.device_state === 'Missing' ||
                row.device_state === 'Offline'
              ) {
                this.state.storeMissingDevices.push({
                  serial_number: row.serial_number,
                  device_label: row.label,
                  last_seen: row.updated_on,
                })
              } else if (
                (row.device_state === 'Active' ||
                  row.device_state === 'Online') &&
                (row.department === 'myDevice' ||
                  row.department === 'myCheckout')
              ) {
                this.state.storeActiveDevices.push({
                  serial_number: row.serial_number,
                  device_label: row.label,
                  last_seen: row.updated_on,
                })
              }
              if (
                row.battery_status &&
                moment(row.updated_on).isBetween(end, start) &&
                row.battery_status.level !== null &&
                row.battery_status.level < 20
              ) {
                this.state.storeBatteryIssues.push({
                  serial_number: row.serial_number,
                  device_label: row.label,
                  battery_level: row.battery_status.level,
                })
              }
              return null
            })
          this.setState({
            tablets: response.data,
          })
        })
        .catch((error) => {
          if (error && error.response) {
            const eventData = {
              customMetrics: {
                metric1: 'Tablet',
                metric3: error.response.status,
              },
              event: {
                type: `apiErrorTablet`,
              },
            }
            this.props.trackEvent(eventData)
          }
        })
        .finally(() => {
          this._isMounted &&
            this.setState({ handheldsUtilizationLoading: false })
        })
  }

  render() {
    const { classes } = this.props
    let data = [...this.state.handhelds, ...this.state.tablets]
    let filterList = {
      filteredMyDevice: this.props.filtermyDevice,
      filteredMyCheckout: this.props.filtermyCheckout,
      filteredPaymentKiosk: this.props.filterPaymentKiosk,
      filteredServiceKiosk: this.props.filterServiceKiosk,
      filteredMyKiosk: this.props.filterMyKiosk,
      filteredGiftRegistry: this.props.filterGiftRegistry,
      filteredGiftRegistryScanner: this.props.filterGiftRegistry,
      filteredGiftRegistryKiosk: this.props.filterGiftRegistry,
      filteredPML: this.props.filterPML,
      filteredTimeclock: this.props.filterTimeclock,
      filteredVisualMerchandiser: this.props.filterVisualMerchandiser,
      filteredDirectStoreDeliveryKiosk:
        this.props.filterDirectStoreDeliveryKiosk,
      filteredMobilePrinter: this.props.filterMobilePrinter,
      filteredScangun: this.props.filterScangun,
      filteredDCHRTablet: this.props.filterDCHRTablet,
      filteredAPTablet: this.props.filterAPTablet,
      filteredDCTimeclock: this.props.filterDCTimeclock,
      filteredICQATablet: this.props.filterICQATablet,
      filteredYardTruckTablet: this.props.filterYardTruckTablet,
      filteredDCFKTablet: this.props.filterDCFKTablet,
      filteredDCOLETablet: this.props.filterDCOLETablet,
      filteredADWTablet: this.props.filterADWTablet,
    }

    return (
      <div>
        <Paper>
          <Table>
            <TableHead>
              <TableRow
                hover
                className={classes.tableRow}
                onClick={this.props.handleCollapsedRow}
              >
                {this.props.collapsedRow ? (
                  <TableCell className={classes.tableRow}>
                    <List dense>
                      <ListItem className={classes.tableRow}>
                        <Typography variant="h6" className={classes.metrics}>
                          Dashboard
                        </Typography>
                        <ExpandMoreIcon />
                      </ListItem>
                    </List>
                  </TableCell>
                ) : (
                  <TableCell className={classes.tableRow}>
                    <List dense>
                      <ListItem className={classes.tableRow}>
                        <Typography variant="h6" className={classes.metrics}>
                          Dashboard
                        </Typography>
                        <ExpandLessIcon />
                      </ListItem>
                    </List>
                  </TableCell>
                )}
                <TableCell />
                <TableCell />
                <TableCell />
              </TableRow>
            </TableHead>
          </Table>
        </Paper>
        <Collapse
          in={!this.props.collapsedRow}
          timeout="auto"
          unmountOnExit
          className={classes.honeyComb}
        >
          {this.state.handheldsLoading && this.state.tabletsLoading ? (
            <Spinner />
          ) : (
            <TopPanel
              storeLostStolenHistory={this.state.storeLostStolenHistory}
              storeMissingDevices={this.state.storeMissingDevices}
              storeDeviceLockerdevices={this.state.storeDeviceLockerdevices}
              storeDirectBootModeDevices={this.state.storeDirectBootModeDevices}
              storeBatteryIssues={this.state.storeBatteryIssues}
              storeMPTCStatus={this.state.storeMPTCStatus}
              location_id={this.props.locationId}
              locationFullDetails={this.props.locationFullDetails}
              filteredMyCheckout={filterList.filteredMyCheckout}
              env={this.props.env}
              storeActiveDevices={this.state.storeActiveDevices}
              onChangeValue={this.onChangeValueHandler}
              onDirectBootModeValue={this.onDirectBootModeClickHandler}
              onMPTCDisconnectedClickHandler={
                this.onMPTCDisconnectedClickHandler
              }
              onFilterDeviceStateValue={this.onFilterDeviceStateClickHandler}
              onFilterDeviceModelClickHandler={
                this.onFilterDeviceModelClickHandler
              }
              onFilterDeviceTypeClickHandler={
                this.onFilterDeviceTypeClickHandler
              }
            />
          )}
        </Collapse>
        {/* END - Components for top panel details */}
        {this.state.handheldsLoading && this.state.tabletsLoading ? (
          <Spinner />
        ) : (
          <>
            <Paper>
              <MuiThemeProvider theme={this.getMuiTheme()}>
                <HandheldsColumnConfig
                  handheldDetails={data}
                  customFilters={this.props.customFilters}
                  env={this.props.env}
                  filterList={filterList}
                  platform={this.props.platform}
                  handleFiltersState={this.props.handleFiltersState}
                  locationFullDetails={this.props.locationFullDetails}
                  filterValue={this.state.filterValue}
                  filterDBModeValue={this.state.filterDBModeValue}
                  filterMPTCDisconnectedValue={
                    this.state.filterMPTCDisconnectedValue
                  }
                  filterDeviceState={this.state.filterDeviceState}
                  filterDeviceModel={this.state.filterDeviceModel}
                  filterDeviceType={this.state.filterDeviceType}
                  onFilterChange={this.onFilterChange}
                />
              </MuiThemeProvider>
            </Paper>
          </>
        )}
      </div>
    )
  }
  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTableToolbar: {
          filterPaper: {
            width: 420,
          },
        },
        MuiTableCell: {
          root: {
            paddingTop: 4,
            paddingBottom: 4,
          },
        },
      },
    })
}

const styles = (theme) => ({
  honeyComb: {
    height: '320px',
    overflow: 'auto',
    backgroundImage:
      `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='24.5' ` +
      `viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%23888888' fill-opacity='0.50' ` +
      `fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 ` +
      `11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 ` +
      `0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
  },
  tableRow: {
    cursor: 'cursor',
    width: 'flex',
    paddingLeft: 5,
    paddingTop: 0,
    paddingBottom: 0,
    height: 30,
  },
  metrics: {
    paddingLeft: 14,
  },
})

export default withStyles(styles, { withTheme: true })(
  withEnv()(withAnalytics()(DashboardPlatformsAndroid)),
)
