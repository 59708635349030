import * as d3 from 'd3'

import { useEffect, useState } from 'react'

import Error from '@material-ui/icons/ErrorOutline'
import Spinner from 'nicollet-react/es/components/Spinner/Spinner'
import SvcStoreMapInfo from '../../../../services/svcStoreMapInfo'
import Typography from '@material-ui/core/Typography'
import { withEnv } from '@praxis/component-runtime-env'
import { withStyles } from '@material-ui/styles'

const HandheldNeptuneMap = (props) => {
  const { mapCenter, acornPositions, classes } = props

  const [state, setState] = useState({
    mapCenter: mapCenter,
    storeMapInfoXML: null,
    storeMapInfoLoading: false,
    storeMapInfoLoadSuccess: false,
  })

  const [mapRandom] = useState('map' + Math.floor(Math.random() * 1000) + 1)
  const svcStoreMapInfo = new SvcStoreMapInfo()

  const updateMap = (storeMapInfoXML) => {
    // d3 xml to parse the svg as xml data, this allows use of adding layers and applying attrs
    d3.xml(storeMapInfoXML).then((data) => {
      document.getElementById(mapRandom).appendChild(data.documentElement)
      let client = document.getElementById(mapRandom)
      // select relative div to apply styling and layers
      let svg = d3
        .select('#' + mapRandom)
        .style('width', `${client.parentElement.clientWidth}px`)
        .style('height', `${client.parentElement.clientHeight}px`)
      // DEFAULT CSS ATTRIBUTES - BEGIN //
      svg
        .select('#body')
        .attr('margin', '0')
        .attr('background-color', '#FFFFFF')
      svg.select('#background').attr('display', 'none')
      svg.select('#background-vo').attr('display', 'none')
      svg
        .select('#Total-Building-Shapes')
        .attr('fill', '#616161')
        .attr('stroke-width', '0.2032px')
        .attr('stroke', '#7a7a7a')
        .attr('stroke-linejoin', 'round')
      svg
        .selectAll('#Wall-Shapes, #Stockroom-Shapes')
        .attr('fill', '#cacaca')
        .attr('stroke-width', '0.2032px')
        .attr('stroke', '#7a7a7a')
        .attr('stroke-linejoin', 'round')
      svg
        .select('#Sales-Floor-Island-Shapes')
        .attr('fill', '#8a8888')
        .attr('stroke', 'none')
      svg
        .selectAll('#Floor-Pads')
        .attr('fill', '#b2b2b2')
        .attr('stroke', 'none')
      svg
        .select('#Register-Shapes')
        .attr('fill', '#9b9b9b')
        .attr('stroke-linejoin', 'miter')
      svg.select('#Aisle-Names > text')
      svg.select('#Aisle-Shapes').attr('fill', '#FFFFFF')
      svg.selectAll('#Adjacency-Icons > g > g >circle').attr('fill', '#333333')
      svg
        .select('.small-format-store')
        .attr('transform', 'translateY(-1.5px)')
        .attr('font-size', '1.0px')
      svg.select('.normal-format-store').attr('font-size', '1.524px')
      // DEFAULT CSS ATTRIBUTES - END //
      let deviceLocationLayer = svg
        // this adds a layer in the g(roup) id=content named 'Device-Location'
        .select('#content')
        .append('g')
        .attr('id', 'Device-Location')

      var circle = deviceLocationLayer
        // this appends a circle SVG type to 'Device-Location' layer at x,y coordinates
        .append('circle')
        .attr('cx', acornPositions.xcoordinate)
        .attr('cy', acornPositions.ycoordinate)
        .attr('r', 0.9)
        .style('fill', 'red')
        .style('stroke', 'darkred')
        .style('stroke-width', '0.1')

      function pulse() {
        circle
          .transition()
          .duration(1000)
          .ease(d3.easeElasticOut)
          .attr('r', 0.9)
          .transition()
          .duration(300)
          .ease(d3.easeBackIn)
          .attr('r', 0.6)
          .on('end', pulse)
      }
      pulse()
    })
  }

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      storeMapInfoLoading: true,
    }))
    svcStoreMapInfo
      .getStoreMapInfo(
        props.acornPositions.physical_location_id,
        props.acornPositions.floor_id,
        props.env,
      )
      .then((response) => {
        let base64Flag = 'data:application/xml;base64,'
        setState((prevState) => ({
          ...prevState,
          storeMapInfoXML: base64Flag + response.data.svg_info,
          storeMapInfoLoading: false,
          storeMapInfoLoadSuccess: true,
        }))
        state.storeMapInfoXML !== 'undefined' &&
          updateMap(base64Flag + response.data.svg_info)
      })
      .catch((error) => {
        setState((prevState) => ({
          ...prevState,
          storeMapInfoLoading: false,
          storeMapInfoLoadSuccess: false,
        }))
      })
      .finally(() => {
        setState((prevState) => ({
          ...prevState,
          storeMapInfoLoading: false,
        }))
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.acornPositions.physical_location_id,
    props.acornPositions.floor_id,
    props.env,
  ])

  return state.storeMapInfoLoading ? (
    <Spinner className={classes.spinner} />
  ) : state.storeMapInfoLoadSuccess ? (
    <div id={mapRandom} />
  ) : (
    <Typography className={classes.messageText} variant="h6">
      {props.acornPositions.floor_id &&
      props.acornPositions.floor_id.toLowerCase().includes('m') ? (
        <>
          <p>
            Store Maps are NOT Available for the Office Spaces on Mezzanine
            Levels
          </p>
          <Error />
        </>
      ) : (
        'No Store Map Available For Location and Floor'
      )}
    </Typography>
  )
}

const styles = {
  messageText: {
    textAlign: 'center',
    margin: 'auto',
    paddingTop: 25,
  },
  spinner: {
    marginBottom: 15,
  },
}

export default withStyles(styles)(withEnv()(HandheldNeptuneMap))
