import React from 'react'
import axios from 'axios'
import queryString from 'query-string'

class SvcAdvancedDownload extends React.Component {
  getAdvancedSearchDownloadsDevices(config, submission, excludeList) {
    let queryParameters = { page: 1 }

    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey
    const jsonBody = {
      id: null,
      search_body: submission,
      exclude_list: excludeList,
    }
    let endpointParams = queryString.stringify(
      Object.assign(queryParameters, { key: apiKey }),
      {
        sort: false,
        skipNull: true,
      },
    )
    var apiUri = apiUriBase + `/advanced_search/download?` + endpointParams
    return axios.post(apiUri, jsonBody)
  }

  getAdvancedDownloadScroll(config, scrollId) {
    // this is the secondary api call that accepts the scroll_id from the header response used to iterate over the results
    // no other query params are required beyond the apiKey, jsonBody will contain the scroll_id to pass to elastic search
    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey

    const jsonBody = { id: scrollId }

    var apiUri = apiUriBase + `/advanced_search/download?key=` + apiKey
    return axios.post(apiUri, jsonBody)
  }

  getDevices(auth, submission, queryParams) {
    const apiUriBase = auth.apiUriBase
    const apiKey = auth.apiKey

    const jsonBody = submission

    const endpointParams = queryString.stringify(queryParams)

    var apiUri =
      apiUriBase + `/advanced_search?` + endpointParams + `&key=` + apiKey
    return axios.post(apiUri, jsonBody)
  }

  getAggregatons(auth, submission, queryParams) {
    const apiUriBase = auth.apiUriBase
    const apiKey = auth.apiKey

    const jsonBody = submission

    const endpointParams = queryString.stringify(queryParams)

    var apiUri =
      apiUriBase +
      `/advanced_search/aggregation?` +
      endpointParams +
      `&key=` +
      apiKey
    return axios.post(apiUri, jsonBody)
  }

  getDevicesByToken(auth, token) {
    const apiUriBase = auth.apiUriBase
    const apiKey = auth.apiKey

    let queryParameters = {}
    queryParameters['token'] = token
    let endpointParams = queryString.stringify(
      Object.assign(queryParameters, { key: apiKey }),
    )
    var apiUri = apiUriBase + `/advanced_search/token?` + endpointParams
    return axios.get(apiUri)
  }

  getToken(config, submission) {
    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey

    const jsonBody = submission

    var apiUri = apiUriBase + `/advanced_search/token?key=` + apiKey
    return axios.post(apiUri, jsonBody)
  }
}

export default SvcAdvancedDownload
