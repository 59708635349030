import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import { fade } from '@material-ui/core/styles/colorManipulator'
import handheldDeviceTypes from '../deviceTypes/handheldDeviceTypes'
import leftNavNavigationConfig from './leftNavNavigationConfig'
import printerDeviceTypes from '../deviceTypes/printerDeviceTypes'
import { withAnalytics } from '@praxis/component-analytics'
import { withEnv } from '@praxis/component-runtime-env'
import { withStyles } from '@material-ui/styles'

class DevicesListItems extends React.Component {
  constructor(props) {
    super(props)
    this.handleFiltersChange = this.handleFiltersChange.bind(this)
  }
  handleFiltersChange = (name, platform) => (event) => {
    const { dataFiltersCallBack } = this.props
    dataFiltersCallBack(name, platform)
    const eventData = {
      customInteraction: {
        key: `leftnav`,
        value: platform + '-' + name,
      },
    }
    this.props.trackEvent(eventData)
    window.history.pushState(
      { '?platform=': platform },
      '',
      '?platform=' + platform,
    )
  }
  render() {
    const { classes } = this.props
    return (
      <Tooltip title={'Filter ' + this.props.itemText} placement="right">
        <ListItem
          button
          disabled={
            !this.props.disabled.includes(this.props.locationType.locationType)
          }
          className={
            this.props.disabled.includes(this.props.locationType.locationType)
              ? classes.filters
              : classes.disabled
          }
          onClick={this.handleFiltersChange(
            this.props.itemFilter,
            this.props.platform,
          )}
        >
          <ListItemIcon>
            <this.props.itemIcon />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.listItemText }}
            primary={this.props.itemText}
          />
        </ListItem>
      </Tooltip>
    )
  }
}

class RightNav extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      collapsedApplications: true,
      collapsedDevices: false,
    }
    this.handleSideNavDrawerChange = this.handleSideNavDrawerChange.bind(this)
    this.handleSideNavPlatformSelect =
      this.handleSideNavPlatformSelect.bind(this)
  }

  handleSideNavDrawerChange = (name) => (event) => {
    const { dataSideNavCallBack } = this.props
    dataSideNavCallBack(name)
  }

  handleSideNavPlatformSelect = (name) => (event) => {
    const { dataSideNavPlatformCallBack } = this.props
    dataSideNavPlatformCallBack(name)
    const eventData = {
      customInteraction: {
        key: 'leftnav',
        value: name,
      },
    }
    this.props.trackEvent(eventData)
    window.history.pushState({ '?platform=': name }, '', '?platform=' + name)
  }

  BuildNavigation = (locationType) => {
    return (
      <>
        {Object.entries(leftNavNavigationConfig['NavigationGroups']).map(
          (navGroup, navGroupIndex, navGroupArr) => {
            return (
              navGroup[1].enabled && (
                <List key={navGroupIndex} disablePadding>
                  <Tooltip title={`${navGroup[0]} Devices`} placement="right">
                    <ListItem
                      className={
                        navGroup[1].locationType.includes(
                          locationType.locationType,
                        )
                          ? this.props.classes.headers
                          : this.props.classes.disabled
                      }
                      disabled={
                        !navGroup[1].locationType.includes(
                          locationType.locationType,
                        )
                      }
                    >
                      <ListItemIcon>{navGroup[1].icon}</ListItemIcon>
                      <ListItemText
                        primary={`${navGroup[0]}:`}
                        primaryTypographyProps={{
                          className: this.props.classes.subHeader,
                        }}
                      />
                    </ListItem>
                  </Tooltip>

                  {Object.entries(navGroup[1]['NavigationItems']).map(
                    (navItem, navItemIndex) => {
                      return (
                        navItem[1].enabled && (
                          <>
                            <Tooltip
                              key={navItemIndex}
                              title={`View ${navItem[0]} Devices`}
                              placement="right"
                            >
                              <ListItem
                                disabled={
                                  !navItem[1].locationType.includes(
                                    locationType.locationType,
                                  )
                                }
                                className={
                                  navItem[1].locationType.includes(
                                    locationType.locationType,
                                  )
                                    ? this.props.classes.nested
                                    : this.props.classes.disabled
                                }
                                button
                                onClick={this.handleSideNavPlatformSelect(
                                  navItem[1].platformSelect,
                                )}
                              >
                                <ListItemIcon>{navItem[1].icon}</ListItemIcon>
                                <ListItemText
                                  primary={navItem[0]}
                                  primaryTypographyProps={{
                                    className: this.props.classes.nestedList,
                                  }}
                                />
                              </ListItem>
                            </Tooltip>
                            {navItem[0] === 'Handheld/Tablet' &&
                              Object.entries(handheldDeviceTypes).map(
                                (item, index) => {
                                  if (
                                    (item[1].device_classification.includes(
                                      'handheld',
                                    ) ||
                                      item[1].device_classification.includes(
                                        'tablet',
                                      )) &&
                                    item[1]['itemIcon']
                                  ) {
                                    return (
                                      <DevicesListItems
                                        key={index}
                                        itemIcon={item[1]['itemIcon']}
                                        itemText={item[1]['itemText']}
                                        itemFilter={item[1]['itemFilter']}
                                        disabled={item[1]['locationType']}
                                        locationType={locationType}
                                        {...this.props}
                                        platform="android"
                                      />
                                    )
                                  }
                                  return null
                                },
                              )}
                            {navItem[0] === 'Printer' &&
                              Object.entries(printerDeviceTypes).map(
                                (item, index) => {
                                  if (
                                    item[1].platform.indexOf('printer') === 0 &&
                                    item[1]['itemIcon']
                                  ) {
                                    return (
                                      <DevicesListItems
                                        key={index}
                                        itemIcon={item[1]['itemIcon']}
                                        itemText={item[1]['itemText']}
                                        itemFilter={item[1]['itemFilter']}
                                        disabled={item[1]['locationType']}
                                        locationType={locationType}
                                        {...this.props}
                                        platform="printer"
                                      />
                                    )
                                  }
                                  return null
                                },
                              )}
                          </>
                        )
                      )
                    },
                  )}
                </List>
              )
            )
          },
        )}
      </>
    )
  }

  render() {
    const { classes, locationFullDetails } = this.props
    return (
      <List dense disablePadding>
        <Tooltip title="Device Filter Options" placement="right">
          {this.props.open ? (
            <ListItem
              button
              divider
              onClick={this.handleSideNavDrawerChange('open')}
            >
              <ListItemIcon>
                <ChevronLeftIcon />
              </ListItemIcon>
              <ListItemText
                primary="Device Filter Options"
                classes={{ primary: classes.subHeader }}
              />
            </ListItem>
          ) : (
            <ListItem
              button
              divider
              onClick={this.handleSideNavDrawerChange('open')}
            >
              <ListItemIcon>
                <ChevronRightIcon />
              </ListItemIcon>
              <ListItemText
                primary="Filter Device Options"
                classes={{ primary: classes.subHeader }}
              />
            </ListItem>
          )}
        </Tooltip>
        <this.BuildNavigation locationType={locationFullDetails.type} />
      </List>
    )
  }
}

const styles = (theme) => ({
  listItemText: {
    fontSize: 12,
    paddingTop: 0,
    paddingBottom: 0,
  },
  disabled: {
    display: 'none',
  },
  nestedList: {
    fontSize: 12,
  },
  nested: {
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 30,
  },
  subHeader: {
    fontSize: 12,
    fontWeight: 550,
    textTransform: 'uppercase',
    paddingLeft: theme.spacing(2),
    paddingTop: 0,
    paddingBottom: 0,
  },
  filters: {
    paddingLeft: 50,
    paddingTop: 2,
    paddingBottom: 2,
  },
  headers: {
    backgroundColor: `${fade('#999', 0.15)}`,
    paddingTop: 4,
    paddingBottom: 4,
  },
})

export default withStyles(styles, { withTheme: true })(
  withAnalytics()(withEnv()(RightNav)),
)
