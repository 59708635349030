import {
  AccountTree,
  AddShoppingCart,
  CloudCircle,
  Computer,
  DeviceHub,
  Dns,
  Domain,
  EvStation,
  FlashOn,
  LibraryMusic,
  Lock,
  Person,
  Phone,
  PhoneBluetoothSpeaker,
  Power,
  PowerInput,
  Print,
  Radio,
  Router,
  Scanner,
  SettingsEthernet,
  SettingsInputComposite,
  Storage,
  StorageOutlined,
  SupervisorAccount,
  TouchApp,
  Videocam,
  VpnKey,
} from '@material-ui/icons'

// import Camera from '../../../images/camera.svg'
import Devices from '../../../images/devices.svg'
import Rack from '../../../images/rack.svg'
import RingScanners from '../../../images/ringScanner.svg'
import Scale from '../../../images/scaleLanding.svg'

export const landingNavigationConfig = {
  NavigationGroups: {
    'Camera access control': {
      enabled: true,
      locationType: ['STR', 'STR-V'],
      startExpanded: false,
      stateName: 'cameraAccessControl',
      icon: <Videocam />,
      NavigationItems: {
        'Door Access Control': {
          enabled: true,
          locationType: ['STR', 'STR-V'],
          platformSelect: 'doorAccessControl',
          icon: <Lock />,
        },
      },
    },
    Compute: {
      enabled: true,
      locationType: ['STR', 'STR-V', 'OSW'],
      startExpanded: false,
      stateName: 'compute',
      icon: <Rack style={{ transform: 'rotate(90deg)' }} />,
      NavigationItems: {
        'Baremetal Server': {
          enabled: true,
          locationType: ['STR', 'STR-V', 'OSW'],
          platformSelect: 'baremetalServer',
          icon: <Storage />,
        },
        'Server Chassis': {
          enabled: true,
          locationType: ['STR', 'STR-V', 'OSW'],
          platformSelect: 'chassis',
          icon: <StorageOutlined />,
        },
      },
    },
    'Enduser/Endpoint': {
      enabled: true,
      locationType: ['STR', 'STR-V', 'DC', 'DC-V', 'OSW', 'RGD', 'OFFICE'],
      startExpanded: true,
      stateName: 'enduserEndpoint',
      icon: <Person />,
      NavigationItems: {
        'Handheld/Tablet': {
          enabled: true,
          locationType: ['STR', 'STR-V', 'DC', 'DC-V', 'OSW', 'RGD', 'OFFICE'],
          platformSelect: 'android',
          icon: <Devices />,
        },
        // Tablet: {
        //   enabled: true,
        //   locationType: [ 'STR', 'STR-V'],
        //   tabValue: 2,
        //   icon: <TabletAndroid />,
        // },
        'Bluetooth Scanner': {
          enabled: true,
          locationType: ['STR', 'STR-V', 'OFFICE'],
          platformSelect: 'bluetoothscanner',
          icon: <RingScanners />,
        },
        /*Camera: {
          enabled: true,
          locationType: ['STR', 'STR-V', 'DC', 'DC-V', 'OSW', 'RGD', 'OFFICE'],
          platformSelect: 'camera',
          icon: <Camera />,
        },*/
        Printer: {
          enabled: true,
          locationType: ['STR', 'STR-V', 'OFFICE', 'OSW'],
          tabValue: 5,
          platformSelect: 'printer',
          icon: <Print />,
        },
        Computer: {
          enabled: true,
          locationType: ['STR', 'STR-V', 'DC', 'DC-V', 'OSW', 'RGD', 'OFFICE'],
          platformSelect: 'computer',
          icon: <Computer />,
        },
        Register: {
          enabled: true,
          locationType: ['STR', 'STR-V', 'OFFICE'],
          platformSelect: 'register',
          icon: <AddShoppingCart />,
        },
        'Retail Scale': {
          enabled: true,
          locationType: ['STR', 'STR-V', 'OFFICE'],
          platformSelect: 'scale',
          icon: <Scale />,
        },
        'RFID handheld': {
          enabled: true,
          locationType: ['STR', 'STR-V', 'OFFICE'],
          platformSelect: 'rfidHandheld',
          icon: <Scanner />,
        },
      },
    },
    Network: {
      enabled: true,
      locationType: ['STR', 'STR-V', 'OFFICE', 'OSW'],
      startExpanded: false,
      stateName: 'network',
      icon: <Router />,
      NavigationItems: {
        'Network access point': {
          enabled: true,
          locationType: ['STR', 'STR-V', 'OFFICE', 'OSW'],
          platformSelect: 'networkAccessPoint',
          icon: <DeviceHub />,
        },
        'Network chassis': {
          enabled: true,
          locationType: ['STR', 'STR-V', 'OFFICE', 'OSW'],
          platformSelect: 'networkChassis',
          icon: <Dns />,
        },
        'Network gateway': {
          enabled: true,
          locationType: ['STR', 'STR-V', 'OFFICE', 'OSW'],
          platformSelect: 'networkGateway',
          icon: <VpnKey />,
        },
        'Network management': {
          enabled: true,
          locationType: ['STR', 'STR-V', 'OFFICE', 'OSW'],
          platformSelect: 'networkManagement',
          icon: <SupervisorAccount />,
        },
        'Network router': {
          enabled: true,
          locationType: ['STR', 'STR-V', 'OFFICE', 'OSW'],
          platformSelect: 'networkRouter',
          icon: <Router />,
        },
        'Network switch': {
          enabled: true,
          locationType: ['STR', 'STR-V', 'OFFICE', 'OSW'],
          platformSelect: 'networkSwitch',
          icon: <SettingsInputComposite />,
        },
        'Network traffic management': {
          enabled: true,
          locationType: ['STR', 'STR-V', 'OFFICE', 'OSW'],
          platformSelect: 'networkTrafficManagement',
          icon: <SettingsEthernet />,
        },
      },
    },
    Power: {
      enabled: true,
      locationType: ['STR', 'STR-V', 'OFFICE', 'OSW'],
      startExpanded: false,
      stateName: 'power',
      icon: <FlashOn />,
      NavigationItems: {
        ATS: {
          enabled: true,
          locationType: ['STR', 'STR-V', 'OFFICE', 'OSW'],
          platformSelect: 'ats',
          icon: <AccountTree />,
        },
        Generator: {
          enabled: true,
          locationType: ['STR', 'STR-V', 'OFFICE', 'OSW'],
          platformSelect: 'generator',
          icon: <EvStation />,
        },
        PDU: {
          enabled: true,
          locationType: ['STR', 'STR-V', 'OFFICE', 'OSW'],
          platformSelect: 'pdu',
          icon: <PowerInput />,
        },
        UPS: {
          enabled: true,
          locationType: ['STR', 'STR-V', 'OFFICE', 'OSW'],
          platformSelect: 'ups',
          icon: <Power />,
        },
      },
    },
    'Property Management': {
      enabled: true,
      locationType: ['STR', 'STR-V', 'OFFICE'],
      startExpanded: false,
      stateName: 'propertyManagement',
      icon: <Domain />,
      NavigationItems: {
        'Submetering Controls': {
          enabled: true,
          locationType: ['STR', 'STR-V', 'OFFICE'],
          platformSelect: 'submeteringControls',
          icon: <TouchApp />,
        },
      },
    },
    'Voice and collaboration': {
      enabled: true,
      locationType: ['STR', 'STR-V', 'OFFICE', 'OSW'],
      startExpanded: false,
      stateName: 'voiceCollab',
      icon: <PhoneBluetoothSpeaker />,
      NavigationItems: {
        Broadcast: {
          enabled: true,
          locationType: ['STR', 'STR-V', 'OFFICE', 'OSW'],
          platformSelect: 'broadcast',
          icon: <Radio />,
        },
        'Digital media player': {
          enabled: true,
          locationType: ['STR', 'STR-V', 'OFFICE', 'OSW'],
          platformSelect: 'digitalMediaPlayer',
          icon: <LibraryMusic />,
        },
        Phone: {
          enabled: true,
          locationType: ['STR', 'STR-V', 'OFFICE', 'OSW'],
          platformSelect: 'phone',
          icon: <Phone />,
        },
        'Voice gateway': {
          enabled: true,
          locationType: ['STR', 'STR-V', 'OFFICE', 'OSW'],
          platformSelect: 'voiceGateway',
          icon: <CloudCircle />,
        },
      },
    },
  },
}

export default landingNavigationConfig
