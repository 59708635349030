export const columnMap = {
  ip: [
    'ups',
    'pdu',
    'baremetalServer',
    'chassis',
    'networkTrafficManagement',
    'digitalMediaPlayer',
    'submeteringControls',
    'register',
    'scale',
  ],
  macAddress: [
    'register',
    'scale',
    'ats',
    'pdu',
    'broadcast',
    'digitalMediaPlayer',
    'phone',
    'submeteringControls',
  ],
  hostName: [
    'register',
    'scale',
    'networkAccessPoint',
    'networkManagement',
    'networkChassis',
    'networkGateway',
    'networkRouter',
    'networkSwitch',
    'pdu',
    'ats',
    'ups',
    'broadcast',
    'digitalMediaPlayer',
    'voiceGateway',
    'submeteringControls',
  ],
}
