import GridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
import Griddler from './Griddler'
import React from 'react'
import { fade } from '@material-ui/core/styles/colorManipulator'
import { withAuth } from '@praxis/component-auth'
import { withEnv } from '@praxis/component-runtime-env'
import { withStyles } from '@material-ui/styles'

class SummarizedWidgetsRoot extends React.Component {
  render() {
    const { classes, env, widgets, tabLocationName } = this.props
    return (
      <>
        {env.landingPageWidgets && (
          <div className={classes.root}>
            <GridList className={classes.gridList}>
              {Object.keys(env.landingPageWidgets).flatMap(
                (row, index) =>
                  env.landingPageWidgets[row].name !== 'welcomeMickra' &&
                  env.landingPageWidgets[row].name !==
                    'devicesI10iEndUserEndPoint' &&
                  env.landingPageWidgets[row].active &&
                  env.landingPageWidgets[row][widgets] &&
                  ((env.landingPageWidgets[row].name.match(
                    /^(devicesHandheldLocationType|deviceLockerStates|devicesHandheldUtilization|devicesTabletUtilization|devicesHandheldManagementSystem)$/,
                  ) &&
                    tabLocationName.match(/^(DC)$/)) ||
                  (env.landingPageWidgets[row].name.match(
                    /^(devicesHandheldLocationType)$/,
                  ) &&
                    tabLocationName.match(/^(STR)$/)) ? (
                    ''
                  ) : (
                    <GridListTile
                      style={{
                        height: env.landingPageWidgets[row].layout.h,
                        width: env.landingPageWidgets[row].layout.w,
                        background: `${fade('#fff', 0.75)}`,
                        margin: 10,
                        alignItems: 'center',
                        border: env.landingPageWidgets[row].layout.border
                          ? '1px solid #666666'
                          : 0,
                        borderRadius: 5,
                      }}
                      key={index}
                    >
                      <Griddler
                        widgetProperty={widgets}
                        gridDetails={env.landingPageWidgets[row]}
                        deviceClassificationMapReverse={
                          env.deviceClassificationMapReverse
                        }
                        tabLocationName={tabLocationName}
                      />
                    </GridListTile>
                  )),
              )}
            </GridList>
          </div>
        )}
      </>
    )
  }
}
const styles = {
  root: {
    justifyContent: 'space-around',
    marginTop: 5,
    // marginLeft: 25,
    // minHeight: `calc(100vh - 75px)`,
    //   backgroundImage:
    //     `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='24.5' ` +
    //     `viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%23888888' fill-opacity='0.50' ` +
    //     `fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 ` +
    //     `11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 ` +
    //     `0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
  },
  gridList: {
    // width: 1000,
    // height: 500,
  },
  tableBorder: {
    borderBottom: 0,
    padding: '4px 4px 4px 4px',
  },
  icon: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
  dialog: {
    textAlign: 'center',
    marginTop: 25,
  },
  cardContent: {
    paddingBottom: 15,
    marginTop: 25,
    marginLeft: 25,
    paddingTop: 15,
    paddingRight: 15,
    paddingLeft: 15,
    maxHeight: '185px',
    width: '185px',
  },
  cardStyle: {
    textAlign: 'center',
    paddingRight: 0,
  },
  listItemHeader: {
    textAlign: 'center',
    paddingLeft: 2,
    paddingRight: 2,
    paddingTop: 0,
    paddingBottom: 5,
  },
  listItem: {
    color: '#B85300',
    textAlign: 'center',
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
  tableRow: {
    display: 'flex',
    minHeight: 275,
    alignContent: 'center',
    justifyContent: 'space-evenly',
  },
  cell: {
    height: 10,
    padding: 'dense',
  },
}

export default withStyles(styles)(
  withAuth(null)(withEnv()(SummarizedWidgetsRoot)),
)
