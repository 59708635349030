export const l4DeviceMap = [
  'devicesI10iAll',
  'devicesUpsModels',
  'devicesAtsModels',
  'devicesPduModels',
  'devicesGeneratorModels',
  'devicesDigitalMediaModels',
  'devicesBMSModels',
  'devicesChassisModels',
  'devicesScaleModels',
  'devicesPrinterModels2',
  'devicesRegisterModels',
  'devicesVoiceGatewayModels',
  'devicesDoorAccessControlModels',
  //'devicesNetworkAccessControlAndVisibilityModels',
  'devicesNetworkAccessPointModels',
  'devicesNetworkChassisModels',
  'devicesNetworkManagementModels',
  'devicesNetworkGatewayModels',
  'devicesNetworkSwitchModels',
  'devicesNetworkRouterModels',
  'devicesRfidHandheldModels',
  'devicesBroadcastModels',
  'devicesPhoneModels',
  'devicesNetworkTrafficManagementModels',
  'devicesSubmeteringControlsModels',
]

export default l4DeviceMap
