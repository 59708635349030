export const l3Classifications = [
  'Voice and Collaboration',
  'Compute',
  'EndUser/EndPoint',
  'Power',
  'Camera and Access Control',
  'Network',
  'Property Management',
]
export default l3Classifications
