import React from 'react'
import moment from 'moment'

class DeviceLastSeenFormat extends React.Component {
  render() {
    let currentDateFromNow = moment.utc(this.props.lastSeenValue).fromNow()
    let yesterday = moment().subtract(24, 'hours').format()
    let lastSeen = moment(this.props.lastSeenValue).format()
    if (lastSeen !== moment('0000-00-00 00:00:00.000').format()) {
      // if the date is greater than 24 hour, render the full date info.  else render the current date from now
      if (lastSeen < yesterday) {
        return moment(lastSeen).format('llll')
      } else {
        return currentDateFromNow
      }
    } else {
      return ''
    }
  }
}

export default DeviceLastSeenFormat
