import { InputAdornment, Typography } from '@material-ui/core'
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import CheckCircleTwoTone from '@material-ui/icons/CheckCircleTwoTone'
import CloseIcon from '@material-ui/icons/Close'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import FormControl from '@material-ui/core/FormControl'
import Good from '@material-ui/icons/CheckCircleTwoTone'
import IconButton from '@material-ui/core/IconButton'
import InputLabel from '@material-ui/core/InputLabel'
import ListItem from '@material-ui/core/ListItem'
import MenuItem from '@material-ui/core/MenuItem'
import React from 'react'
import Select from '@material-ui/core/Select'
import Snackbar from '@material-ui/core/Snackbar'
import Spinner from 'nicollet-react/es/components/Spinner/Spinner'
import SvcHandhelds from '../../../services/svcHandhelds'
import SvcLocationDetails from '../../../services/svcLocationDetails'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import ValidateOrgGroup from '../../../utilities/validateOrgGroup'
import { withAnalytics } from '@praxis/component-analytics'
import { withEnv } from '@praxis/component-runtime-env'
import withRouter from '../../withRouter'
import { withStyles } from '@material-ui/styles'

class UpdateOrgGroup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      orgGroup: '',
      incidentNumber: '',
      snackBarOpen: false,
      snackBarMessageInfo: '',
      actionSuccess: false,
      loading: false,
      errorOrgGroup: true,
      errorIncidentNumber: true,
      errorLabelOrgGroup: 'enter a valid Airwatch Store or DC org group name',
      labelOrgGroup: 'valid Airwatch Store or DC org group name',
      errorLabelIncidentNumber: 'enter a valid incident number',
      labelIncidentNumber: 'valid incident number',
      disabled: true,
      orgGroupLocation: 'A',
    }
    this.svcHandhelds = new SvcHandhelds()
    this.validateOrgGroup = new ValidateOrgGroup()
    this.svcLocationDetails = new SvcLocationDetails()
    this.goBack = this.goBack.bind(this)
  }
  componentDidMount() {
    // if locationFullDetails is null, source was location page.  we need to fetch from locations service to get type here from devices page.
    // we use the location type to flip it to a type of AW group for DCs, otherwise it defaults to Store OG
    if (!this.props.locationFullDetails) {
      this.svcLocationDetails
        .getLocationDetails(this.props.rowData.location_id, this.props.env)
        .then((response) => {
          if (
            response.data[0].type === 'DC' ||
            response.data[0].type === 'D-V'
          ) {
            this.setState({
              orgGroupLocation: 'D',
            })
          }
        })
    } else {
      if (
        this.props.locationFullDetails.type &&
        (this.props.locationFullDetails.type === 'DC' ||
          this.props.locationFullDetails.type === 'DC-V')
      ) {
        this.setState({
          orgGroupLocation: 'D',
        })
      }
    }
  }

  handleChangeOrgGroup = (nameOrgGroup) => (event) => {
    this.setState({
      [nameOrgGroup]: event.target.value,
    })
    this.setState({
      errorOrgGroup: this.validateOrgGroup.ValidateOrgGroupPattern(
        event.target.value,
      ),
    })
  }

  handleChangeIncidentNumber = (nameIncidentNumber) => (event) => {
    this.setState({
      [nameIncidentNumber]: event.target.value,
    })
    this.setState({
      errorIncidentNumber: this.validateOrgGroup.ValidateIncidentNumberPattern(
        event.target.value,
      ),
    })
  }

  handleChangeOGType(event) {
    this.setState({
      orgGroupLocation: event.target.value,
    })
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    this.setState({ snackBarOpen: false })
  }
  goBack() {
    this.props.router.navigate(0)
  }
  handleSubmit() {
    const {
      rowData: { id },
    } = this.props

    if (this.state.orgGroup !== '') {
      this.svcHandhelds
        .updateDeviceOrgGroup(
          this.props,
          id,
          this.state.orgGroupLocation + this.state.orgGroup,
          this.state.incidentNumber,
        )
        .then((success) => {
          const eventData = {
            customMetrics: {
              metric1: 'UpdateDeviceOrgGroup-' + this.state.orgGroupLocation,
              metric3: success.status,
            },
            event: {
              type: 'apiSuccessUpdateDeviceOrgGroup',
            },
          }
          this.props.trackEvent(eventData)
          if (Math.round(success.status / 100) === 2) {
            this.setState({
              snackBarMessageInfo: `Device ${id} successfully moved to location ${this.state.orgGroup}. It may take up to 60 minutes to fully reflect in Mickra.`,
              snackBarOpen: true,
              actionSuccess: true,
              orgGroup: '',
            })
          }
        })
        .catch((error) => {
          const eventData = {
            customMetrics: {
              metric1: 'UpdateDeviceOrgGroup',
              metric3: error.response.status,
            },
            event: {
              type: 'apiErrorUpdateDeviceOrgGroup',
            },
          }
          this.props.trackEvent(eventData)
          if (
            Math.round(error.response.status / 100) === 5 ||
            Math.round(error.response.status / 100) === 4
          ) {
            this.setState({
              snackBarOpen: true,
              snackBarMessageInfo:
                'ERROR - An error occurred while updating the device store org group. Please retry after sometime.',
              errorIncidentNumber: true,
              errorOrgGroup: true,
              orgGroup: '',
              incidentNumber: '',
            })
          } else {
            this.setState({
              snackBarOpen: true,
              snackBarMessageInfo:
                'ERROR - An error occurred while updating the device store org group. Please retry after sometime.',
              errorIncidentNumber: true,
              errorOrgGroup: true,
              orgGroup: '',
              incidentNumber: '',
            })
          }
        })
        .finally(() => {
          this.setState({ loading: false })
        })
    } else {
      this.setState({
        snackBarOpen: true,
        snackBarMessageInfo: 'ERROR - Please enter a org group',
        errorIncidentNumber: true,
        errorOrgGroup: true,
        orgGroup: '',
        incidentNumber: '',
      })
    }
  }

  render() {
    const { classes } = this.props
    const { snackBarMessageInfo, actionSuccess, loading } = this.state
    return (
      <Dialog
        fullWidth
        maxWidth="lg"
        open={this.props.open}
        onClose={this.props.onClose}
      >
        <DialogTitle className={classes.dialogTitle}>
          Update Device Organization group
        </DialogTitle>
        <IconButton
          aria-label="Close"
          className={classes.icon}
          onClick={this.props.onClose}
        >
          <CloseIcon />
        </IconButton>
        <div>
          {loading ? (
            <Spinner className={classes.spinner} />
          ) : !actionSuccess ? (
            <React.Fragment>
              <ListItem className={classes.listTextField}>
                <TextField
                  id="enter-a-incident-number"
                  label={
                    this.state.errorIncidentNumber ? (
                      <Typography variant="button" style={{ color: '#666' }}>
                        {this.state.errorLabelIncidentNumber}
                      </Typography>
                    ) : (
                      <Typography variant="button" style={{ color: '#666' }}>
                        {this.state.labelIncidentNumber}
                      </Typography>
                    )
                  }
                  value={this.state.incidentNumber}
                  onChange={this.handleChangeIncidentNumber('incidentNumber')}
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: this.state.errorIncidentNumber ? (
                      <InputAdornment position="end">
                        <Tooltip title="Enter a valid incident number (Optional)">
                          <ErrorOutlineIcon style={{ color: '#b92c09' }} />
                        </Tooltip>
                      </InputAdornment>
                    ) : (
                      <InputAdornment title="Valid incident number">
                        <CheckCircleTwoTone style={{ color: '#008300' }} />
                      </InputAdornment>
                    ),
                  }}
                  className={classes.searchTextbarInt}
                  margin="dense"
                  variant="filled"
                  onKeyPress={(ev) => {
                    if (ev.key === 'Enter') {
                      this.handleSubmit()
                      ev.preventDefault()
                    }
                  }}
                />
                <Tooltip title="Org Group Type change is disabled">
                  <FormControl className={classes.searchTextbarType}>
                    <InputLabel id="dselect-store-or-dc-type-label">
                      Org Group Type
                    </InputLabel>
                    <Select
                      disabled
                      id="select-store-or-dc-type"
                      value={this.state.orgGroupLocation}
                      label="Choose Store or DC Org Group"
                      onChange={(event) => {
                        this.handleChangeOGType(event)
                      }}
                    >
                      <MenuItem value={'A'}>Store</MenuItem>
                      <MenuItem value={'D'}>Distribution Center</MenuItem>
                    </Select>
                  </FormControl>
                </Tooltip>
                <TextField
                  id="enter-a-new-org-group"
                  label={
                    this.state.errorOrgGroup ? (
                      <Typography variant="button" style={{ color: '#666' }}>
                        {this.state.errorLabelOrgGroup}
                      </Typography>
                    ) : (
                      <Typography variant="button" style={{ color: '#666' }}>
                        {this.state.labelOrgGroup}
                      </Typography>
                    )
                  }
                  value={this.state.orgGroup}
                  onChange={this.handleChangeOrgGroup('orgGroup')}
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: this.state.errorOrgGroup ? (
                      <InputAdornment position="end">
                        <Tooltip title="Enter a valid Airwatch Store or DC org group name in 4 digits format. For eg: '0003'">
                          <ErrorOutlineIcon style={{ color: '#b92c09' }} />
                        </Tooltip>
                      </InputAdornment>
                    ) : (
                      <InputAdornment title="Valid Airwatch Store or DC org group name">
                        <CheckCircleTwoTone style={{ color: '#008300' }} />
                      </InputAdornment>
                    ),
                  }}
                  className={classes.searchTextbarOG}
                  margin="dense"
                  variant="filled"
                  onKeyPress={(ev) => {
                    if (ev.key === 'Enter') {
                      this.handleSubmit()
                      ev.preventDefault()
                    }
                  }}
                />
              </ListItem>
              <br />
              <ListItem className={classes.listButton}>
                <Button
                  className={classes.submitButton}
                  variant="outlined"
                  disabled={this.state.errorOrgGroup === true}
                  onClick={() => this.handleSubmit()}
                >
                  Confirm
                </Button>
                <Button
                  className={classes.cancelButton}
                  variant="outlined"
                  onClick={this.props.onClose}
                >
                  CANCEL
                </Button>
              </ListItem>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className={classes.backButton}>
                <Button
                  className={classes.successButton}
                  variant="outlined"
                  onClick={this.goBack}
                >
                  BACK
                </Button>
                <Tooltip title="Success" placement="top">
                  <Good className={classes.good} />
                </Tooltip>
              </div>
            </React.Fragment>
          )}
        </div>
        <MuiThemeProvider theme={this.getMuiTheme()}>
          <Snackbar
            open={this.state.snackBarOpen}
            autoHideDuration={3000}
            onClose={this.handleClose}
            message={snackBarMessageInfo}
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                onClick={this.props.onClose}
              >
                <CloseIcon className={classes.CloseIcon} />
              </IconButton>,
            ]}
          />
        </MuiThemeProvider>
      </Dialog>
    )
  }

  getMuiTheme = () =>
    createTheme({
      overrides: {
        MuiSnackbarContent: {
          message: {
            fontSize: 'medium',
          },
        },
      },
    })
}

const styles = {
  icon: {
    position: 'absolute',
    right: 8,
    top: 8,
    justifyContent: 'end',
  },
  searchTextbarOG: {
    width: 500,
    paddingRight: '5px',
  },
  searchTextbarInt: {
    width: 300,
    paddingRight: '5px',
  },
  searchTextbarType: {
    width: 200,
    paddingRight: '5px',
  },
  submitButton: {
    float: 'left',
    paddingLeft: '10px',
  },
  cancelButton: {
    float: 'left',
    marginLeft: 5,
  },
  backButton: {
    justifyContent: 'center',
    display: 'flex',
    paddingBottom: '10px',
  },
  listButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '0mm',
    paddingBottom: '3mm',
  },
  listTextField: {
    justifyContent: 'center',
    paddingBottom: '0mm',
    paddingTop: '0mm',
  },
  spinner: {
    marginBottom: 15,
  },
  good: {
    fontSize: 40,
    color: 'green',
    paddingLeft: 5,
  },
  dialogTitle: {
    paddingRight: '50px',
    paddingLeft: '20px',
  },
}

export default withStyles(styles)(
  withRouter(withAnalytics()(withEnv()(UpdateOrgGroup))),
)
