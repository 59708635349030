import { Dialog, DialogTitle, IconButton } from '@material-ui/core'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'

import CloseIcon from '@material-ui/icons/Close'
import DeviceHealthAirwatch from '../DeviceHealth/DeviceHealthAirwatch'
import DeviceHealthTep from '../DeviceHealth/DeviceHealthTep'
import DeviceLastSeenFormat from '../DeviceLastSeenFormat/DeviceLastSeenFormat'
import Divider from '@material-ui/core/Divider'
import HandheldStateInfoModal from '../DeviceStateInfoModal/HandheldStateInfoModal'
import HistoryIcon from '@material-ui/icons/History'
import MUIDataTable from 'mui-datatables'
import React from 'react'
import { Spinner } from 'nicollet-react'
import Tooltip from '@material-ui/core/Tooltip'
import moment from 'moment'
import sort from '../../../utilities/sort'
import svcHandhelds from '../../../services/svcHandhelds'

function DeviceStateHistoryChartDialog({
  open,
  onClose,
  deviceHistoryLoading,
  deviceHistoryResponse,
}) {
  return (
    <React.Fragment>
      <Dialog fullWidth maxWidth={'md'} open={open} onClose={onClose}>
        <DialogTitle>State History - Last 10 Updates</DialogTitle>
        <IconButton
          aria-label="Close"
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        {deviceHistoryLoading === true ? (
          <Spinner layout="selfCentering" />
        ) : (
          <MUIDataTable
            data={deviceHistoryResponse}
            options={{
              selectableRows: 'none',
              elevation: 0,
              print: false,
              pagination: false,
              rowsPerPage: 10,
              rowsPerPageOptions: [5, 10],
              sortOrder: {
                name: 'event_timestamp',
                direction: 'desc',
              },
            }}
            columns={[
              {
                name: 'device_state',
                label: 'Device State',
              },
              {
                name: 'event_timestamp',
                label: 'Event Timestamp',
                options: {
                  filter: false,
                  customBodyRender: (value) => {
                    return moment(value).format('llll')
                  },
                },
              },
              {
                name: 'event_user',
                label: 'Event User',
                options: {
                  filter: false,
                  customBodyRender: (value) => {
                    if (
                      value &&
                      value.employee_id &&
                      value.employee_id.length !== 0
                    ) {
                      const lostuser =
                        value.first_name +
                        ' ' +
                        value.last_name +
                        ' (' +
                        value.employee_id +
                        ')'
                      return lostuser
                    } else {
                      return null
                    }
                  },
                },
              },
              {
                name: 'location_id',
                label: 'Location ID',
                options: {
                  filter: false,
                  customBodyRender: (value, tableMeta, updateValue) => {
                    if (value == null || value === 'null') {
                      return 'No Location Provided'
                    } else {
                      return value
                    }
                  },
                },
              },
            ]}
          />
        )}
      </Dialog>
    </React.Fragment>
  )
}

class TableDataTabletDetailsHeader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      deviceHistoryDialog: false,
      deviceHistoryResponse: [],
      deviceHistoryLoading: false,
    }
    this.svcHandhelds = new svcHandhelds()
  }

  handleDeviceStateHistory = () => {
    this.setState({
      deviceHistoryDialog: !this.state.deviceHistoryDialog,
    })
    this.setState({ deviceHistoryLoading: true })
    this.svcHandhelds
      .getHandheldDeviceStateHistory(
        this.props.tabletDetails[0].id,
        this.props.env,
      )
      .then((response) => {
        this.setState({
          deviceHistoryResponse: response.data.device_state_history,
        })
      })
      .catch((error) => {
        console.log(error.response)
      })
      .finally(() => {
        this.setState({ deviceHistoryLoading: false })
      })
  }

  render() {
    const { tabletDetails, env } = this.props
    return (
      <>
        <MuiThemeProvider theme={this.getMuiTheme()}>
          <MUIDataTable
            data={tabletDetails}
            options={{
              selectableRows: 'none',
              rowHover: false,
              search: false,
              pagination: false,
              filter: false,
              print: false,
              download: false,
              elevation: 0,
              viewColumns: false,
              sort: false,
            }}
            columns={[
              {
                // Health needs to be the first column
                name: 'id',
                label: 'Health',
                options: {
                  filter: false,
                  sort: false,
                  customBodyRender: (value, tableMeta, updateValue) => {
                    // console.log(tableMeta)
                    const rowData = new TabletsRowData(tableMeta.rowData)
                    if (rowData.battery_status !== null) {
                      if (rowData.management_system === 'TEP') {
                        return <DeviceHealthTep rowData={rowData} />
                      } else {
                        return <DeviceHealthAirwatch rowData={rowData} />
                      }
                    }
                  },
                },
              },
              {
                name: 'label',
                label: 'Device Label',
                options: {
                  filter: false,
                  sortCompare: (order) => {
                    return (a, b) => {
                      return new sort().label(a, b, order)
                    }
                  },
                  customBodyRender: (value, tableMeta, updateValue) => {
                    if (value) {
                      return value
                    } else {
                      return 'Not Set'
                    }
                  },
                },
              },
              {
                name: 'device_state',
                label: 'State ',
                options: {
                  filter: false,
                  display: true,
                  customBodyRender: (value, tableMeta) => {
                    return (
                      value &&
                      value !== 'null' && (
                        <div
                          style={{ display: 'inline', whiteSpace: 'nowrap' }}
                        >
                          {value}
                          <Tooltip title={`Click to see History of States `}>
                            <IconButton
                              onClick={() => this.handleDeviceStateHistory()}
                            >
                              <HistoryIcon />
                            </IconButton>
                          </Tooltip>
                        </div>
                      )
                    )
                  },
                  customHeadLabelRender: (columnMeta) => {
                    return (
                      <div style={{ display: 'inline', whiteSpace: 'nowrap' }}>
                        {'State'}
                        <HandheldStateInfoModal env={env} />
                      </div>
                    )
                  },
                },
              },
              {
                name: 'id',
                label: 'Device ID',
                options: {
                  filter: false,
                  display: false,
                },
              },
              {
                name: 'unique_id',
                label: 'Unique ID',
              },
              {
                name: 'tcin',
                label: 'TCIN',
              },
              {
                name: 'name',
                label: 'Device Name',
                options: {
                  filter: false,
                },
              },
              {
                name: 'model_name',
                label: 'Model',
                options: {
                  filter: false,
                  display: false,
                },
              },
              {
                name: 'manufacturer_name',
                label: 'Manufacturer',
                options: {
                  filter: false,
                  display: false,
                },
              },
              {
                name: 'department', //
                label: 'Device Type',
                options: {
                  // filterList: handheldsFilterArray,
                  filter: false,
                },
              },
              {
                name: 'platform',
                label: 'Platform',
                options: {
                  filter: false,
                  display: false,
                },
              },
              {
                name: 'operating_system_version',
                label: 'OS Version',
                options: {
                  filter: false,
                  display: false,
                },
              },
              {
                name: 'agent_version',
                label: 'Agent Version',
                options: {
                  filter: false,
                  display: false,
                },
              },
              {
                name: 'location_id',
                label: 'Location ID',
                options: {
                  filter: false,
                  display: false,
                },
              },
              {
                name: 'mac_address',
                label: 'Mac Address',
                options: {
                  filter: false,
                  display: false,
                  viewColumns: false,
                },
              },
              {
                name: 'policy_compliant',
                label: 'Policy Compliant',
                options: {
                  filter: false,
                  display: false,
                  viewColumns: false,
                },
              },
              {
                name: 'battery_status',
                label: 'Battery Level',
                options: {
                  filter: false,
                  display: false,
                  customBodyRender: (value, tableMeta, updateValue) => {
                    return value && value.level !== null && value.level + '%'
                  },
                },
              },
              {
                name: 'bluetooth_status',
                label: 'Bluetooth Enabled',
                options: {
                  filter: false,
                  display: false,
                  customBodyRender: (value, tableMeta, updateValue) => {
                    return value && value.isEnabled
                  },
                },
              },
              {
                name: 'updated_on',
                label: 'Updated On',
                options: {
                  filter: false,
                  customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                      value && (
                        <Tooltip title={value}>
                          <div>
                            <DeviceLastSeenFormat
                              lastSeenValue={value}
                              platform={'android'}
                            />
                          </div>
                        </Tooltip>
                      )
                    )
                  },
                },
              },
              {
                name: 'certificate_expiration',
                label: 'Cert Expiration',
                options: {
                  filter: false,
                  display: false,
                },
              },
              {
                name: 'product_compliance',
                label: 'Product Compliant',
                options: {
                  filter: false,
                  display: false,
                  viewColumns: false,
                },
              },
              {
                name: 'management_system',
                label: 'Management System',
                options: {
                  filter: false,
                  display: true,
                  viewColumns: true,
                },
              },
              {
                name: 'network_compliant',
                label: 'Network Compliant',
                options: {
                  filter: false,
                  display: false,
                  viewColumns: false,
                },
              },
              {
                // actions needs to be the last column
                name: 'id',
                label: 'Actions',
                options: {
                  filter: false,
                  sort: false,
                  display: false,
                  // customBodyRender: (value, tableMeta, updateValue) => {
                  //   const rowData = new TepRowData(tableMeta.rowData)
                  //   return <TepActionsMenu rowData={rowData} platform={platform} />
                  // },
                },
              },
            ]}
          />
        </MuiThemeProvider>
        <Divider style={{ backgroundColor: 'black' }} />
        {
          <MuiThemeProvider theme={this.getMuiTheme()}>
            <DeviceStateHistoryChartDialog
              open={this.state.deviceHistoryDialog}
              onClose={() => this.handleDeviceStateHistory()}
              deviceHistoryLoading={this.state.deviceHistoryLoading}
              deviceHistoryResponse={
                this.state.deviceHistoryResponse !== null
                  ? this.state.deviceHistoryResponse
                  : []
              }
            />
          </MuiThemeProvider>
        }
      </>
    )
  }
  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTableBodyRow: {
          root: {
            backgroundColor: '#eeeeee',
            height: 10,
            textAlign: 'center',
          },
        },
        MUIDataTableHeadRow: {
          root: {
            height: 24,
          },
        },
        MUIDataTableBodyCell: {
          root: {
            backgroundColor: '#eeeeee',
            padding: 0,
            paddingLeft: 8,
          },
        },
        MUIDataTableHeadCell: {
          root: {
            backgroundColor: '#eeeeee',
            padding: 0,
            paddingLeft: 17,
          },
        },
        MUIDataTableToolbar: {
          root: {
            height: 10,
          },
          titleText: {
            fontSize: 14,
          },
          left: {
            flex: '100 0 auto',
          },
        },
      },
    })
}

export class TabletsRowData {
  constructor(rowData) {
    if (rowData !== undefined) {
      this.id = rowData[0]
      this.label = rowData[1]
      this.device_state = rowData[2]
      this.id = rowData[3]
      this.unique_id = rowData[4]
      this.tcin = rowData[5]
      this.name = rowData[6]
      this.model_name = rowData[7]
      this.manufacturer_name = rowData[8]
      this.department = rowData[9]
      this.platform = rowData[10]
      this.operating_system_version = rowData[11]
      this.agent_version = rowData[12]
      this.location_id = rowData[13]
      this.mac_address = rowData[14]
      this.policy_compliant = rowData[15]
      this.battery_status = rowData[16]
      this.bluetooth_status = rowData[17]
      this.updated_on = rowData[18]
      this.certificate_expiration = rowData[19]
      this.product_compliance = rowData[20]
      this.management_system = rowData[21]
      this.network_compliant = rowData[22]
      this.id = rowData[23]
      this.preserved_bluetooth_connections = rowData[24]
    }
  }
}

export default TableDataTabletDetailsHeader
