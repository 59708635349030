import TableDataBTScanners from '../TableData/TableDataBTScanners'
// import TableDataCamera from '../TableData/TableDataCamera'
import TableDataI10iCore from '../TableData/TableDataI10iLocationView/LocationDashboardI10iDetails'
import TableDataLexmarkPrinter from '../TableData/TableDataLexmarkPrinter'
// import TableDataMacComputer from '../TableData/TableDataMacComputer'
import TableDataPrinter from '../TableData/TableDataPrinter'
import TableDataRFIDScanners from '../TableData/TableDataRFIDScanners'
import TableOptionsConfigComputers from './TableOptionsConfigComputers'
import TableOptionsConfigMacComputers from './TableOptionsConfigMacComputers'
import l4CamelCase from '../../Devices/I10iExports/l4CamelCase'

function TableOptions(storeReportResults, dataFiddle, platform) {
  if (platform === 'printer') {
    return {
      pagination: true,
      expandableRows: true,
      elevation: 0,
      viewColumns: true,
      selectableRows: 'none',
      expandableRowsHeader: false,
      filterType: 'multiselect',
      rowsPerPage: 50,
      rowsPerPageOptions: [10, 25, 50, 100, 250],
      renderExpandableRow: (rowData, rowMeta) => {
        if (
          storeReportResults[rowMeta.dataIndex].department &&
          storeReportResults[rowMeta.dataIndex].department === 'Lexmark'
        ) {
          return (
            <TableDataLexmarkPrinter
              rowData={rowData}
              rowMeta={rowMeta}
              storeReportResults={storeReportResults}
            />
          )
        } else {
          return (
            <TableDataPrinter
              rowData={rowData}
              rowMeta={rowMeta}
              storeReportResults={storeReportResults}
            />
          )
        }
      },
      onFilterChange: (changedColumn, filterList) => {
        changedColumn === 'department' && dataFiddle(filterList)
      },
      sortOrder: {
        name: 'label',
        direction: 'desc',
      },
    }
  }
  if (platform === 'computer') {
    return {
      pagination: true,
      expandableRows: true,
      elevation: 0,
      viewColumns: true,
      selectableRows: 'none',
      expandableRowsHeader: false,
      filterType: 'multiselect',
      rowsPerPage: 50,
      rowsPerPageOptions: [10, 25, 50, 100, 250],
      renderExpandableRow: (rowData, rowMeta) => {
        if (
          // handle i10i case...
          storeReportResults[rowMeta.dataIndex].hasOwnProperty('computer')
        ) {
          return (
            <TableDataI10iCore
              rowData={rowData}
              rowMeta={rowMeta}
              storeReportResults={storeReportResults}
            />
          )
        }
        if (
          storeReportResults[rowMeta.dataIndex].operating_system
            .toLowerCase()
            .includes('mac')
        ) {
          return (
            <TableOptionsConfigMacComputers
              rowData={rowData}
              rowMeta={rowMeta}
              storeReportResults={storeReportResults}
            />
          )
        } else if (
          storeReportResults[rowMeta.dataIndex].operating_system
            .toLowerCase()
            .includes('windows')
        ) {
          return (
            <TableOptionsConfigComputers
              storeReportResults={storeReportResults}
              rowData={rowData}
              rowMeta={rowMeta}
            />
          )
        }
      },
      onFilterChange: (changedColumn, filterList) => {
        dataFiddle(filterList)
      },
    }
  }
  // if (platform === 'camera') {
  //   return {
  //     pagination: true,
  //     expandableRows: true,
  //     elevation: 0,
  //     viewColumns: true,
  //     expandableRowsHeader: false,
  //     selectableRows: 'none',
  //     filterType: 'multiselect',
  //     rowsPerPage: 50,
  //     rowsPerPageOptions: [10, 25, 50, 100, 250],
  //     renderExpandableRow: (rowData, rowMeta) => {
  //       return (
  //         <TableDataCamera
  //           rowData={rowData}
  //           rowMeta={rowMeta}
  //           storeReportResults={storeReportResults}
  //         />
  //       )
  //     },
  //     onFilterChange: (changedColumn, filterList) => {
  //       dataFiddle(filterList)
  //     },
  //   }
  // }

  if (platform === 'rfidHandheld') {
    return {
      pagination: true,
      expandableRows: true,
      elevation: 0,
      viewColumns: true,
      expandableRowsHeader: false,
      selectableRows: 'none',
      filterType: 'multiselect',
      rowsPerPage: 50,
      rowsPerPageOptions: [10, 25, 50, 100, 250],
      renderExpandableRow: (rowData, rowMeta) => {
        return (
          <TableDataRFIDScanners
            rowData={rowData}
            rowMeta={rowMeta}
            storeRFIDScanners={storeReportResults}
          />
        )
      },
      onFilterChange: (changedColumn, filterList) => {
        dataFiddle(filterList)
      },
    }
  }

  /* I10i expandables for location dashboard */
  if (l4CamelCase.includes(platform)) {
    return {
      pagination: true,
      expandableRows: true,
      isRowExpandable: (dataIndex) => {
        return true
      },
      enableNestedDataAccess: '.',
      elevation: 0,
      viewColumns: true,
      expandableRowsHeader: false,
      selectableRows: 'none',
      filterType: 'multiselect',
      rowsPerPage: 50,
      rowsPerPageOptions: [10, 25, 50, 100, 250],
      renderExpandableRow: (rowData, rowMeta) => {
        return (
          <>
            {l4CamelCase.includes(platform) ? (
              <TableDataI10iCore
                rowData={rowData}
                rowMeta={rowMeta}
                storeReportResults={storeReportResults}
              />
            ) : (
              <></>
            )}
          </>
        )
      },
      onFilterChange: (changedColumn, filterList) => {
        dataFiddle(filterList)
      },
    }
  }

  if (platform === 'bluetoothscanner') {
    return {
      pagination: true,
      expandableRows: true,
      elevation: 0,
      viewColumns: true,
      expandableRowsHeader: false,
      selectableRows: 'none',
      filterType: 'multiselect',
      rowsPerPage: 50,
      rowsPerPageOptions: [10, 25, 50, 100, 250],
      renderExpandableRow: (rowData, rowMeta) => {
        return (
          <TableDataBTScanners
            rowData={rowData}
            rowMeta={rowMeta}
            storeBTScanners={storeReportResults}
          />
        )
      },
      onFilterChange: (changedColumn, filterList) => {
        dataFiddle(filterList)
      },
    }
  }
}

export default TableOptions
