import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'

import Apps from '@material-ui/icons/Apps'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Divider from '@material-ui/core/Divider'
import React from 'react'
import SettingsIcon from '@material-ui/icons/Settings'
import SvcI10iDevices from '../../../services/svcI10iDevices'
import Tab from '@material-ui/core/Tab'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableDataI10iCore from '../TableData/TableDataI10i/TableDataI10iCore'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Tabs from '@material-ui/core/Tabs'
import Typography from '@material-ui/core/Typography'
import { fade } from '@material-ui/core/styles/colorManipulator'
import { withEnv } from '@praxis/component-runtime-env'
import { withStyles } from '@material-ui/styles'
import TableDataRFIDScannersDetails from './TableDataRFIDScannersDetails'
import TableDataRFIDScannersDetailsHeader from './TableDataRFIDScannersDetailsHeader'

const styles = (theme) => ({
  root: {
    backgroundColor: '#eeeeee',
    padding: 'dense',
    fontSize: '40pt',
  },
  cell: {
    padding: 0,
  },
  honeyComb: {
    minHeight: `calc(100vh - 75px)`,
    backgroundImage:
      `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='24.5' ` +
      `viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%23888888' fill-opacity='0.50' ` +
      `fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 ` +
      `11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 ` +
      `0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
  },
  tab: {
    borderRight: '1px solid #666666',
    borderLeft: '1px solid #666666',
    borderTop: '1px solid #666666',
    borderBottom: '1px solid #666666',
    borderRadius: '5px 5px 0px 0px',
  },
  tabs: {
    backgroundColor: '#999999',
  },
  tabsProps: {
    backgroundColor: `${fade('#fff', 0.1)}`,
    borderBottom: '1px solid white',
  },
})

class TableDataRFIDScanners extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tabValue: 0,
      data: [],
    }
    this.svcI10iDevices = new SvcI10iDevices()
  }

  handleChange = (event, tabValue) => {
    this.setState({ tabValue })
  }

  render() {
    const colSpan = this.props.rowData.length + 1
    const { classes, storeRFIDScanners, rowMeta } = this.props

    return (
      <TableRow className={classes.honeyComb}>
        <TableCell colSpan={colSpan}>
          <Card raised>
            <CardContent>
              {typeof storeRFIDScanners[rowMeta.dataIndex] !== 'undefined' ? (
                <div>
                  {
                    <MuiThemeProvider theme={this.getMuiTheme()}>
                      <Tabs
                        value={this.state.tabValue}
                        onChange={this.handleChange}
                        variant="fullWidth"
                        className={classes.tabs}
                        TabIndicatorProps={{ className: classes.tabsProps }}
                      >
                        <Tab
                          icon={<SettingsIcon />}
                          label="Device Details"
                          className={classes.tab}
                        />
                        <Tab
                          icon={<Apps />}
                          label="General"
                          className={classes.tab}
                        />
                        <Tab disabled className={classes.tab} />
                        <Tab disabled className={classes.tab} />
                      </Tabs>
                    </MuiThemeProvider>
                  }
                  {this.state.tabValue === 0 && (
                    <div>
                      <TableDataRFIDScannersDetailsHeader
                        rfidscannerDetails={
                          storeRFIDScanners[rowMeta.dataIndex]
                        }
                        env={this.props.env}
                        classes={this.props.classes}
                      />
                      <Typography variant="h6" id="tableTitle">
                        Device Status
                      </Typography>
                      <Table className={classes.root}>
                        <TableHead>
                          <TableRow className={classes.cell}>
                            <TableCell className={classes.cell}>
                              Manufacturing Date
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow className={classes.cell}>
                            <TableCell className={classes.cell}>
                              {
                                storeRFIDScanners[rowMeta.dataIndex]
                                  .rfid_handheld.manufacturing_date
                              }
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                      <Divider style={{ backgroundColor: 'black' }} />
                      <TableDataRFIDScannersDetails
                        rfidscannerDetails={
                          storeRFIDScanners[rowMeta.dataIndex]
                        }
                      />
                    </div>
                  )}
                  {this.state.tabValue === 1 && (
                    <>
                      <TableDataI10iCore
                        i10iDetails={storeRFIDScanners[rowMeta.dataIndex]}
                      />
                    </>
                  )}
                </div>
              ) : (
                <div>
                  Note, additional details are not available for this device.{' '}
                </div>
              )}
            </CardContent>
          </Card>
        </TableCell>
      </TableRow>
    )
  }

  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MuiTab: {
          root: { '&$selected': { backgroundColor: '#fff' } },
        },
        MUIDataTableBodyCell: {
          root: {
            backgroundColor: '#eeeeee',
            height: 10,
          },
        },
        MUIDataTableHeadRow: {
          root: {
            height: 10,
          },
        },
        MUIDataTableBodyRow: {
          root: {
            backgroundColor: '#eeeeee',
            height: 10,
          },
        },
        MUIDataTableToolbar: {
          root: {
            backgroundColor: '#eeeeee',
            height: 10,
          },
          titleText: {
            fontSize: 14,
          },
          left: {
            flex: '100 0 auto',
          },
        },
        MuiPaper: {
          root: {
            minWidth: '400',
            paddingLeft: '0',
          },
        },
      },
    })
}

export default withStyles(styles, { withTheme: true })(
  withEnv()(TableDataRFIDScanners),
)
