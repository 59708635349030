import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'

import DeviceLastSeenFormat from '../DeviceLastSeenFormat/DeviceLastSeenFormat'
import Divider from '@material-ui/core/Divider'
import MUIDataTable from 'mui-datatables'
import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'

class TableDataBTScannersDetailsHeader extends React.Component {
  render() {
    return (
      <>
        <MuiThemeProvider theme={this.getMuiTheme()}>
          <MUIDataTable
            data={this.props.btscannerDetails}
            options={{
              selectableRows: 'none',
              rowHover: false,
              search: false,
              pagination: false,
              filter: false,
              print: false,
              download: false,
              elevation: 0,
              viewColumns: false,
            }}
            columns={[
              {
                name: 'label',
                label: 'Device Label',
              },
              {
                name: 'name',
                label: 'Name',
                options: {
                  filter: true,
                },
              },
              {
                name: 'id',
                label: 'ID',
                options: {
                  display: false,
                },
              },
              {
                name: 'unique_id',
                label: 'Unique ID',
              },
              {
                name: 'tcin',
                label: 'TCIN',
              },
              {
                name: 'status',
                label: 'Status',
              },
              {
                name: 'device_state',
                label: 'Device State',
              },
              {
                name: 'updated_on',
                label: 'Updated On',
                options: {
                  filter: false,
                  customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                      value && (
                        <Tooltip title={value}>
                          <div>
                            <DeviceLastSeenFormat lastSeenValue={value} />
                          </div>
                        </Tooltip>
                      )
                    )
                  },
                },
              },
            ]}
          />
        </MuiThemeProvider>
        <Divider style={{ backgroundColor: 'black' }} />
      </>
    )
  }
  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTableBodyRow: {
          root: {
            backgroundColor: '#eeeeee',
            textAlign: 'center',
          },
        },
        MUIDataTableHeadRow: {
          root: {
            height: 24,
          },
        },
        MUIDataTableBodyCell: {
          root: {
            padding: 0,
          },
        },
        MUIDataTableHeadCell: {
          root: {
            padding: 0,
          },
        },
      },
    })
}

export default TableDataBTScannersDetailsHeader
