import React from 'react'
import axios from 'axios'
import queryString from 'query-string'

class SvcDevicesDownload extends React.Component {
  getDownloadsDevices(config, submission) {
    // first request post a body with id: null
    // take response count and divide by 10000(TBD) to get total iterations
    // response will include a scroll_id that is subsequently passed to the scroll method
    const queryParameters = {
      handhelds: {
        serial_number: null,
        mac_address: null,
        model_name: null,
        operating_system_version: null,
        last_user: null,
        location_id: null,
        id: null,
        unique_id: null,
        tcin: null,
        device_event_state: null,
      },
      devices: {
        id: null,
        manufacturer_name: null,
        model_name: null,
        serial_number: null,
        updated_on: null,
        classification_name: null,
        ip_address: null,
        location_id: null,
        region_id: null,
        group_id: null,
        district_id: null,
        mac_address: null,
        operating_system_version: null,
        operating_system: null,
        // forces initial call to be page 1
        // page isn't required for subsequent calls
        page: 1,
        per_page: null,
        department: null,
        device_state: null,
        management_system: null,
        operating_system_name: null,
        domain_name: null,
        'products.name': null,
        'products.version_name': null,
        'insights.bios_version': null,
        'insights.ram': null,
        'insights.ad_organizational_unit': null,
        'insights.os_version': null,
        'insights.ci_installed_application.name': null,
        'insights.ci_installed_application.version': null,
        state: null,
        'insights.location_type': null,
        windows_not: null,
        status: null,
        unique_id: null,
        tcin: null,
        device_event_state: null,
        location_type: null,
      },
    }
    let params = []
    let queryParametersVar = queryParameters
    Object.entries(submission).forEach((param) => {
      if (queryParametersVar.devices.hasOwnProperty(param[0])) {
        if (param[0] === 'classification_name') {
          Object.entries(submission).forEach((row) => {
            params.push(config.deviceClassificationMap[row[1]])
          })
          queryParametersVar.devices[`${param[0]}`] = params
        } else {
          queryParametersVar.devices[`${param[0]}`] = param[1]
        }
      }
    })

    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey
    const jsonBody = { id: null }
    if (queryParametersVar.devices['products.name'] != null) {
      queryParametersVar.devices['products.package_name'] =
        queryParametersVar.devices['products.name']
      delete queryParametersVar.devices['products.name']
    }
    let endpointParams = queryString.stringify(
      Object.assign(queryParametersVar.devices, { key: apiKey }),
      {
        sort: false,
        skipNull: true,
      },
    )
    var apiUri = apiUriBase + `/download/devices?` + endpointParams
    return axios.post(apiUri, jsonBody)
  }

  getDevicesDownloadScroll(config, scrollId) {
    // this is the secondary api call that accepts the scroll_id from the header response used to iterate over the results
    // no other query params are required beyond the apiKey, jsonBody will contain the scroll_id to pass to elastic search
    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey

    const jsonBody = { id: scrollId }

    var apiUri = apiUriBase + `/download/devices?key=` + apiKey
    return axios.post(apiUri, jsonBody)
  }
}

export default SvcDevicesDownload
