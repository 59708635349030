import 'date-fns'

import {
  Tooltip as ChartTooltip,
  Legend,
  Line,
  LineChart,
  XAxis,
  YAxis,
} from 'recharts'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { Tab, Tabs } from '@material-ui/core'

import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import DateFnsUtils from '@date-io/date-fns'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import FileSaver from 'file-saver'
import GridListTileBar from '@material-ui/core/GridListTileBar'
import IconButton from '@material-ui/core/IconButton'
import Label from '@material-ui/icons/Label'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import MUIDataTable from 'mui-datatables'
import MenuItem from '@material-ui/core/MenuItem'
import PapaParse from 'papaparse'
import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import Spinner from 'nicollet-react/es/components/Spinner/Spinner'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
// import TopPanelMobileHelp from '../Dashboard/TopPanel/TopPanelHelp/TopPanelMobileHelp'
import Typography from '@material-ui/core/Typography'
import { fade } from '@material-ui/core/styles/colorManipulator'
import moment from 'moment'
import svcDevices from '../../services/svcDevices'
import { withAnalytics } from '@praxis/component-analytics'
import { withEnv } from '@praxis/component-runtime-env'
import { withStyles } from '@material-ui/core/styles'

function UtilizationChartDialog({
  open,
  onClose,
  storeUtilization,
  name,
  tabValue,
}) {
  return (
    <React.Fragment>
      <Dialog fullWidth open={open} onClose={onClose}>
        <DialogTitle>
          {name === 'devicesHandheldUtilization'
            ? tabValue === 0
              ? 'myDevice Utilization Details'
              : 'myCheckout Utilization Details'
            : 'Service Kiosk Utilization Details'}
        </DialogTitle>
        <IconButton
          aria-label="Close"
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        <MUIDataTable
          data={storeUtilization}
          options={{
            selectableRows: 'none',
            elevation: 0,
            print: false,
            pagination: true,
          }}
          columns={[
            {
              name: 'date',
              label: 'Date',
            },
            {
              name: 'total_devices',
              label: 'Total Devices',
              options: {
                filter: false,
              },
            },
            {
              name: 'max_utilized',
              label: 'Max Utilized',
              options: {
                filter: false,
              },
            },
            {
              name: 'max_utilized_percentage',
              label: 'Max Utilized Percentage',
              options: {
                filter: false,
              },
            },
          ]}
        />
      </Dialog>
    </React.Fragment>
  )
}

class DeviceUtilization extends React.Component {
  _isMounted = false
  constructor(props) {
    super(props)
    this.state = {
      dialogOpenUtilization: false,
      utilizationDataChartOpen: false,
      label: '',
      dropDownLabel: 'All',
      selected: 'All',
      searchText: '',
      startDate: moment(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)).format(
        'MM/DD/YYYY',
      ),
      endDate: moment(new Date(Date.now())).format('MM/DD/YYYY'),
      selectedSubmit: '',
      searchTextSubmit: '',
      dropDownLabelSubmit: '',
      startDateSubmit: '',
      endDateSubmit: '',
      tabValue: 0,
      deviceUtilization: [],
      snackBarOpen: false,
      snackBarMessageInfo: '',
      regionList: [
        'All',
        ...this.props.env.devicesSearchItems.region_id.search_suggestions,
      ],
      districtList: [
        'All',
        ...this.props.env.devicesSearchItems.district_id.search_suggestions,
      ],
      groupList: [
        'All',
        ...this.props.env.devicesSearchItems.group_id.search_suggestions,
      ],
      downloadStarted: false,
    }
    this.svcDevices = new svcDevices()
    this.counts = []
    this.totalCount = []
  }

  componentDidMount() {
    this.getUtilizationData()
  }

  getUtilizationData() {
    this._isMounted = true
    this.setState({ deviceCountsLoading: true })
    if (this.props.gridDetails.name === 'devicesHandheldUtilization') {
      if (this.state.tabValue === 0) {
        this.svcDevices
          .getmyDeviceUtilization(this.props, this.state)
          .then((response) => {
            const eventData = {
              customMetrics: {
                metric1: 'myDeviceMaxUtilization',
                metric3: response.status,
              },
              event: {
                type: `apiSuccessmyDeviceUtilization`,
              },
            }
            this.props.trackEvent(eventData)
            this.setState({
              deviceUtilization: response.data.max_utilizations,
            })
          })
          .catch((error, response) => {
            const eventData = {
              customMetrics: {
                metric1: 'myDeviceMaxUtilization',
                metric3: error.response.status,
              },
              event: {
                type: `apiErrormyDeviceUtilization`,
              },
            }
            this.props.trackEvent(eventData)
            this.setState({
              deviceUtilization: response,
            })
            console.log(error)
          })
          .finally(() => {
            this._isMounted && this.setState({ deviceCountsLoading: false })
          })
      } else if (this.state.tabValue === 1) {
        this.svcDevices
          .getmyCheckoutUtilization(this.props, this.state)
          .then((response) => {
            const eventData = {
              customMetrics: {
                metric1: 'myCheckoutMaxUtilization',
                metric3: response.status,
              },
              event: {
                type: `apiSuccessmyCheckoutUtilization`,
              },
            }
            this.props.trackEvent(eventData)
            this.setState({
              deviceUtilization: response.data.max_utilizations,
            })
          })
          .catch((error, response) => {
            const eventData = {
              customMetrics: {
                metric1: 'myCheckoutMaxUtilization',
                metric3: error.response.status,
              },
              event: {
                type: `apiErrormyCheckoutUtilization`,
              },
            }
            this.props.trackEvent(eventData)
            this.setState({
              deviceUtilization: response,
            })
            console.log(error)
          })
          .finally(() => {
            this._isMounted && this.setState({ deviceCountsLoading: false })
          })
      }
    }
    if (this.props.gridDetails.name === 'devicesTabletUtilization') {
      this.svcDevices
        .getKioskUtilization(this.props, this.state)
        .then((response) => {
          const eventData = {
            customMetrics: {
              metric1: 'KioskMaxUtilization',
              metric3: response.status,
            },
            event: {
              type: `apiSuccessServiceKioskUtilization`,
            },
          }
          this.props.trackEvent(eventData)
          this.setState({
            deviceUtilization: response.data.max_utilizations,
          })
        })
        .catch((error, response) => {
          const eventData = {
            customMetrics: {
              metric1: 'KioskMaxUtilization',
              metric3: error.response.status,
            },
            event: {
              type: `apiErrorServiceKioskUtilization`,
            },
          }
          this.props.trackEvent(eventData)
          this.setState({
            deviceUtilization: response,
          })
          console.log(error)
        })
        .finally(() => {
          this._isMounted && this.setState({ deviceCountsLoading: false })
        })
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  handleDialogOpen = (details, row) => {
    this.setState({
      dialogOpenUtilization: true,
      dialogDetails: details,
      dialogHeader: row,
    })
  }
  handleDialogClose = () => {
    this.setState({
      dialogOpen: false,
      dialogDetails: null,
      dialogHeader: null,
    })
  }

  handleDialogUtilizationClose = () => {
    this.getUtilizationData()
    this.setState({
      startDateSubmit: this.state.startDate,
      endDateSubmit: this.state.endDate,
      selectedSubmit: this.state.selected,
      dropDownLabelSubmit: this.state.dropDownLabel,
      searchTextSubmit: this.state.searchText,
      dialogOpenUtilization: false,
      dialogDetails: null,
      dialogHeader: null,
    })
  }

  handleDialogUtilizationReset = () => {
    this.setState({
      label: '',
      dropDownLabel: 'All',
      selected: 'All',
      searchText: '',
      startDate: moment(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)).format(
        'MM/DD/YYYY',
      ),
      endDate: moment(new Date(Date.now())).format('MM/DD/YYYY'),
      selectedSubmit: '',
      searchTextSubmit: '',
      dropDownLabelSubmit: '',
      startDateSubmit: '',
      endDateSubmit: '',
      deviceUtilization: [],
    })
  }

  handleUtilizationDownload = () => {
    this.setState({ downloadStarted: true })
    if (this.props.gridDetails.name === 'devicesTabletUtilization') {
      this.svcDevices
        .getKioskUtilization(this.props, this.state, true)
        .then((response) => {
          const eventData = {
            customMetrics: {
              metric1: 'KioskMaxUtilizationCsvDownload',
              metric3: response.status,
            },
            event: {
              type: `apiSuccessServiceKioskUtilizationCsvDownload`,
            },
          }
          this.props.trackEvent(eventData)
          // select the column order
          const dataString = PapaParse.unparse({
            fields: [
              'date',
              'region_id',
              'group_id',
              'district_id',
              'location_id',
              'available_devices',
              'max_utilized',
              'max_utilized_percentage',
            ],
            data: response.data,
          })
          const blob = new Blob([dataString], {
            type: 'text/csv;charset=utf-8',
          })
          let fileName =
            'mickra-kiosk-utilization-' + this.state.startDate + '.csv'
          FileSaver.saveAs(blob, fileName)
          this.setState({
            snackBarMessageInfo: 'CSV File Download Complete',
            snackBarOpen: true,
            downloadStarted: false,
          })
        })
        .catch((error, response) => {
          const eventData = {
            customMetrics: {
              metric1: 'KioskMaxUtilizationCsvDownload',
              metric3: error.response.status,
            },
            event: {
              type: `apiErrorServiceKioskUtilizationCsvDownload`,
            },
          }
          this.props.trackEvent(eventData)
          this.setState({
            snackBarMessageInfo: 'CSV File Download Error',
            snackBarOpen: true,
            downloadStarted: false,
          })
          console.log(error)
        })
    } else {
      if (this.state.tabValue === 0) {
        this.svcDevices
          .getmyDeviceUtilization(this.props, this.state, true)
          .then((response) => {
            const eventData = {
              customMetrics: {
                metric1: 'myDeviceMaxUtilizationCsvDownload',
                metric3: response.status,
              },
              event: {
                type: `apiSuccessmyDeviceUtilizationCsvDownload`,
              },
            }
            this.props.trackEvent(eventData)
            // select the column order
            const dataString = PapaParse.unparse({
              fields: [
                'date',
                'region_id',
                'group_id',
                'district_id',
                'location_id',
                'available_devices',
                'max_utilized',
                'max_utilized_percentage',
              ],
              data: response.data,
            })
            const blob = new Blob([dataString], {
              type: 'text/csv;charset=utf-8',
            })
            let fileName =
              'mickra-mydevice-utilization-' + this.state.startDate + '.csv'
            FileSaver.saveAs(blob, fileName)
            this.setState({
              snackBarMessageInfo: 'CSV File Download Complete',
              snackBarOpen: true,
              downloadStarted: false,
            })
          })
          .catch((error, response) => {
            const eventData = {
              customMetrics: {
                metric1: 'myDeviceMaxUtilizationCsvDownload',
                metric3: error.response.status,
              },
              event: {
                type: `apiErrormyDeviceUtilizationCsvDownload`,
              },
            }
            this.props.trackEvent(eventData)
            this.setState({
              snackBarMessageInfo: 'CSV File Download Error',
              snackBarOpen: true,
              downloadStarted: false,
            })
            console.log(error)
          })
      } else if (this.state.tabValue === 1) {
        this.svcDevices
          .getmyCheckoutUtilization(this.props, this.state, true)
          .then((response) => {
            const eventData = {
              customMetrics: {
                metric1: 'myCheckoutMaxUtilizationCsvDownload',
                metric3: response.status,
              },
              event: {
                type: `apiSuccessmyCheckoutUtilizationCsvDownload`,
              },
            }
            this.props.trackEvent(eventData)
            const dataString = PapaParse.unparse({
              fields: [
                'date',
                'region_id',
                'group_id',
                'district_id',
                'location_id',
                'available_devices',
                'max_utilized',
                'max_utilized_percentage',
              ],
              data: response.data,
            })
            const blob = new Blob([dataString], {
              type: 'text/csv;charset=utf-8',
            })
            let fileName =
              'mickra-mycheckout-utilization-' + this.state.startDate + '.csv'
            FileSaver.saveAs(blob, fileName)
            this.setState({
              snackBarMessageInfo: 'CSV File Download Complete',
              snackBarOpen: true,
              downloadStarted: false,
            })
          })
          .catch((error, response) => {
            const eventData = {
              customMetrics: {
                metric1: 'myCheckoutMaxUtilizationCsvDownload',
                metric3: error.response.status,
              },
              event: {
                type: `apiErrormyCheckoutUtilizationCsvDownload`,
              },
            }
            this.props.trackEvent(eventData)
            this.setState({
              snackBarMessageInfo: 'CSV File Download Error',
              snackBarOpen: true,
              downloadStarted: false,
            })
            console.log(error)
          })
      }
    }
    this.setState({
      startDateSubmit: this.state.startDate,
      endDateSubmit: this.state.endDate,
      selectedSubmit: this.state.selected,
      dropDownLabelSubmit: this.state.dropDownLabel,
      searchTextSubmit: this.state.searchText,
    })
  }

  handleClose = () => {
    this.setState({
      dialogOpenUtilization: false,
      dialogDetails: null,
      dialogHeader: null,
    })
  }

  handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    this.setState({ snackBarOpen: false })
  }

  footerUtilization = (gridDetails) => {
    return (
      <GridListTileBar
        onClick={() =>
          gridDetails.navigate_url !== null &&
          this.handleDialogOpen(gridDetails, this.props.gridDetails.name)
        }
        title={
          gridDetails.name === 'devicesHandheldUtilization'
            ? this.state.tabValue === 0
              ? gridDetails.myDeviceTitle
              : gridDetails.myCheckoutTitle
            : gridDetails.title
        }
        style={{
          textAlign: 'center',
          background: '#999999',
        }}
        actionIcon={
          this.props.gridDetails.navigate_url !== null && (
            <IconButton>
              {this.state.dialogHeader === gridDetails.name ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              )}
            </IconButton>
          )
        }
      ></GridListTileBar>
    )
  }

  selectOnOff = () => {
    const res = Object.entries(this.props.env.devicesSearchItems).filter(
      (f) =>
        f[0] === this.state.selected &&
        f[0] !== 'location_id' &&
        f[1].hasOwnProperty('search_suggestions'),
    )
    return res.length > 0
  }

  handleClick = (event) => {
    this.setState({
      label: event.target.value.search_label,
      selected: event.target.value.search_param,
      dropDownLabel: event.target.value.chip_label,
      searchText: '',
    })
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    })
  }

  handleStartDate = (date) => {
    this.setState({
      startDate: moment(date).format('MM/DD/YYYY'),
    })
  }
  handleEndDate = (date) => {
    this.setState({
      endDate: moment(date).format('MM/DD/YYYY'),
    })
  }

  handleUtililization() {
    const eventData = {
      customInteraction: {
        key: `UtilizationWidgetLandingPage`,
      },
    }
    this.props.trackEvent(eventData)
    this.setState({
      utilizationDataChartOpen: !this.state.utilizationDataChartOpen,
    })
  }

  handleChangeTab = (event, tabValue) => {
    this.setState({ tabValue }, () => {
      this.getUtilizationData()
    })
  }

  render() {
    const { classes, gridDetails } = this.props
    const { dialogOpenUtilization, snackBarMessageInfo } = this.state
    return this.state.deviceCountsLoading ? (
      <Spinner layout="selfCentering" />
    ) : this.props.gridDetails.name === 'devicesHandheldUtilization' ? (
      <>
        <MuiThemeProvider theme={this.getMuiTheme()}>
          <Tabs
            value={this.state.tabValue}
            onChange={this.handleChangeTab}
            variant="fullWidth"
            className={classes.tabs}
            TabIndicatorProps={{ className: classes.tabsProps }}
          >
            <Tab label="myDevice" className={classes.tab} />
            <Tab label="myCheckout" className={classes.tab} />
          </Tabs>
        </MuiThemeProvider>
        <AppBar
          position="static"
          elevation={0}
          square={false}
          className={classes.appBar}
        >
          {this.state.selectedSubmit !== '' &&
          this.state.selectedSubmit !== 'All' ? (
            <List className={classes.listSubmit}>
              <ListItem dense={true}>
                <ListItemText
                  className={classes.listItemText}
                  classes={{ primary: classes.listItemText }}
                >
                  {this.state.dropDownLabelSubmit +
                    ' : ' +
                    this.state.searchTextSubmit}
                </ListItemText>
              </ListItem>
              <ListItem dense={true}>
                <ListItemText
                  className={classes.listItemText}
                  classes={{ primary: classes.listItemText }}
                >
                  {'Start Date: ' + this.state.startDateSubmit + ' and '}
                  {'End Date: ' + this.state.endDateSubmit}
                </ListItemText>
              </ListItem>
            </List>
          ) : (
            <List className={classes.list}>
              <ListItem>
                <ListItemText
                  className={classes.listItemText}
                  classes={{ primary: classes.listItemText }}
                >
                  {'Start Date: ' + this.state.startDate + ' and '}
                  {'End Date: ' + this.state.endDate}
                </ListItemText>
              </ListItem>
            </List>
          )}
        </AppBar>
        {this.state.deviceUtilization === undefined ||
        this.state.deviceUtilization.length === 0 ? (
          <Typography style={{ paddingLeft: 100 }} variant="button">
            No data found for the given details
          </Typography>
        ) : (
          <LineChart
            width={gridDetails.layout.w * 0.88}
            height={gridDetails.layout.h * 0.5}
            data={this.state.deviceUtilization}
            margin={{ top: 30, right: 0, bottom: 10, left: -10 }}
            onClick={() => this.handleUtililization()}
            cursor="pointer"
          >
            <XAxis
              dataKey="date"
              tick={{
                fontSize: '0.875rem',
                fontFamily: 'roboto',
                fill: '#000000',
                // fontWeight: 500,
              }}
            />
            <YAxis
              type="number"
              width={80}
              domain={['auto', 'auto']}
              tick={{
                fontSize: '0.875rem',
                fontFamily: 'roboto',
                fill: '#000000',
                // fontWeight: 500,
              }}
            />
            <Legend
              iconType="square"
              iconSize={10}
              layout="vertical"
              align="center"
              verticalAlign="bottom"
              content={
                <CustomLegendLineChart gridDetails={this.props.gridDetails} />
              }
            />
            <ChartTooltip content={CustomUtilTooltipLineChart} />
            <Line
              type="monotone"
              dataKey="total_devices"
              stroke="#008300"
              dot={false}
              strokeWidth="3"
              name={
                this.state.tabValue === 0
                  ? 'Total myDevices'
                  : 'Total myCheckouts'
              }
            />
            <Line
              type="monotone"
              dataKey="max_utilized"
              stroke="#B85300"
              dot={false}
              strokeWidth="3"
              name={
                this.state.tabValue === 0
                  ? 'Max Utilized myDevices'
                  : 'Max Utilized myCheckouts'
              }
            />
          </LineChart>
        )}
        {/* commented out info in case we need to re-implement quickly */}
        {/* <div style={{ display: 'flex' }}>
          <InfoOutlinedIcon style={{ paddingRight: '10' }} />
          <Typography
            style={{
              display: 'flex',
              justifyContent: 'center',
              color: 'black',
              fontWeight: '400',
              textAlign: 'center',
              paddingTop: '10',
            }}
          >
            {this.props.env.widgetHelperText.utilizationWidgetMessage}
          </Typography>
        </div> */}
        {this.footerUtilization(this.props.gridDetails)}
        <MuiThemeProvider theme={this.getMuiTheme()}>
          <UtilizationChartDialog
            open={this.state.utilizationDataChartOpen}
            onClose={() => this.handleUtililization()}
            storeUtilization={this.state.deviceUtilization}
            name={this.props.gridDetails.name}
            tabValue={this.state.tabValue}
          />
        </MuiThemeProvider>
        {dialogOpenUtilization ? (
          <Dialog
            open={dialogOpenUtilization}
            onClose={this.handleDialogUtilizationClose}
            className={classes.dialog}
            maxWidth="sm"
          >
            <AppBar
              position="static"
              elevation={0}
              square={false}
              className={classes.appBar}
            >
              <Typography variant="h6" className={classes.title}>
                {this.state.tabValue === 0
                  ? 'MyDevice Utilization'
                  : 'MyCheckout Utilization'}
              </Typography>
              <IconButton
                aria-label="Close"
                className={classes.icon}
                onClick={this.handleClose}
              >
                <CloseIcon />
              </IconButton>
            </AppBar>
            <DialogContent className={classes.dialog}>
              <TextField
                style={{
                  marginLeft: 5,
                  marginRight: 5,
                  width: 240,
                  textAlign: 'left',
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                SelectProps={{
                  value: this.state.dropDownLabel,
                  renderValue: (value) => value,
                }}
                select
                onChange={this.handleClick}
                margin="dense"
                variant="standard"
                color="primary"
                label={
                  <Typography variant="button" style={{ color: 'black' }}>
                    Please Select a Search Option
                  </Typography>
                }
              >
                <MenuItem
                  value={{
                    device_utilization: true,
                    chip_label: 'All',
                    search_label: '',
                    search_param: 'All',
                  }}
                  styles={{ color: 'black' }}
                >
                  All
                </MenuItem>
                {Object.entries(this.props.env.devicesSearchItems).map(
                  (row, index) => {
                    return (
                      row[1].device_utilization && (
                        <MenuItem
                          key={index}
                          value={row[1]}
                          styles={{ color: 'black' }}
                        >
                          {row[1].chip_label}
                        </MenuItem>
                      )
                    )
                  },
                )}
              </TextField>
              <TextField
                value={this.state.searchText}
                onChange={this.handleChange('searchText')}
                style={{
                  marginLeft: 5,
                  marginRight: 5,
                  width: 260,
                  textAlign: 'left',
                }}
                margin="dense"
                variant="standard"
                color="primary"
                disabled={this.state.selected === 'All'}
                label={
                  <Typography variant="button" style={{ color: 'black' }}>
                    {this.state.label}
                  </Typography>
                }
                select={this.selectOnOff()}
              >
                {this.state.selected === 'region_id'
                  ? [
                      'All',
                      ...this.props.env.devicesSearchItems.region_id
                        .search_suggestions,
                    ]
                      .sort((a, b) => (+b === b && +a !== a) || a - b)
                      .map((option, index) => (
                        <MenuItem key={index} dense value={option}>
                          {option}
                        </MenuItem>
                      ))
                  : this.state.selected === 'group_id'
                    ? [
                        'All',
                        ...this.props.env.devicesSearchItems.group_id
                          .search_suggestions,
                      ]
                        .sort((a, b) => (+b === b && +a !== a) || a - b)
                        .map((option, index) => (
                          <MenuItem key={index} dense value={option}>
                            {option}
                          </MenuItem>
                        ))
                    : this.state.selected === 'district_id' &&
                      [
                        'All',
                        ...this.props.env.devicesSearchItems.district_id
                          .search_suggestions,
                      ]
                        .sort((a, b) => (+b === b && +a !== a) || a - b)
                        .map((option, index) => (
                          <MenuItem key={index} dense value={option}>
                            {option}
                          </MenuItem>
                        ))}
              </TextField>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableFuture
                  minDate={moment(
                    new Date(Date.now() - 90 * 24 * 60 * 60 * 1000),
                  ).format('MM/DD/YYYY')}
                  margin="normal"
                  cursor="pointer"
                  label="PLEASE SELECT A START DATE"
                  format="MM/dd/yyyy"
                  value={this.state.startDate}
                  onChange={(date) => this.handleStartDate(date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  className={classes.date}
                />
                <KeyboardDatePicker
                  disableFuture
                  minDate={moment(
                    new Date(Date.now() - 90 * 24 * 60 * 60 * 1000),
                  ).format('MM/DD/YYYY')}
                  margin="normal"
                  cursor="pointer"
                  label="PLEASE SELECT A END DATE"
                  format="MM/dd/yyyy"
                  value={this.state.endDate}
                  onChange={(date) => this.handleEndDate(date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  className={classes.date}
                />
              </MuiPickersUtilsProvider>
              <Tooltip title="Search">
                <Button
                  className={classes.button}
                  variant="outlined"
                  disabled={
                    this.state.downloadStarted ||
                    (this.state.selected !== 'All' &&
                      this.state.searchText === '')
                  }
                  onClick={() => this.handleDialogUtilizationClose()}
                >
                  Search
                </Button>
              </Tooltip>
              <Tooltip title="Reset Search Options">
                <Button
                  className={classes.button}
                  variant="outlined"
                  disabled={this.state.downloadStarted}
                  onClick={() => this.handleDialogUtilizationReset()}
                >
                  Reset
                </Button>
              </Tooltip>
              <Tooltip title={'CSV Download'}>
                <Button
                  className={classes.button}
                  variant="outlined"
                  disabled={
                    this.state.selected !== 'All' &&
                    this.state.searchText === ''
                  }
                  onClick={() => this.handleUtilizationDownload()}
                >
                  Download
                  {this.state.downloadStarted ? (
                    <Spinner className={classes.spinner} />
                  ) : (
                    ''
                  )}
                </Button>
              </Tooltip>

              {/* <TopPanelMobileHelp widgetHelperText="utilizationDownloadHelp" /> */}
            </DialogContent>
            <Snackbar
              open={this.state.snackBarOpen}
              autoHideDuration={5000}
              onClose={this.handleSnackBarClose}
              message={snackBarMessageInfo}
              action={[
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  onClick={this.handleSnackBarClose}
                >
                  <CloseIcon className={classes.CloseIcon} />
                </IconButton>,
              ]}
            />
          </Dialog>
        ) : (
          ''
        )}
      </>
    ) : (
      <>
        <MuiThemeProvider theme={this.getMuiTheme()}></MuiThemeProvider>
        <AppBar
          position="static"
          elevation={0}
          square={false}
          className={classes.appBar}
        >
          {this.state.selectedSubmit !== '' &&
          this.state.selectedSubmit !== 'All' ? (
            <List className={classes.listSubmit}>
              <ListItem dense={true}>
                <ListItemText
                  className={classes.listItemText}
                  classes={{ primary: classes.listItemText }}
                >
                  {this.state.dropDownLabelSubmit +
                    ' : ' +
                    this.state.searchTextSubmit}
                </ListItemText>
              </ListItem>
              <ListItem dense={true}>
                <ListItemText
                  className={classes.listItemText}
                  classes={{ primary: classes.listItemText }}
                >
                  {'Start Date: ' + this.state.startDateSubmit + ' and '}
                  {'End Date: ' + this.state.endDateSubmit}
                </ListItemText>
              </ListItem>
            </List>
          ) : (
            <List className={classes.list}>
              <ListItem>
                <ListItemText
                  className={classes.listItemText}
                  classes={{ primary: classes.listItemText }}
                >
                  {'Start Date: ' + this.state.startDate + ' and '}
                  {'End Date: ' + this.state.endDate}
                </ListItemText>
              </ListItem>
            </List>
          )}
        </AppBar>
        {this.state.deviceUtilization === undefined ||
        this.state.deviceUtilization.length === 0 ? (
          <Typography style={{ paddingLeft: 100 }} variant="button">
            No data found for the given details
          </Typography>
        ) : (
          <LineChart
            width={gridDetails.layout.w * 0.88}
            height={gridDetails.layout.h * 0.6}
            data={this.state.deviceUtilization}
            margin={{ top: 30, right: 0, bottom: 10, left: -10 }}
            onClick={() => this.handleUtililization()}
            cursor="pointer"
          >
            <XAxis
              dataKey="date"
              tick={{
                fontSize: '0.875rem',
                fontFamily: 'roboto',
                fill: '#000000',
                // fontWeight: 500,
              }}
            />
            <YAxis
              type="number"
              width={80}
              domain={['auto', 'auto']}
              tick={{
                fontSize: '0.875rem',
                fontFamily: 'roboto',
                fill: '#000000',
                // fontWeight: 500,
              }}
            />
            <Legend
              iconType="square"
              iconSize={10}
              layout="vertical"
              align="center"
              verticalAlign="bottom"
              content={
                <CustomLegendLineChart gridDetails={this.props.gridDetails} />
              }
            />
            <ChartTooltip content={CustomUtilTooltipLineChart} />
            <Line
              type="monotone"
              dataKey="total_devices"
              stroke="#008300"
              dot={false}
              strokeWidth="3"
              name={'Total Service Kiosks'}
            />
            <Line
              type="monotone"
              dataKey="max_utilized"
              stroke="#B85300"
              dot={false}
              strokeWidth="3"
              name={'Max Utilized Service Kiosks'}
            />
          </LineChart>
        )}
        {/* commented out info in case we need to re-implement quickly */}
        {/* <div style={{ display: 'flex' }}>
          <InfoOutlinedIcon style={{ paddingRight: '10' }} />
          <Typography
            style={{
              display: 'flex',
              justifyContent: 'center',
              color: 'black',
              fontWeight: '400',
              textAlign: 'center',
              paddingTop: '10',
            }}
          >
            {this.props.env.widgetHelperText.utilizationWidgetMessage}
          </Typography>
        </div> */}
        {this.footerUtilization(this.props.gridDetails)}
        <MuiThemeProvider theme={this.getMuiTheme()}>
          <UtilizationChartDialog
            open={this.state.utilizationDataChartOpen}
            onClose={() => this.handleUtililization()}
            storeUtilization={this.state.deviceUtilization}
            name={this.props.gridDetails.name}
          />
        </MuiThemeProvider>
        {dialogOpenUtilization ? (
          <Dialog
            open={dialogOpenUtilization}
            onClose={this.handleDialogUtilizationClose}
            className={classes.dialog}
            maxWidth="sm"
          >
            <AppBar
              position="static"
              elevation={0}
              square={false}
              className={classes.appBar}
            >
              <Typography variant="h6" className={classes.title}>
                Service Kiosk Utilization
              </Typography>
              <IconButton
                aria-label="Close"
                className={classes.icon}
                onClick={this.handleClose}
              >
                <CloseIcon />
              </IconButton>
            </AppBar>
            <DialogContent className={classes.dialog}>
              <TextField
                style={{
                  marginLeft: 5,
                  marginRight: 5,
                  width: 240,
                  textAlign: 'left',
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                SelectProps={{
                  value: this.state.dropDownLabel,
                  renderValue: (value) => value,
                }}
                select
                onChange={this.handleClick}
                margin="dense"
                variant="standard"
                color="primary"
                label={
                  <Typography variant="button" style={{ color: 'black' }}>
                    Please Select a Search Option
                  </Typography>
                }
              >
                <MenuItem
                  value={{
                    device_utilization: true,
                    chip_label: 'All',
                    search_label: '',
                    search_param: 'All',
                  }}
                  styles={{ color: 'black' }}
                >
                  All
                </MenuItem>
                {Object.entries(this.props.env.devicesSearchItems).map(
                  (row, index) => {
                    return (
                      row[1].device_utilization && (
                        <MenuItem
                          key={index}
                          value={row[1]}
                          styles={{ color: 'black' }}
                        >
                          {row[1].chip_label}
                        </MenuItem>
                      )
                    )
                  },
                )}
              </TextField>
              <TextField
                value={this.state.searchText}
                onChange={this.handleChange('searchText')}
                style={{
                  marginLeft: 5,
                  marginRight: 5,
                  width: 260,
                  textAlign: 'left',
                }}
                margin="dense"
                variant="standard"
                color="primary"
                disabled={this.state.selected === 'All'}
                label={
                  <Typography variant="button" style={{ color: 'black' }}>
                    {this.state.label}
                  </Typography>
                }
                select={this.selectOnOff()}
              >
                {this.state.selected === 'region_id'
                  ? this.state.regionList
                      .sort((a, b) => (+b === b && +a !== a) || a - b)
                      .map((option, index) => (
                        <MenuItem key={index} dense value={option}>
                          {option}
                        </MenuItem>
                      ))
                  : this.state.selected === 'group_id'
                    ? this.state.groupList
                        .sort((a, b) => (+b === b && +a !== a) || a - b)
                        .map((option, index) => (
                          <MenuItem key={index} dense value={option}>
                            {option}
                          </MenuItem>
                        ))
                    : this.state.selected === 'district_id' &&
                      this.state.districtList
                        .sort((a, b) => (+b === b && +a !== a) || a - b)
                        .map((option, index) => (
                          <MenuItem key={index} dense value={option}>
                            {option}
                          </MenuItem>
                        ))}
              </TextField>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableFuture
                  minDate={moment(
                    new Date(Date.now() - 90 * 24 * 60 * 60 * 1000),
                  ).format('MM/DD/YYYY')}
                  margin="normal"
                  cursor="pointer"
                  label="PLEASE SELECT A START DATE"
                  format="MM/dd/yyyy"
                  value={this.state.startDate}
                  onChange={(date) => this.handleStartDate(date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  className={classes.date}
                />
                <KeyboardDatePicker
                  disableFuture
                  minDate={moment(
                    new Date(Date.now() - 90 * 24 * 60 * 60 * 1000),
                  ).format('MM/DD/YYYY')}
                  margin="normal"
                  cursor="pointer"
                  label="PLEASE SELECT A END DATE"
                  format="MM/dd/yyyy"
                  value={this.state.endDate}
                  onChange={(date) => this.handleEndDate(date)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  className={classes.date}
                />
              </MuiPickersUtilsProvider>
              <Tooltip title="Search">
                <Button
                  className={classes.button}
                  variant="outlined"
                  disabled={
                    this.state.downloadStarted ||
                    (this.state.selected !== 'All' &&
                      this.state.searchText === '')
                  }
                  onClick={() => this.handleDialogUtilizationClose()}
                >
                  Search
                </Button>
              </Tooltip>
              <Tooltip title="Reset Search Options">
                <Button
                  className={classes.button}
                  variant="outlined"
                  disabled={this.state.downloadStarted}
                  onClick={() => this.handleDialogUtilizationReset()}
                >
                  Reset
                </Button>
              </Tooltip>
              <Tooltip title={'CSV Download'}>
                <Button
                  className={classes.button}
                  variant="outlined"
                  disabled={
                    this.state.selected !== 'All' &&
                    this.state.searchText === ''
                  }
                  onClick={() => this.handleUtilizationDownload()}
                >
                  Download
                  {this.state.downloadStarted ? (
                    <Spinner className={classes.spinner} />
                  ) : (
                    ''
                  )}
                </Button>
              </Tooltip>

              {/* <TopPanelMobileHelp widgetHelperText="utilizationDownloadHelp" /> */}
            </DialogContent>
            <Snackbar
              open={this.state.snackBarOpen}
              autoHideDuration={5000}
              onClose={this.handleSnackBarClose}
              message={snackBarMessageInfo}
              action={[
                <IconButton
                  key="close"
                  aria-label="Close"
                  color="inherit"
                  onClick={this.handleSnackBarClose}
                >
                  <CloseIcon className={classes.CloseIcon} />
                </IconButton>,
              ]}
            />
          </Dialog>
        ) : (
          ''
        )}
      </>
    )
  }
  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTableBodyCell: {
          root: {
            backgroundColor: '#eeeeee',
            padding: 1,
            paddingLeft: 8,
          },
        },
        MUIDataTableHeadCell: {
          root: {
            backgroundColor: '#eeeeee',
            padding: 0,
            paddingLeft: 17,
          },
        },
        MUIDataTableHeadRow: {
          root: {
            height: 10,
          },
        },
        MUIDataTableBodyRow: {
          root: {
            backgroundColor: '#eeeeee',
            height: 10,
          },
        },
        MUIDataTableToolbar: {
          root: {
            height: 10,
          },
          titleText: {
            fontSize: 14,
          },
          left: {
            flex: '100 0 auto',
          },
        },
        MuiTab: {
          root: { '&$selected': { backgroundColor: '#fff' } },
        },
      },
    })
}

const CustomLegendLineChart = (props) => {
  return props.payload.map((row, index) => (
    <Typography
      variant="button"
      style={{
        display: 'inline-flex',
        fontSize: 14,
        marginRight: 15,
      }}
    >
      <Label
        style={{
          fontSize: 24,
          color: props.payload[index].color,
          paddingRight: 5,
        }}
      />
      {row.value}
    </Typography>
  ))
}
const CustomUtilTooltipLineChart = (props) => {
  return (
    props.payload !== null &&
    props.payload.map(
      (row, index) =>
        props.active && (
          <Typography
            key={index}
            style={{
              display: 'flex',
              alignItems: 'center',
              fontSize: 12,
              fontWeight: 'medium',
              backgroundColor: '#666666',
              color: '#F7F7F7',
              borderRadius: 5,
              padding: '4px 8px',
              margin: 2,
            }}
          >
            <Label
              style={{
                fontSize: 24,
                color: row.stroke,
                paddingRight: 5,
              }}
            />
            {row.name + ': ' + row.value + ' (' + row.payload.date + ')'}
          </Typography>
        ),
    )
  )
}
const styles = {
  paper: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    paddingLeft: '0',
    minWidth: '400',
  },
  list: {
    maxHeight: 30,
    paddingTop: 5,
  },
  listSubmit: {
    maxHeight: 60,
    paddingTop: 0,
  },
  listItemText: {
    color: 'black',
    paddingTop: 0,
    paddingBottom: 0,
    textTransform: 'uppercase',
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 12,
  },
  button: {
    marginTop: 5,
    marginBottom: 5,
    marginRight: 5,
  },
  dialog: {
    textAlign: 'center',
    marginTop: 15,
  },
  appBar: {
    background: '#ffffff',
  },
  title: {
    paddingTop: 15,
    color: 'black',
  },
  icon: {
    position: 'absolute',
    right: 8,
    top: 8,
    paddingTop: 8,
  },
  date: {
    marginLeft: 5,
    marginRight: 5,
    width: 250,
  },
  tab: {
    borderRight: '1px solid #666666',
    borderLeft: '1px solid #666666',
    borderTop: '1px solid #666666',
    borderBottom: '1px solid #666666',
    borderRadius: '5px 5px 0px 0px',
  },
  tabs: {
    backgroundColor: '#999999',
  },
  tabsProps: {
    backgroundColor: `${fade('#fff', 0.1)}`,
    borderBottom: '1px solid white',
  },
  closeIcon: {
    fontSize: 20,
  },
  iconNull: {
    color: '#999',
    '&:hover': {
      backgroundColor: 'transparent',
      cursor: 'not-allowed',
    },
  },
  spinner: {
    width: '30px',
    height: '30px',
    marginLeft: '10px',
  },
}

export default withStyles(styles)(withEnv()(withAnalytics()(DeviceUtilization)))
