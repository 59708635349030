export const l4DeviceTypes = [
  'ups',
  'ats',
  'pdu',
  'generator',
  'digital_media_player',
  'baremetal_server',
  'chassis',
  'scale',
  'printer',
  'register',
  'voice_gateway',
  'door_access_control',
  //'network_access_control_and_visibility',
  'network_access_point',
  'network_chassis',
  'network_management',
  'network_gateway',
  'network_switch',
  'network_router',
  'rfid_handheld',
  'broadcast',
  'phone',
  'network_traffic_management',
  'submetering_controls',
]
export default l4DeviceTypes
