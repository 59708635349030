import { MuiThemeProvider, createTheme } from '@material-ui/core/styles'

import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import Dialog from '@material-ui/core/Dialog'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Good from '@material-ui/icons/CheckCircleTwoTone'
import IconButton from '@material-ui/core/IconButton'
import ListItem from '@material-ui/core/ListItem'
import Paper from '@material-ui/core/Paper'
import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import Spinner from 'nicollet-react/es/components/Spinner/Spinner'
import SvcCICOActions from '../../../services/svcCICOActions'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import WarningIcon from '@material-ui/icons/Warning'
import { withAnalytics } from '@praxis/component-analytics'
import { withEnv } from '@praxis/component-runtime-env'
import withRouter from '../../withRouter'
import { withStyles } from '@material-ui/styles'

const OnSwitch = withStyles({
  switchBase: {
    '&$checked': {
      color: '#36b54e',
    },
    '&$checked + $track': {
      backgroundColor: '#aae6b5',
    },
  },
  checked: {},
  track: {},
})(Switch)

class CICOBulkActions extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      snackBarOpen: false,
      snackBarMessageInfo: '',
      actionSuccess: false,
      loading: false,
      incidentNumber: '',
      helperText: '*Required',
      error: false,
      disableButton: true,
      checkedCICO: this.props.cicoEnabled,
      checkedAlarm: this.props.alarmCICOEnabled,
    }
    this.svcCICOActions = new SvcCICOActions()
    this.goBack = this.goBack.bind(this)
  }
  handleChange = (name) => (event) => {
    const { cicoEnabled, alarmCICOEnabled } = this.props
    const { checkedCICO, checkedAlarm } = this.state
    this.setState({
      disableButton: false,
      incidentNumber: event.target.value,
    })
    const regularExp = /^[A-Za-z0-9-]*$/
    if (event.target.value.length === 0) {
      this.setState({
        disableButton: true,
      })
    }
    if (regularExp.test(event.target.value)) {
      this.setState({ helperText: '*Required', error: false })
      if (checkedCICO === cicoEnabled && checkedAlarm === alarmCICOEnabled) {
        this.setState({ disableButton: true })
      }
    } else {
      this.setState({
        helperText: 'Invalid format: Only letters, numbers and hyphen allowed',
        error: true,
        disableButton: true,
      })
    }
  }
  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    this.setState({ snackBarOpen: false })
  }
  handleOnToggleCICO = () => {
    const { alarmCICOEnabled, cicoEnabled } = this.props
    const { checkedAlarm, incidentNumber, error } = this.state

    this.setState({ checkedCICO: !this.state.checkedCICO })
    if (
      (!this.state.checkedCICO === cicoEnabled &&
        checkedAlarm === alarmCICOEnabled) ||
      incidentNumber === '' ||
      error === true
    ) {
      this.setState({ disableButton: true })
    } else {
      this.setState({ disableButton: false })
    }
  }

  handleOnToggleAlarm = () => {
    const { alarmCICOEnabled, cicoEnabled } = this.props
    const { checkedCICO, incidentNumber, error } = this.state
    this.setState({ checkedAlarm: !this.state.checkedAlarm })
    if (
      (!this.state.checkedAlarm === alarmCICOEnabled &&
        checkedCICO === cicoEnabled) ||
      incidentNumber === '' ||
      error === true
    ) {
      this.setState({ disableButton: true })
    } else {
      this.setState({ disableButton: false })
    }
  }

  goBack() {
    this.props.router.navigate(0)
  }

  handleSubmit() {
    const { dataCICO, locationID } = this.props
    const { incidentNumber, checkedCICO, checkedAlarm } = this.state
    this.setState({ loading: true })
    this.svcCICOActions
      .postCICOAction(
        dataCICO,
        'T' + locationID.padStart(4, '0'),
        this.props.env,
        incidentNumber,
        'STORE',
        checkedCICO,
        !checkedCICO ? false : checkedAlarm,
      )
      .then((success) => {
        const eventData = {
          customMetrics: {
            metric1: 'actionCICO',
            metric3: success.status,
          },
          event: {
            type: 'apiSuccessCICOAction',
          },
        }
        this.props.trackEvent(eventData)
        if (Math.round(success.status / 100) === 2) {
          this.setState({
            snackBarMessageInfo: `Updated Checkout Status for location ${locationID}`,
            snackBarOpen: true,
            actionSuccess: true,
          })
        }
      })
      .catch((error) => {
        const eventData = {
          customMetrics: {
            metric1: 'actionCICO',
            metric3: error.response.status,
          },
          event: {
            type: 'apiErrorCICOAction',
          },
        }
        this.props.trackEvent(eventData)
        if (
          Math.round(error.response.status / 100) === 5 ||
          Math.round(error.response.status / 100) === 4
        ) {
          if (
            error.response.data.message !== null &&
            error.response.data.message !== '' &&
            error.response.data.message !== undefined
          ) {
            this.setState({
              snackBarOpen: true,
              snackBarMessageInfo: error.response.data.message,
            })
          } else {
            this.setState({
              snackBarOpen: true,
              snackBarMessageInfo:
                'ERROR - An error has occurred while updating checkout status',
            })
          }
        } else {
          this.setState({
            snackBarOpen: true,
            snackBarMessageInfo:
              'ERROR - An error has occurred while updating checkout status',
          })
        }
      })
      .finally(() => {
        this.setState({ loading: false })
      })
  }

  render() {
    const { classes, locationID } = this.props
    const {
      snackBarMessageInfo,
      actionSuccess,
      loading,
      helperText,
      error,
      disableButton,
      checkedCICO,
      checkedAlarm,
    } = this.state

    return (
      <Dialog
        fullWidth
        open={this.props.open}
        onClose={this.props.onClose}
        className={classes.dialog}
      >
        <DialogTitle className={classes.dialogTitle}>
          <WarningIcon size="large" className={classes.warningIcon} /> Confirm
          Action - Manage Checkout Status
        </DialogTitle>
        <Divider />
        <br />
        <DialogContentText>
          <MuiThemeProvider theme={this.getMuiTheme()}>
            <FormGroup>
              <FormControlLabel
                control={
                  <OnSwitch
                    checked={checkedCICO}
                    inputProps={{
                      'aria-label': 'checkbox with default color',
                    }}
                  />
                }
                onClick={this.handleOnToggleCICO}
                label="Toggle Checkout Status :"
                labelPlacement="start"
                className={classes.toggleButton}
              />
              {checkedAlarm !== null && checkedCICO !== false && (
                <FormControlLabel
                  control={
                    <OnSwitch
                      checked={checkedAlarm}
                      color="default"
                      inputProps={{
                        'aria-label': 'checkbox with default color',
                      }}
                    />
                  }
                  onClick={this.handleOnToggleAlarm}
                  label="Toggle Checkout Alarm :"
                  labelPlacement="start"
                  className={classes.toggleButton}
                />
              )}
            </FormGroup>
          </MuiThemeProvider>
          <TextField
            id="enter-incident-details"
            label="Enter Incident Number"
            value={this.state.incidentNumber}
            onChange={this.handleChange('incidentNumber')}
            className={classes.searchTextbar}
            error={error}
            helperText={helperText}
            margin="dense"
            variant="filled"
          />
          <Paper className={classes.warningBox}>
            <Typography variant="body1" className={classes.warningBoxTitle}>
              <strong>Warning</strong>
            </Typography>
            <Typography variant="body2" className={classes.warningBoxContent}>
              This action has a store wide effect on store{' '}
              <strong>
                {'T'}
                {locationID}
              </strong>
              !
              <br />
              The changes will be reflected after <strong>{'15 mins'}</strong>.
              <br />
              This feature will <strong>{'reactivate'}</strong> automatically
              after <strong>{'48 hours'}</strong>. At that time, any device that
              is not on cradle (or in use) will start ringing.
            </Typography>
          </Paper>
        </DialogContentText>
        <IconButton
          aria-label="Close"
          className={classes.icon}
          onClick={this.props.onClose}
        >
          <CloseIcon />
        </IconButton>
        <div>
          {loading ? (
            <Spinner className={classes.spinner} />
          ) : (
            <ListItem className={classes.list}>
              {!actionSuccess ? (
                <React.Fragment>
                  <Tooltip
                    title={
                      disableButton === true
                        ? 'Enter a valid Incident Number'
                        : 'Confirm Action'
                    }
                    placement="top"
                  >
                    <Button
                      className={classes.submitButton}
                      variant="outlined"
                      onClick={() => this.handleSubmit()}
                      disabled={disableButton}
                    >
                      Confirm
                    </Button>
                  </Tooltip>
                  <Button
                    className={classes.cancelButton}
                    variant="outlined"
                    onClick={this.props.onClose}
                  >
                    CANCEL
                  </Button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Button
                    className={classes.successButton}
                    variant="outlined"
                    onClick={this.goBack}
                  >
                    BACK
                  </Button>
                  <Tooltip title="Success" placement="top">
                    <Good className={classes.good} />
                  </Tooltip>
                </React.Fragment>
              )}
            </ListItem>
          )}
        </div>
        <Snackbar
          open={this.state.snackBarOpen}
          autoHideDuration={5000}
          onClose={this.handleClose}
          message={snackBarMessageInfo}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.props.onClose}
            >
              <CloseIcon className={classes.CloseIcon} />
            </IconButton>,
          ]}
        />
      </Dialog>
    )
  }
  getMuiTheme = () =>
    createTheme({
      overrides: {
        MuiFormControlLabel: {
          label: {
            fontWeight: 'bold',
          },
        },
        MuiDialogTitle: {
          root: {
            fontWeight: 'lighter',
          },
        },
      },
    })
}

const styles = {
  icon: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
  submitButton: {
    float: 'left',
  },
  successButton: {
    float: 'left',
  },
  cancelButton: {
    float: 'left',
    marginLeft: 5,
  },
  dialog: {
    textAlign: 'center',
  },
  dialogTitle: {
    marginTop: 20,
  },
  list: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 20,
  },
  spinner: {
    left: '40%',
  },
  good: {
    fontSize: 40,
    color: 'green',
    paddingLeft: 5,
  },
  warningIcon: {
    position: 'relative',
    top: 5,
  },
  warningBox: {
    textAlign: 'left',
    backgroundColor: '#faf5ed',
    width: '75%',
    marginLeft: 'auto',
    marginRight: 'auto',
    margin: 10,
    padding: 20,
  },
  warningBoxTitle: {
    color: '#ffb345',
  },
  warningBoxContent: {
    color: '#97784b',
  },
  searchTextbar: {
    minWidth: 200,
    width: '300px',
  },
  toggleButton: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}

export default withStyles(styles, { withTheme: true })(
  withRouter(withAnalytics()(withEnv()(CICOBulkActions))),
)
