import React from 'react'
import axios from 'axios'

class SvcCICOActions extends React.Component {
  getPolicyDetails(locationID, config) {
    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey
    const apiUri = encodeURI(
      `${apiUriBase}/handhelds/tep_store_opacs/store_id/${locationID}?key=${apiKey}`,
    )
    return axios.get(apiUri)
  }

  getOpacDetails(locationID, config) {
    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey
    const apiUri = encodeURI(
      `${apiUriBase}/handhelds/tep_device_opacs/store_id/${locationID}?key=${apiKey}`,
    )
    return axios.get(apiUri)
  }

  getOpacStatus(id, config) {
    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey
    const apiUri = encodeURI(
      `${apiUriBase}/handhelds/tep_device_opacs_status/${id}?key=${apiKey}`,
    )
    return axios.get(apiUri)
  }

  postCICOAction(
    dataCICO,
    id,
    config,
    incidentRef,
    policyType,
    enableCICO,
    enableAlarm,
  ) {
    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey
    const { policyToApply, policyName, action, policyRequest, deviceTypes } =
      dataCICO

    const jsonBody = {
      policyToApply: policyToApply,
      policyName: policyName,
      action: action,
      input: {
        device_locker_policy: {
          device_locker_enabled: enableCICO,
          device_locker_ring_enabled: enableAlarm,
        },
      },
      policyRequest: policyRequest,
      deviceTypes: deviceTypes,
      policyType: policyType,
      incident_ref: incidentRef,
    }

    var apiUri =
      apiUriBase + `/handhelds/tep_store_opacs/` + id + `?key=` + apiKey
    return axios.put(apiUri, jsonBody)
  }

  postOpacAction(data, id, selectedOptions, config) {
    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey
    const { target_type, opac_name, type } = data

    const jsonBody = {
      basic_targets: [id],
      target_type: target_type,
      opac_type: opac_name,
      type: type,
    }

    const iterator1 = selectedOptions.keys()

    for (const item of iterator1) {
      jsonBody[item] = selectedOptions.get(item)
    }

    var apiUri = apiUriBase + `/handhelds/tep_device_opacs?key=` + apiKey
    return axios.post(apiUri, jsonBody)
  }
}

export default SvcCICOActions
