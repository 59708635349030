import ApplePhone from '@material-ui/icons/PhoneIphone'
import Kiosk from '@material-ui/icons/TabletAndroid'
import Tablet from '@material-ui/icons/Tablet'
// import mac from '../../../images/mac.svg'
import myCheckout from '../../../images/myCheckout.svg'
import myDevice from '../../../images/myDevice.svg'
// import windows from '../../../images/windows.svg'

export const handheldDeviceTypes = {
  myDevice: {
    device_classification: 'handheld',
    platform: 'android',
    itemIcon: myDevice,
    itemText: 'MyDevice',
    itemFilter: 'filtermyDevice',
    locationType: ['STR', 'STR-V', 'OFFICE', 'OSW'],
    availableActions: [
      'editDeviceLabel',
      'setLostStolen',
      'deleteHandheld',
      'markDeviceQuarantine',
      'deleteRepairDevice',
      'markOfflineHandheldDevice',
      'updateOrgGroup',
      'tepOpacs',
      'setStolen',
    ],
    availableHeathItems: ['batteryHealth'], // 'utilizationHealth'],
  },
  myCheckout: {
    device_classification: 'handheld',
    platform: 'android',
    itemIcon: myCheckout,
    itemText: 'MyCheckout',
    locationType: ['STR', 'STR-V', 'OFFICE'],
    itemFilter: 'filtermyCheckout',
    availableActions: [
      'editDeviceLabel',
      'setLostStolen',
      'deleteHandheld',
      'markDeviceQuarantine',
      'deleteRepairDevice',
      'markOfflineHandheldDevice',
      'updateOrgGroup',
      'tepOpacs',
      'setStolen',
    ],
    availableHeathItems: ['batteryHealth'], // 'utilizationHealth'],
  },
  'My Kiosk': {
    device_classification: 'tablet',
    platform: 'android',
    itemIcon: Kiosk,
    itemText: 'My Kiosk',
    locationType: ['STR', 'STR-V', 'OFFICE'],
    itemFilter: 'filterMyKiosk',
    availableActions: [
      'editDeviceLabel',
      'setLostStolen',
      'findDevice',
      'deleteHandheld',
      'markDeviceQuarantine',
      'deleteRepairDevice',
      'updateOrgGroup',
      'setStolen',
    ],
    availableHeathItems: ['batteryHealth'], //'utilizationHealth'],
  },
  'Payment Kiosk': {
    device_classification: 'tablet',
    platform: 'android',
    itemIcon: Kiosk,
    itemText: 'Payment Kiosks',
    locationType: ['STR', 'STR-V', 'OFFICE'],
    itemFilter: 'filterPaymentKiosk',
    availableActions: [
      'editDeviceLabel',
      'setLostStolen',
      'findDevice',
      'deleteHandheld',
      'markDeviceQuarantine',
      'deleteRepairDevice',
      'updateOrgGroup',
      'setStolen',
    ],
    availableHeathItems: ['batteryHealth'], //'utilizationHealth'],
  },
  'Service Kiosk': {
    device_classification: 'tablet',
    platform: 'android',
    itemIcon: Kiosk,
    itemText: 'Service Kiosks',
    locationType: ['STR', 'STR-V', 'OFFICE'],
    itemFilter: 'filterServiceKiosk',
    availableActions: [
      'editDeviceLabel',
      'setLostStolen',
      'findDevice',
      'deleteHandheld',
      'markDeviceQuarantine',
      'deleteRepairDevice',
      'updateOrgGroup',
      'setStolen',
      'tepOpacs',
    ],
    availableHeathItems: ['batteryHealth'], //'utilizationHealth'
  },
  'Gift Registry': {
    device_classification: 'tablet',
    platform: 'android',
    itemIcon: ApplePhone,
    itemText: 'Gift Registry',
    locationType: ['STR', 'STR-V', 'OFFICE'],
    itemFilter: 'filterGiftRegistry',
    availableActions: [
      'editDeviceLabel',
      'setLostStolen',
      // 'findDevice',
      'deleteHandheld',
      'markDeviceQuarantine',
      'deleteRepairDevice',
      'updateOrgGroup',
      'setStolen',
    ],
    availableHeathItems: ['batteryHealth'], //'utilizationHealth'],
  },
  'Gift Registry Scanner': {
    device_classification: 'handheld',
    platform: 'android',
    itemIcon: null,
    itemText: 'Gift Registry Scanner',
    locationType: ['STR', 'STR-V', 'OFFICE'],
    itemFilter: 'filterGiftRegistryScanner',
    availableActions: [
      'editDeviceLabel',
      'setLostStolen',
      'findDevice',
      'deleteHandheld',
      'markDeviceQuarantine',
      'deleteRepairDevice',
      'markOfflineHandheldDevice',
      'updateOrgGroup',
      'setStolen',
      'tepOpacs',
    ],
    availableHeathItems: ['batteryHealth'],
  },
  'Gift Registry Kiosk': {
    device_classification: 'tablet',
    platform: 'android',
    itemIcon: null,
    itemText: 'Gift Registry Tablet',
    locationType: ['STR', 'STR-V', 'OFFICE'],
    itemFilter: 'filterGiftRegistryTablet',
    availableActions: [
      'editDeviceLabel',
      'setLostStolen',
      'findDevice',
      'deleteHandheld',
      'markDeviceQuarantine',
      'deleteRepairDevice',
      'updateOrgGroup',
      'setStolen',
    ],
    availableHeathItems: ['batteryHealth'],
  },
  PML: {
    device_classification: 'tablet',
    platform: 'android',
    itemIcon: ApplePhone,
    itemText: 'PMLs',
    locationType: ['STR', 'STR-V', 'OFFICE'],
    itemFilter: 'filterPML',
    availableActions: [
      'editDeviceLabel',
      'setLostStolen',
      'findDevice',
      'deleteHandheld',
      'markDeviceQuarantine',
      'deleteRepairDevice',
      'updateOrgGroup',
      'markOfflineHandheldDevice',
      'setStolen',
    ],
    availableHeathItems: ['batteryHealth'], //'utilizationHealth'],
  },
  Timeclock: {
    device_classification: 'tablet',
    platform: 'android',
    itemIcon: Tablet,
    itemText: 'Timeclock',
    locationType: [
      'STR',
      'STR-V',
      'OFFICE',
      'OSW',
      'DC',
      'DC-V',
      'RGD',
      'OFFICE',
    ],
    itemFilter: 'filterTimeclock',
    availableActions: [
      'editDeviceLabel',
      'setLostStolen',
      'findDevice',
      'deleteHandheld',
      'markDeviceQuarantine',
      'deleteRepairDevice',
      'updateOrgGroup',
      'setStolen',
      'tepOpacs',
    ],
    availableHeathItems: ['batteryHealth'], //'utilizationHealth'],
  },
  'Visual Merchandiser': {
    device_classification: 'tablet',
    platform: 'android',
    itemIcon: Tablet,
    itemText: 'Visual Merchandiser',
    locationType: ['STR', 'STR-V', 'OFFICE'],
    itemFilter: 'filterVisualMerchandiser',
    availableActions: [
      'editDeviceLabel',
      'setLostStolen',
      'findDevice',
      'deleteHandheld',
      'markDeviceQuarantine',
      'deleteRepairDevice',
      'updateOrgGroup',
      'setStolen',
    ],
    availableHeathItems: ['batteryHealth'], //'utilizationHealth'],
  },
  'Direct Store Delivery Kiosk': {
    device_classification: 'tablet',
    platform: 'android',
    itemIcon: Tablet,
    itemText: 'Direct Store Delivery Kiosk',
    locationType: ['STR', 'STR-V', 'OFFICE'],
    itemFilter: 'filterDirectStoreDeliveryKiosk',
    availableActions: [
      'editDeviceLabel',
      'setLostStolen',
      'findDevice',
      'deleteHandheld',
      'markDeviceQuarantine',
      'deleteRepairDevice',
      'updateOrgGroup',
      'setStolen',
      'tepOpacs',
    ],
    availableHeathItems: ['batteryHealth'],
  },
  // all AW devices should get 'updateOrgGroup' availableActions,
  Scangun: {
    device_classification: 'handheld',
    platform: 'android',
    itemIcon: myDevice,
    itemText: 'Scangun',
    locationType: ['DC', 'DC-V', 'RGD', 'OFFICE'],
    itemFilter: 'filterScangun',
    availableActions: ['editDeviceLabel', 'findDevice', 'updateOrgGroup'],
    availableHeathItems: ['batteryHealth'], // 'utilizationHealth'],
  },
  'DCHR Tablet': {
    //to be moved to tablet post backend changes
    device_classification: 'tablet',
    platform: 'android',
    itemIcon: Tablet,
    itemText: 'DCHR Tablet',
    locationType: ['DC', 'DC-V', 'RGD', 'OFFICE'],
    itemFilter: 'filterDCHRTablet',
    availableActions: ['editDeviceLabel', 'findDevice', 'updateOrgGroup'],
    availableHeathItems: ['batteryHealth'], // 'utilizationHealth'],
  },
  'AP Tablet': {
    device_classification: 'tablet',
    platform: 'android',
    itemIcon: Tablet,
    itemText: 'AP Tablet',
    locationType: ['DC', 'DC-V', 'RGD', 'OFFICE'],
    itemFilter: 'filterAPTablet',
    availableActions: ['editDeviceLabel', 'findDevice', 'updateOrgGroup'],
    availableHeathItems: ['batteryHealth'], // 'utilizationHealth'],
  },
  'ICQA Tablet': {
    device_classification: 'tablet',
    platform: 'android',
    itemIcon: Tablet,
    itemText: 'ICQA Tablet',
    locationType: ['DC', 'DC-V', 'RGD', 'OFFICE'],
    itemFilter: 'filterICQATablet',
    availableActions: ['editDeviceLabel', 'findDevice', 'updateOrgGroup'],
    availableHeathItems: ['batteryHealth'], // 'utilizationHealth'],
  },
  'Yard Truck Tablet': {
    device_classification: 'tablet',
    platform: 'android',
    itemIcon: Tablet,
    itemText: 'Yard Truck Tablet',
    locationType: ['DC', 'DC-V', 'RGD', 'OFFICE'],
    itemFilter: 'filterYardTruckTablet',
    availableActions: ['editDeviceLabel', 'findDevice', 'updateOrgGroup'],
    availableHeathItems: ['batteryHealth'], // 'utilizationHealth'],
  },
  'FourKites Tablet': {
    device_classification: 'tablet',
    platform: 'android',
    itemIcon: Tablet,
    itemText: 'FourKites Tablet',
    locationType: ['DC', 'DC-V', 'RGD', 'OFFICE'],
    itemFilter: 'filterDCFKTablet',
    availableActions: ['editDeviceLabel', 'findDevice', 'updateOrgGroup'],
    availableHeathItems: ['batteryHealth'], // 'utilizationHealth'],
  },
  'OLE Tablet': {
    device_classification: 'tablet',
    platform: 'android',
    itemIcon: Tablet,
    itemText: 'OLE Tablet',
    locationType: ['DC', 'DC-V', 'RGD', 'OFFICE'],
    itemFilter: 'filterDCOLETablet',
    availableActions: ['editDeviceLabel', 'findDevice', 'updateOrgGroup'],
    availableHeathItems: ['batteryHealth'], // 'utilizationHealth'],
  },
  Gladiator: {
    device_classification: 'tablet',
    platform: 'android',
    itemIcon: Tablet,
    itemText: 'Gladiator',
    locationType: ['DC', 'DC-V', 'RGD', 'OFFICE'],
    itemFilter: 'filterADWTablet',
    availableActions: ['editDeviceLabel', 'findDevice', 'updateOrgGroup'],
    availableHeathItems: ['batteryHealth'], // 'utilizationHealth'],
  },
  'Sample and Props': {
    device_classification: 'handheld',
    platform: 'android',
    itemIcon: null,
    itemText: 'Sample and Props',
    locationType: ['OFFICE'],
    itemFilter: null,
    availableActions: ['editDeviceLabel', 'findDevice', 'updateOrgGroup'],
    availableHeathItems: ['batteryHealth'], // 'utilizationHealth'],
  },
  'HQ myDevice': {
    device_classification: 'handheld',
    platform: 'android',
    itemIcon: null,
    itemText: 'HQ myDevice',
    locationType: ['OFFICE'],
    itemFilter: null,
    availableActions: ['editDeviceLabel', 'findDevice', 'updateOrgGroup'],
    availableHeathItems: ['batteryHealth'], // 'utilizationHealth'],
  },
  'Floor Tablet': {
    device_classification: 'tablet',
    platform: 'android',
    itemIcon: null,
    itemText: 'Floor Tablet',
    locationType: ['OFFICE'],
    itemFilter: null,
    availableActions: ['editDeviceLabel', 'findDevice', 'updateOrgGroup'],
    availableHeathItems: ['batteryHealth'], // 'utilizationHealth'],
  },
  null: {
    device_classification: 'handheld,tablet',
    platform: 'android',
    itemIcon: null,
    itemText: 'PMLs',
    locationType: ['STR', 'STR-V', 'OFFICE', 'OSW'],
    itemFilter: 'filterPML',
    availableActions: [
      'editDeviceLabel',
      'setLostStolen',
      'findDevice',
      'markDeviceQuarantine',
      'deleteRepairDevice',
      'markOfflineHandheldDevice',
      'updateOrgGroup',
      'setStolen',
    ],
    availableHeathItems: [],
  },
  Unknown: {
    device_classification: 'handheld,tablet',
    platform: 'android',
    itemIcon: null,
    itemText: 'PMLs',
    locationType: ['STR', 'STR-V', 'OFFICE', 'OSW'],
    itemFilter: 'filterPML',
    availableActions: [
      'editDeviceLabel',
      'setLostStolen',
      'findDevice',
      'markDeviceQuarantine',
      'deleteRepairDevice',
      'markOfflineHandheldDevice',
      'updateOrgGroup',
      'setStolen',
    ],
    availableHeathItems: [],
  },
  // 'MAC OS X': {
  //   device_classification: 'computer',
  //   platform: 'computer',
  //   itemIcon: mac,
  //   itemText: 'MacOSX',
  //   itemFilter: 'filterMacOSX',
  //   availableActions: [],
  //   availableHeathItems: [],
  // },
  // 'Microsoft Windows 10 Pro': {
  //   device_classification: 'computer',
  //   platform: 'computer',
  //   itemIcon: windows,
  //   itemText: 'MicrosoftWindows10Pro',
  //   itemFilter: 'filterMicrosoftWindows10Pro',
  //   availableActions: [],
  //   availableHeathItems: [],
  // },
  TEP: {
    device_classification: 'tep',
    platform: 'tep',
    itemIcon: myDevice,
    itemText: 'TepMyDevice',
    locationType: ['STR', 'STR-V', 'OFFICE', 'OSW'],
    itemFilter: 'filterTepmyDevice',
    availableActions: ['editDeviceLabel'],
    availableHeathItems: [
      'batteryHealth',
      'complianceHealth',
      'bluetoothCompliance',
    ],
  },
  Airwatch: {
    device_classification: 'aw',
    platform: 'AW',
    itemIcon: myDevice,
    itemText: 'MyDevice',
    locationType: ['STR', 'STR-V', 'OFFICE', 'OSW'],
    itemFilter: 'filtermyDevice',
    availableActions: [],
    availableHeathItems: [],
  },
}

export default handheldDeviceTypes
