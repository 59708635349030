import CICOBulkActions from '../MenuActionItems/CICOBulkActions'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import React from 'react'
import SettingsIcon from '@material-ui/icons/Settings'
import Spinner from 'nicollet-react/es/components/Spinner/Spinner'
import SvcCICOActions from '../../../services/svcCICOActions'
import Tooltip from '@material-ui/core/Tooltip'
import { withAnalytics } from '@praxis/component-analytics'
import { withEnv } from '@praxis/component-runtime-env'
import { withStyles } from '@material-ui/styles'

function MenuActionItem(props) {
  return (
    <MenuItem
      onClick={() => {
        props.onClose()
        props.onClick()
      }}
    >
      {props.label}
    </MenuItem>
  )
}
class BulkActions extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      anchorEl: null,
      showDeviceLostStolenDialog: false,
      menuDisabled: true,
      toDoAction: '',
      opacsCICO: [],
      locationID: '',
      bulkActionsLoading: false,
      enableCICO: true,
      checked: true,
      checkedAlarm: true,
      showCICOActionDialog: false,
      cicoEnabled: false,
      alarmCICOEnabled: false,
    }
    this.svcCICOActions = new SvcCICOActions()
  }
  handleClose = (event, reason) => {
    this.setState({ anchorEl: null })
  }

  handleClick = (event) => {
    const { locationID } = this.state
    this.setState({ bulkActionsLoading: true })
    this.svcCICOActions
      .getPolicyDetails('T' + locationID.padStart(4, '0'), this.props.env)
      .then((response) => {
        if (Math.round(response.status / 100) === 2) {
          this.setState({ opacsCICO: response.data })
        }
      })
      .catch((error) => {
        console.log('ERROR policy-details-CI-CO', error)
      })
      .finally(() => {
        const { opacsCICO } = this.state
        if (opacsCICO.length !== 0) {
          let deviceLockerEnabled = opacsCICO[0].required_parameters.filter(
            (e) => e.name === 'device_locker_enabled',
          )
          let deviceLockerRingEnabled = opacsCICO[0].required_parameters.filter(
            (e) => e.name === 'device_locker_ring_enabled',
          )

          if (deviceLockerEnabled[0].current_value !== null) {
            this.setState({
              cicoEnabled: deviceLockerEnabled[0].current_value,
            })
          } else {
            this.setState({ cicoEnabled: null })
          }

          if (deviceLockerRingEnabled[0].current_value !== null) {
            this.setState({
              alarmCICOEnabled: deviceLockerRingEnabled[0].current_value,
            })
          } else {
            this.setState({ alarmCICOEnabled: null })
          }
        }
        this.setState({ bulkActionsLoading: false })
      })
    this.setState({ anchorEl: event.currentTarget })
  }

  componentDidMount() {
    this.setState({ locationID: window.location.pathname.split('/')[2] })
  }
  handleMenu = () => {
    this.setState({ menuDisabled: false })
  }
  handleCICOActionClick = () => {
    this.setState({ showCICOActionDialog: true })
  }
  handleCICODialogClose = (event) => {
    this.setState({ showCICOActionDialog: false })
  }

  render() {
    const { classes } = this.props
    const {
      anchorEl,
      menuDisabled,
      bulkActionsLoading,
      opacsCICO,
      locationID,
      alarmCICOEnabled,
      cicoEnabled,
    } = this.state

    return (
      <>
        <Tooltip title={'Bulk Actions'}>
          <IconButton onClick={this.handleClick}>
            <SettingsIcon />
          </IconButton>
        </Tooltip>

        <Menu
          style={{ paddingTop: 0, paddingBottom: 0 }}
          disableAutoFocusItem
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {bulkActionsLoading ? (
            <Spinner size="small" className={classes.spinner} />
          ) : (
            <>
              {opacsCICO.length !== 0 &&
                cicoEnabled !== null &&
                opacsCICO[0].policyName === 'deviceLockerPolicy' &&
                (menuDisabled && this.handleMenu(),
                (
                  <>
                    <MenuActionItem
                      onClick={() => this.handleCICOActionClick()}
                      label={'Manage Checkout Status'}
                      onClose={() => {
                        this.handleClose()
                      }}
                    />
                  </>
                ))}
              {menuDisabled && (
                <div>
                  <MenuItem disabled>No Available Actions</MenuItem>
                </div>
              )}
            </>
          )}
        </Menu>

        {this.state.showCICOActionDialog && (
          <>
            <CICOBulkActions
              open={this.state.showCICOActionDialog}
              onClose={() => {
                this.handleCICODialogClose()
              }}
              {...this.props}
              dataCICO={opacsCICO[0]}
              locationID={locationID}
              cicoEnabled={cicoEnabled}
              alarmCICOEnabled={alarmCICOEnabled}
            />
          </>
        )}
      </>
    )
  }
}

const styles = {
  spinner: {
    marginLeft: 50,
    marginRight: 50,
    margin: 10,
  },
}

export default withStyles(styles, { withTheme: true })(
  withAnalytics()(withEnv()(BulkActions)),
)
