import React from 'react'
import axios from 'axios'
import queryString from 'query-string'

class SvcRFIDScanners extends React.Component {
  async getRFIDScannersId(config, submission) {
    const queryParameters = {
      rfidscanners: {
        location_id: null,
        mac_address: null,
        id: null,
      },
    }
    let queryParametersVar = queryParameters
    Object.entries(submission).forEach((param) => {
      if (queryParametersVar.rfidscanners.hasOwnProperty(param[0])) {
        queryParametersVar.rfidscanners[`${param[0]}`] = param[1]
      }
    })

    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey
    const endpointParams = queryString.stringify(
      queryParametersVar.rfidscanners,
      {
        sort: false,
        skipNull: true,
      },
    )
    const apiUri = encodeURI(
      apiUriBase + `/rfidhandhelds?` + endpointParams + `&key=` + apiKey,
    )
    return axios.get(apiUri)
  }

  getRFIDScanners(submission, config) {
    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey
    const apiUri = encodeURI(
      apiUriBase +
        `/rfidhandhelds?location_id=` +
        submission +
        `&key=` +
        apiKey,
    )
    return axios.get(apiUri)
  }

  getRFIDScannersByUniqueId(config, submission) {
    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey
    const apiUri = encodeURI(
      apiUriBase + `/rfidhandhelds?unique_id=` + submission + `&key=` + apiKey,
    )
    return axios.get(apiUri)
  }
}
export default SvcRFIDScanners
