import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { func, shape, string } from 'prop-types'

import CloseIcon from '@material-ui/icons/Close'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import MenuItem from '@material-ui/core/MenuItem'
import React from 'react'
import SearchIcon from '@material-ui/icons/Search'
import Snackbar from '@material-ui/core/Snackbar'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import { Typography } from '@material-ui/core'
import ValidateMacIpAddress from '../../utilities/ValidateMacIpAddress'
import ValidateStoreNumber from '../../utilities/ValidateStoreNumber'
import { fade } from '@material-ui/core/styles/colorManipulator'
import queryString from 'query-string'
import { withAnalytics } from '@praxis/component-analytics'
import { withEnv } from '@praxis/component-runtime-env'
import withRouter from '../withRouter'
import { withStyles } from '@material-ui/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'

class SearchBar extends React.Component {
  constructor(props) {
    super(props)
    this.validateStoreNumber = new ValidateStoreNumber()
    this.state = {
      store: '',
      snackBarOpen: false,
      snackBarMessageInfo: 'Entered search is invalid',
      dashboardRedirect: false,
      label: 'Enter a Location # (Store, HQ, DC)',
      dropDownLabel: 'Location',
      selected: 'location_id',
      searchText: '',
      platform: 'android',
      error: false,
      errorLabel: null,
      optionOpen: false,
      searchOpen: false,
    }
    this.submission = {}
    this.validateMacIpAddress = new ValidateMacIpAddress()
  }

  static propTypes = {
    headerTitle: string,
    layoutActions: shape({
      openSideNav: func,
      closeSideNav: func,
    }),
  }

  static defaultProps = {
    headerTitle: undefined,
    layoutActions: {},
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    })
    if (this.state.selected === 'mac_address') {
      this.setState({
        error: this.validateMacIpAddress.validateMacAddress(event.target.value),
        errorLabel:
          this.props.env.devicesSearchItems[this.state.selected].error_label,
      })
    }
    if (this.state.selected === 'ip_address') {
      this.setState({
        error: this.validateMacIpAddress.validateIpAddress(event.target.value),
        errorLabel:
          this.props.env.devicesSearchItems[this.state.selected].error_label,
      })
    }
  }

  handleClick = (event) => {
    this.setState({
      label: event.target.value.search_label,
      selected: event.target.value.search_param,
      dropDownLabel: event.target.value.chip_label,
      error: false,
      searchText: '',
    })
  }
  handleIncoming = (event, searchText) => {
    this.setState({
      label: event.search_label,
      selected: event.search_param,
      dropDownLabel: event.chip_label,
      searchText: searchText[0],
    })
  }

  handleIncomingLocation = (searchText) => {
    this.setState({
      searchText: searchText,
      dropDownLabel: 'Location',
    })
  }

  handleIncomingDevice = (event, searchText) => {
    this.setState({
      label: event.search_label,
      selected: event.search_param,
      dropDownLabel: event.chip_label,
      searchText: searchText,
    })
  }

  redirect = () => {
    var searchText = this.state.searchText
    if (this.state.selected === 'mac_address' || this.state.selected === 'id') {
      if (!searchText.startsWith('VMware') && searchText.match(/:|-/g)) {
        searchText = this.state.searchText.replace(/:|-/g, '')
      }
    }
    if (this.state.selected === 'applications') {
      if (searchText === 'Windows') {
        return (window.location =
          window.location.origin +
          `/applications_computers?${this.state.selected}=${searchText}`)
      }
      if (searchText === 'Mac') {
        return (window.location =
          window.location.origin +
          `/applications_mac?${this.state.selected}=${searchText}`)
      }
      return (window.location =
        window.location.origin +
        `/applications?${this.state.selected}=${searchText}`)
    } else if (this.state.selected !== 'logged_in_user') {
      return (window.location =
        window.location.origin +
        `/devices?${this.state.selected}=${searchText}`)
    } else {
      return (window.location =
        window.location.origin +
        `/search_results?${this.state.selected}=${searchText}`)
    }
  }

  handleSubmit(event) {
    const eventData = {
      customInteraction: {
        key: 'Searchbar',
        value: this.state.selected,
      },
    }
    this.props.trackEvent(eventData)
    this.state.selected === 'location_id'
      ? this.validateStoreNumber.validateStoreNumber(this.state.searchText) !==
        ''
        ? (window.location =
            window.location.origin +
            '/location/' +
            this.validateStoreNumber.validateStoreNumber(
              this.state.searchText,
            ) +
            '?platform=' +
            this.state.platform)
        : this.setState({ snackBarOpen: true })
      : this.redirect()
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    this.setState({ snackBarOpen: false })
  }

  handleOptionOpen(openValue) {
    if (openValue) {
      this.setState({ optionOpen: false })
    } else {
      this.setState({ optionOpen: true })
    }
  }
  handleOptionClose = () => {
    this.setState({ optionOpen: false })
  }

  handleSearchOpen(openValue) {
    if (openValue) {
      this.setState({ searchOpen: false })
    } else {
      this.setState({ searchOpen: true })
    }
  }
  handleSearchClose = () => {
    this.setState({ searchOpen: false })
  }

  selectOnOff = () => {
    // turns select on if object contains a search_suggestions array and is selected
    const res = Object.entries(this.props.env.devicesSearchItems).filter(
      (f) =>
        f[0] === this.state.selected &&
        f[1].hasOwnProperty('search_suggestions') &&
        f[1].search_suggestions.length > 0,
    )
    return res.length > 0
  }

  componentDidMount() {
    this.submission = queryString.parse(this.props.router.location.search, {
      sort: false,
    })
    let location = window.location.pathname.split('/')[2]
    let ua = window.navigator.userAgent
    let isIE = /MSIE|Trident/.test(ua)

    const searchParam = new URLSearchParams(this.props.router.location.search)
    const isAdvancedSearch = searchParam.get('advanced') === 'true'

    if (isIE) {
      // need to remove
      window.location = window.location.origin + '/chrome'
    }
    if (window.location.search === '?store_not_found') {
      this.setState({
        snackBarOpen: true,
        snackBarMessageInfo: 'Entered store number is invalid',
      })
    }
    if (window.location.search === '?internal_server_error') {
      this.setState({
        snackBarOpen: true,
        snackBarMessageInfo: 'Internal Server Error',
      })
    }
    if (this.submission.no_results) {
      this.setState({
        snackBarOpen: true,
        snackBarMessageInfo: 'Search Returned No Results',
      })
    }
    if (!isAdvancedSearch) {
      Object.entries(this.props.env.devicesSearchItems).forEach((row) => {
        this.submission &&
          Object.keys(this.submission).length === 1 &&
          Object.keys(this.submission).includes(row[1].search_param) &&
          this.handleIncoming(row[1], Object.values(this.submission))
        window.location.pathname.split('/')[1] === 'location' &&
          this.handleIncomingLocation(location)
        Object.keys(this.submission).length >= 2 &&
          Object.keys(this.submission)[0].startsWith(row[1].search_param) &&
          this.handleIncomingDevice(row[1], Object.values(this.submission)[0])
      })
    }
  }

  render() {
    const { classes } = this.props
    const { snackBarMessageInfo } = this.state
    return (
      <>
        <MuiThemeProvider theme={this.getMuiTheme()}>
          <TextField
            className={classes.dropDown}
            select
            onChange={this.handleClick}
            margin="dense"
            variant="standard"
            color="primary"
            SelectProps={{
              value: this.state.dropDownLabel,
              disableUnderline: true,
              open: this.state.optionOpen,
              onClose: this.handleOptionClose,
              onOpen: () => this.handleOptionOpen(this.state.optionOpen),
              renderValue: (value) => value,
              SelectDisplayProps: {
                style: {
                  color: '#fff',
                  borderBottom: '2px solid white',
                },
              },
            }}
            label={
              <Typography variant="button" style={{ color: '#fff' }}>
                Please Select a Search Option
              </Typography>
            }
          >
            {Object.entries(this.props.env.devicesSearchItems).map(
              (row, index) => {
                return (
                  <MenuItem key={index} value={row[1]}>
                    {row[1].chip_label}
                  </MenuItem>
                )
              },
            )}
          </TextField>
          {this.selectOnOff() ? (
            <>
              <Autocomplete
                value={this.state.searchText || null}
                onChange={(event, value) =>
                  this.setState({ searchText: value })
                }
                className={classes.searchTextbar}
                renderInput={(params) => (
                  <>
                    <TextField
                      {...params}
                      label={
                        <Typography variant="button" style={{ color: '#fff' }}>
                          {this.state.label}
                        </Typography>
                      }
                      onChange={this.handleChange('searchText')}
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                        endAdornment: (
                          <InputAdornment position="end">
                            <Tooltip title="Search">
                              <IconButton
                                className={[classes.submitButton, classes.icon]}
                                variant="outlined"
                                disabled={this.state.error}
                                onClick={(event) => this.handleSubmit(event)}
                              >
                                <SearchIcon></SearchIcon>
                              </IconButton>
                            </Tooltip>
                          </InputAdornment>
                        ),
                      }}
                      onKeyPress={(ev) => {
                        if (ev.key === 'Enter') {
                          this.handleSubmit()
                          ev.preventDefault()
                        }
                      }}
                    />
                  </>
                )}
                options={
                  this.props.env.devicesSearchItems[this.state.selected]
                    .search_suggestions
                }
                filterOptions={(options) => {
                  if (options.length > 0 && this.state.searchText.length > 0) {
                    return options.filter((o) =>
                      o.includes(this.state.searchText),
                    )
                  }
                  return options
                }}
                margin="dense"
                variant="standard"
                color="primary"
                style={{
                  color: '#fff',
                  borderBottom: '2px solid white',
                }}
              />
            </>
          ) : (
            <TextField
              value={this.state.searchText}
              onChange={this.handleChange('searchText')}
              InputProps={{
                disableUnderline: true,
                endAdornment: this.state.error ? (
                  this.state.dropDownLabel === 'Mac Address' ? (
                    <InputAdornment position="end">
                      <Tooltip title="Enter a valid Mac Address">
                        <ErrorOutlineIcon style={{ color: '#fff' }} />
                      </Tooltip>
                    </InputAdornment>
                  ) : (
                    this.state.dropDownLabel === 'IP Address' && (
                      <InputAdornment position="end">
                        <Tooltip title="Enter a valid IP Address">
                          <ErrorOutlineIcon style={{ color: '#fff' }} />
                        </Tooltip>
                      </InputAdornment>
                    )
                  )
                ) : (
                  <InputAdornment position="end">
                    <Tooltip title="Search">
                      <IconButton
                        className={classes.submitButton}
                        variant="outlined"
                        disabled={this.state.error}
                        onClick={(event) => this.handleSubmit(event)}
                      >
                        <SearchIcon></SearchIcon>
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
              className={classes.searchTextbar}
              margin="dense"
              variant="standard"
              label={
                this.state.error ? (
                  <Typography variant="button" style={{ color: '#fff' }}>
                    {this.state.errorLabel}
                  </Typography>
                ) : (
                  <Typography variant="button" style={{ color: '#fff' }}>
                    {this.state.label}
                  </Typography>
                )
              }
              error={this.state.error}
              onKeyPress={(ev) => {
                if (ev.key === 'Enter') {
                  this.handleSubmit()
                  ev.preventDefault()
                }
              }}
            ></TextField>
          )}
        </MuiThemeProvider>
        <Snackbar
          open={this.state.snackBarOpen}
          autoHideDuration={3000}
          onClose={this.handleClose}
          message={snackBarMessageInfo}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleClose}
            >
              <CloseIcon className={classes.CloseIcon} />
            </IconButton>,
          ]}
        />
      </>
    )
  }
  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MuiSelect: {
          icon: {
            color: '#fff',
          },
          selectMenu: {
            color: '#fff',
          },
        },
        MuiInputBase: {
          input: {
            color: '#fff',
          },
        },
        MuiFormLabel: {
          root: {
            color: '#fff',
          },
        },
        MuiInputLabel: {
          root: {
            color: '#fff',
          },
        },
        MuiFormHelperText: {
          root: {
            color: '#fff',
          },
        },
        MuiList: {
          padding: {
            paddingTop: 55,
          },
        },
      },
    })
}

const styles = {
  icon: {
    position: 'absolute',
  },
  searchTextbar: {
    padddingRight: 25,
    minWidth: 370,
    width: 370,
    borderBottom: '2px solid white',
    marginRight: 25,
  },
  dropDown: {
    marginLeft: 20,
    minWidth: 250,
    width: 250,
    marginRight: 25,
  },
  submitButton: {
    color: '#fff',
    '&:hover, &.Mui-focusVisible': {
      backgroundColor: `${fade('#fff', 0.75)}`,
      color: '#666',
    },
  },
}

export default withStyles(styles)(
  withRouter(withAnalytics()(withEnv()(SearchBar))),
)
