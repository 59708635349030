class sort {
  label = (a, b, order) => {
    // we will group 'not set' together to not mix with human names by appending zzz
    // this makes sorting make sense if you want to see which devices have a label
    // also we pad compare to numerically sort
    var aData, bData
    var aPad = 0
    var bPad = 0
    const maxChar = 50
    if (a.data === null || a.data === '') {
      aData = 'zzzNot Set'
      aPad = 0
    } else {
      aData = a.data.toLowerCase()
      if (!/^[a-z]/i.test(aData.charAt(0))) {
        aPad = maxChar - aData.length
      } else {
        aPad = 0
      }
    }

    if (b.data === null || b.data === '') {
      bData = 'zzzNot Set'
      bPad = 0
    } else {
      bData = b.data.toLowerCase()
      if (!/^[a-z]/i.test(bData.charAt(0))) {
        bPad = maxChar - bData.length
      } else {
        bPad = 0
      }
    }

    if (
      a.data !== null &&
      b.data !== null &&
      a.data !== '' &&
      b.data !== '' &&
      !isNaN(a.data) &&
      !isNaN(b.data)
    ) {
      if (parseInt(a.data) >= parseInt(b.data)) {
        return 1 * (order === 'asc' ? 1 : -1)
      } else {
        return -1 * (order === 'asc' ? 1 : -1)
      }
    }

    return (
      '' +
      aData.padStart(aPad, '0').localeCompare(bData.padStart(bPad, '0')) *
        (order === 'asc' ? 1 : -1)
    )
  }
}

export default sort
