import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'

import Apps from '@material-ui/icons/Apps'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import React from 'react'
import SettingsIcon from '@material-ui/icons/Settings'
import Spinner from 'nicollet-react/es/components/Spinner/Spinner'
import SvcI10iDevices from '../../../services/svcI10iDevices'
import SvcRFIDScanners from '../../../services/svcRFIDScanners'
import Tab from '@material-ui/core/Tab'
import TableCell from '@material-ui/core/TableCell'
import TableDataAtsDetails from '../../Dashboard/TableData/TableDataI10i/TableDataAtsDetails'
import TableDataBMSDetails from '../../Dashboard/TableData/TableDataI10i/TableDataBMSDetails'
import TableDataBroadcastDetails from '../../Dashboard/TableData/TableDataI10i/TableDataBroadcastDetails'
import TableDataChassisDetails from '../../Dashboard/TableData/TableDataI10i/TableDataChassisDetails'
import TableDataDigitalMediaDetails from '../../Dashboard/TableData/TableDataI10i/TableDataDigitalMediaDetails'
import TableDataDoorAccessControlDetails from '../../Dashboard/TableData/TableDataI10i/TableDataDoorAccessControlDetails'
import TableDataGeneratorDetails from '../../Dashboard/TableData/TableDataI10i/TableDataGeneratorDetails'
import TableDataI10iCore from '../../Dashboard/TableData/TableDataI10i/TableDataI10iCore'
import TableDataNetworkAccessPointDetails from '../../Dashboard/TableData/TableDataI10i/TableDataNetworkAccessPointDetails'
import TableDataNetworkChassisDetails from '../../Dashboard/TableData/TableDataI10i/TableDataNetworkChassisDetails'
import TableDataNetworkGatewayDetails from '../../Dashboard/TableData/TableDataI10i/TableDataNetworkGatewayDetails'
import TableDataNetworkManagementDetails from '../../Dashboard/TableData/TableDataI10i/TableDataNetworkManagementDetails'
import TableDataNetworkRouterDetails from '../../Dashboard/TableData/TableDataI10i/TableDataNetworkRouterDetails'
import TableDataNetworkSwitchDetails from '../../Dashboard/TableData/TableDataI10i/TableDataNetworkSwitchDetails'
import TableDataNetworkTrafficManagementDetails from '../../Dashboard/TableData/TableDataI10i/TableDataNetworkTrafficManagementDetails'
import TableDataPduDetails from '../../Dashboard/TableData/TableDataI10i/TableDataPduDetails'
import TableDataPhoneDetails from '../../Dashboard/TableData/TableDataI10i/TableDataPhoneDetails'
import TableDataPrinterDetails from '../../Dashboard/TableData/TableDataI10i/TableDataPrinterDetails'
import TableDataRegisterDetails from '../../Dashboard/TableData/TableDataI10i/TableDataRegisterDetails'
import TableDataRfidHandheldDetails from '../../Dashboard/TableData/TableDataI10i/TableDataRfidHandheldDetails'
import TableDataScaleDetails from '../../Dashboard/TableData/TableDataI10i/TableDataScaleDetails'
import TableDataSubmeteringControlsDetails from '../../Dashboard/TableData/TableDataI10i/TableDataSubmeteringControlsDetails'
import TableDataUpsDetails from '../../Dashboard/TableData/TableDataI10i/TableDataUpsDetails'
import TableDataVoiceGatewayDetails from '../../Dashboard/TableData/TableDataI10i/TableDataVoiceGatewayDetails'
import TableRow from '@material-ui/core/TableRow'
import Tabs from '@material-ui/core/Tabs'
import { fade } from '@material-ui/core/styles/colorManipulator'
import { withEnv } from '@praxis/component-runtime-env'
import { withStyles } from '@material-ui/styles'

class DeviceExpansionI10iCore extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tabValue: 0,
      loading: false,
      data: [],
      operationalData: [],
    }
    this.svcI10iDevices = new SvcI10iDevices()
    this.svcRFIDScanners = new SvcRFIDScanners()
  }

  handleChange = (event, tabValue) => {
    this.setState({ tabValue })
  }

  async componentDidMount() {
    this.setState({ loading: true })

    const submission = {
      id: this.props.rowData[2],
    }

    await this.svcI10iDevices
      .getSingleDevice(this.props.env, submission.id)
      .then(async (response) => {
        this.setState({
          data: response.data,
        })
        if (response.data[0].specific_object_name === 'rfid_handheld') {
          const identification = {
            id: submission.id,
          }
          await this.svcRFIDScanners
            .getRFIDScannersId(this.props.env, identification)
            .then((response) => {
              this.setState({
                operationalData: response.data,
              })
            })
            .catch((error) => {
              console.log(error)
            })
        }
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        this.setState({ loading: false })
      })
  }

  render() {
    const colSpan = this.props.rowData.length + 1
    const { classes } = this.props
    const deviceType = this.props.deviceType
    return (
      <TableRow className={classes.honeyComb}>
        <TableCell colSpan={colSpan}>
          {this.state.loading ? (
            <Spinner layout="selfCentering" />
          ) : (
            <Card>
              <CardContent>
                {
                  <MuiThemeProvider theme={this.getMuiTheme()}>
                    <Tabs
                      value={this.state.tabValue}
                      onChange={this.handleChange}
                      variant="fullWidth"
                      className={classes.tabs}
                      TabIndicatorProps={{ className: classes.tabsProps }}
                    >
                      <Tab
                        icon={<SettingsIcon />}
                        label={`${deviceType} Details`}
                        className={classes.tab}
                      />
                      <Tab
                        icon={<Apps />}
                        label="General"
                        className={classes.tab}
                      />
                    </Tabs>
                  </MuiThemeProvider>
                }

                {this.state.tabValue === 0 && (
                  <>
                    {deviceType === 'Ups' ? (
                      <TableDataUpsDetails upsDetails={this.state.data[0]} />
                    ) : deviceType === 'Pdu' ? (
                      <TableDataPduDetails pduDetails={this.state.data[0]} />
                    ) : deviceType === 'Ats' ? (
                      <TableDataAtsDetails atsDetails={this.state.data[0]} />
                    ) : deviceType === 'Generator' ? (
                      <TableDataGeneratorDetails
                        generatorDetails={this.state.data[0]}
                      />
                    ) : deviceType === 'Register' ? (
                      <TableDataRegisterDetails
                        registerDetails={this.state.data[0]}
                      />
                    ) : deviceType === 'Digital Media Player' ? (
                      <TableDataDigitalMediaDetails
                        digitalMediaDetails={this.state.data[0]}
                      />
                    ) : deviceType === 'Broadcast' ? (
                      <TableDataBroadcastDetails
                        broadcastDetails={this.state.data[0]}
                      />
                    ) : deviceType === 'Door Access Control' ? (
                      <TableDataDoorAccessControlDetails
                        doorAccessControlDetails={this.state.data[0]}
                      />
                    ) : deviceType === 'Scale' ? (
                      <TableDataScaleDetails
                        scaleDetails={this.state.data[0]}
                      />
                    ) : deviceType === 'Rfid Handheld' ? (
                      <TableDataRfidHandheldDetails
                        rfidHandheldDetails={this.state.data[0]}
                        operationalData={this.state.operationalData}
                      />
                    ) : deviceType === 'Printer' ? (
                      <TableDataPrinterDetails
                        printerDetails={this.state.data[0]}
                      />
                    ) : deviceType === 'Baremetal Server' ? (
                      <TableDataBMSDetails bmsDetails={this.state.data[0]} />
                    ) : deviceType === 'Chassis' ? (
                      <TableDataChassisDetails
                        chassisDetails={this.state.data[0]}
                      />
                    ) : deviceType === 'Network Access Point' ? (
                      <TableDataNetworkAccessPointDetails
                        networkAccessPointDetails={this.state.data[0]}
                      />
                    ) : deviceType === 'Network Chassis' ? (
                      <TableDataNetworkChassisDetails
                        networkChassisDetails={this.state.data[0]}
                      />
                    ) : deviceType === 'Network Management' ? (
                      <TableDataNetworkManagementDetails
                        networkManagementDetails={this.state.data[0]}
                      />
                    ) : deviceType === 'Network Gateway' ? (
                      <TableDataNetworkGatewayDetails
                        networkGatewayDetails={this.state.data[0]}
                      />
                    ) : deviceType === 'Network Switch' ? (
                      <TableDataNetworkSwitchDetails
                        networkSwitchDetails={this.state.data[0]}
                      />
                    ) : deviceType === 'Network Traffic Management' ? (
                      <TableDataNetworkTrafficManagementDetails
                        networkTrafficManagementDetails={this.state.data[0]}
                      />
                    ) : deviceType === 'Network Router' ? (
                      <TableDataNetworkRouterDetails
                        networkRouterDetails={this.state.data[0]}
                      />
                    ) : deviceType === 'Voice Gateway' ? (
                      <TableDataVoiceGatewayDetails
                        voiceGatewayDetails={this.state.data[0]}
                      />
                    ) : deviceType === 'Submetering Controls' ? (
                      <TableDataSubmeteringControlsDetails
                        submeteringControlsDetails={this.state.data[0]}
                      />
                    ) : deviceType === 'Phone' ? (
                      <TableDataPhoneDetails
                        phoneDetails={this.state.data[0]}
                      />
                    ) : (
                      <></>
                    )}
                  </>
                )}

                {this.state.tabValue === 1 && (
                  <>
                    <TableDataI10iCore i10iDetails={this.state.data[0]} />
                  </>
                )}
              </CardContent>
            </Card>
          )}
        </TableCell>
      </TableRow>
    )
  }
  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MuiTab: {
          root: { '&$selected': { backgroundColor: '#fff' } },
        },
        MUIDataTableBodyCell: {
          root: {
            backgroundColor: '#eeeeee',
            height: 10,
          },
        },
        MUIDataTableHeadRow: {
          root: {
            height: 10,
          },
        },
        MUIDataTableBodyRow: {
          root: {
            backgroundColor: '#eeeeee',
            height: 10,
          },
        },
        MUIDataTableToolbar: {
          root: {
            backgroundColor: '#eeeeee',
            height: 10,
          },
          titleText: {
            fontSize: 14,
          },
          left: {
            flex: '100 0 auto',
          },
        },
        MuiPaper: {
          root: {
            minWidth: '400',
            paddingLeft: '0',
          },
        },
      },
    })
}

const styles = (theme) => ({
  honeyComb: {
    backgroundImage:
      `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='24.5' ` +
      `viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%23888888' fill-opacity='0.50' ` +
      `fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 ` +
      `11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 ` +
      `0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
  },
  tab: {
    borderRight: '1px solid #666666',
    borderLeft: '1px solid #666666',
    borderTop: '1px solid #666666',
    borderBottom: '1px solid #666666',
    borderRadius: '5px 5px 0px 0px',
  },
  tabs: {
    backgroundColor: '#999999',
  },
  tabsProps: {
    backgroundColor: `${fade('#fff', 0.1)}`,
    borderBottom: '1px solid white',
  },
})

export default withStyles(styles, { withTheme: true })(
  withEnv()(DeviceExpansionI10iCore),
)
