import BulkActions from '../../Dashboard/BulkActions/BulkActions'
import TableDataHandheld from '../TableData/TableDataHandheld'

const chipLabelSearchItems = {
  classification_name: 'Device Classification',
  'products.name': 'Application Name',
  'products.version_name': 'Application Version',
  device_state: 'State',
  model_name: 'Model',
  department: 'Device Type',
  management_system: 'Management System',
  operating_system_name: 'Operating System',
  location_id: 'Location',
  region_id: 'Region',
  group_id: 'Group',
  district_id: 'District',
  id: 'ID',
  mac_address: 'Mac Address',
  manufacturer_name: 'Manufacturer',
  serial_number: 'Serial Number',
  ip_address: 'IP Address',
  status: 'Status',
  device_event_state: 'Check-Out Status',
  unique_id: 'Unique ID',
  tcin: 'TCIN',
  device_type: 'Device Type',
  location_type: 'Location Type',
  direct_boot_mode: 'Direct Boot Mode',
  e355_pinpad_information: 'mPTC Disconnected',
}

export const handheldsTableOptions = (
  results,
  dataFiddle,
  platform,
  locationFullDetails,
  onFilterChange,
) => {
  if (platform === 'android') {
    return {
      pagination: true,
      expandableRows: true,
      elevation: 0,
      viewColumns: true,
      selectableRows: 'none',
      expandableRowsHeader: false,
      filterType: 'multiselect',
      rowsPerPage: 50,
      rowsPerPageOptions: [10, 25, 50, 100, 250, 2000],
      downloadOptions: {
        filename: 'tableDownload.csv',
        separator: ',',
        filterOptions: { useDisplayedRowsOnly: true },
      },
      setFilterChipProps: (colIndex, colName, data) => {
        return {
          label: `${chipLabelSearchItems[colName]}: ${data}`,
        }
      },
      renderExpandableRow: (rowData, rowMeta) => {
        return (
          <TableDataHandheld
            rowData={rowData}
            rowMeta={rowMeta}
            results={results}
            locationFullDetails={locationFullDetails}
          />
        )
      },
      customToolbar: (tableMeta) => {
        return (
          <>
            <BulkActions tableMeta={tableMeta} platform={platform} />
          </>
        )
      },
      onFilterChange: (changedColumn, filterList, changedColumnIndex) => {
        onFilterChange(changedColumn, filterList[changedColumnIndex])
        changedColumn === 'department' && dataFiddle(filterList)
      },
    }
  }
}

export default handheldsTableOptions
