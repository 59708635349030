import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Divider from '@material-ui/core/Divider'
import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { Typography } from '@material-ui/core'
import moment from 'moment'
import { withStyles } from '@material-ui/styles'

const styles = (theme) => ({
  root: {
    backgroundColor: '#eeeeee',
    padding: 'dense',
    fontSize: '40pt',
  },
  cell: {
    padding: 0,
  },
  honeyComb: {
    minHeight: `calc(100vh - 75px)`,
    backgroundImage:
      `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='24.5' ` +
      `viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%23888888' fill-opacity='0.50' ` +
      `fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 ` +
      `11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 ` +
      `0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
  },
})

class TableDataPrinter extends React.Component {
  render() {
    const colSpan = this.props.rowData.length + 1
    const { classes, storeReportResults, rowMeta } = this.props
    return (
      <TableRow className={classes.honeyComb}>
        <TableCell colSpan={colSpan}>
          <Card raised>
            <CardContent>
              {typeof storeReportResults[rowMeta.dataIndex] !== 'undefined' ? (
                <div>
                  <Typography variant="h6" id="tableTitle">
                    General
                  </Typography>
                  <Table className={classes.root}>
                    <TableHead>
                      <TableRow className={classes.cell}>
                        <TableCell className={classes.cell}>
                          Firmware Version
                        </TableCell>
                        <TableCell className={classes.cell}>
                          Mac Address
                        </TableCell>
                        <TableCell className={classes.cell}>
                          Unique ID
                        </TableCell>
                        <TableCell className={classes.cell}>TCIN</TableCell>
                        <TableCell className={classes.cell}>
                          Label X Move
                        </TableCell>
                        <TableCell className={classes.cell}>
                          Label Y Move
                        </TableCell>
                        <TableCell className={classes.cell}>
                          Media Sense Mode
                        </TableCell>
                        <TableCell className={classes.cell}>
                          Media TOF
                        </TableCell>
                        <TableCell className={classes.cell}>
                          Media Type
                        </TableCell>
                        <TableCell className={classes.cell}>
                          ZPL Timeout
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow className={classes.cell}>
                        <TableCell className={classes.cell}>
                          {storeReportResults[rowMeta.dataIndex].firmware &&
                            storeReportResults[rowMeta.dataIndex].firmware}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {storeReportResults[rowMeta.dataIndex].mac_address &&
                            storeReportResults[rowMeta.dataIndex].mac_address}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {storeReportResults[rowMeta.dataIndex].unique_id &&
                            storeReportResults[rowMeta.dataIndex].unique_id}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {storeReportResults[rowMeta.dataIndex].tcin &&
                            storeReportResults[rowMeta.dataIndex].tcin}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {storeReportResults[rowMeta.dataIndex].label_x_move &&
                            storeReportResults[rowMeta.dataIndex].label_x_move}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {storeReportResults[rowMeta.dataIndex].label_y_move &&
                            storeReportResults[rowMeta.dataIndex].label_y_move}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {storeReportResults[rowMeta.dataIndex]
                            .media_sense_mode &&
                            storeReportResults[rowMeta.dataIndex]
                              .media_sense_mode}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {storeReportResults[rowMeta.dataIndex].media_tof &&
                            storeReportResults[rowMeta.dataIndex].media_tof}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {storeReportResults[rowMeta.dataIndex].media_type &&
                            storeReportResults[rowMeta.dataIndex].media_type}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {storeReportResults[rowMeta.dataIndex]
                            .zpl_data_timeout_in_seconds &&
                            storeReportResults[rowMeta.dataIndex]
                              .zpl_data_timeout_in_seconds}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  <Divider style={{ backgroundColor: 'black' }} />
                  <div>
                    <Typography variant="h6" id="tableTitle">
                      Battery
                    </Typography>
                    <Table className={classes.root}>
                      <TableHead>
                        <TableRow className={classes.cell}>
                          <TableCell className={classes.cell}>
                            Power Health
                          </TableCell>
                          <TableCell className={classes.cell}>
                            Power Percent
                          </TableCell>
                          <TableCell className={classes.cell}>
                            Power Serial Number
                          </TableCell>
                          <TableCell className={classes.cell}>
                            Power Cycle Count
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow className={classes.cell}>
                          <TableCell className={classes.cell}>
                            {storeReportResults[rowMeta.dataIndex]
                              .power_health &&
                              storeReportResults[rowMeta.dataIndex]
                                .power_health}
                          </TableCell>
                          <TableCell className={classes.cell}>
                            {storeReportResults[rowMeta.dataIndex]
                              .power_percent_full &&
                              storeReportResults[rowMeta.dataIndex]
                                .power_percent_full}
                          </TableCell>
                          <TableCell className={classes.cell}>
                            {storeReportResults[rowMeta.dataIndex]
                              .power_serial_number &&
                              storeReportResults[rowMeta.dataIndex]
                                .power_serial_number}
                          </TableCell>
                          <TableCell className={classes.cell}>
                            {storeReportResults[rowMeta.dataIndex]
                              .power_cycle_count &&
                              storeReportResults[rowMeta.dataIndex]
                                .power_cycle_count}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                  <Divider style={{ backgroundColor: 'black' }} />
                  {storeReportResults[rowMeta.dataIndex].device_state ===
                    'Lost' && (
                    <div>
                      <Typography variant="h6" id="tableTitle">
                        Lost Event Details
                      </Typography>
                      <Table className={classes.root}>
                        <TableHead>
                          <TableRow className={classes.cell}>
                            <TableCell className={classes.cell}>
                              Lost Event User
                            </TableCell>
                            <TableCell className={classes.cell}>
                              Lost Event Timestamp
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow className={classes.cell}>
                            <TableCell className={classes.cell}>
                              {storeReportResults[rowMeta.dataIndex]
                                .lost_event_user &&
                                storeReportResults[rowMeta.dataIndex]
                                  .lost_event_user}
                            </TableCell>
                            <TableCell className={classes.cell}>
                              {moment(
                                storeReportResults[rowMeta.dataIndex]
                                  .lost_event_timestamp,
                              ).format() !==
                              moment('0000-00-00 00:00:00.000').format()
                                ? moment(
                                    storeReportResults[rowMeta.dataIndex]
                                      .lost_event_timestamp,
                                  ).format() <
                                  moment().subtract(24, 'hours').format()
                                  ? moment(
                                      moment(
                                        storeReportResults[rowMeta.dataIndex]
                                          .lost_event_timestamp,
                                      ).format(),
                                    ).format('llll')
                                  : moment
                                      .utc(
                                        storeReportResults[rowMeta.dataIndex]
                                          .lost_event_timestamp,
                                      )
                                      .fromNow()
                                : 'No Existing Record'}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </div>
                  )}
                </div>
              ) : (
                <div>
                  Note, additional details are not available for this device.{' '}
                </div>
              )}
            </CardContent>
          </Card>
        </TableCell>
      </TableRow>
    )
  }
}

export default withStyles(styles)(TableDataPrinter)
