import React from 'react'
import axios from 'axios'
import queryString from 'query-string'

class SvcBTScanners extends React.Component {
  getBTScannersId(config, submission) {
    const queryParameters = {
      bluetoothscanners: {
        location_id: null,
        mac_address: null,
        id: null,
      },
    }
    let queryParametersVar = queryParameters
    Object.entries(submission).forEach((param) => {
      if (queryParametersVar.bluetoothscanners.hasOwnProperty(param[0])) {
        queryParametersVar.bluetoothscanners[`${param[0]}`] = param[1]
      }
    })

    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey
    const endpointParams = queryString.stringify(
      queryParametersVar.bluetoothscanners,
      {
        sort: false,
        skipNull: true,
      },
    )
    const apiUri = encodeURI(
      apiUriBase + `/bluetoothscanners?` + endpointParams + `&key=` + apiKey,
    )
    return axios.get(apiUri)
  }

  getBTScanners(submission, config) {
    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey
    const apiUri = encodeURI(
      apiUriBase +
        `/bluetoothscanners?location_id=` +
        submission +
        `&key=` +
        apiKey,
    )
    return axios.get(apiUri)
  }

  postBTScannersLostStolenMoves(id, config) {
    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey
    const apiUri = encodeURI(
      `${apiUriBase}/bluetoothscanners/${id}/lost_stolen_moves?key=${apiKey}`,
    )
    return axios.post(apiUri)
  }

  putBTScannersLabel(id, deviceLabel, config) {
    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey
    const apiUri = encodeURI(
      apiUriBase + `/bluetoothscanners/` + id + `/labels?key=` + apiKey,
    )
    return axios.put(apiUri, {
      label: deviceLabel,
    })
  }

  deleteBTScanners(id, config) {
    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey
    const apiUri = encodeURI(
      apiUriBase + `/bluetoothscanners/` + id + `?key=` + apiKey,
    )
    return axios.delete(apiUri)
  }
}
export default SvcBTScanners
