import Badge from '@material-ui/core/Badge'
import Good from '@material-ui/icons/CheckCircleTwoTone'
import ListItem from '@material-ui/core/ListItem'
import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import WarningIcon from '@material-ui/icons/Warning'
import { withStyles } from '@material-ui/styles'

class DeviceHealthI10i extends React.Component {
  render() {
    const { classes } = this.props
    return (
      <ListItem className={classes.listItem}>
        <Tooltip title={'Hostname has been validated'}>
          <Badge
            badgeContent={<Good className={classes.good} />}
            className={classes.badge}
          >
            <WarningIcon className={classes.icon} />
          </Badge>
        </Tooltip>
      </ListItem>
    )
  }
}

const styles = (theme) => ({
  good: {
    fontSize: 16,
    color: 'green',
  },
  hostnameNull: {
    fontSize: 16,
    color: 'grey',
  },
  bad: {
    fontSize: 16,
    color: 'red',
  },
  badge: {
    margin: 4,
  },
  icon: {
    color: '#757575',
    fontSize: 16,
  },
  listItem: {
    paddingLeft: 0,
    paddingRight: 0,
  },
})

export default withStyles(styles, { withTheme: true })(DeviceHealthI10i)
