import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Paper,
  Snackbar,
  TableCell,
  TableFooter,
  TablePagination,
  TableRow,
} from '@material-ui/core'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { useEffect, useState } from 'react'

import CloseIcon from '@material-ui/icons/Close'
import Collapse from '@material-ui/core/Collapse'
import CustomDownloadAdvanced from '../Devices/CustomDownloadAdvanced/CustomDownload'
import DynamicPieChart from './DynamicPieChart'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import FlipCard from './FlipCard'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import MUIDataTable from 'mui-datatables'
import ShareToken from './ShareToken/ShareToken'
import { Spinner } from 'nicollet-react'
import SvcAdvancedDownload from '../../services/svcAdvancedDownload'
import SvcDevices from '../../services/svcDevices'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import Tooltip from '@material-ui/core/Tooltip'
import TopPanelMobileHelp from '../Dashboard/TopPanel/TopPanelHelp/TopPanelMobileHelp'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import { parseLinkHeader } from '@web3-storage/parse-link-header'
import { redirectHandler } from '../../utilities/RedirectHandler'
import { withAnalytics } from '@praxis/component-analytics'
import { withEnv } from '@praxis/component-runtime-env'
import withRouter from '../withRouter'
import { withStyles } from '@material-ui/styles'

const Search = (props) => {
  const { classes, auth } = props
  const [deviceList, setDeviceList] = useState(null)
  const [groupByDropdown, setGroupByDropdown] = useState([])
  const [aggs, setAggs] = useState({})
  const [query, setQuery] = useState(null)
  const [classificationName, setClassificationName] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [perPage, setPerPage] = useState(50)
  const [pageCount, setPageCount] = useState()
  const [count, setCount] = useState(null)
  const [totalCount, setTotalCount] = useState(0)
  const [params, setParams] = useState([])
  const [snackBar, setSnackBar] = useState({
    isOpen: false,
    message: '',
  })
  const [svc] = useState(new SvcDevices())
  const [svcDownload] = useState(new SvcAdvancedDownload())
  const [collapsedRow, setCollapsedRow] = useState(false)
  const [cards, setCards] = useState([])

  let excludeList = []

  useEffect(() => {
    // a) check for token in URL param
    //    true  -> svc call to v2
    //    false -> check for advanced_search in local storage
    // setState -> query
    // b) svc call to v2
    // setState -> deviceList
    setIsLoading(true)
    const searchParam = new URLSearchParams(props.router.location.search)
    const token = searchParam.get('token')

    if (token) {
      getQuery(token)
    } else {
      const query = localStorage.getItem('advanced_search')
      if (query !== null) {
        setQuery(JSON.parse(query))
      } else {
        setCount(0)
        setDeviceList([])
        setIsLoading(false)
      }
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    svc
      .getAdvancedSearchMapping(auth, classificationName)
      .then((response) => {
        const data = [...response.data]
        setGroupByDropdown(
          data
            .sort((a, b) => -b.field_name.localeCompare(a.field_name))
            .filter((suggestion) => suggestion.is_aggregatable === true)
            .filter((suggestion) => !cards.includes(suggestion.field_name))
            .map((suggestion) => suggestion.field_name),
        )
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {})
    // eslint-disable-next-line
  }, [cards])

  useEffect(() => {
    if (query !== null) {
      getAdvancedSearchData()
      let params = []
      Object.entries(query).forEach((row) => {
        if (row[1].field_name === 'classification_name') {
          setClassificationName(row[1].field_value)
        }
        params.push(
          row[1].field_name + ' ' + row[1].operator + ' ' + row[1].field_value,
        )
      })
      setParams(params)
    }
    // eslint-disable-next-line
  }, [query, page, perPage])

  const getQuery = (token) => {
    svcDownload
      .getDevicesByToken(auth, token)
      .then((res) => {
        const eventData = {
          customMetrics: {
            metric1: 'Query',
            metric3: res.status,
          },
          event: {
            type: `apiSuccessQuery-AdvancedSearch`,
          },
        }
        props.trackEvent(eventData)

        const response = [...res.data]
        setQuery(response)
        localStorage.setItem('advanced_search', JSON.stringify(response))
      })
      .catch((error) => {
        const eventData = {
          customMetrics: {
            metric1: 'Query',
            metric3: error.response.status,
          },
          event: {
            type: `apiErrorQuery-AdvancedSearch`,
          },
        }
        props.trackEvent(eventData)

        setCount(0)
        setDeviceList([])

        handleSnackbarOpen('Token is not found!')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleClose = (reason) => {
    if (reason === 'clickaway') {
      return
    }
    setSnackBar({ isOpen: false })
  }

  const handleCloseCard = (card) => {
    setCards(cards.filter((f) => f !== card))
  }

  const handleSnackbarOpen = (message) => {
    setSnackBar({ isOpen: true, message: message })
  }

  const getAdvancedSearchData = () => {
    svcDownload
      .getDevices(
        auth,
        query,
        Object.assign({
          page: page,
          per_page: perPage,
        }),
        null,
      )
      .then((res) => {
        const eventData = {
          customMetrics: {
            metric1: 'Devices',
            metric3: res.status,
          },
          event: {
            type: `apiSuccessDevices-AdvancedSearch`,
          },
        }
        props.trackEvent(eventData)

        var linkHeader = parseLinkHeader(res.headers.link).last
        setPageCount(
          linkHeader.page === '0' ? 1 : parseInt(linkHeader.page) + 1,
        )
        setTotalCount(res.headers.total)
        setCount(parseInt(res.headers.total))
        if (res.headers.classification_name) {
          setClassificationName(res.headers.classification_name)
        }
        const response = [...res.data.devices]
        setDeviceList(response)
        const aggregations = res.data.aggregations
        if (aggregations) {
          setAggs(aggregations)
          setCards(Object.keys(aggregations))
        } else {
          setCollapsedRow(true)
        }
      })
      .catch((error) => {
        setCollapsedRow(true)
        const eventData = {
          customMetrics: {
            metric1: 'Devices',
            metric3: error.response.status,
          },
          event: {
            type: `apiErrorDevices-AdvancedSearch`,
          },
        }
        props.trackEvent(eventData)

        setCount(0)
        setDeviceList([])

        handleSnackbarOpen('No device found!')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const getAggregationData = (field) => {
    svcDownload
      .getAggregatons(
        auth,
        query,
        Object.assign({
          group_by: field,
        }),
        field,
      )
      .then((res) => {
        const eventData = {
          customMetrics: {
            metric1: 'Devices',
            metric3: res.status,
          },
          event: {
            type: `apiSuccessDevices-AdvancedAggregation`,
          },
        }
        props.trackEvent(eventData)
        const aggregations = res.data.aggregations
        setAggs((prevVal) => ({
          ...prevVal,
          [field]: aggregations[field],
        }))
        handleAddCard(field)
        setCards([...cards, field])
      })
      .catch((error) => {
        const eventData = {
          customMetrics: {
            metric1: 'Devices',
            metric3: error.response.status,
          },
          event: {
            type: `apiErrorDevices-Aggregations`,
          },
        }
        props.trackEvent(eventData)
        handleSnackbarOpen('No data found!')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }
  const changePage = (page) => {
    setIsLoading(true)
    setPage(page)
  }

  const changeRow = (perPage) => {
    setIsLoading(true)
    setPerPage(perPage)
    setPage(0)
  }

  const ColumnBuilder = () => {
    let columns = []

    function addAttributeInput(obj, run, objkeys) {
      Object.entries(obj).forEach((prop) => {
        if (Array.isArray(prop[1])) {
          return
        } else if (prop[1] != null && typeof prop[1] == 'object') {
          addAttributeInput(prop[1], run + prop[0] + '.', objkeys)
        } else {
          objkeys.push(run + prop[0])
        }
      })
    }

    if (deviceList.length > 0) {
      const allKeys = deviceList.reduce((keys, obj) => {
        var objkeys = []
        addAttributeInput(obj, '', objkeys)
        return keys.concat(objkeys)
      }, [])

      const uniqueColumns = [...new Set(allKeys)]
      uniqueColumns.forEach((column) => {
        if (
          ['updated_on', 'updated_at', 'insights.updated_at'].includes(column)
        ) {
          columns.push({
            name: column,
            label: column,
            options: {
              filter: false,
              customBodyRenderLite: (dataIndex) => {
                const columnNames = column.split('.')
                const value = columnNames.reduce(
                  (obj, propName) => obj && obj[propName],
                  deviceList[dataIndex],
                )
                if (value !== undefined) {
                  const date = new Date(value)
                  return (
                    <div style={{ whiteSpace: 'nowrap', padding: '5px' }}>
                      {moment(date).add(23, 'hours').isAfter(/*now*/) === true
                        ? moment(date).fromNow().toString()
                        : moment(date).format('llll').toString()}
                    </div>
                  )
                } else return ''
              },
            },
          })
        } else {
          columns.push({
            name: column,
            label: column,
            options: {
              filter: false,
              customBodyRenderLite: (dataIndex) => {
                const columnNames = column.split('.')
                const value = columnNames.reduce(
                  (obj, propName) => obj && obj[propName],
                  deviceList[dataIndex],
                )
                if (value !== undefined && value !== null) {
                  return (
                    <div style={{ whiteSpace: 'nowrap', padding: '5px' }}>
                      {value.toString()}
                    </div>
                  )
                } else return ''
              },
            },
          })
        }
      })
      return columns
    } else {
      columns.push(
        'id',
        'serial_number',
        'updated_on',
        'classification_name',
        'model_name',
        'device_state',
        'mac_address',
        'location_id',
        'region_id',
        'group_id',
        'district_id',
        'manufacturer_name',
      )
      return columns
    }
  }

  const handleAddCard = (selectedOption) => {
    setCards([...cards, selectedOption])
  }

  const handleCollapsedRow = () => {
    setCollapsedRow(!collapsedRow)
  }

  const handleGroupBySubmit = (selectedOption) => {
    getAggregationData(selectedOption)
  }

  const updateQuery = (field, value) => {
    let updatedQuery = query.filter((item) => item.field_name !== field)
    updatedQuery.push({
      field_name: field,
      field_value: value,
      operator: '=',
    })
    setQuery(updatedQuery)
    localStorage.setItem('advanced_search', JSON.stringify(updatedQuery))
    redirectHandler('advanced_search')
  }

  return (
    <>
      {isLoading ? (
        <Spinner></Spinner>
      ) : (
        <>
          {deviceList !== null && cards !== null && (
            <>
              <Paper>
                <Table>
                  <TableHead>
                    <TableRow
                      hover
                      className={classes.tableRow}
                      onClick={handleCollapsedRow}
                    >
                      {collapsedRow ? (
                        <TableCell className={classes.tableRow}>
                          <List dense>
                            <ListItem className={classes.tableRow}>
                              <Typography
                                variant="h6"
                                className={classes.metrics}
                              >
                                Aggregations
                              </Typography>
                              <ExpandMoreIcon />
                            </ListItem>
                          </List>
                        </TableCell>
                      ) : (
                        <TableCell className={classes.tableRow}>
                          <List dense>
                            <ListItem className={classes.tableRow}>
                              <Typography
                                variant="h6"
                                className={classes.metrics}
                              >
                                {'Aggregations'}
                                <HtmlTooltip
                                  title={
                                    <Typography>
                                      <div style={{ fontSize: 14 }}>
                                        Displaying Top 10 largest aggregations
                                        only (for a selected field)
                                      </div>
                                    </Typography>
                                  }
                                >
                                  <IconButton
                                    color="inherit"
                                    className={classes.iconButton}
                                  >
                                    <InfoIcon />
                                  </IconButton>
                                </HtmlTooltip>
                              </Typography>
                              <ExpandLessIcon />
                            </ListItem>
                          </List>
                        </TableCell>
                      )}
                      <TableCell />
                      <TableCell />
                      <TableCell />
                    </TableRow>
                  </TableHead>
                </Table>
              </Paper>
              <Collapse
                in={!collapsedRow}
                timeout="auto"
                unmountOnExit
                className={classes.honeyComb}
              >
                <div className={classes.divStyle}>
                  {cards.map((card) => (
                    <Card className={classes.cardStyle}>
                      <CardHeader
                        title={
                          card.length <= 26 ? card : card.slice(0, 26) + '... '
                        }
                        titleTypographyProps={{
                          variant: 'button',
                          gutterBottom: false,
                          fontWeight: 'bold',
                          align: 'center',
                        }}
                        action={[
                          <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            onClick={(e) => {
                              handleCloseCard(card)
                            }}
                          >
                            <CloseIcon className={classes.CloseIcon} />
                          </IconButton>,
                        ]}
                      />
                      <CardContent>
                        <DynamicPieChart
                          field={card}
                          data={aggs[card]}
                          onAggClick={updateQuery}
                        />
                      </CardContent>
                    </Card>
                  ))}
                  <FlipCard
                    onSubmit={handleGroupBySubmit}
                    aggregatableFields={groupByDropdown}
                  />
                </div>
              </Collapse>
            </>
          )}
          {deviceList !== null && (
            <Paper elevation={0}>
              <MuiThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                  title="Advanced Search Results"
                  data={deviceList}
                  className={classes.theme}
                  options={{
                    selectableRows: 'none',
                    rowHover: false,
                    filter: false,
                    filterType: 'multiselect',
                    print: false,
                    elevation: 4,
                    viewColumns: true,
                    onColumnViewChange: (changedColumns, allColumns) => {
                      if (excludeList.includes(changedColumns)) {
                        excludeList = excludeList.filter(
                          (column) => column !== changedColumns,
                        )
                      } else {
                        excludeList.push(changedColumns)
                      }
                    },
                    serverSide: true,
                    pagination: false,
                    search: false,
                    page: page,
                    sort: false,
                    count: count,
                    rowsPerPageOptions: [perPage],
                    download: false,
                    expandableRows: false,
                    serverSideFilterList: [params],
                    enableNestedDataAccess: '.',
                    downloadOptions: {
                      filterOptions: {
                        useDisplayedColumnsOnly: true,
                        useDisplayedRowsOnly: true,
                      },
                    },
                    expandableRowsHeader: false,
                    customToolbar: () => {
                      return (
                        <>
                          <ShareToken
                            submission={query}
                            totalCount={totalCount}
                            excludeList={excludeList}
                          />
                          {totalCount > 0 ? (
                            <>
                              <CustomDownloadAdvanced
                                submission={query}
                                totalCount={totalCount}
                                excludeList={excludeList}
                              />
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      )
                    },
                    setFilterChipProps: (colIndex, colName, data) => {
                      return {
                        style: {
                          maxWidth: '100%',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        },
                      }
                    },
                    onFilterChipClose: (index, removedFilter, filterList) => {
                      const filteredQuery = query.filter(function (row) {
                        return row.field_name !== removedFilter.split(' ')[0]
                      })

                      localStorage.setItem(
                        'advanced_search',
                        JSON.stringify(filteredQuery),
                      )
                      redirectHandler('advanced_search')
                    },
                    customFooter: (textLabels) => {
                      return (
                        <TableFooter>
                          <TableRow>
                            <TableCell>
                              <TablePagination
                                component="div"
                                count={count}
                                rowsPerPage={perPage}
                                page={page}
                                labelRowsPerPage={textLabels.rowsPerPage}
                                labelDisplayedRows={() => (
                                  <>
                                    Page {page + 1} of {pageCount} - Devices
                                    Count: {totalCount}{' '}
                                    <TopPanelMobileHelp widgetHelperText="paginationHelp" />
                                  </>
                                )}
                                rowsPerPageOptions={[
                                  10, 25, 50, 100, 250, 3000,
                                ]}
                                onPageChange={(event, page) => changePage(page)}
                                onRowsPerPageChange={(event) =>
                                  changeRow(event.target.value)
                                }
                              />
                            </TableCell>
                          </TableRow>
                        </TableFooter>
                      )
                    },
                  }}
                  columns={ColumnBuilder()}
                />
              </MuiThemeProvider>
              <Snackbar
                open={snackBar.isOpen}
                autoHideDuration={3000}
                onClose={handleClose}
                message={snackBar.message}
                action={[
                  <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    onClick={handleClose}
                  >
                    <CloseIcon className={classes.CloseIcon} />
                  </IconButton>,
                ]}
              />
            </Paper>
          )}
        </>
      )}
    </>
  )
}

const getMuiTheme = () =>
  createMuiTheme({
    overrides: {
      MUIDataTableSelectCell: {
        fixedHeader: {
          // padding: '25px 0px 0px 0px',
          // width: 50,
        },
        root: {
          // padding: '0px 0px 0px 0px',
          // textAlign: 'center',
          // verticalAlign: 'middle',
          // display: 'table-cell',
        },
      },
      MUIDataTableBodyCell: {
        root: {
          backgroundColor: '#eeeeee',
          borderRight: '1px dashed white',
          padding: 10,
          paddingLeft: 0,
          paddingRight: 0,
          paddingTop: 0,
          paddingBottom: 0,
          textAlign: 'center',
          // whiteSpace: 'nowrap',
        },
      },
      MUIDataTableHeadRow: {
        root: {
          height: 24,
        },
      },
      MUIDataTableHeadCell: {
        data: {
          fontSize: 14,
        },
        root: {
          textAlign: 'center',
        },
      },
      MuiTableCell: {
        root: {
          height: 10,
          paddingLeft: 4,
          paddingRight: 4,
          paddingTop: 4,
          paddingBottom: 4,
        },
      },
      MUIDataTableBodyRow: {
        root: {
          padding: 10,
          height: 50,
        },
      },
      MUIDataTableToolbar: {
        root: {
          backgroundColor: '#eeeeee',
          height: 10,
        },
        titleText: {
          fontSize: 20,
        },
        left: {
          flex: '100 0 auto',
        },
        filterPaper: {
          width: 420,
        },
        iconActive: {
          display: 'none',
        },
      },
    },
  })

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#ffffff',
    color: '#333333',
    maxWidth: 450,
    fontSize: 30,
    border: '1px solid #666666',
    marginBottom: '0px',
  },
}))(Tooltip)

const styles = (theme) => ({
  honeyComb: {
    minHeight: '400px',
    height: 'flex',
    overflow: 'auto',
    backgroundImage:
      `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='24.5' ` +
      `viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%23888888' fill-opacity='0.50' ` +
      `fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 ` +
      `11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 ` +
      `0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
  },
  cell: {
    height: 10,
    verticalAlign: 'top',
    backgroundColor: '#fff',
  },
  row: {
    height: 10,
    verticalAlign: 'top',
    backgroundColor: '#fff',
  },
  listItem: {
    verticalAlign: 'top',
    height: 10,
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 5,
    border: 'none',
  },
  root: {
    verticalAlign: 'top',
    backgroundColor: '#eeeeee',
    padding: 'dense',
  },
  cardStyle: {
    maxWidth: '320px',
    minWidth: '320px',
    textAlign: 'center',
    paddingBottom: 5,
    margin: '1rem',
  },
  divStyle: {
    minHeight: '300px',
    display: 'flex',
  },
  tableRow: {
    cursor: 'cursor',
    width: 'flex',
    paddingLeft: 5,
    height: 30,
  },
  metrics: {
    paddingLeft: 14,
  },
  iconButton: {
    '&.MuiIconButton-root': {
      padding: '5px',
    },
  },
})

export default withStyles(styles, { withTheme: true })(
  withRouter(withAnalytics()(withEnv()(Search))),
)
