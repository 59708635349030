import { Dialog, DialogTitle, IconButton, TableCell } from '@material-ui/core'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'

import CloseIcon from '@material-ui/icons/Close'
import DeviceHealthAirwatch from '../DeviceHealth/DeviceHealthAirwatch'
import DeviceHealthTep from '../DeviceHealth/DeviceHealthTep'
import DeviceLastSeenFormat from '../DeviceLastSeenFormat/DeviceLastSeenFormat'
import Divider from '@material-ui/core/Divider'
import HandheldActionMenu from '../DeviceActionMenu/HandheldActionMenu'
import HandheldStateInfoModal from '../DeviceStateInfoModal/HandheldStateInfoModal'
import HistoryIcon from '@material-ui/icons/History'
import MUIDataTable from 'mui-datatables'
import React from 'react'
import Spinner from 'nicollet-react/es/components/Spinner/Spinner'
import Tooltip from '@material-ui/core/Tooltip'
import deviceFilterMap from '../deviceFilterMap/deviceFilterMap'
import handheldsTableOptions from '../TableOptionsConfig/HandheldsTableOptionsConfig'
import moment from 'moment'
import sort from '../../../utilities/sort'
import svcHandhelds from '../../../services/svcHandhelds'

function DeviceStateHistoryChartDialog({
  open,
  onClose,
  handheldStateHistoryLoading,
  handheldStateHistoryResponse,
}) {
  return (
    <React.Fragment>
      <Dialog fullWidth maxWidth={'md'} open={open} onClose={onClose}>
        <DialogTitle>State History - Last 10 Updates</DialogTitle>
        <IconButton
          aria-label="Close"
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        {handheldStateHistoryLoading === true ? (
          <Spinner layout="selfCentering" />
        ) : (
          <MUIDataTable
            data={handheldStateHistoryResponse}
            options={{
              selectableRows: 'none',
              elevation: 0,
              print: false,
              pagination: false,
              rowsPerPage: 10,
              rowsPerPageOptions: [5, 10],
              sortOrder: {
                name: 'event_timestamp',
                direction: 'desc',
              },
            }}
            columns={[
              {
                name: 'device_state',
                label: 'Device State',
              },
              {
                name: 'event_timestamp',
                label: 'Event Timestamp',
                options: {
                  filter: false,
                  customBodyRender: (value, tableMeta, updateValue) => {
                    return moment(value).format('llll')
                  },
                },
              },
              {
                name: 'event_user',
                label: 'Event User',
                options: {
                  filter: false,
                  customBodyRender: (value) => {
                    if (
                      value &&
                      value.employee_id &&
                      value.employee_id.length !== 0
                    ) {
                      const lostuser =
                        value.first_name +
                        ' ' +
                        value.last_name +
                        ' (' +
                        value.employee_id +
                        ')'
                      return lostuser
                    } else {
                      return null
                    }
                  },
                },
              },
              {
                name: 'location_id',
                label: 'Location ID',
                options: {
                  filter: false,
                  customBodyRender: (value, tableMeta, updateValue) => {
                    if (value == null || value === 'null') {
                      return 'No Location Provided'
                    } else {
                      return value
                    }
                  },
                },
              },
            ]}
          />
        )}
      </Dialog>
    </React.Fragment>
  )
}

class HandheldsColumnConfig extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      handheldStateHistoryDialog: false,
      handheldStateHistoryResponse: [],
      handheldStateHistoryLoading: false,
    }
    this.svcHandhelds = new svcHandhelds()
    this.onFilterChange = this.props.onFilterChange.bind(this)
    this.sort = new sort()
  }

  handleDeviceStateHistory = (id) => {
    this.setState({
      handheldStateHistoryDialog: !this.state.handheldStateHistoryDialog,
    })

    this.setState({ handheldStateHistoryLoading: true })
    this.svcHandhelds
      .getHandheldDeviceStateHistory(id, this.props.env)
      .then((response) => {
        this.setState({
          handheldStateHistoryResponse: response.data.device_state_history,
        })
      })
      .catch((error) => {
        console.log(error.response)
      })
      .finally(() => {
        this.setState({ handheldStateHistoryLoading: false })
      })
  }
  render() {
    const {
      customFilters,
      filterList,
      handheldDetails,
      handleFiltersState,
      platform,
      locationFullDetails,
      env,
      filterValue,
      filterDBModeValue,
      filterDeviceState,
      filterDeviceModel,
      filterMPTCDisconnectedValue,
    } = this.props
    var handheldsFilterArray = []
    Object.entries(filterList).forEach((item) => {
      if (item[1] === true) {
        handheldsFilterArray.push(deviceFilterMap[item[0]])
      }
    })
    customFilters.map((item) => handheldsFilterArray.push(item))
    return (
      <>
        <MUIDataTable
          data={handheldDetails}
          title={'Handhelds and Tablets'}
          options={handheldsTableOptions(
            { results: handheldDetails },
            handleFiltersState,
            platform,
            locationFullDetails,
            this.props.onFilterChange,
          )}
          columns={[
            {
              // Health needs to be the first column
              name: 'id',
              label: 'Health',
              options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                  const rowData = new HandheldsRowData(tableMeta.rowData)
                  if (rowData.battery_status !== null) {
                    if (rowData.management_system === 'TEP') {
                      return <DeviceHealthTep rowData={rowData} />
                    } else {
                      return <DeviceHealthAirwatch rowData={rowData} />
                    }
                  }
                },
              },
            },
            {
              name: 'label',
              label: 'Device Label',
              options: {
                filter: false,
                sortCompare: (order) => {
                  return (a, b) => {
                    return this.sort.label(a, b, order)
                  }
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                  if (value) {
                    return value
                  } else {
                    return 'Not Set'
                  }
                },
              },
            },
            {
              name: 'device_state',
              label: 'State',
              options: {
                filter: true,
                filterList: filterDeviceState,
                display: true,
                customBodyRender: (value, tableMeta) => {
                  let id = tableMeta.rowData[0]
                  return (
                    value &&
                    value !== 'null' && (
                      <div style={{ display: 'inline', whiteSpace: 'nowrap' }}>
                        {value}
                        <Tooltip title={`Click to see History of States `}>
                          <IconButton
                            onClick={() => this.handleDeviceStateHistory(id)}
                          >
                            <HistoryIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )
                  )
                },
                customHeadRender: (columnMeta, updateDirection) => (
                  <TableCell
                    key={2}
                    onClick={() => updateDirection(2)}
                    style={{
                      cursor: 'pointer',
                    }}
                  >
                    <Tooltip title="sort">
                      <span>State</span>
                    </Tooltip>
                    <HandheldStateInfoModal env={env} />
                  </TableCell>
                ),
              },
            },
            {
              name: 'id',
              label: 'ID',
              options: {
                filter: false,
              },
            },
            {
              name: 'last_user',
              label: 'Last User',
              options: {
                sort: true,
                filter: false,
                sortCompare: (order) => {
                  var aData
                  var bData
                  // we will group 'not available' together to not mix with human names by appending zzz
                  // this makes sorting make sense if you want to see which devices have a last user
                  return (a, b) => {
                    if (
                      a.data['first_name'] === null ||
                      a.data['first_name'] === ''
                    ) {
                      aData = 'zzzNot Available'
                    } else {
                      aData = a.data['first_name']
                    }

                    if (
                      b.data['first_name'] === null ||
                      b.data['first_name'] === ''
                    ) {
                      bData = 'zzzNot Available'
                    } else {
                      bData = b.data['first_name']
                    }

                    return (
                      '' +
                      aData.localeCompare(bData) * (order === 'asc' ? 1 : -1)
                    )
                  }
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                  if (value && value.first_name && value.last_name) {
                    return value.first_name + ' ' + value.last_name
                  } else {
                    return 'Not Available'
                  }
                },
              },
            },
            {
              name: 'device_event_state',
              label: 'Check-Out Status',
              options: {
                filter: true,
                filterList: filterValue,
                sortCompare: (order) => {
                  return (a, b) => {
                    return this.sort.label(a, b, order)
                  }
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                  if (value) {
                    return value
                  } else {
                    return 'Not Enabled'
                  }
                },
              },
            },
            {
              name: 'model_name',
              label: 'Model',
              options: {
                filter: true,
                filterList: filterDeviceModel,
                customBodyRender: (value, tableMeta, updateValue) => {
                  if (value) {
                    return value.toUpperCase()
                  }
                },
              },
            },
            {
              name: 'manufacturer_name', //
              label: 'Manufacturer',
              options: {
                filter: true,
              },
            },
            {
              name: 'department', //
              label: 'Device Type',
              options: {
                filterList: handheldsFilterArray,
                // filterList: filterDeviceType,
                filter: true,
              },
            },
            {
              name: 'platform', //
              label: 'Platform',
              options: {
                filter: false,
                display: false,
              },
            },
            // {
            //   name: 'operating_system_version', //
            //   label: 'OS Version',
            //   options: {
            //     filter: false,
            //   },
            // },
            // {
            //   // 7
            //   name: 'system_variants', //
            //   label: 'System Variants',
            //   options: {
            //     filter: false,
            //     display: false,
            //   },
            // },
            {
              name: 'agent_version', //
              label: 'Agent Version',
              options: {
                filter: false,
                display: false,
              },
            },
            {
              name: 'location_id', //
              label: 'Location ID',
              options: {
                filter: false,
                display: false,
              },
            },
            {
              name: 'mac_address', //
              label: 'Mac Address',
              options: {
                filter: false,
                display: false,
                viewColumns: false,
              },
            },
            {
              // 11
              name: 'network_compliant',
              label: 'Network Compliant',
              options: {
                filter: false,
                display: false,
                viewColumns: false,
              },
            },
            {
              name: 'policy_compliant',
              label: 'Policy Compliant',
              options: {
                filter: false,
                display: false,
                viewColumns: false,
              },
            },
            {
              name: 'battery_status',
              label: 'Battery Level',
              options: {
                filter: false,
                display: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                  return value && value.level !== null && value.level + '%'
                },
              },
            },
            {
              name: 'bluetooth_status',
              label: 'Bluetooth Enabled',
              options: {
                filter: false,
                display: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                  return value && value.isEnabled
                },
              },
            },
            // {
            //   // 15
            //   name: 'bluetooth_status', //
            //   label: 'Bluetooth Paired Devices',
            //   options: {
            //     filter: false,
            //     display: true,
            //     customBodyRender: (value, tableMeta, updateValue) => {
            //       console.log(value)
            //       return value && value.pairedDevices
            //     },
            //   },
            // },
            // {
            //   // 16
            //   name: 'products',
            //   label: 'Products',
            //   options: {
            //     filter: false,
            //     display: false,
            //     viewColumns: false,
            //     // customBodyRender: (value, tableMeta, updateValue) => {
            //     //   return null
            //     // },
            //   },
            // },
            {
              name: 'updated_on',
              label: 'Updated On',
              options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                  return (
                    value && (
                      <Tooltip title={value}>
                        <div>
                          <DeviceLastSeenFormat
                            lastSeenValue={value}
                            platform={platform}
                          />
                        </div>
                      </Tooltip>
                    )
                  )
                },
              },
            },
            {
              name: 'certificate_expiration',
              label: 'Cert Expiration',
              options: {
                filter: false,
                display: false,
              },
            },
            {
              name: 'product_compliance',
              label: 'Product Compliant',
              options: {
                filter: false,
                display: false,
                viewColumns: false,
              },
            },
            {
              name: 'management_system',
              label: 'Management System',
              options: {
                filter: true,
                display: true,
                viewColumns: true,
              },
            },
            {
              // actions needs to be the last column
              name: 'id',
              label: 'Actions',
              options: {
                filter: false,
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                  const rowData = new HandheldsRowData(tableMeta.rowData)
                  return (
                    <HandheldActionMenu
                      rowData={rowData}
                      platform={platform}
                      env={env}
                      locationFullDetails={locationFullDetails}
                    />
                  )
                },
              },
            },
            {
              name: 'preserved_bluetooth_connections',
              label: 'Preserved Bluetooth Connections',
              options: {
                filter: false,
                display: false,
                viewColumns: false,
              },
            },
            {
              name: 'name',
              label: 'Name',
              options: {
                filter: false,
                display: false,
                viewColumns: false,
              },
            },
            {
              name: 'direct_boot_mode',
              label: 'Direct Boot Mode',
              options: {
                filter: true,
                filterList: filterDBModeValue,
                customBodyRender: (value, tableMeta, updateValue) => {
                  if (value && value === true) {
                    return 'TRUE'
                  } else {
                    return 'FALSE'
                  }
                },
                display: false,
                viewColumns: false,
              },
            },
            {
              name: 'e355_pinpad_information',
              label: 'mPTC Disconnected',
              options: {
                filter: true,
                filterList: filterMPTCDisconnectedValue,
                customBodyRender: (value, tableMeta, updateValue) => {
                  if (value && value.connection_status === 'DISCONNECTED') {
                    return 'TRUE'
                  } else {
                    return 'FALSE'
                  }
                },
                display: false,
                viewColumns: false,
              },
            },
          ]}
        />
        <Divider style={{ backgroundColor: 'black' }} />
        {
          <MuiThemeProvider theme={this.getMuiTheme()}>
            <DeviceStateHistoryChartDialog
              open={this.state.handheldStateHistoryDialog}
              onClose={() => this.handleDeviceStateHistory()}
              handheldStateHistoryLoading={
                this.state.handheldStateHistoryLoading
              }
              handheldStateHistoryResponse={
                this.state.handheldStateHistoryResponse !== null
                  ? this.state.handheldStateHistoryResponse
                  : []
              }
            />
          </MuiThemeProvider>
        }
      </>
    )
  }
  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTableBodyRow: {
          root: {
            backgroundColor: '#eeeeee',
            height: 10,
            textAlign: 'center',
          },
        },
        MUIDataTableHeadRow: {
          root: {
            height: 24,
          },
        },
        MUIDataTableBodyCell: {
          root: {
            backgroundColor: '#eeeeee',
            padding: 0,
            paddingLeft: 8,
          },
        },
        MUIDataTableHeadCell: {
          root: {
            backgroundColor: '#eeeeee',
            padding: 0,
            paddingLeft: 17,
          },
        },
        MUIDataTableToolbar: {
          root: {
            height: 10,
          },
          titleText: {
            fontSize: 14,
          },
          left: {
            flex: '100 0 auto',
          },
        },
      },
    })
}

export class HandheldsRowData {
  constructor(rowData) {
    // classBuilder(rowData)
    // used for updating this class until i can figure out how to do this dynamically.
    // probably could run the function once and store it in state...
    if (rowData !== undefined) {
      this.id = rowData[0]
      this.label = rowData[1]
      this.device_state = rowData[2]
      this.id = rowData[3]
      this.last_user = rowData[4]
      this.checkout_status = rowData[5]
      this.model_name = rowData[6]
      this.manufacturer_name = rowData[7]
      this.department = rowData[8]
      this.platform = rowData[9]
      this.agent_version = rowData[10]
      this.location_id = rowData[11]
      this.mac_address = rowData[12]
      this.network_compliant = rowData[13]
      this.policy_compliant = rowData[14]
      this.battery_status = rowData[15]
      this.bluetooth_status = rowData[16]
      this.updated_on = rowData[17]
      this.certificate_expiration = rowData[18]
      this.product_compliance = rowData[19]
      this.management_system = rowData[20]
      this.id = rowData[21]
      this.preserved_bluetooth_connections = rowData[22]
      this.name = rowData[23]
      this.direct_boot_mode = rowData[24] ? rowData[24] : false
      this.e355_pinpad_information = rowData[25] ? rowData[25] : false
    }
  }
}

export default HandheldsColumnConfig
