import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'

import ActionMenu from './ActionMenu/ActionMenu'
import DeviceLastSeenFormat from '../Dashboard/DeviceLastSeenFormat/DeviceLastSeenFormat'
import IconButton from '@material-ui/core/IconButton'
import { Link } from 'react-router-dom'
import MUIDataTable from 'mui-datatables'
import Paper from '@material-ui/core/Paper'
import React from 'react'
import Spinner from 'nicollet-react/es/components/Spinner/Spinner'
import TableOptionsConfig from '../Dashboard/TableOptionsConfig/TableOptionsConfig'
import Tooltip from '@material-ui/core/Tooltip'
import queryString from 'query-string'
import svcMac from '../../services/svcMac'
import svcWindows from '../../services/svcWindows'
import { withAnalytics } from '@praxis/component-analytics'
import { withEnv } from '@praxis/component-runtime-env'
import withRouter from '../withRouter'
import { withStyles } from '@material-ui/styles'

class Devices extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      getMacData: [],
      getWindowsData: [],
      loading: false,
    }
    this.svcWindows = new svcWindows()
    this.svcMac = new svcMac()
  }

  getWindowsData = async () => {
    this.submission = queryString.parse(this.props.router.location.search)

    await this.svcWindows
      .getWindows(
        this.props.env,
        queryString.parse(this.props.router.location.search),
      )
      .then((response) => {
        this.setState({
          getWindowsData: response.data,
          devicesLoading: false,
        })
      })
      .catch((error) => {
        console.log(error)
        this.setState({ devicesLoading: false })
      })
      .finally(() => {
        this.setState({ devicesLoading: false })
      })
  }

  getMacData = async () => {
    this.submission = queryString.parse(this.props.router.location.search)
    await this.svcMac
      .getMac(
        this.props.env,
        queryString.parse(this.props.router.location.search),
      )
      .then((response) => {
        this.setState({
          getMacData: response.data,
          devicesLoading: false,
        })
      })
      .catch((error) => {
        console.log(error)
        this.setState({ devicesLoading: false })
      })

      .finally(() => {
        this.setState({
          devicesLoading: false,
        })
      })
  }

  async componentDidMount() {
    this.setState({ devicesLoading: true })
    await this.getWindowsData()
    await this.getMacData()
  }

  ColumnBuilder = () => {
    let includeClassification = null
    if (Object.keys(this.submission).includes('classification_name')) {
      includeClassification = this.submission.classification_name
    } else includeClassification = 'all'
    let platformColumns = []

    // only reveal classification_name column when its a non classification search
    let excluded = null
    includeClassification === 'all'
      ? (excluded = true)
      : (excluded = 'excluded')

    const columns = [
      {
        reveal: ['all', 'handheld', 'tablet', 'camera', 'printer', 'computer'],
        name: 'classification_name',
        label: 'Classification',
        options: {
          display: excluded,
        },
      },
      {
        reveal: ['all', 'handheld', 'tablet', 'camera', 'printer', 'computer'],
        name: 'id',
        label: 'ID',
      },

      {
        reveal: ['all', 'handheld', 'tablet', 'printer'],
        name: 'serial_number',
        label: 'Serial Number',
      },
      {
        reveal: ['all', 'handheld', 'tablet', 'camera', 'printer', 'computer'],
        name: 'model_name',
        label: 'Model',
      },
      {
        reveal: ['all', 'handheld', 'tablet', 'computer'],
        name: 'manufacturer_name',
        label: 'Manufacturer',
      },
      {
        reveal: ['all', 'computer'],
        name: 'operating_system',
        label: 'OS',
      },
      {
        reveal: ['all', 'handheld', 'tablet', 'computer'],
        name: 'operating_system_version',
        label: 'OS Version',
      },
      {
        reveal: ['all', 'handheld', 'tablet', 'camera', 'printer', 'computer'],
        name: 'location.location_id',
        label: 'Location',
        options: {
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            if (
              value &&
              (value === 'unknown' || value === 'Location code not set')
            ) {
              return value
            } else {
              return (
                value &&
                value !== 'null' && (
                  <Tooltip
                    title={
                      'Click to see ' +
                      tableMeta.rowData[0] +
                      's at location ' +
                      value
                    }
                  >
                    <Link
                      to={
                        '/location/' +
                        value +
                        '?platform=' +
                        tableMeta.rowData[0]
                      }
                    >
                      {value}
                    </Link>
                  </Tooltip>
                )
              )
            }
          },
        },
      },
      {
        reveal: ['all', 'handheld', 'tablet', 'camera', 'printer', 'computer'],
        name: 'location.region_id',
        label: 'Region',
        options: {
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              value &&
              value !== 'null' &&
              value !== 'unknown' && (
                <>
                  {value}
                  <Tooltip
                    title={'Click to see all devices at Region ' + value}
                  >
                    <IconButton
                      onClick={() => {
                        window.location =
                          window.location.origin + `/devices?region_id=${value}`
                      }}
                    >
                      {this.deviceIcons['allDevices']}
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={`Click to see all ${tableMeta.rowData[0]}s at Region ${value}`}
                  >
                    <IconButton
                      onClick={() => {
                        window.location =
                          window.location.origin +
                          `/devices?region_id=${value}&classification_name=${tableMeta.rowData[0]}`
                      }}
                    >
                      {this.deviceIcons[tableMeta.rowData[0]]}
                    </IconButton>
                  </Tooltip>
                </>
              )
            )
          },
        },
      },
      {
        reveal: ['all', 'handheld', 'tablet', 'camera', 'printer', 'computer'],
        name: 'location.group_id',
        label: 'Group',
        options: {
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              value &&
              value !== 'null' && (
                <>
                  {value}
                  <Tooltip title={'Click to see all devices at Group ' + value}>
                    <IconButton
                      onClick={() => {
                        window.location =
                          window.location.origin + `/devices?group_id=${value}`
                      }}
                    >
                      {this.deviceIcons['allDevices']}
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={`Click to see all ${tableMeta.rowData[0]}s at Group ${value}`}
                  >
                    <IconButton
                      onClick={() => {
                        window.location =
                          window.location.origin +
                          `/devices?group_id=${value}&classification_name=${tableMeta.rowData[0]}`
                      }}
                    >
                      {this.deviceIcons[tableMeta.rowData[0]]}
                    </IconButton>
                  </Tooltip>
                </>
              )
            )
          },
        },
      },
      {
        reveal: ['all', 'handheld', 'tablet', 'camera', 'printer', 'computer'],
        name: 'location.district_id',
        label: 'District',
        options: {
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              value &&
              value !== 'null' && (
                <>
                  {value}
                  <Tooltip
                    title={'Click to see all devices at District ' + value}
                  >
                    <IconButton
                      onClick={() => {
                        window.location =
                          window.location.origin +
                          `/devices?district_id=${value}`
                      }}
                    >
                      {this.deviceIcons['allDevices']}
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={`Click to see all ${tableMeta.rowData[0]}s at District ${value}`}
                  >
                    <IconButton
                      onClick={() => {
                        window.location =
                          window.location.origin +
                          `/devices?district_id=${value}&classification_name=${tableMeta.rowData[0]}`
                      }}
                    >
                      {this.deviceIcons[tableMeta.rowData[0]]}
                    </IconButton>
                  </Tooltip>
                </>
              )
            )
          },
        },
      },
      {
        reveal: ['all', 'camera', 'printer', 'computer'],
        name: 'network.ip_address',
        label: 'IP Address',
        options: {
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return value && value !== 'null' && value
          },
        },
      },
      {
        reveal: ['all', 'handheld', 'tablet', 'camera', 'printer', 'computer'],
        name: 'network.mac_address',
        label: 'Mac Address',
        options: {
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return value && value !== 'null' && value
          },
        },
      },
      {
        reveal: ['all', 'computer'],
        name: 'network.network_connection_name',
        label: 'Network Connection Name',
        options: {
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return value && value !== 'null' && value
          },
        },
      },
      {
        reveal: ['all', 'camera'],
        name: 'network.port_number',
        label: 'Port Number',
        options: {
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return value && value !== 'null' && value
          },
        },
      },
      {
        reveal: ['all', 'computer'],
        name: 'network.ssid',
        label: 'SSID',
        options: {
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return value && value !== 'null' && value
          },
        },
      },
      {
        reveal: ['all', 'handheld', 'tablet', 'camera', 'printer', 'computer'],
        name: 'updated_on',
        label: 'Updated On',
        options: {
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              value && (
                <Tooltip title={value}>
                  <>
                    <DeviceLastSeenFormat lastSeenValue={value} />
                  </>
                </Tooltip>
              )
            )
          },
        },
      },
      {
        reveal: ['all', 'handheld', 'tablet', 'camera', 'printer'],
        name: 'classification_name',
        label: 'Actions',
        options: {
          filter: false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return <ActionMenu rowData={tableMeta.rowData} />
          },
        },
      },
    ]

    columns.forEach((row) => {
      if (
        row.hasOwnProperty('reveal') &&
        row.reveal.includes(`${includeClassification}`)
      ) {
        return platformColumns.push(row)
      }
    })
    return platformColumns
  }

  render() {
    return (
      <>
        {this.state.devicesLoading ? (
          <Spinner />
        ) : (
          <>
            {this.state.deviceInfo !== null && (
              <Paper elevation={0}>
                <MuiThemeProvider theme={this.getMuiTheme()}>
                  <MUIDataTable
                    data={[
                      ...this.state.getWindowsData,
                      ...this.state.getMacData,
                    ]}
                    title={'Computers'}
                    className={this.theme}
                    options={TableOptionsConfig(
                      [...this.state.getWindowsData, ...this.state.getMacData],
                      null,
                      'computer',
                    )}
                    columns={[
                      {
                        name: 'computer_name',
                        label: 'Computer Name',
                        options: {
                          filter: false,
                        },
                      },
                      {
                        name: 'model_name',
                        label: 'Model',
                        options: {
                          filter: false,
                        },
                      },
                      {
                        name: 'logged_in_user',
                        label: 'User',
                        options: {
                          filter: false,
                        },
                      },
                      {
                        name: 'operating_system',
                        label: 'OS Name',
                        options: {
                          filter: true,
                        },
                      },
                      {
                        name: 'location_id',
                        label: 'Location',
                        options: {
                          filter: false,
                        },
                      },
                      {
                        name: 'updated_on',
                        label: 'Last Seen',
                        options: {
                          filter: false,
                          customBodyRender: (value, tableMeta, updateValue) => {
                            return value ? (
                              <Tooltip title={value}>
                                <div>
                                  <DeviceLastSeenFormat lastSeenValue={value} />
                                </div>
                              </Tooltip>
                            ) : (
                              'N/A'
                            )
                          },
                        },
                      },
                    ]}
                  />
                </MuiThemeProvider>
              </Paper>
            )}
          </>
        )}
      </>
    )
  }
  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTableSelectCell: {
          fixedHeader: {
            padding: '0px 0px 0px 0px',
            width: 50,
          },
        },
        MUIDataTableBodyCell: {
          root: {
            backgroundColor: '#eeeeee',
            borderRight: '1px dashed white',
            height: '30px',
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 5,
            paddingBottom: 5,
            textAlign: 'left',
            whiteSpace: 'nowrap',
          },
        },
        MUIDataTableHeadRow: {
          root: {
            height: 24,
          },
        },
        MUIDataTableHeadCell: {
          data: {
            fontSize: 14,
          },
          root: {
            textAlign: 'center',
          },
        },
        MuiTableCell: {
          root: {
            height: 10,
            paddingLeft: 4,
            paddingRight: 4,
            paddingTop: 4,
            paddingBottom: 4,
          },
        },
        MUIDataTableBodyRow: {
          root: {
            height: 10,
          },
        },
        MUIDataTableToolbar: {
          root: {
            backgroundColor: '#eeeeee',
            height: 10,
          },
          titleText: {
            fontSize: 20,
          },
          left: {
            flex: '100 0 auto',
          },
        },
      },
    })
}

const styles = (theme) => ({
  honeyComb: {
    minHeight: `calc(100vh - 75px)`,
    backgroundImage:
      `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='24.5' ` +
      `viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%23888888' fill-opacity='0.50' ` +
      `fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 ` +
      `11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 ` +
      `0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
  },
  cell: {
    height: 10,
    verticalAlign: 'top',
    backgroundColor: '#fff',
  },
  row: {
    height: 10,
    verticalAlign: 'top',
    backgroundColor: '#fff',
  },
  listItem: {
    verticalAlign: 'top',
    height: 10,
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 5,
    border: 'none',
  },
  root: {
    verticalAlign: 'top',
    backgroundColor: '#eeeeee',
    padding: 'dense',
  },
})

export default withStyles(styles, { withTheme: true })(
  withRouter(withAnalytics()(withEnv()(Devices))),
)
