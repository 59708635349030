import React from 'react'
import axios from 'axios'

class SvcMacApplications extends React.Component {
  getApplications(config, submission) {
    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey
    var apiUri = encodeURI(apiUriBase + `/mac_application_counts?key=` + apiKey)
    return axios.get(apiUri)
  }
}
export default SvcMacApplications
