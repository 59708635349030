export const locationOptions = {
  all: {
    enabled: true,
    switchLabel: 'All',
    tabLocationName: '',
  },
  dc: {
    enabled: true,
    switchLabel: 'Distribution Centers',
    tabLocationName: 'DC',
  },
  hq: {
    enabled: true,
    switchLabel: 'HQ',
    tabLocationName: 'HQ',
  },
  store: {
    enabled: true,
    switchLabel: 'Stores',
    tabLocationName: 'STR',
  },
}
