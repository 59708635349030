import React from 'react'
import axios from 'axios'
import queryString from 'query-string'

class SvcTablets extends React.Component {
  getTablets(config, submission) {
    const queryParameters = {
      tablets: {
        serial_number: null,
        mac_address: null,
        model_name: null,
        operating_system_version: null,
        last_user: null,
        location_id: null,
        id: null,
      },
    }
    let queryParametersVar = queryParameters
    Object.entries(submission).forEach((param) => {
      if (queryParametersVar.tablets.hasOwnProperty(param[0])) {
        queryParametersVar.tablets[`${param[0]}`] = param[1]
      }
    })

    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey
    const endpointParams = queryString.stringify(queryParametersVar.tablets, {
      sort: false,
      skipNull: true,
    })
    const apiUri = encodeURI(
      apiUriBase + `/tablets?` + endpointParams + `&key=` + apiKey,
    )
    return axios.get(apiUri)
  }
  getTabletDetail(config, submission) {
    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey
    const apiUri = encodeURI(
      apiUriBase +
        (submission.type === 'geo_positions' ? `/handhelds/` : `/tablets/`) +
        submission.id +
        `/` +
        submission.type +
        `?key=` +
        apiKey,
    )
    return axios.get(apiUri)
  }
  deleteTablet(id, config) {
    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey
    const apiUri = encodeURI(apiUriBase + `/tablets/` + id + `?key=` + apiKey)
    return axios.delete(apiUri)
  }
}
export default SvcTablets
