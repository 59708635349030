import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'

import React from 'react'
import SummarizedWidgetsRoot from '../SummarizedWidgets/SummarizedWidgetsRoot'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import { fade } from '@material-ui/core/styles/colorManipulator'
import { withEnv } from '@praxis/component-runtime-env'
import { withStyles } from '@material-ui/styles'

class ComputerTabs extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tabValue: 0,
    }
  }

  handleChange = (event, tabValue) => {
    this.setState({ tabValue })
  }

  render() {
    const { classes, tabLocationName } = this.props
    return (
      <>
        <MuiThemeProvider theme={this.getMuiTheme()}>
          <Tabs
            value={this.state.tabValue}
            variant="fullWidth"
            className={classes.tabs}
            onChange={this.handleChange}
            TabIndicatorProps={{ className: classes.tabsProps }}
            centered
          >
            <Tab
              label={<span className={classes.tabLabel}>WINDOWS</span>}
              className={classes.tab}
            />
            {!this.props.tabLocationName.match(/^STR|DC$/) ? (
              <Tab
                label={<span className={classes.tabLabel}>MAC</span>}
                className={classes.tab}
              />
            ) : (
              <Tab
                label={<span className={classes.tabLabel}>MAC</span>}
                className={classes.tab}
                disabled={true}
              />
            )}
            {/* <Tab
              label={<span className={classes.tabLabel}>MAC</span>}
              className={classes.tab}
            /> */}
            {this.props.tabLocationName === 'HQ' ||
            this.props.tabLocationName === '' ? (
              <Tab
                label={<span className={classes.tabLabel}>VMWare</span>}
                className={classes.tab}
              />
            ) : (
              <></>
            )}
          </Tabs>

          {this.state.tabValue === 0 && (
            <SummarizedWidgetsRoot
              widgets="windows"
              tabLocationName={tabLocationName}
            />
          )}
          {this.state.tabValue === 1 && <SummarizedWidgetsRoot widgets="mac" />}

          {this.state.tabValue === 2 &&
            (this.props.tabLocationName === 'HQ' ||
              this.props.tabLocationName === '') && (
              <SummarizedWidgetsRoot
                widgets="vmware"
                tabLocationName={tabLocationName}
              />
            )}

          {this.state.tabValue === 2 &&
            !(
              this.props.tabLocationName === 'HQ' ||
              this.props.tabLocationName === ''
            ) && (
              <SummarizedWidgetsRoot
                widgets="windows"
                tabLocationName={tabLocationName}
              />
            )}
        </MuiThemeProvider>
      </>
    )
  }

  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MuiTab: {
          root: { '&$selected': { backgroundColor: '#fff' } },
        },
        MUIDataTableBodyCell: {
          root: {
            backgroundColor: '#eeeeee',
            height: 10,
          },
        },
        MUIDataTableHeadRow: {
          root: {
            height: 10,
          },
        },
        MUIDataTableBodyRow: {
          root: {
            backgroundColor: '#eeeeee',
            height: 10,
          },
        },
        MUIDataTableToolbar: {
          root: {
            backgroundColor: '#eeeeee',
            height: 10,
          },
          titleText: {
            fontSize: 14,
          },
          left: {
            flex: '100 0 auto',
          },
        },
        MuiPaper: {
          root: {
            minWidth: '400',
            paddingLeft: '0',
          },
        },
      },
    })
}

const styles = (theme) => ({
  honeyComb: {
    backgroundImage:
      `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='24.5' ` +
      `viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%23888888' fill-opacity='0.50' ` +
      `fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 ` +
      `11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 ` +
      `0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
  },
  tab: {
    borderRight: '1px solid #666666',
    borderLeft: '1px solid #666666',
    borderTop: '1px solid #666666',
    borderBottom: '1px solid #666666',
    borderRadius: '5px 5px 0px 0px',
    backgroundColor: '#999999',
  },
  tabs: {
    marginTop: 70,
  },
  tabsProps: {
    backgroundColor: `${fade('#fff', 0.1)}`,
    borderBottom: '1px solid white',
  },
})

export default withStyles(styles, { withTheme: true })(withEnv()(ComputerTabs))
