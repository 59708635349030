import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import { Typography } from '@material-ui/core'
import { withEnv } from '@praxis/component-runtime-env'
import { withStyles } from '@material-ui/styles'

class TopPanelMobileHelp extends React.Component {
  render() {
    const { classes } = this.props
    return (
      <HtmlTooltip
        title={
          <React.Fragment>
            <Typography>
              {this.props.widgetHelperText &&
                this.props.env.widgetHelperText[this.props.widgetHelperText]}
            </Typography>
          </React.Fragment>
        }
      >
        <HelpOutlineIcon className={classes.icon} />
      </HtmlTooltip>
    )
  }
}

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#ffffff',
    color: '#333333',
    maxWidth: 250,
    fontSize: 30,
    border: '1px solid #666666',
    borderRadius: 5,
  },
}))(Tooltip)
const styles = (theme) => ({
  icon: {
    verticalAlign: 'middle',
    marginBottom: '0px',
  },
})

export default withStyles(styles)(withEnv()(TopPanelMobileHelp))
