import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'

import Apps from '@material-ui/icons/Apps'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import React from 'react'
import SettingsIcon from '@material-ui/icons/Settings'
import SvcRFIDScanners from '../../../../services/svcRFIDScanners'
import Tab from '@material-ui/core/Tab'
import TableCell from '@material-ui/core/TableCell'
import TableDataAtsDetails from '../TableDataI10i/TableDataAtsDetails'
import TableDataBaremetalServerDetails from '../TableDataI10i/TableDataBMSDetails'
import TableDataBroadcastDetails from '../TableDataI10i/TableDataBroadcastDetails'
import TableDataChassisDetails from '../TableDataI10i/TableDataChassisDetails'
import TableDataDigitalMediaDetails from '../TableDataI10i/TableDataDigitalMediaDetails'
import TableDataDoorAccessControlDetails from '../TableDataI10i/TableDataDoorAccessControlDetails'
import TableDataGeneratorDetails from '../TableDataI10i/TableDataGeneratorDetails'
import TableDataI10iCore from '../TableDataI10i/TableDataI10iCore'
import TableDataNetworkAccessPointDetails from '../TableDataI10i/TableDataNetworkAccessPointDetails'
import TableDataNetworkChassisDetails from '../TableDataI10i/TableDataNetworkChassisDetails'
import TableDataNetworkGatewayDetails from '../TableDataI10i/TableDataNetworkGatewayDetails'
import TableDataNetworkManagementDetails from '../TableDataI10i/TableDataNetworkManagementDetails'
import TableDataNetworkRouterDetails from '../TableDataI10i/TableDataNetworkRouterDetails'
import TableDataNetworkSwitchDetails from '../TableDataI10i/TableDataNetworkSwitchDetails'
import TableDataNetworkTrafficManagementDetails from '../TableDataI10i/TableDataNetworkTrafficManagementDetails'
import TableDataPduDetails from '../TableDataI10i/TableDataPduDetails'
import TableDataPhoneDetails from '../TableDataI10i/TableDataPhoneDetails'
import TableDataPrinterDetails from '../TableDataI10i/TableDataPrinterDetails'
import TableDataRegisterDetails from '../TableDataI10i/TableDataRegisterDetails'
import TableDataRfidHandheldDetails from '../TableDataI10i/TableDataRfidHandheldDetails'
import TableDataScaleDetails from '../TableDataI10i/TableDataScaleDetails'
import TableDataSubmeteringControlsDetails from '../TableDataI10i/TableDataSubmeteringControlsDetails'
import TableDataUpsDetails from '../TableDataI10i/TableDataUpsDetails'
import TableDataVoiceGatewayDetails from '../TableDataI10i/TableDataVoiceGatewayDetails'
import TableRow from '@material-ui/core/TableRow'
import Tabs from '@material-ui/core/Tabs'
import { fade } from '@material-ui/core/styles/colorManipulator'
import { withEnv } from '@praxis/component-runtime-env'
import { withStyles } from '@material-ui/styles'

class LocationDashboardI10iDetails extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tabValue: 0,
      operationalData: [],
    }
    this.svcRFIDScanners = new SvcRFIDScanners()
  }

  handleChange = (event, tabValue) => {
    this.setState({ tabValue })
  }

  componentDidMount() {
    const { rowData } = this.props

    if (rowData[1] === 'RFID Handheld') {
      const identification = {
        id: rowData[6],
      }
      this.svcRFIDScanners
        .getRFIDScannersId(this.props.env, identification)
        .then((response) => {
          this.setState({
            operationalData: response.data,
          })
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }

  render() {
    const colSpan = this.props.rowData.length + 1
    const { classes, storeReportResults, rowMeta, rowData } = this.props
    return (
      <TableRow className={classes.honeyComb}>
        <TableCell colSpan={colSpan}>
          <Card>
            <CardContent>
              {typeof storeReportResults[rowMeta.dataIndex] !== 'undefined' ? (
                <div>
                  {
                    <MuiThemeProvider theme={this.getMuiTheme()}>
                      <Tabs
                        value={this.state.tabValue}
                        onChange={this.handleChange}
                        variant="fullWidth"
                        className={classes.tabs}
                        TabIndicatorProps={{ className: classes.tabsProps }}
                      >
                        <Tab
                          icon={<SettingsIcon />}
                          label={`${rowData[1]} Details`}
                          className={classes.tab}
                        />
                        <Tab
                          icon={<Apps />}
                          label="General"
                          className={classes.tab}
                        />
                      </Tabs>
                    </MuiThemeProvider>
                  }
                  {this.state.tabValue === 1 && (
                    <TableDataI10iCore
                      i10iDetails={storeReportResults[rowMeta.dataIndex]}
                    />
                  )}

                  {this.state.tabValue === 0 && (
                    <>
                      {rowData[1] === 'Ups' ? (
                        <TableDataUpsDetails
                          upsDetails={storeReportResults[rowMeta.dataIndex]}
                        />
                      ) : rowData[1] === 'Pdu' ? (
                        <TableDataPduDetails
                          pduDetails={storeReportResults[rowMeta.dataIndex]}
                        />
                      ) : rowData[1] === 'Ats' ? (
                        <TableDataAtsDetails
                          atsDetails={storeReportResults[rowMeta.dataIndex]}
                        />
                      ) : rowData[1] === 'Generator' ? (
                        <TableDataGeneratorDetails
                          generatorDetails={
                            storeReportResults[rowMeta.dataIndex]
                          }
                        />
                      ) : rowData[1] === 'Digital Media Player' ? (
                        <TableDataDigitalMediaDetails
                          digitalMediaDetails={
                            storeReportResults[rowMeta.dataIndex]
                          }
                        />
                      ) : rowData[1] === 'Baremetal Server' ? (
                        <TableDataBaremetalServerDetails
                          rowData={rowData}
                          bmsDetails={storeReportResults[rowMeta.dataIndex]}
                        />
                      ) : rowData[1] === 'Chassis' ? (
                        <TableDataChassisDetails
                          chassisDetails={storeReportResults[rowMeta.dataIndex]}
                        />
                      ) : rowData[1] === 'Door Access Control' ? (
                        <TableDataDoorAccessControlDetails
                          doorAccessControlDetails={
                            storeReportResults[rowMeta.dataIndex]
                          }
                        />
                      ) : rowData[1] === 'Scale' ? (
                        <TableDataScaleDetails
                          scaleDetails={storeReportResults[rowMeta.dataIndex]}
                        />
                      ) : rowData[1] === 'Register' ? (
                        <TableDataRegisterDetails
                          registerDetails={
                            storeReportResults[rowMeta.dataIndex]
                          }
                        />
                      ) : rowData[1] === 'Broadcast' ? (
                        <TableDataBroadcastDetails
                          broadcastDetails={
                            storeReportResults[rowMeta.dataIndex]
                          }
                        />
                      ) : rowData[1] === 'Rfid Handheld' ? (
                        <TableDataRfidHandheldDetails
                          rfidHandheldDetails={
                            storeReportResults[rowMeta.dataIndex]
                          }
                          operationalData={this.state.operationalData}
                        />
                      ) : rowData[1] === 'Printer' ? (
                        <TableDataPrinterDetails
                          printerDetails={storeReportResults[rowMeta.dataIndex]}
                        />
                      ) : rowData[1] ===
                        'Network AccessControl And Visibility' ? (
                        <></>
                      ) : /*
                      <TableDataNetworkAccessControlAndVisibilityDetails
                        rowData={rowData}
                        rowMeta={rowMeta}
                        storeReportResults={storeReportResults}
                      />
                      */
                      rowData[1] === 'Network Access Point' ? (
                        <TableDataNetworkAccessPointDetails
                          networkAccessPointDetails={
                            storeReportResults[rowMeta.dataIndex]
                          }
                        />
                      ) : rowData[1] === 'Network Chassis' ? (
                        <TableDataNetworkChassisDetails
                          networkChassisDetails={
                            storeReportResults[rowMeta.dataIndex]
                          }
                        />
                      ) : rowData[1] === 'Network Management' ? (
                        <TableDataNetworkManagementDetails
                          networkManagementDetails={
                            storeReportResults[rowMeta.dataIndex]
                          }
                        />
                      ) : rowData[1] === 'Network Gateway' ? (
                        <TableDataNetworkGatewayDetails
                          networkGatewayDetails={
                            storeReportResults[rowMeta.dataIndex]
                          }
                        />
                      ) : rowData[1] === 'Voice Gateway' ? (
                        <TableDataVoiceGatewayDetails
                          voiceGatewayDetails={
                            storeReportResults[rowMeta.dataIndex]
                          }
                        />
                      ) : rowData[1] === 'Network Switch' ? (
                        <TableDataNetworkSwitchDetails
                          networkSwitchDetails={
                            storeReportResults[rowMeta.dataIndex]
                          }
                        />
                      ) : rowData[1] === 'Network Router' ? (
                        <TableDataNetworkRouterDetails
                          networkRouterDetails={
                            storeReportResults[rowMeta.dataIndex]
                          }
                        />
                      ) : rowData[1] === 'Phone' ? (
                        <TableDataPhoneDetails
                          phoneDetails={storeReportResults[rowMeta.dataIndex]}
                        />
                      ) : rowData[1] === 'Submetering Controls' ? (
                        <TableDataSubmeteringControlsDetails
                          submeteringControlsDetails={
                            storeReportResults[rowMeta.dataIndex]
                          }
                        />
                      ) : rowData[1] === 'Network Traffic Management' ? (
                        <TableDataNetworkTrafficManagementDetails
                          networkTrafficManagementDetails={
                            storeReportResults[rowMeta.dataIndex]
                          }
                        />
                      ) : (
                        <div>
                          Note, additional details are not available for this
                          device.{' '}
                        </div>
                      )}
                    </>
                  )}
                </div>
              ) : (
                <div>
                  Note, additional details are not available for this device.
                </div>
              )}
            </CardContent>
          </Card>
        </TableCell>
      </TableRow>
    )
  }
  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MuiTab: {
          root: { '&$selected': { backgroundColor: '#fff' } },
        },
        MUIDataTableBodyCell: {
          root: {
            backgroundColor: '#eeeeee',
            height: 10,
          },
        },
        MUIDataTableHeadRow: {
          root: {
            height: 10,
          },
        },
        MUIDataTableBodyRow: {
          root: {
            backgroundColor: '#eeeeee',
            height: 10,
          },
        },
        MUIDataTableToolbar: {
          root: {
            backgroundColor: '#eeeeee',
            height: 10,
          },
          titleText: {
            fontSize: 14,
          },
          left: {
            flex: '100 0 auto',
          },
        },
        MuiPaper: {
          root: {
            minWidth: '400',
            paddingLeft: '0',
          },
        },
      },
    })
}

const styles = () => ({
  honeyComb: {
    backgroundImage:
      `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='24.5' ` +
      `viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%23888888' fill-opacity='0.50' ` +
      `fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 ` +
      `11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 ` +
      `0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
  },
  tab: {
    borderRight: '1px solid #666666',
    borderLeft: '1px solid #666666',
    borderTop: '1px solid #666666',
    borderBottom: '1px solid #666666',
    borderRadius: '5px 5px 0px 0px',
  },
  tabs: {
    backgroundColor: '#999999',
  },
  tabsProps: {
    backgroundColor: `${fade('#fff', 0.1)}`,
    borderBottom: '1px solid white',
  },
})

export default withStyles(styles, { withTheme: true })(
  withEnv()(LocationDashboardI10iDetails),
)
