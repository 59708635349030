import Bad from '@material-ui/icons/RemoveCircleOutlineTwoTone'
import Badge from '@material-ui/core/Badge'
import Good from '@material-ui/icons/CheckCircleTwoTone'
import ListItem from '@material-ui/core/ListItem'
import NotInterested from '@material-ui/icons/NotInterestedOutlined'
import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import WarningIcon from '@material-ui/icons/Warning'
import { withStyles } from '@material-ui/styles'

class DeviceHealthPrinter extends React.Component {
  render() {
    const {
      classes,
      rowData: { department, is_renamed },
    } = this.props
    return (
      <ListItem className={classes.listItem}>
        {department === 'HIP' && is_renamed === null && (
          <Tooltip title={'Hostname rename state is unknown'}>
            <Badge
              badgeContent={<NotInterested className={classes.hostnameNull} />}
              className={classes.badge}
            >
              <WarningIcon className={classes.icon} />
            </Badge>
          </Tooltip>
        )}
        {department === 'HIP' && is_renamed === false && (
          <Tooltip title={'Hostname has been validated'}>
            <Badge
              badgeContent={<Good className={classes.good} />}
              className={classes.badge}
            >
              <WarningIcon className={classes.icon} />
            </Badge>
          </Tooltip>
        )}
        {department === 'HIP' && is_renamed === true && (
          <Tooltip title={'Hostname has been renamed, action is required'}>
            <Badge
              badgeContent={<Bad className={classes.bad} />}
              className={classes.badge}
            >
              <WarningIcon className={classes.icon} />
            </Badge>
          </Tooltip>
        )}
        {(department === 'SFS' || department === 'Lexmark') && (
          <Tooltip title={'Not Applicable for SFS/Lexmark Printers'}>
            <Badge
              badgeContent={<NotInterested className={classes.hostnameNull} />}
              className={classes.badge}
            >
              <WarningIcon className={classes.icon} />
            </Badge>
          </Tooltip>
        )}
      </ListItem>
    )
  }
}

const styles = (theme) => ({
  good: {
    fontSize: 16,
    color: 'green',
  },
  hostnameNull: {
    fontSize: 16,
    color: 'grey',
  },
  bad: {
    fontSize: 16,
    color: 'red',
  },
  badge: {
    margin: 4,
  },
  icon: {
    color: '#757575',
    fontSize: 16,
  },
  listItem: {
    paddingLeft: 0,
    paddingRight: 0,
  },
})

export default withStyles(styles, { withTheme: true })(DeviceHealthPrinter)
