import {
  Bar,
  BarChart,
  CartesianGrid,
  Tooltip as ChartTooltip,
  Legend,
  XAxis,
  YAxis,
} from 'recharts'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import CloseIcon from '@material-ui/icons/Close'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import MUIDataTable from 'mui-datatables'
import React from 'react'
import Table from '@material-ui/core/Table'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TopPanelMobileHelp from './TopPanelHelp/TopPanelMobileHelp'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import { withAnalytics } from '@praxis/component-analytics'
import { withStyles } from '@material-ui/styles'

const styles = {
  tableBorder: {
    borderBottom: 0,
    padding: '4px 4px 4px 4px',
  },
  cardContent: {
    paddingBottom: 0,
    paddingTop: 0,
    overflow: 'auto',
    height: '130px',
  },
  cardContentLine: {
    paddingBottom: 0,
    paddingTop: 0,
    maxHeight: '187px',
  },
  cardStyle: {
    maxHeight: '245px',
    overflow: 'auto',
    textAlign: 'center',
    width: '350px',
    paddingRight: 0,
    paddingBottom: 5,
  },
  cardStyleRename: {
    maxHeight: '245px',
    overflow: 'auto',
    textAlign: 'center',
    width: '280px',
    paddingRight: 0,
    paddingBottom: 5,
  },
  listItem: {
    padding: 0,
    color: '#B85300',
  },
  tableRow: {
    display: 'flex',
    minHeight: 275,
    alignContent: 'center',
    justifyContent: 'space-evenly',
  },
  cell: {
    padding: 0,
  },
  root: {
    padding: 'dense',
  },
}

function CustomTooltip() {
  return null
}

function LostStolenChartDialog({ open, onClose, storeLostStolenHistory }) {
  return (
    <React.Fragment>
      <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
        <DialogTitle>Lost Stolen Details </DialogTitle>
        <IconButton
          aria-label="Close"
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        <MUIDataTable
          data={storeLostStolenHistory}
          options={{
            selectableRows: 'none',
            elevation: 0,
            print: false,
            pagination: true,
            rowsPerPage: 20,
            rowsPerPageOptions: [10, 20, 50],
            downloadOptions: {
              filterOptions: {
                useDisplayedColumnsOnly: true,
                useDisplayedRowsOnly: true,
              },
            },
          }}
          columns={[
            {
              name: 'id',
              label: 'ID',
            },
            {
              name: 'lost_event_user',
              label: 'Lost Event User',
            },
            {
              name: 'updated_on',
              label: 'Updated On',
              options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                  return moment(value).format('llll')
                },
              },
            },
            {
              name: 'lost_event_timestamp',
              label: 'Date Marked Lost',
              options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                  return moment(value).format('llll')
                },
              },
            },
          ]}
        />
      </Dialog>
    </React.Fragment>
  )
}

class TopPanelBTScanners extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      lostChartOpen: false,
      lostStolenHistory: [],
    }
    this.handleChartClick = this.handleChartClick.bind(this)
    this.lostStolenHistoryCounted = false
  }

  handleChartClick() {
    this.setState({ lostChartOpen: !this.state.lostChartOpen })
    const eventData = {
      customInteraction: {
        key: `BluetoothScannerLostStolenWidget`,
      },
    }
    this.props.trackEvent(eventData)
  }

  render() {
    const { classes } = this.props
    if (
      this.lostStolenHistoryCounted === false &&
      this.props.storeLostStolenHistory.length !== 0
    ) {
      // if no data, skip mapping over row data and just render zeros
      let lostStolenHistory = [
        {
          month: moment().format('MMMM'),
          count: 0,
          monthNumber: moment().format('YYYYMM'),
        },
        {
          month: moment().subtract(1, 'months').format('MMMM'),
          count: 0,
          monthNumber: moment().subtract(1, 'months').format('YYYYMM'),
        },
        {
          month: moment().subtract(2, 'months').format('MMMM'),
          count: 0,
          monthNumber: moment().subtract(2, 'months').format('YYYYMM'),
        },
        {
          month: moment().subtract(3, 'months').format('MMMM'),
          count: 0,
          monthNumber: moment().subtract(3, 'months').format('YYYYMM'),
        },
        {
          month: moment().subtract(4, 'months').format('MMMM'),
          count: 0,
          monthNumber: moment().subtract(4, 'months').format('YYYYMM'),
        },
        {
          month: moment().subtract(5, 'months').format('MMMM'),
          count: 0,
          monthNumber: moment().subtract(5, 'months').format('YYYYMM'),
        },
        {
          month: moment().subtract(6, 'months').format('MMMM'),
          count: 0,
          monthNumber: moment().subtract(6, 'months').format('YYYYMM'),
        },
        {
          month: moment().subtract(7, 'months').format('MMMM'),
          count: 0,
          monthNumber: moment().subtract(7, 'months').format('YYYYMM'),
        },
        {
          month: moment().subtract(8, 'months').format('MMMM'),
          count: 0,
          monthNumber: moment().subtract(8, 'months').format('YYYYMM'),
        },
        {
          month: moment().subtract(9, 'months').format('MMMM'),
          count: 0,
          monthNumber: moment().subtract(9, 'months').format('YYYYMM'),
        },
        {
          month: moment().subtract(10, 'months').format('MMMM'),
          count: 0,
          monthNumber: moment().subtract(10, 'months').format('YYYYMM'),
        },
        {
          month: moment().subtract(11, 'months').format('MMMM'),
          count: 0,
          monthNumber: moment().subtract(11, 'months').format('YYYYMM'),
        },
      ]
      if (!this.props.storeLostStolenHistory.includes('noData')) {
        this.props.storeLostStolenHistory.forEach((row) => {
          let colMonth = moment(row.lost_event_timestamp).format('YYYYMM')
          Object.values(lostStolenHistory).forEach((monthData) => {
            if (monthData.monthNumber === colMonth) {
              monthData.count++
            }
          })
        })
      }
      this.setState({ lostStolenHistory: lostStolenHistory })
      this.lostStolenHistoryCounted = true
    }
    let months = [
      moment().subtract(2, 'months').format('MMMM'),
      moment().subtract(1, 'months').format('MMMM'),
      moment().format('MMMM'),
    ]
    return (
      <React.Fragment>
        <Table>
          <TableHead>
            <TableRow className={classes.tableRow}>
              <TableCell className={classes.tableBorder}>
                <Card className={classes.cardStyle}>
                  <CardHeader
                    title="Bluetooth Scanners Lost Stolen History"
                    titleTypographyProps={{
                      variant: 'button',
                      gutterBottom: false,
                      fontWeight: 'bold',
                      align: 'center',
                    }}
                  />
                  <CardContent className={classes.cardContent}>
                    {this.props.storeLostStolenHistory.length !== 0 &&
                    this.lostStolenHistoryCounted === true ? (
                      <React.Fragment>
                        <BarChart
                          style={{ cursor: 'pointer' }}
                          data={this.state.lostStolenHistory
                            .filter(
                              (a) =>
                                a.month === months[0] ||
                                a.month === months[1] ||
                                a.month === months[2],
                            )
                            .sort(function (a, b) {
                              return parseInt(a.monthNumber) <
                                parseInt(b.monthNumber)
                                ? -1
                                : 1
                            })}
                          width={300}
                          height={130}
                          margin={{ top: 15, right: 0, left: -35, bottom: 10 }}
                          onClick={() => this.handleChartClick()}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="month" height={40} />
                          <YAxis type="number" ticks={[5, 10]} />
                          <Legend iconType="square" iconSize={10} />
                          <ChartTooltip
                            content={<CustomTooltip />}
                            isAnimationActive={false}
                            position={{ x: 200, y: -55 }}
                          />
                          <Bar
                            fill="#B85300"
                            isAnimationActive={false}
                            dataKey="count"
                            barSize={25}
                            name="Lost or Stolen"
                            label={{
                              position: 'top',
                              fontSize: '0.875rem',
                              fontFamily: 'roboto',
                              color: '#000000',
                              fontWeight: 500,
                            }}
                          />
                        </BarChart>
                      </React.Fragment>
                    ) : (
                      <Typography
                        variant="caption"
                        className={classes.cardStyle}
                      >
                        No Data Available
                      </Typography>
                    )}
                  </CardContent>
                  <TopPanelMobileHelp widgetHelperText="mobileLostStolen" />
                </Card>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
        <MuiThemeProvider theme={this.getMuiTheme()}>
          {!this.props.storeLostStolenHistory.includes('noData') ? (
            <LostStolenChartDialog
              open={this.state.lostChartOpen}
              onClose={() => this.handleChartClick()}
              storeLostStolenHistory={this.props.storeLostStolenHistory}
            />
          ) : (
            <LostStolenChartDialog
              open={this.state.lostChartOpen}
              onClose={() => this.handleChartClick()}
              storeLostStolenHistory={[]}
            />
          )}
        </MuiThemeProvider>
      </React.Fragment>
    )
  }
  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTableBodyCell: {
          root: {
            backgroundColor: '#eeeeee',
            padding: 1,
            paddingLeft: 9,
          },
        },
        MUIDataTableHeadCell: {
          root: {
            backgroundColor: '#eeeeee',
            padding: 0,
            paddingLeft: 18,
          },
        },
        MUIDataTableHeadRow: {
          root: {
            height: 10,
          },
        },
        MUIDataTableBodyRow: {
          root: {
            backgroundColor: '#eeeeee',
            height: 10,
          },
        },
      },
    })
}

export default withStyles(styles, { withTheme: true })(
  withAnalytics()(TopPanelBTScanners),
)
