import CssBaseline from '@material-ui/core/CssBaseline'
import DashboardLocation from './DashboardLocation'
import DashboardPlatformsAndroid from '../DashboardPlatforms/DashboardPlatformsAndroid'
import DashboardPlatformsBTScanners from '../DashboardPlatforms/DashboardPlatformsBTScanners'
// import DashboardPlatformsCamera from '../DashboardPlatforms/DashboardPlatformsCamera'
import DashboardPlatformsComputer from '../DashboardPlatforms/DashboardPlatformsComputer'
import DashboardPlatformsI10iDevices from '../DashboardPlatforms/DashboardPlatformsI10iDevices'
import DashboardPlatformsPrinter from '../DashboardPlatforms/DashboardPlatformsPrinter'
// import DashboardPlatformsRegister from '../DashboardPlatforms/DashboardPlatformsRegister'
import Drawer from '@material-ui/core/Drawer'
import LeftNav from '../LeftNav/LeftNav'
import PropTypes from 'prop-types'
import React from 'react'
import SvcLocationDetails from '../../../services/svcLocationDetails'
import ValidateStoreNumber from '../../../utilities/ValidateStoreNumber'
import classNames from 'classnames'
import l4CamelCase from '../../Devices/I10iExports/l4CamelCase'
import queryString from 'query-string'
import { withAnalytics } from '@praxis/component-analytics'
import { withAuth } from '@praxis/component-auth'
import { withEnv } from '@praxis/component-runtime-env'
import withRouter from '../../withRouter'
import { withStyles } from '@material-ui/styles'
// import { withRouter } from '../../withRouter'

class DashboardMain extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      collapsedRow: false,
      customFilters: [],
      customFiltersPrinters: [],
      filterGiftRegistry: false,
      filterGiftRegistryScanner: false,
      filterGiftRegistryKiosk: false,
      filtermyCheckout: false,
      filtermyDevice: false,
      filterMyKiosk: false,
      filterPaymentKiosk: false,
      filterPML: false,
      filterTimeclock: false,
      filterVisualMerchandiser: false,
      filterDirectStoreDeliveryKiosk: false,
      filterHIP: false,
      filterSFS: false,
      filterLexmark: false,
      filterScangun: false,
      filterDCHRTablet: false,
      filterAPTablet: false,
      filterDCTimeclock: false,
      filterICQATablet: false,
      filterYardTruckTablet: false,
      filterDCFKTablet: false,
      filterDCOLETablet: false,
      filterADWTablet: false,
      filters: [],
      filterServiceKiosk: false,
      locationFullDetails: [],
      capabilities: [],
      lostStolenDeviceLocationInfo: null,
      lostStolenDeviceMacAddress: undefined,
      lostStolenDeviceNumber: undefined,
      lostStolenDevicePlatform: undefined,
      lostStolenDialogOpen: false,
      open: false,
      platform: 'android',
      storeFullDetails: [],
      storeReport: [],
    }
    this.svcLocationDetails = new SvcLocationDetails()
    this.validateStoreNumber = new ValidateStoreNumber()
    this.handleFiltersState = this.handleFiltersState.bind(this)
    this.handleSideNavDrawerCallback =
      this.handleSideNavDrawerCallback.bind(this)
    this.handleSideNavPlatformSelectCallback =
      this.handleSideNavPlatformSelectCallback.bind(this)
    this.handleFiltersChangeCallback =
      this.handleFiltersChangeCallback.bind(this)
    this.handleCollapsedRowCallback = this.handleCollapsedRowCallback.bind(this)
    this.dashboardPlatform = {
      android: function () {
        return (
          <DashboardPlatformsAndroid
            collapsedRow={this.state.collapsedRow}
            customFilters={this.state.customFilters}
            filterGiftRegistry={this.state.filterGiftRegistry}
            filterGiftRegistryScanner={this.state.filterGiftRegistryScanner}
            filterGiftRegistryKioskr={this.state.filterGiftRegistryKiosk}
            filterMobilePrinter={this.state.filterMobilePrinter}
            filtermyCheckout={this.state.filtermyCheckout}
            filtermyDevice={this.state.filtermyDevice}
            filterMyKiosk={this.state.filterMyKiosk}
            filterPaymentKiosk={this.state.filterPaymentKiosk}
            filterPML={this.state.filterPML}
            filterTimeclock={this.state.filterTimeclock}
            filterVisualMerchandiser={this.state.filterVisualMerchandiser}
            filterDirectStoreDeliveryKiosk={
              this.state.filterDirectStoreDeliveryKiosk
            }
            filterServiceKiosk={this.state.filterServiceKiosk}
            filterScangun={this.state.filterScangun}
            filterDCHRTablet={this.state.filterDCHRTablet}
            filterAPTablet={this.state.filterAPTablet}
            filterDCTimeclock={this.state.filterDCTimeclock}
            filterICQATablet={this.state.filterICQATablet}
            filterYardTruckTablet={this.state.filterYardTruckTablet}
            filterDCFKTablet={this.state.filterDCFKTablet}
            filterDCOLETablet={this.state.filterDCOLETablet}
            filterADWTablet={this.state.filterADWTablet}
            handleCollapsedRow={this.handleCollapsedRowCallback}
            handleFiltersState={this.handleFiltersState}
            handleLostStolenClick={this.handleLostStolenClick}
            platform={this.state.platform}
            locationFullDetails={this.state.locationFullDetails}
            storeBatteryIssues={this.state.storeBatteryIssues}
            storeDevices={this.state.storeDevices}
            locationId={this.props.router.params.locationid}
          />
        )
      }.bind(this),
      printer: function () {
        return (
          <DashboardPlatformsPrinter
            collapsedRow={this.state.collapsedRow}
            customFiltersPrinters={this.state.customFiltersPrinters}
            handleCollapsedRow={this.handleCollapsedRowCallback}
            handleFiltersState={this.handleFiltersState}
            filterSFS={this.state.filterSFS}
            filterHIP={this.state.filterHIP}
            filterLexmark={this.state.filterLexmark}
            platform={this.state.platform}
            locationId={this.props.router.params.locationid}
          />
        )
      }.bind(this),
      computer: function () {
        return (
          <DashboardPlatformsComputer
            collapsedRow={this.state.collapsedRow}
            // customFiltersComputers={this.state.customFiltersComputers}
            handleCollapsedRow={this.handleCollapsedRowCallback}
            handleFiltersState={this.handleFiltersState}
            // filterMacOSX={this.state.filterMacOSX}
            // filterMicrosoftWindows10Pro={this.state.filterMicrosoftWindows10Pro}
            platform={this.state.platform}
            locationId={this.props.router.params.locationid}
          />
        )
      }.bind(this),
      // camera: function () {
      //   return (
      //     <DashboardPlatformsCamera
      //       collapsedRow={this.state.collapsedRow}
      //       handleCollapsedRow={this.handleCollapsedRowCallback}
      //       handleFiltersState={this.handleFiltersState}
      //       platform={this.state.platform}
      //       storeCameras={this.state.storeCameras}
      //       cameraDeviceTypeAnalog={this.state.cameraDeviceTypeAnalog}
      //       cameraDeviceTypeIP={this.state.cameraDeviceTypeIP}
      //       locationId={this.props.router.params.locationid}
      //     />
      //   )
      // }.bind(this),
      bluetoothscanner: function () {
        return (
          <DashboardPlatformsBTScanners
            collapsedRow={this.state.collapsedRow}
            handleCollapsedRow={this.handleCollapsedRowCallback}
            handleFiltersState={this.handleFiltersState}
            platform={this.state.platform}
            storeBTScanners={this.state.storeBTScanners}
            locationId={this.props.router.params.locationid}
          />
        )
      }.bind(this),
      ups: function () {
        return (
          <DashboardPlatformsI10iDevices
            key={this.state.platform}
            collapsedRow={this.state.collapsedRow}
            handleCollapsedRow={this.handleCollapsedRowCallback}
            handleFiltersState={this.handleFiltersState}
            platform={this.state.platform}
            i10iDeviceTitle="UPS"
            i10iDeviceTypeList={['ups']}
            locationId={this.props.router.params.locationid}
          />
        )
      }.bind(this),
      ats: function () {
        return (
          <DashboardPlatformsI10iDevices
            key={this.state.platform}
            collapsedRow={this.state.collapsedRow}
            handleCollapsedRow={this.handleCollapsedRowCallback}
            handleFiltersState={this.handleFiltersState}
            platform={this.state.platform}
            i10iDeviceTitle="ATS"
            i10iDeviceTypeList={['ats']}
            locationId={this.props.router.params.locationid}
          />
        )
      }.bind(this),
      pdu: function () {
        return (
          <DashboardPlatformsI10iDevices
            key={this.state.platform}
            collapsedRow={this.state.collapsedRow}
            handleCollapsedRow={this.handleCollapsedRowCallback}
            handleFiltersState={this.handleFiltersState}
            platform={this.state.platform}
            i10iDeviceTitle="PDU"
            i10iDeviceTypeList={['pdu']}
            locationId={this.props.router.params.locationid}
          />
        )
      }.bind(this),
      generator: function () {
        return (
          <DashboardPlatformsI10iDevices
            key={this.state.platform}
            collapsedRow={this.state.collapsedRow}
            handleCollapsedRow={this.handleCollapsedRowCallback}
            handleFiltersState={this.handleFiltersState}
            platform={this.state.platform}
            i10iDeviceTitle="Generator"
            i10iDeviceTypeList={['generator']}
            locationId={this.props.router.params.locationid}
          />
        )
      }.bind(this),
      digitalMediaPlayer: function () {
        return (
          <DashboardPlatformsI10iDevices
            key={this.state.platform}
            collapsedRow={this.state.collapsedRow}
            handleCollapsedRow={this.handleCollapsedRowCallback}
            handleFiltersState={this.handleFiltersState}
            platform={this.state.platform}
            i10iDeviceTitle="Digital Media Player"
            i10iDeviceTypeList={['digitalMediaPlayer']}
            locationId={this.props.router.params.locationid}
          />
        )
      }.bind(this),
      baremetalServer: function () {
        return (
          <DashboardPlatformsI10iDevices
            key={this.state.platform}
            collapsedRow={this.state.collapsedRow}
            handleCollapsedRow={this.handleCollapsedRowCallback}
            handleFiltersState={this.handleFiltersState}
            platform={this.state.platform}
            i10iDeviceTitle="Baremetal Server"
            i10iDeviceTypeList={['baremetalServer']}
            locationId={this.props.router.params.locationid}
          />
        )
      }.bind(this),
      chassis: function () {
        return (
          <DashboardPlatformsI10iDevices
            key={this.state.platform}
            collapsedRow={this.state.collapsedRow}
            handleCollapsedRow={this.handleCollapsedRowCallback}
            handleFiltersState={this.handleFiltersState}
            platform={this.state.platform}
            i10iDeviceTitle="Chassis"
            i10iDeviceTypeList={['chassis']}
            locationId={this.props.router.params.locationid}
          />
        )
      }.bind(this),
      doorAccessControl: function () {
        return (
          <DashboardPlatformsI10iDevices
            key={this.state.platform}
            collapsedRow={this.state.collapsedRow}
            handleCollapsedRow={this.handleCollapsedRowCallback}
            handleFiltersState={this.handleFiltersState}
            platform={this.state.platform}
            i10iDeviceTitle="Door Access Control"
            i10iDeviceTypeList={['doorAccessControl']}
            locationId={this.props.router.params.locationid}
          />
        )
      }.bind(this),
      register: function () {
        return (
          <DashboardPlatformsI10iDevices
            key={this.state.platform}
            collapsedRow={this.state.collapsedRow}
            handleCollapsedRow={this.handleCollapsedRowCallback}
            handleFiltersState={this.handleFiltersState}
            platform={this.state.platform}
            i10iDeviceTitle="Register"
            i10iDeviceTypeList={['register']}
            locationId={this.props.router.params.locationid}
          />
        )
      }.bind(this),
      broadcast: function () {
        return (
          <DashboardPlatformsI10iDevices
            key={this.state.platform}
            collapsedRow={this.state.collapsedRow}
            handleCollapsedRow={this.handleCollapsedRowCallback}
            handleFiltersState={this.handleFiltersState}
            platform={this.state.platform}
            i10iDeviceTitle="Broadcast"
            i10iDeviceTypeList={['broadcast']}
            locationId={this.props.router.params.locationid}
          />
        )
      }.bind(this),
      scale: function () {
        return (
          <DashboardPlatformsI10iDevices
            key={this.state.platform}
            collapsedRow={this.state.collapsedRow}
            handleCollapsedRow={this.handleCollapsedRowCallback}
            handleFiltersState={this.handleFiltersState}
            platform={this.state.platform}
            i10iDeviceTitle="Retail Scale"
            i10iDeviceTypeList={['scale']}
            locationId={this.props.router.params.locationid}
          />
        )
      }.bind(this),
      phone: function () {
        return (
          <DashboardPlatformsI10iDevices
            key={this.state.platform}
            collapsedRow={this.state.collapsedRow}
            handleCollapsedRow={this.handleCollapsedRowCallback}
            handleFiltersState={this.handleFiltersState}
            platform={this.state.platform}
            i10iDeviceTitle="Phone"
            i10iDeviceTypeList={['phone']}
            locationId={this.props.router.params.locationid}
          />
        )
      }.bind(this),
      /*
      This needs to be addressed at some point in the future
      printer: function () {
        return (
          <DashboardPlatformsI10iDevices
            key={this.state.platform}
            collapsedRow={this.state.collapsedRow}
            handleCollapsedRow={this.handleCollapsedRowCallback}
            handleFiltersState={this.handleFiltersState}
            platform={this.state.platform}
            i10iDeviceTitle="Printer"
            i10iDeviceTypeList={['printer']}
            locationId={this.props.router.params.locationid}
          />
        )
      }.bind(this),
      */
      networkAccessPoint: function () {
        return (
          <DashboardPlatformsI10iDevices
            key={this.state.platform}
            collapsedRow={this.state.collapsedRow}
            handleCollapsedRow={this.handleCollapsedRowCallback}
            handleFiltersState={this.handleFiltersState}
            platform={this.state.platform}
            i10iDeviceTitle="Network Access Point"
            i10iDeviceTypeList={['networkAccessPoint']}
            locationId={this.props.router.params.locationid}
          />
        )
      }.bind(this),
      networkGateway: function () {
        return (
          <DashboardPlatformsI10iDevices
            key={this.state.platform}
            collapsedRow={this.state.collapsedRow}
            handleCollapsedRow={this.handleCollapsedRowCallback}
            handleFiltersState={this.handleFiltersState}
            platform={this.state.platform}
            i10iDeviceTitle="Network Gateway"
            i10iDeviceTypeList={['networkGateway']}
            locationId={this.props.router.params.locationid}
          />
        )
      }.bind(this),
      networkSwitch: function () {
        return (
          <DashboardPlatformsI10iDevices
            key={this.state.platform}
            collapsedRow={this.state.collapsedRow}
            handleCollapsedRow={this.handleCollapsedRowCallback}
            handleFiltersState={this.handleFiltersState}
            platform={this.state.platform}
            i10iDeviceTitle="Network Switch"
            i10iDeviceTypeList={['networkSwitch']}
            locationId={this.props.router.params.locationid}
          />
        )
      }.bind(this),
      networkChassis: function () {
        return (
          <DashboardPlatformsI10iDevices
            key={this.state.platform}
            collapsedRow={this.state.collapsedRow}
            handleCollapsedRow={this.handleCollapsedRowCallback}
            handleFiltersState={this.handleFiltersState}
            platform={this.state.platform}
            i10iDeviceTitle="Network Chassis"
            i10iDeviceTypeList={['networkChassis']}
            locationId={this.props.router.params.locationid}
          />
        )
      }.bind(this),
      networkManagement: function () {
        return (
          <DashboardPlatformsI10iDevices
            key={this.state.platform}
            collapsedRow={this.state.collapsedRow}
            handleCollapsedRow={this.handleCollapsedRowCallback}
            handleFiltersState={this.handleFiltersState}
            platform={this.state.platform}
            i10iDeviceTitle="Network Management"
            i10iDeviceTypeList={['networkManagement']}
            locationId={this.props.router.params.locationid}
          />
        )
      }.bind(this),
      networkRouter: function () {
        return (
          <DashboardPlatformsI10iDevices
            key={this.state.platform}
            collapsedRow={this.state.collapsedRow}
            handleCollapsedRow={this.handleCollapsedRowCallback}
            handleFiltersState={this.handleFiltersState}
            platform={this.state.platform}
            i10iDeviceTitle="Network Router"
            i10iDeviceTypeList={['networkRouter']}
            locationId={this.props.router.params.locationid}
          />
        )
      }.bind(this),
      networkTrafficManagement: function () {
        return (
          <DashboardPlatformsI10iDevices
            key={this.state.platform}
            collapsedRow={this.state.collapsedRow}
            handleCollapsedRow={this.handleCollapsedRowCallback}
            handleFiltersState={this.handleFiltersState}
            platform={this.state.platform}
            i10iDeviceTitle="Network Traffic Management"
            i10iDeviceTypeList={['networkTrafficManagement']}
            locationId={this.props.router.params.locationid}
          />
        )
      }.bind(this),
      submeteringControls: function () {
        return (
          <DashboardPlatformsI10iDevices
            key={this.state.platform}
            collapsedRow={this.state.collapsedRow}
            handleCollapsedRow={this.handleCollapsedRowCallback}
            handleFiltersState={this.handleFiltersState}
            platform={this.state.platform}
            i10iDeviceTitle="Submetering Controls"
            i10iDeviceTypeList={['submeteringControls']}
            locationId={this.props.router.params.locationid}
          />
        )
      }.bind(this),
      rfidHandheld: function () {
        return (
          <DashboardPlatformsI10iDevices
            key={this.state.platform}
            collapsedRow={this.state.collapsedRow}
            handleCollapsedRow={this.handleCollapsedRowCallback}
            handleFiltersState={this.handleFiltersState}
            platform={this.state.platform}
            i10iDeviceTitle="RFID Handheld"
            i10iDeviceTypeList={['rfidHandheld']}
            locationId={this.props.router.params.locationid}
          />
        )
      }.bind(this),
      voiceGateway: function () {
        return (
          <DashboardPlatformsI10iDevices
            key={this.state.platform}
            collapsedRow={this.state.collapsedRow}
            handleCollapsedRow={this.handleCollapsedRowCallback}
            handleFiltersState={this.handleFiltersState}
            platform={this.state.platform}
            i10iDeviceTitle="Voice Gateway"
            i10iDeviceTypeList={['voiceGateway']}
            locationId={this.props.router.params.locationid}
          />
        )
      }.bind(this),

      // register: function () {
      //   return (
      //     <DashboardPlatformsRegister
      //       collapsedRow={this.state.collapsedRow}
      //       handleCollapsedRow={this.handleCollapsedRowCallback}
      //       handleFiltersState={this.handleFiltersState}
      //       platform={this.state.platform}
      //       storeRegisters={this.state.storeRegisters}
      //       locationId={this.props.router.params.locationid}
      //       locationFullDetails={this.state.locationFullDetails}
      //       capabilities={this.state.capabilities}
      //     />
      //   )
      // }.bind(this),
    }
  }

  handleSideNavDrawerCallback() {
    this.setState({ open: !this.state.open })
  }
  handleSideNavPlatformSelectCallback(platform) {
    this.setState({ platform: platform })
  }
  handleCollapsedRowCallback() {
    this.setState({ collapsedRow: !this.state.collapsedRow })
  }

  handleFiltersState(filterList) {
    if (this.state.platform === 'android') {
      var baseState = this.state
      baseState['customFilters'] = []
      Object.keys(baseState).map((key) => {
        if (key.indexOf('filter') === 0 && key !== 'filters') {
          baseState[key] = false
        }
        return null
      })
      // column number that contains device type
      filterList[8].map((filter) => {
        // if filter not a side nav, we still need to handle it
        // import and update device filter map
        if (filter) {
          const filterName = 'filter' + filter.replace(/\s/g, '')
          if (filterName in this.state) {
            baseState[filterName] = true
          } else {
            baseState.customFilters.push(filter)
          }
        }
        return null
      })
      this.setState(baseState)
    }
    if (this.state.platform === 'printer') {
      var baseStatePrinters = this.state
      baseStatePrinters['customFiltersPrinters'] = []
      Object.keys(baseStatePrinters).map((key) => {
        if (key.indexOf('filter') === 0 && key !== 'filters') {
          baseStatePrinters[key] = false
        }
        return null
      })
      // column number that contains device type
      filterList[9].map((filter) => {
        // if filter not a side nav, we still need to handle it
        // import and update device filter map
        if (filter) {
          const filterName = 'filter' + filter.replace(/\s/g, '')
          if (filterName in this.state) {
            baseStatePrinters[filterName] = true
          } else {
            baseStatePrinters.customFiltersPrinters.push(filter)
          }
        }
        return null
      })
      this.setState(baseStatePrinters)
    }
    // hello world 123
    // if (this.state.platform === 'computer') {
    //   var baseStateComputers = this.state
    //   baseStateComputers['customFiltersComputers'] = []
    //   Object.keys(baseStateComputers).map((key) => {
    //     if (key.indexOf('filter') === 0 && key !== 'filters') {
    //       baseStateComputers[key] = false
    //     }
    //     return null
    //   })
    //   // column number that contains device type
    //   filterList[4].map((filter) => {
    //     // if filter not a side nav, we still need to handle it
    //     // import and update device filter map
    //     if (filter) {
    //       const filterName = 'filter' + filter.replace(/\s/g, '')
    //       if (filterName in this.state) {
    //         baseStateComputers[filterName] = true
    //       } else {
    //         baseStateComputers.customFiltersComputers.push(filter)
    //       }
    //     }
    //     return null
    //   })
    //   this.setState(baseStateComputers)
    // }
  }

  handleFiltersChangeCallback(name, platform) {
    this.setState({ [name]: !this.state[name] })
    this.state.platform !== platform && this.setState({ platform: platform })
  }

  componentDidMount() {
    let locationId = this.validateStoreNumber.validateStoreNumber(
      this.props.router.params.locationid,
    )
    let params = queryString.parse(this.props.router.location.search)
    if (
      params &&
      (params.platform === 'handheld' || params.platform === 'tablet')
    ) {
      this.handleSideNavPlatformSelectCallback('android')
    }
    // if (params && params.platform === 'camera') {
    //   this.handleSideNavPlatformSelectCallback('camera')
    // }

    if (params && params.platform === 'printer') {
      this.handleSideNavPlatformSelectCallback('printer')
    }
    if (
      params &&
      (params.platform === 'computer' ||
        params.platform === 'windows' ||
        params.platform === 'mac')
    ) {
      this.handleSideNavPlatformSelectCallback('computer')
    }
    if (params && params.platform === 'bluetoothscanner') {
      this.handleSideNavPlatformSelectCallback('bluetoothscanner')
    }

    /*I10i handlers */

    if (params && l4CamelCase.includes(params.platform)) {
      this.handleSideNavPlatformSelectCallback(params.platform)
    }

    if (locationId) {
      this.svcLocationDetails
        .getLocationDetails(locationId, this.props.env)
        .then((response) => {
          const eventData = {
            customMetrics: {
              metric1: 'LocationDetails',
              metric3: response.status,
            },
            event: {
              type: `apiSuccessLocationDetails`,
            },
          }
          this.props.trackEvent(eventData)
          this.setState({
            locationFullDetails: response.data[0],
            capabilities: response.data[0].capabilities,
          })
        })
        .catch((error) => {
          if (error && error.response) {
            const eventData = {
              customMetrics: {
                metric1: 'LocationDetails',
                metric3: error.response.status,
              },
              event: {
                type: `apiErrorLocationDetails`,
              },
            }
            this.props.trackEvent(eventData)
            if (
              error.response.status === 500 ||
              error.response.status === 503
            ) {
              window.location =
                window.location.origin + '/?internal_server_error'
            }
          }
        })
    } else {
      this.props.history.push('/?store_not_found')
    }
  }

  render() {
    const { classes } = this.props
    const { platform, locationFullDetails } = this.state
    return (
      <div className={classes.root}>
        <CssBaseline />
        <div
          className={classNames(classes.content, {
            [classes.contentShift]: this.state.open,
          })}
        >
          {/* BEGIN - components for main */}
          <DashboardLocation locationFullDetails={locationFullDetails} />
          {this.dashboardPlatform[platform]()}
          {/* END - components for main */}
        </div>

        {/* BEGIN - components for left navigation */}
        <Drawer
          anchor="left"
          variant="permanent"
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: this.state.open,
            [classes.drawerClose]: !this.state.open,
          })}
          classes={{
            paper: classNames({
              [classes.drawerOpen]: this.state.open,
              [classes.drawerClose]: !this.state.open,
            }),
          }}
          open={this.state.open}
        >
          <div className={classes.drawerHeader} />
          <LeftNav
            locationFullDetails={locationFullDetails}
            dataSideNavCallBack={this.handleSideNavDrawerCallback}
            dataSideNavPlatformCallBack={
              this.handleSideNavPlatformSelectCallback
            }
            open={this.state.open}
            dataFiltersCallBack={this.handleFiltersChangeCallback}
          />
        </Drawer>
        {/* END - components for left navigation */}
      </div>
    )
  }
}

DashboardMain.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}
const drawerWidth = 275

const styles = (theme) => ({
  root: {
    // display: 'flex',
  },
  honeyComb: {
    minHeight: `calc(100vh - 75px)`,
    backgroundImage:
      `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='24.5' ` +
      `viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%23888888' fill-opacity='0.50' ` +
      `fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 ` +
      `11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 ` +
      `0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    boxShadow: 'none',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    whiteSpace: 'nowrap',
    marginLeft: drawerWidth,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    whiteSpace: 'nowrap',
    width: theme.spacing(7 + 1),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(11),
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
    paddingTop: 78,
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: drawerWidth - 185,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  },
  tableRow: {
    cursor: 'cursor',
    width: 'flex',
    paddingLeft: 5,
    height: 30,
  },
  titles: {
    textAlign: 'left',
    paddingTop: 5,
    paddingLeft: 15,
    fontSize: 24,
  },
  metrics: {
    paddingLeft: 14,
  },
})

export default withStyles(styles, { withTheme: true })(
  withAuth(null)(withRouter(withEnv()(withAnalytics()(DashboardMain)))),
)
