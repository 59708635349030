import AppBar from '@material-ui/core/AppBar'
import Avatar from '@material-ui/core/Avatar'
import ChevronLeft from '@material-ui/icons/SubdirectoryArrowLeft'
import ChevronRight from '@material-ui/icons/SubdirectoryArrowRight'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'
import React from 'react'
import Spinner from 'nicollet-react/es/components/Spinner/Spinner'
import Tab from '@material-ui/core/Tab'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Tabs from '@material-ui/core/Tabs'
import Tooltip from '@material-ui/core/Tooltip'
import { createMuiTheme } from '@material-ui/core/styles'
import svcDevices from '../../services/svcDevices'
import { withAnalytics } from '@praxis/component-analytics'
import { withEnv } from '@praxis/component-runtime-env'
import { withStyles } from '@material-ui/styles'

class AppBarAvatar extends React.Component {
  render() {
    return (
      <List
        style={{
          borderRight: '1px white solid',
          cursor: this.props.disabled ? 'cursor' : 'pointer',
          paddingTop: 0,
          paddingBottom: 0,
        }}
        onClick={this.props.disabled ? null : this.props.onClick}
        disabled={this.props.disabled ? this.props.disabled : false}
      >
        <ListItem>
          <ListItemAvatar>
            <Avatar>{this.props.label.slice(0, 1)}</Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={this.props.label}
            primaryTypographyProps={{ style: { color: '#fafafa' } }}
          />
        </ListItem>
      </List>
    )
  }
}

class GridPlatforms extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      tabLabel: null,
      tabLabelRegion: 'Regions',
      tabLabelGroup: 'Groups',
      tabLabelDistrict: 'Districts',
      tabLabelLocation: 'Locations',
      tabValue: 0,
      tabData: {},
      tabDataGroup: {},
      tabDataDistrict: {},
      tabDataLocation: {},
      tabDisabledGroup: true,
      tabDisabledDistrict: true,
      tabDisabledLocation: true,
    }
    this.svcDevices = new svcDevices()
    this.counts = []
    this.totalCount = []
    this.regionZero = []
    this.regionValid = []
  }

  handleChange = (event, tabValue) => {
    this.setState({ tabValue })
  }

  tabClick = (tabValue) => {
    tabValue === 0 && this.regionRowClick()
    tabValue === 1 && this.groupRowClick()
    tabValue === 2 && this.districtRowClick()
    // tabValue === 3 && this.locationRowClick()
  }
  regionRowClick = (row, index) => {
    row
      ? this.setState({
          tabLabelRegion: 'Region ' + row.region_id,
          tabDataGroup: row.groups,
          tabValue: 1,
          tabDisabledGroup: false,
        })
      : this.setState({
          tabLabelRegion: 'Regions',
          tabDataGroup: {},
          tabValue: 0,
          tabDisabledGroup: true,
          tabLabelGroup: 'Groups',
          tabDataDistrict: {},
          tabDisabledDistrict: true,
          tabLabelDistrict: 'Districts',
          tabDataLocation: {},
          tabDisabledLocation: true,
        })
  }

  groupRowClick = (row, index) => {
    row
      ? this.setState({
          tabLabelGroup: 'Group ' + row.group_id,
          tabDataDistrict: row.districts,
          tabValue: 2,
          tabDisabledDistrict: false,
        })
      : this.setState({
          tabValue: 1,
          tabLabelGroup: 'Groups',
          tabDataDistrict: {},
          tabDisabledDistrict: true,
          tabLabelDistrict: 'Districts',
          tabDataLocation: {},
          tabDisabledLocation: true,
        })
  }

  districtRowClick = (row, index) => {
    row
      ? this.setState({
          tabLabelDistrict: 'District ' + row.district_id,
          tabDataLocation: row.locations,
          tabValue: 3,
          tabDisabledLocation: true,
        })
      : this.setState({
          tabValue: 2,
          tabLabelDistrict: 'Districts',
          tabLabelLocation: 'Locations',
          tabDataLocation: {},
          tabDisabledLocation: true,
        })
  }
  componentDidMount() {
    this.setState({ deviceCountsLoading: true })
    this.svcDevices
      .getDeviceCounts(this.props.env, {
        count_url: this.props.gridDetails.modal_properties.api,
        count_parameters:
          this.props.gridDetails.modal_properties.api_parameters,
      })
      .then((response) => {
        const eventData = {
          customMetrics: {
            metric1: 'DeviceTypeCount',
            metric3: response.status,
          },
          event: {
            type: `apiSuccessDeviceTypeCount`,
          },
        }
        this.props.trackEvent(eventData)
        response.data.flatMap((row, index) =>
          row.region_id === 0
            ? this.regionZero.push(row)
            : row && this.regionValid.push(row),
        )
      })
      .catch((error) => {
        const eventData = {
          customMetrics: {
            metric1: 'DeviceTypeCount',
            metric3: error.response.status,
          },
          event: {
            type: `apiErrorDeviceTypeCount`,
          },
        }
        this.props.trackEvent(eventData)
        console.log(error)
      })
      .finally(() => {
        this.setState({ deviceCountsLoading: false })
      })
  }
  render() {
    const { classes } = this.props
    return this.state.deviceCountsLoading ? (
      <Spinner layout="selfCentering" />
    ) : (
      <>
        <AppBar
          position="static"
          elevation={2}
          square={false}
          style={{ background: '#999' }}
        >
          <Tabs value={this.state.tabValue} onChange={this.handleChange}>
            <Tab
              component={() => (
                <AppBarAvatar
                  label={this.state.tabLabelRegion}
                  onClick={() => this.tabClick(0)}
                />
              )}
            ></Tab>
            <Tab
              component={() => (
                <AppBarAvatar
                  label={this.state.tabLabelGroup}
                  disabled={this.state.tabDisabledGroup}
                  onClick={() => this.tabClick(1)}
                />
              )}
            />
            <Tab
              component={() => (
                <AppBarAvatar
                  label={this.state.tabLabelDistrict}
                  disabled={this.state.tabDisabledDistrict}
                  onClick={() => this.tabClick(2)}
                />
              )}
            />
            <Tab
              component={() => (
                <AppBarAvatar
                  label={this.state.tabLabelLocation}
                  disabled={this.state.tabDisabledLocation}
                />
              )}
            />
            <Tab
              style={{
                background: 'black',
                borderRadius: 5,
              }}
              label="No RGD"
            />
          </Tabs>
        </AppBar>

        {this.state.tabValue === 0 && this.regionValid && (
          <Paper elevation={1}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Region Id</TableCell>
                  <TableCell>Count</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.regionValid.flatMap((row, index) => {
                  return (
                    <Tooltip
                      key={index}
                      title={'Expand Region ' + row.region_id}
                    >
                      <TableRow
                        className={classes.cell}
                        hover
                        onClick={() => this.regionRowClick(row, index)}
                      >
                        <TableCell>
                          <ChevronRight style={{ color: '#666' }} />
                        </TableCell>
                        <TableCell>{row.region_id}</TableCell>
                        <TableCell>{row.count}</TableCell>
                      </TableRow>
                    </Tooltip>
                  )
                })}
              </TableBody>
            </Table>
          </Paper>
        )}
        {this.state.tabValue === 1 && (
          <Paper elevation={1}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Group Id</TableCell>
                  <TableCell>Count</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.tabDataGroup.flatMap((row, index) => {
                  return (
                    <Tooltip key={index} title={'Expand Group ' + row.group_id}>
                      <TableRow
                        className={classes.cell}
                        hover
                        onClick={() => this.groupRowClick(row, index)}
                      >
                        <TableCell>
                          <ChevronRight style={{ color: '#666' }} />
                        </TableCell>
                        <TableCell>{row.group_id}</TableCell>
                        <TableCell>{row.count}</TableCell>
                      </TableRow>
                    </Tooltip>
                  )
                })}
              </TableBody>
            </Table>
            <Tooltip title="back">
              <IconButton onClick={() => this.tabClick(0)}>
                <ChevronLeft />
              </IconButton>
            </Tooltip>
          </Paper>
        )}
        {this.state.tabValue === 2 && (
          <Paper elevation={1}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>District Id</TableCell>
                  <TableCell>Count</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.tabDataDistrict.flatMap((row, index) => {
                  return (
                    <Tooltip
                      key={index}
                      title={'Expand District ' + row.district_id}
                    >
                      <TableRow
                        className={classes.cell}
                        hover
                        onClick={() => this.districtRowClick(row, index)}
                      >
                        <TableCell>
                          <ChevronRight style={{ color: '#666' }} />
                        </TableCell>
                        <TableCell>{row.district_id}</TableCell>
                        <TableCell>{row.count}</TableCell>
                      </TableRow>
                    </Tooltip>
                  )
                })}
              </TableBody>
            </Table>
            <Tooltip title="back">
              <IconButton onClick={() => this.tabClick(1)}>
                <ChevronLeft />
              </IconButton>
            </Tooltip>
          </Paper>
        )}
        {this.state.tabValue === 3 && (
          <Paper elevation={1}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Location Id</TableCell>
                  <TableCell>Count</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.tabDataLocation.flatMap((row, index) => {
                  return (
                    <TableRow className={classes.cell} key={index} hover>
                      <TableCell />
                      <TableCell>{row.location_id}</TableCell>
                      <TableCell>{row.count}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
            <Tooltip title="back">
              <IconButton onClick={() => this.tabClick(2)}>
                <ChevronLeft />
              </IconButton>
            </Tooltip>
          </Paper>
        )}
        {this.state.tabValue === 4 && this.regionZero.length > 0 && (
          <Paper elevation={1}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Location Id</TableCell>
                  <TableCell>Count</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.regionZero[0].groups[0].districts[0].locations.flatMap(
                  (row, index) => {
                    return (
                      <TableRow className={classes.cell} key={index}>
                        <TableCell>{row.location_id}</TableCell>
                        <TableCell>{row.count}</TableCell>
                      </TableRow>
                    )
                  },
                )}
              </TableBody>
            </Table>
          </Paper>
        )}
      </>
    )
  }

  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MuiIconButton: {
          root: {
            padding: 0,
          },
        },
        MUIDataTableBodyCell: {
          root: {
            backgroundColor: '#eeeeee',
            height: 10,
          },
        },
        MUIDataTableHeadRow: {
          root: {
            height: 10,
          },
        },
        MUIDataTableBodyRow: {
          root: {
            backgroundColor: '#eeeeee',
            height: 10,
          },
        },
        MUIDataTableToolbar: {
          root: {
            backgroundColor: '#eeeeee',
            height: 10,
          },
          titleText: {
            fontSize: 14,
          },
          left: {
            flex: '100 0 auto',
          },
        },
        MuiPaper: {
          root: {
            minWidth: '400',
            paddingLeft: '0',
          },
        },
      },
    })
}

const styles = (theme) => ({
  root: {
    backgroundColor: '#eeeeee',
    padding: 'dense',
    fontSize: '40pt',
  },
  cell: {
    height: 10,
  },
  honeyComb: {
    minHeight: `calc(100vh - 75px)`,
    backgroundImage:
      `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='24.5' ` +
      `viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%23888888' fill-opacity='0.50' ` +
      `fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 ` +
      `11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 ` +
      `0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
  },
})

export default withStyles(styles, { withTheme: true })(
  withAnalytics()(withEnv()(GridPlatforms)),
)
