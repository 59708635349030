import { Card, CardContent, CardHeader, Typography } from '@material-ui/core'
import { Cell, Tooltip as ChartTooltip, Legend, Pie, PieChart } from 'recharts'

import Label from '@material-ui/icons/Label'
import React from 'react'
import Spinner from 'nicollet-react/es/components/Spinner/Spinner'
import svcDevices from '../../../services/svcDevices'
import { withEnv } from '@praxis/component-runtime-env'
import { withStyles } from '@material-ui/styles'

class TopPanelDC extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stateDataLoading: false,
      isDeviceState: false,
      submission: {
        classification_name: 'handheld',
        field_name: 'device_state',
        location_id: '551',
      },
    }
    this.counts = []
    this.svcDevices = new svcDevices()
  }

  componentDidMount() {
    this.setState({ stateDataLoading: true })
    this.svcDevices
      .getDeviceCountsLocation(this.props.env, {
        classification_name: this.props.classification_name,
        field_name: this.props.field_name,
        location_id: this.props.location_id,
      })
      .then((response) => {
        Object.entries(response.data)
          .sort(function (a, b) {
            return a[1] > b[1] ? -1 : 1
          })
          .flatMap(async (row, index) => {
            this.counts.push({ name: row[0], value: row[1] })
          })
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        this.setState({ stateDataLoading: false })
      })
  }
  render() {
    const COLORS = [
      '#0097a7',
      '#c2185b',
      '#ffa000',
      '#ffce19',
      '#00796b',
      '#303f9f',
      '#7023b7',
      '#244224',
      '#784d41',
      '#5b5c58',
    ]
    const COLORS1 = {
      Offline: '#b85300',
      Online: '#008300',
      Lost: '#0097a7',
      Enrolled: '#c2185b',
      Bonded: '#ffa000',
      Quarantined: '#ffce19',
      Repair: '#00796b',
      Deleted: '#303f9f',
      Blocked: '#7023b7',
      Converted: '#3366cc',
    }
    const { classes } = this.props
    const CustomUtilTooltipPieChart = (props) => {
      return (
        props.active && (
          <Typography
            style={{
              display: 'flex',
              alignItems: 'center',
              fontSize: 12,
              fontWeight: 'medium',
              backgroundColor: '#666666',
              color: '#F7F7F7',
              borderRadius: 5,
              padding: '4px 8px',
              width: '150px',
              height: '30px',
            }}
          >
            <Label
              style={{
                fontSize: 24,
                color: props.payload[0].payload.fill,
                paddingRight: 5,
              }}
            />
            {props.payload[0].payload.displayName !== undefined
              ? props.payload[0].payload.displayName +
                '\n(' +
                props.payload[0].name +
                ')' +
                ': ' +
                props.payload[0].value
              : props.payload[0].payload.name + ': ' + props.payload[0].value}
          </Typography>
        )
      )
    }
    return (
      <Card className={classes.cardStyle}>
        <CardHeader
          title={this.props.title}
          titleTypographyProps={{
            variant: 'button',
            gutterBottom: false,
            fontWeight: 'bold',
            align: 'center',
          }}
        />
        <CardContent className={classes.cardContent}>
          {this.state.stateDataLoading ? (
            <Spinner layout="selfCentering" />
          ) : (
            <PieChart width={350} height={200}>
              <Legend
                wrapperStyle={{
                  cursor: this.props.onChangeClick ? 'pointer' : 'arrow',
                }}
                onClick={(payload) => {
                  this.props.onChangeClick &&
                    this.props.onChangeClick(payload.value)
                }}
                layout="vertical"
                align="left"
                verticalAlign="middle"
                formatter={(value, entry, index) =>
                  `${value}:  ${entry.payload.payload.value}`
                }
              />
              <ChartTooltip content={CustomUtilTooltipPieChart} />
              <Pie
                cursor={this.props.onChangeClick ? 'pointer' : 'arrow'}
                data={this.counts}
                dataKey="value"
                innerRadius="65%"
                outerRadius="100%"
                paddingAngle={2}
              >
                {this.counts.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    onClick={() => {
                      this.props.onChangeClick &&
                        this.props.onChangeClick(entry.name)
                    }}
                    fill={
                      this.props.field_name === 'device_state'
                        ? COLORS1[entry.name]
                        : COLORS[index % COLORS.length]
                    }
                  />
                ))}
              </Pie>
            </PieChart>
          )}
        </CardContent>
      </Card>
    )
  }
}

const styles = {
  cardStyle: {
    textAlign: 'center',
    paddingRight: 0,
  },
  cardContent: {
    paddingBottom: 0,
    paddingTop: 0,
    maxHeight: '200px',
    overflow: 'auto',
    marginBottom: 5,
  },
  cell: {
    height: 10,
    padding: 'dense',
  },
  root: {
    padding: 'dense',
  },
}

export default withStyles(styles)(withEnv()(TopPanelDC))
