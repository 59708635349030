import {
  AppBar,
  Drawer,
  IconButton,
  List,
  Toolbar,
  Typography,
} from '@material-ui/core'
import { ProtectedElement, withAuth } from '@praxis/component-auth'
import React, { useEffect, useState } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'

import AdvancedSearch from '../AdvancedSearch/AdvancedSearch'
import Applications from '../Devices/Applications'
import ApplicationsMac from '../Devices/ApplicationsMac'
import ApplicationsWindows from '../Devices/ApplicationsWindows'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import DashboardMain from '../Dashboard/DashboardMain/DashboardMain'
import Devices from '../Devices/Devices'
import DevicesDetails from '../Devices/DevicesDetails'
import EnrollDevice from './EnrollDevice'
import { Helmet } from 'react-helmet'
import Logo from '../../images/logo.svg'
import { Menu } from '@material-ui/icons'
import NavigationButton from './NavigationButton'
import Profile from '../UserSettings/Profile'
import Search from '../AdvancedSearch/Search'
import SearchBar from '../SearchBar/SearchBar'
import { UserMenu } from './UserMenu'
import UserSettingsHomeLoader from '../UserSettings/UserSettingsHomeLoader'
import axios from 'axios'
import svcLocationDetails from '../../services/svcLocationDetails'
import { useAnalytics } from '@praxis/component-analytics'
import { useEnv } from '@praxis/component-runtime-env'

const Layout = ({ auth, props, isExternalDomain }) => {
  const env = useEnv()
  const [Title, setTitle] = useState(
    process.env.NODE_ENV === 'development' || env.environment === 'dev'
      ? 'MICKRA - UAT'
      : 'MICKRA',
  )
  const [data, setData] = useState()
  const navigate = useNavigate()
  const [isInternal, setIsInternal] = useState()
  const [homeOverride, setHomeOverride] = useState(false)
  const { trackEvent } = useAnalytics()
  const { isAuthenticated, login, logout, session } = auth
  const [isDrawerOpen, setDrawerOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(false)
  const [svclocationdetails] = useState(new svcLocationDetails())
  const [suggestionLoading, setSuggestionLoading] = useState(false)
  const [isDialogOpen, setDialogOpen] = useState(false)

  const goHome = () => {
    setHomeOverride(true)
    navigate('')
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    const getData = async () => {
      try {
        const { data } = await axios.get(
          env.auth.isExternalApi + '?key=' + env.auth.apiKey,
        )
        setData(data)
        setIsInternal(true)
      } catch (error) {
        setIsInternal(false)
      }
    }
    getData()
  }, [env.auth.apiKey, env.auth.isExternalApi])

  useEffect(() => {
    setTitle(!isExternalDomain ? Title : 'MICKRA TEP Enrollment')
  }, [Title, isExternalDomain])

  useEffect(() => {
    if (auth != null && auth.session != null && isInternal !== undefined) {
      // here we want to post to firefly after we've done the internal network connectivity test
      const eventData = {
        user: {
          segment: auth.session.identity.jobtitlecode,
          customDimension1: auth.session.identity.locationnumber,
          customDimension2: auth.session.identity.locationtype,
          customDimension3: isInternal,
        },
      }
      trackEvent(eventData)
    }
  }, [auth, data, isInternal, trackEvent])

  useEffect(
    () => {
      if (
        suggestionLoading === false &&
        env.devicesSearchItems.location_id?.search_suggestions.length === 0
      ) {
        setSuggestionLoading(true)
        svclocationdetails
          .getRGDDetails(env)
          .then((res) => {
            setSuggestionLoading(false)
            env.devicesSearchItems.district_id.search_suggestions = res.data[
              'district_id'
            ]
              .filter((x) => x !== 'null' && x !== '0')
              .sort((a, b) => a - b)
            env.devicesSearchItems.group_id.search_suggestions = res.data[
              'group_id'
            ]
              .filter((x) => x !== 'null' && x !== '0')
              .sort((a, b) => a - b)
            env.devicesSearchItems.region_id.search_suggestions = res.data[
              'region_id'
            ]
              .filter((x) => x !== 'null' && x !== '0')
              .sort((a, b) => a - b)
            env.devicesSearchItems.location_id.search_suggestions = [
              ...new Set(
                res.data['location_id']
                  .map((option) => option.replace(/^0+(?=\d)/, ''))
                  .filter((x) => x !== '' && !x.match(/[a-zA-Z]/g))
                  .sort((a, b) => a - b),
              ),
            ]
          })
          .catch((error) => {
            setSuggestionLoading(false)
            console.log(error)
          })
      }
    },
    // eslint-disable-next-line
  [env],
  )

  return (
    <React.Fragment>
      <AppBar
        position="fixed"
        style={{
          zIndex: 1500,
          backgroundColor:
            process.env.NODE_ENV === 'development' || env.environment === 'dev'
              ? 'black'
              : null,
        }}
      >
        <Toolbar>
          {auth.session != null &&
            props.access.enrollmentAccessGroup.some((r) =>
              auth.session.userInfo.memberOf.includes(r.toUpperCase()),
            ) &&
            props.enableEnrollment && (
              <>
                <IconButton color="inherit" onClick={() => setDrawerOpen(true)}>
                  <Menu style={{ width: 28, marginRight: 10 }} />
                </IconButton>

                <Drawer
                  open={isDrawerOpen}
                  onClick={() => setDrawerOpen(false)}
                >
                  <List>
                    <NavigationButton text="Enroll Device" location="/enroll" />
                  </List>
                </Drawer>
              </>
            )}
          <IconButton onClick={goHome} color="inherit">
            <Logo style={{ width: 28, marginRight: 10 }} />
          </IconButton>
          <Typography
            variant="h6"
            color="inherit"
            noWrap={true}
            style={{ flexGrow: 1 }}
          >
            {Title}
          </Typography>
          <>
            {/* don't render search bar for POL users */}
            {!isExternalDomain ? <SearchBar {...props} /> : null}
            {!isExternalDomain ? (
              <AdvancedSearch
                props={props}
                isDialogOpen={isDialogOpen}
                setDialogOpen={setDialogOpen}
              />
            ) : null}
            {!isExternalDomain ? (
              <>
                <div style={{ paddingRight: 10, paddingLeft: 10 }}>
                  <Avatar
                    size="small"
                    style={{
                      backgroundColor: 'inherit',
                      color: '#fff',
                      border: '2px solid white',
                    }}
                    onClick={handleClick}
                  >
                    {isAuthenticated() &&
                      `${session.userInfo.firstName[0]}${session.userInfo.lastName[0]}`}
                  </Avatar>
                </div>
                <UserMenu anchorEl={anchorEl} handleClose={handleClose} />
              </>
            ) : (
              <Typography
                variant="button"
                style={{ paddingRight: 10 }}
                color="inherit"
              >
                {/* render company name for POL users */}
                {isAuthenticated() &&
                  `${session.userInfo.fullName}, ${session.identity.company_name}`}
              </Typography>
            )}
          </>
          {isExternalDomain ? (
            <Button
              size="small"
              color="inherit"
              variant="outlined"
              onClick={isAuthenticated() ? logout : login}
              onMouseOver={() => (isAuthenticated() ? 'logout' : 'login')}
            >
              {isAuthenticated() ? 'logout' : 'login'}
            </Button>
          ) : null}
        </Toolbar>
      </AppBar>

      <Helmet title={Title} />
      <div style={{ marginTop: 66 }}>
        <Routes>
          {/* Only externally facing routes are allowed and routes would not work when someone tries to open internal route with external OAuth */}
          {
            !isExternalDomain ? (
              <>
                <Route
                  path={!isExternalDomain ? '/' : ''}
                  element={
                    <ProtectedElement>
                      <UserSettingsHomeLoader homeOverride={homeOverride} />
                    </ProtectedElement>
                  }
                ></Route>
                <Route
                  path="/enroll"
                  element={
                    <ProtectedElement
                      allowed={props.access.enrollmentAccessGroup}
                    >
                      <EnrollDevice auth={auth} />
                    </ProtectedElement>
                  }
                ></Route>
                <Route
                  path="/location/:locationid"
                  element={
                    <ProtectedElement>
                      <DashboardMain />
                    </ProtectedElement>
                  }
                ></Route>
                <Route
                  path="/devices"
                  element={
                    <ProtectedElement>
                      <Devices />
                    </ProtectedElement>
                  }
                ></Route>
                <Route
                  path="/applications"
                  element={
                    <ProtectedElement>
                      <Applications />
                    </ProtectedElement>
                  }
                ></Route>
                <Route
                  path="/applications_computers"
                  element={
                    <ProtectedElement>
                      <ApplicationsWindows />
                    </ProtectedElement>
                  }
                ></Route>
                <Route
                  path="/applications_mac"
                  element={
                    <ProtectedElement>
                      <ApplicationsMac />
                    </ProtectedElement>
                  }
                ></Route>
                <Route
                  path="/profile"
                  element={
                    <ProtectedElement>
                      <Profile
                        props={props}
                        isDialogOpen={isDialogOpen}
                        setDialogOpen={setDialogOpen}
                      />
                    </ProtectedElement>
                  }
                ></Route>
                <Route
                  path="/search"
                  element={
                    <ProtectedElement>
                      <Search {...props} />
                    </ProtectedElement>
                  }
                ></Route>
                <Route
                  path="/search_results"
                  element={
                    <ProtectedElement>
                      <DevicesDetails />
                    </ProtectedElement>
                  }
                ></Route>
              </>
            ) : isExternalDomain ? (
              <>
                {/* POL user routes */}
                {/* trap to catch any other routes for POL users */}
                <Route
                  path="*"
                  element={
                    <ProtectedElement
                      allowed={props.access.enrollmentAccessGroup}
                    >
                      <EnrollDevice
                        auth={auth}
                        isExternalDomain={isExternalDomain}
                      />
                    </ProtectedElement>
                  }
                ></Route>
              </>
            ) : null
            // Null makes sure that no other routes are defined or accesible
          }
        </Routes>
      </div>
    </React.Fragment>
  )
}

export default withAuth(null)(Layout)
