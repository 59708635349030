class ValidateMacIpAddress {
  validateMacAddress = (macaddress) => {
    if (
      /^[A-Z0-9]{12}$/i.test(macaddress) ||
      /^([0-9a-f]{1,2}[-]){5}([0-9a-f]{1,2})$/i.test(macaddress) ||
      /^([0-9a-f]{1,2}[:]){5}([0-9a-f]{1,2})$/i.test(macaddress)
    ) {
      return false
    } else {
      return true
    }
  }
  validateIpAddress = (ipaddress) => {
    if (/^\b\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\b$/.test(ipaddress)) {
      return false
    } else {
      return true
    }
  }
}
export default ValidateMacIpAddress
