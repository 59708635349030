import React from 'react'
import axios from 'axios'
import queryString from 'query-string'

class SvcMac extends React.Component {
  getMac(config, submission) {
    const queryParameters = {
      mac: {
        location_id: null,
        computer_name: null,
        operating_system: null,
        operating_system_version: null,
        logged_in_user: null,
        id: null,
      },
    }
    let queryParametersVar = queryParameters
    Object.entries(submission).forEach((param) => {
      if (queryParametersVar.mac.hasOwnProperty(param[0])) {
        queryParametersVar.mac[`${param[0]}`] = param[1]
      }
    })

    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey
    const endpointParams = queryString.stringify(queryParametersVar.mac, {
      sort: false,
      skipNull: true,
    })
    const apiUri = encodeURI(
      apiUriBase + `/mac?` + endpointParams + `&key=` + apiKey,
    )
    return axios.get(apiUri)
  }
  getStoreMacComputers(storeNumber, config) {
    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey
    const apiUri = encodeURI(
      apiUriBase + `/mac?location_id=` + storeNumber + `&key=` + apiKey,
    )
    return axios.get(apiUri)
  }
}
export default SvcMac
