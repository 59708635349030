import MUIDataTable from 'mui-datatables'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import React from 'react'
import Table from '@material-ui/core/Table'
import TableCell from '@material-ui/core/TableCell'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import Typography from '@material-ui/core/Typography'
import { withEnv } from '@praxis/component-runtime-env'
import { withStyles } from '@material-ui/styles'

class TableDataMacApplications extends React.Component {
  render() {
    const { computerDetails } = this.props
    return typeof computerDetails !== 'undefined' &&
      computerDetails.ci_installed_application !== null &&
      computerDetails.ci_installed_application !== undefined &&
      computerDetails.state.toString().toLowerCase() !== 'inactive' ? (
      <>
        <Typography variant="h6" id="tableTitle">
          Installed Applications
        </Typography>

        <MuiThemeProvider theme={this.getMuiTheme()}>
          <Table>
            <TableBody>
              <TableRow style={{ verticalAlign: 'top' }}>
                <TableCell
                  style={{
                    paddingLeft: 0,
                    paddingTop: 0,
                    paddingBottom: 0,
                    paddingRight: 0,
                    width: '100%',
                  }}
                >
                  <MUIDataTable
                    data={computerDetails.ci_installed_application}
                    className={this.theme}
                    options={{
                      selectableRows: 'none',
                      rowHover: false,
                      pagination: true,
                      filter: false,
                      print: false,
                      download: false,
                      elevation: 0,
                      viewColumns: false,
                    }}
                    columns={[
                      {
                        name: 'vendor',
                        label: ' Vendor',
                        options: {
                          filter: false,
                        },
                      },
                      {
                        name: 'id',
                        label: ' ID',
                        options: {
                          align: 'center',
                          filter: false,
                        },
                      },
                      {
                        name: 'name',
                        label: ' Name',
                        options: {
                          filter: false,
                        },
                      },
                      {
                        name: 'version',
                        label: ' Version',
                        options: {
                          filter: false,
                        },
                      },
                    ]}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </MuiThemeProvider>
      </>
    ) : (
      <div>Note, additional details are not available for this device.</div>
    )
  }
  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTableBodyCell: {
          root: {
            backgroundColor: '#eeeeee',
            padding: 0,
          },
        },
        MUIDataTableHeadCell: {
          root: {
            backgroundColor: '#eeeeee',
            padding: 0,
          },
        },
        MUIDataTableHeadRow: {
          root: {
            height: 10,
          },
        },
        MUIDataTableBodyRow: {
          root: {
            backgroundColor: '#eeeeee',
            height: 10,
          },
        },
        MUIDataTableToolbar: {
          root: {
            backgroundColor: '#eeeeee',
            height: 10,
          },
          titleText: {
            fontSize: 14,
          },
          left: {
            flex: '100 0 auto',
          },
        },
        MuiPaper: {
          root: {
            minWidth: '400',
            paddingLeft: '0',
          },
        },
      },
    })
}

const styles = (theme) => ({
  root: {
    backgroundColor: '#eeeeee',
    padding: 'dense',
    fontSize: '40pt',
    verticalAlign: 'top',
  },
  cell: {
    height: 10,
    verticalAlign: 'top',
  },
  honeyComb: {
    minHeight: `calc(100vh - 75px)`,
    backgroundImage:
      `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='24.5' ` +
      `viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%23888888' fill-opacity='0.50' ` +
      `fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 ` +
      `11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 ` +
      `0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
  },
  listItem: {
    verticalAlign: 'top',
    height: 10,
    border: 'none',
  },
})

export default withStyles(styles, { withTheme: true })(
  withEnv()(TableDataMacApplications),
)
