import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Divider from '@material-ui/core/Divider'
import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import { withStyles } from '@material-ui/styles'

const styles = (theme) => ({
  root: {
    backgroundColor: '#eeeeee',
    padding: 'dense',
    fontSize: '40pt',
  },
  cell: {
    padding: 0,
  },
  honeyComb: {
    minHeight: `calc(100vh - 75px)`,
    backgroundImage:
      `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='24.5' ` +
      `viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%23888888' fill-opacity='0.50' ` +
      `fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 ` +
      `11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 ` +
      `0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
  },
})

class TableDataBTScanners extends React.Component {
  render() {
    const colSpan = this.props.rowData.length + 1
    const { classes, storeBTScanners, rowMeta } = this.props
    let pairedDevices = storeBTScanners[rowMeta.dataIndex].paired_devices

    return (
      <TableRow className={classes.honeyComb}>
        <TableCell colSpan={colSpan}>
          <Card raised>
            <CardContent>
              {typeof storeBTScanners[rowMeta.dataIndex] !== 'undefined' ? (
                <div>
                  <Typography variant="h6" id="tableTitle">
                    General
                  </Typography>
                  <Table className={classes.root}>
                    <TableHead>
                      <TableRow className={classes.cell}>
                        <TableCell className={classes.cell}>
                          Unique ID
                        </TableCell>
                        <TableCell className={classes.cell}>TCIN</TableCell>
                        <TableCell className={classes.cell}>
                          Lost Event User
                        </TableCell>
                        <TableCell className={classes.cell}>
                          Lost Event Timestamp
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow className={classes.cell}>
                        <TableCell className={classes.cell}>
                          {storeBTScanners[rowMeta.dataIndex].unique_id &&
                            storeBTScanners[rowMeta.dataIndex].unique_id}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {storeBTScanners[rowMeta.dataIndex].tcin &&
                            storeBTScanners[rowMeta.dataIndex].tcin}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {storeBTScanners[rowMeta.dataIndex].lost_user &&
                          storeBTScanners[rowMeta.dataIndex].lost_user
                            .employee_id
                            ? storeBTScanners[rowMeta.dataIndex].lost_user
                                .first_name +
                              ' ' +
                              storeBTScanners[rowMeta.dataIndex].lost_user
                                .last_name +
                              ' (' +
                              storeBTScanners[rowMeta.dataIndex].lost_user
                                .employee_id +
                              ')'
                            : ''}
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {storeBTScanners[rowMeta.dataIndex]
                            .lost_event_timestamp &&
                            storeBTScanners[rowMeta.dataIndex]
                              .lost_event_timestamp}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                  <Divider style={{ backgroundColor: 'black' }} />
                  <Typography variant="h6" id="tableTitle">
                    Paired Devices
                  </Typography>
                  <Table className={classes.root}>
                    <TableHead>
                      <TableRow className={classes.cell}>
                        <TableCell className={classes.cell}>
                          Paired Device ID
                        </TableCell>
                        <TableCell className={classes.cell}>
                          Paired Device Label
                        </TableCell>
                        {/* <TableCell className={classes.cell}>
                          Last User
                        </TableCell>
                        <TableCell className={classes.cell}>
                          Last User Type
                        </TableCell> */}
                        <TableCell className={classes.cell}>
                          Paired Event Timestamp
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {pairedDevices &&
                        pairedDevices
                          .sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1))
                          .map((row, index) => {
                            let currentDateFromNow = moment
                              .utc(row.timestamp)
                              .fromNow()
                            let yesterday = moment()
                              .subtract(24, 'hours')
                              .format()
                            let lastSeen = moment(row.timestamp).format()
                            return (
                              <TableRow className={classes.cell} key={index}>
                                <TableCell className={classes.cell}>
                                  {row.device_id && row.device_id}
                                </TableCell>
                                <TableCell className={classes.cell}>
                                  {row.label !== null ? row.label : 'Not Set'}
                                </TableCell>
                                {/* <TableCell className={classes.cell}>
                                {row.last_user && row.last_user.employee_id
                                  ? row.last_user.lan_id
                                    ? row.last_user.first_name +
                                      ' ' +
                                      row.last_user.last_name +
                                      ' - ' +
                                      row.last_user.lan_id +
                                      ' (' +
                                      row.last_user.employee_id +
                                      ')'
                                    : row.last_user.employee_id
                                  : 'No Existing Record'}
                              </TableCell>
                              <TableCell className={classes.cell}>
                                {pairedDevices.last_user &&
                                pairedDevices.last_user.employee_id
                                  ? pairedDevices.last_user.employee_type +
                                    ', ' +
                                    pairedDevices.last_user.company
                                  : 'No Existing Record'}
                              </TableCell> */}
                                <TableCell className={classes.cell}>
                                  {lastSeen !==
                                  moment('0000-00-00 00:00:00.000').format()
                                    ? lastSeen < yesterday
                                      ? moment(lastSeen).format('llll')
                                      : currentDateFromNow
                                    : ' '}
                                </TableCell>
                              </TableRow>
                            )
                          })}
                    </TableBody>
                  </Table>
                </div>
              ) : (
                <div>
                  Note, additional details are not available for this device.{' '}
                </div>
              )}
            </CardContent>
          </Card>
        </TableCell>
      </TableRow>
    )
  }
}

export default withStyles(styles)(TableDataBTScanners)
