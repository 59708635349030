import { Divider } from '@material-ui/core'
import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/styles'

class TableDataPrinterDetails extends React.Component {
  render() {
    const { classes, printerDetails } = this.props
    return typeof printerDetails !== 'undefined' ? (
      <>
        <Typography variant="h6" id="tableTitle">
          Alert
        </Typography>
        <Table className={classes.root}>
          <TableHead>
            <TableRow className={classes.cell}>
              <TableCell className={classes.cell}>Text</TableCell>
              <TableCell className={classes.cell}>Severity Level</TableCell>
              <TableCell className={classes.cell}>Training Level</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {printerDetails.printer.alert.map((e, index) => {
              return (
                <TableRow index={index} className={classes.cell}>
                  <TableCell className={classes.cell}>
                    {e.severity_level}
                  </TableCell>
                  <TableCell className={classes.cell}>{e.text}</TableCell>
                  <TableCell className={classes.cell}>
                    {e.training_level}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <Divider style={{ backgroundColor: 'black' }} />
        <Typography variant="h6" id="tableTitle">
          Input
        </Typography>
        <Table className={classes.root}>
          <TableHead>
            <TableRow className={classes.cell}>
              <TableCell className={classes.cell}>Current Level</TableCell>
              <TableCell className={classes.cell}>Description</TableCell>
              <TableCell className={classes.cell}>Max Capacity</TableCell>
              <TableCell className={classes.cell}>Media Type</TableCell>
              <TableCell className={classes.cell}>Model</TableCell>
              <TableCell className={classes.cell}>Serial Number</TableCell>
              <TableCell className={classes.cell}>Status</TableCell>
              <TableCell className={classes.cell}>Type</TableCell>
              <TableCell className={classes.cell}>Vendor Name</TableCell>
              <TableCell className={classes.cell}>Version</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {printerDetails.printer.input.map((e, index) => {
              return (
                <TableRow className={classes.cell} index={index}>
                  <TableCell className={classes.cell}>
                    {e.current_level}
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {e.description}
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {e.max_capacity}
                  </TableCell>
                  <TableCell className={classes.cell}>{e.media_type}</TableCell>
                  <TableCell className={classes.cell}>{e.model}</TableCell>
                  <TableCell className={classes.cell}>
                    {e.serial_number}
                  </TableCell>
                  <TableCell className={classes.cell}>{e.status}</TableCell>
                  <TableCell className={classes.cell}>{e.type}</TableCell>
                  <TableCell className={classes.cell}>
                    {e.vendor_name}
                  </TableCell>
                  <TableCell className={classes.cell}>{e.version}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <Divider style={{ backgroundColor: 'black' }} />
        <Typography variant="h6" id="tableTitle">
          Output
        </Typography>
        <Table className={classes.root}>
          <TableHead>
            <TableRow className={classes.cell}>
              <TableCell className={classes.cell}>Description</TableCell>
              <TableCell className={classes.cell}>Model</TableCell>
              <TableCell className={classes.cell}>Max Capacity</TableCell>
              <TableCell className={classes.cell}>Remaining Capacity</TableCell>

              <TableCell className={classes.cell}>Serial Number</TableCell>
              <TableCell className={classes.cell}>Status</TableCell>
              <TableCell className={classes.cell}>Type</TableCell>
              <TableCell className={classes.cell}>Vendor Name</TableCell>
              <TableCell className={classes.cell}>Version</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {printerDetails.printer.output.map((e, index) => {
              return (
                <TableRow className={classes.cell} index={index}>
                  <TableCell className={classes.cell}>
                    {e.description}
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {e.max_capacity}
                  </TableCell>
                  <TableCell className={classes.cell}>{e.model}</TableCell>
                  <TableCell className={classes.cell}>
                    {e.remaining_capacity}
                  </TableCell>

                  <TableCell className={classes.cell}>
                    {e.serial_number}
                  </TableCell>
                  <TableCell className={classes.cell}>{e.status}</TableCell>
                  <TableCell className={classes.cell}>{e.type}</TableCell>
                  <TableCell className={classes.cell}>
                    {e.vendor_name}
                  </TableCell>
                  <TableCell className={classes.cell}>{e.version}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <Divider style={{ backgroundColor: 'black' }} />
        <Typography variant="h6" id="tableTitle">
          Status
        </Typography>
        <Table className={classes.root}>
          <TableHead>
            <TableRow className={classes.cell}>
              <TableCell className={classes.cell}>Severity Level</TableCell>
              <TableCell className={classes.cell}>Text</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {printerDetails.printer.alert.map((e, index) => {
              return (
                <TableRow className={classes.cell} index={index}>
                  <TableCell className={classes.cell}>
                    {e.severity_level}
                  </TableCell>
                  <TableCell className={classes.cell}>{e.text}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <Divider style={{ backgroundColor: 'black' }} />
        <Typography variant="h6" id="tableTitle">
          Supply
        </Typography>
        <Table className={classes.root}>
          <TableHead>
            <TableRow className={classes.cell}>
              <TableCell className={classes.cell}>Description</TableCell>
              <TableCell className={classes.cell}>Level</TableCell>
              <TableCell className={classes.cell}>Max Capacity</TableCell>
              <TableCell className={classes.cell}>Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {printerDetails.printer.supply.map((e, index) => {
              return (
                <TableRow className={classes.cell} index={index}>
                  <TableCell className={classes.cell}>
                    {e.description}
                  </TableCell>
                  <TableCell className={classes.cell}>{e.level}</TableCell>
                  <TableCell className={classes.cell}>
                    {e.max_capacity}
                  </TableCell>
                  <TableCell className={classes.cell}>{e.type}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
        <Divider style={{ backgroundColor: 'black' }} />
      </>
    ) : (
      <div>Note, additional details are not available for this device.</div>
    )
  }
}

const styles = (theme) => ({
  root: {
    backgroundColor: '#eeeeee',
    padding: 'dense',
    fontSize: '40pt',
    verticalAlign: 'top',
  },
  cell: {
    height: 10,
    verticalAlign: 'top',
  },
  honeyComb: {
    minHeight: `calc(100vh - 75px)`,
    backgroundImage:
      `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='24.5' ` +
      `viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%23888888' fill-opacity='0.50' ` +
      `fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 ` +
      `11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 ` +
      `0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
  },
  listItem: {
    verticalAlign: 'top',
    height: 10,
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 5,
    border: 'none',
  },
})

export default withStyles(styles)(TableDataPrinterDetails)
