import { ExpandLess, ExpandMore, FilterList } from '@material-ui/icons'
import { Table, TableBody, TableCell, TableRow } from '@material-ui/core'

import Box from '@material-ui/core/Box'
import Collapse from '@material-ui/core/Collapse'
import ComputerTabs from './TabPanel'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import React from 'react'
import SummarizedWidgetsRoot from '../SummarizedWidgets/SummarizedWidgetsRoot'
import Switch from '@material-ui/core/Switch'
import Tooltip from '@material-ui/core/Tooltip'
import landingNavigationConfig from './landingNavigationConfig'
import { locationOptions } from './locationOptions'
import { withAnalytics } from '@praxis/component-analytics'
import { withEnv } from '@praxis/component-runtime-env'
import { withStyles } from '@material-ui/styles'

function TabPanel(props) {
  const { children, value, index } = props
  return value === index && children
}

class Landing extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tabValue: 1,
      cameraAccessControlExpanded: false,
      computeExpanded: false,
      enduserEndpointExpanded: true,
      iotExpanded: false,
      networkExpanded: false,
      powerExpanded: false,
      storageExpanded: false,
      voiceCollabExpanded: false,
      propertyManagementExpanded: false,
      filterExpanded: true,
      filterChecked: 'all',
      tabLocationName: '',
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.tabLocationName !== this.state.tabLocationName) {
      if (this.state.tabLocationName === 'HQ') {
        this.setState({ tabValue: 6 })
      }
    }
  }
  handleChange = (event, tabValue) => {
    this.setState({ tabValue })
    let tabName = null
    if (tabValue === 0) {
      tabName = 'root'
    } else if (tabValue === 1) {
      tabName = 'handheld'
    } else if (tabValue === 2) {
      tabName = 'tablet'
    } else if (tabValue === 3) {
      tabName = 'bluetoothscanner'
    } else if (tabValue === 4) {
      tabName = 'camera'
    } else if (tabValue === 5) {
      tabName = 'printer'
    } else if (tabValue === 6) {
      tabName = 'register'
    } else if (tabValue === 7) {
      tabName = 'ups'
    } else if (tabValue === 8) {
      tabName = 'ats'
    } else if (tabValue === 9) {
      tabName = 'pdu'
    } else if (tabValue === 10) {
      tabName = 'generator'
    } else if (tabValue === 11) {
      tabName = 'voiceGateway'
    } else if (tabValue === 12) {
      tabName = 'digitalMediaPlayer'
    } else if (tabValue === 13) {
      tabName = 'baremetalServer'
    } else if (tabValue === 14) {
      tabName = 'chassis'
    } else if (tabValue === 15) {
      tabName = 'registerI10i'
    } else if (tabValue === 16) {
      tabName = 'printerI10i'
    } else if (tabValue === 17) {
      tabName = 'scale'
    } else if (tabValue === 18) {
      tabName = 'networkAccessPoint'
    } else if (tabValue === 19) {
      tabName = 'networkChassis'
    } else if (tabValue === 20) {
      tabName = 'networkGateway'
    } else if (tabValue === 21) {
      tabName = 'networkSwitch'
    } else if (tabValue === 22) {
      tabName = 'networkRouter'
    } else if (tabValue === 23) {
      tabName = 'networkManagement'
    } else if (tabValue === 24) {
      tabName = 'doorAccessControl'
    } else if (tabValue === 27) {
      tabName = 'phone'
    } else if (tabValue === 28) {
      tabName = 'networkTrafficManagement'
    } else if (tabValue === 29) {
      tabName = 'submeteringControls'
    } else if (tabValue === 30) {
      tabName = 'broadcast'
    }
    const eventData = {
      customInteraction: {
        key: 'landing page',
        value: tabName === null ? 'handheld' : tabName,
      },
    }
    this.props.trackEvent(eventData)
  }

  handleChangeLocation = (name) => {
    this.setState({ tabLocationName: locationOptions[name].tabLocationName })
  }

  handleClick = (itemExpand) => {
    this.setState({ [itemExpand]: !this.state[itemExpand] })
  }

  handleFilterExpand = () => {
    this.setState({ filterExpanded: !this.state.filterExpanded })
  }

  handleFiltersChecked = (event) => {
    this.setState({ filterChecked: event.target.name })
    this.handleChangeLocation(event.target.name)
  }

  BuildNavigation = (locationType) => {
    return (
      <>
        {Object.entries(landingNavigationConfig['NavigationGroups']).map(
          (navGroup, navGroupIndex, navGroupArr) => {
            let deviceTypeExpand =
              this.state[`${navGroup[1].stateName + 'Expanded'}`]
            return (
              navGroup[1].enabled && (
                <List
                  key={navGroupIndex}
                  className={
                    navGroup[1].locationType.includes(locationType.locationType)
                      ? this.props.classes.tableCell
                      : this.props.classes.disabled
                  }
                >
                  <ListItem
                    button
                    onClick={(event) =>
                      this.handleClick(`${navGroup[1].stateName + 'Expanded'}`)
                    }
                  >
                    <ListItemText
                      primary={navGroup[0]}
                      primaryTypographyProps={{ variant: 'button' }}
                    />
                    {deviceTypeExpand ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse
                    in={deviceTypeExpand}
                    timeout="auto"
                    unmountOnExit
                    key={navGroupIndex}
                  >
                    {Object.entries(navGroup[1]['NavigationItems']).map(
                      (navItem, navItemIndex) => {
                        return (
                          navItem[1].enabled && (
                            <ListItem
                              disabled={
                                !navItem[1].locationType.includes(
                                  locationType.locationType,
                                )
                              }
                              key={navItemIndex}
                              className={
                                navItem[1].locationType.includes(
                                  locationType.locationType,
                                )
                                  ? this.props.classes.nested
                                  : this.props.classes.disabled
                              }
                              button
                              selected={
                                this.state.tabValue === navItem[1].tabValue
                              }
                              onClick={(event) =>
                                this.handleChange(event, navItem[1].tabValue)
                              }
                            >
                              <ListItemIcon>{navItem[1].icon}</ListItemIcon>
                              <ListItemText
                                primary={navItem[0]}
                                primaryTypographyProps={{
                                  className: this.props.classes.nestedList,
                                }}
                              />
                            </ListItem>
                          )
                        )
                      },
                    )}
                  </Collapse>
                  {/* hide divider on last row */}
                  {navGroupArr.length - 1 !== navGroupIndex && (
                    <Divider
                      className={
                        this.props.env.i10iFeaturesDisabled
                          ? this.props.classes.disabled
                          : this.props.classes.secondaryDivider
                      }
                    />
                  )}
                </List>
              )
            )
          },
        )}
      </>
    )
  }

  render() {
    const { classes } = this.props

    return (
      <>
        <div className={classes.root}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className={classes.tableCell}>
                  <Box
                    sx={{
                      width: 310,
                      maxHeight: '100%',
                      bgcolor: 'background.paper',
                    }}
                  >
                    <List className={this.props.classes.locationOptions}>
                      <ListItem
                        button
                        onClick={(event) => this.handleFilterExpand()}
                      >
                        <ListItemText
                          primary="location options"
                          primaryTypographyProps={{ variant: 'button' }}
                        />
                        {this.state.filterExpanded ? (
                          <FilterList />
                        ) : (
                          <FilterList />
                        )}
                      </ListItem>
                      <Collapse
                        in={this.state.filterExpanded}
                        timeout="auto"
                        unmountOnExit
                      >
                        {Object.entries(locationOptions).map(
                          (locationTypeName, locationTypeIndex) => {
                            return (
                              locationTypeName[1].enabled && (
                                <Tooltip
                                  title={`Click to filter results to  ${locationTypeName[1].switchLabel} devices`}
                                  key={locationTypeIndex}
                                >
                                  <ListItem
                                    className={
                                      this.props.classes.locationOptions
                                    }
                                  >
                                    <Switch
                                      checked={
                                        this.state.filterChecked ===
                                        locationTypeName[0]
                                          ? true
                                          : false
                                      }
                                      onChange={(event) =>
                                        this.handleFiltersChecked(event)
                                      }
                                      name={locationTypeName[0]}
                                    ></Switch>
                                    {locationTypeName[1].switchLabel}
                                  </ListItem>
                                </Tooltip>
                              )
                            )
                          },
                        )}
                      </Collapse>
                    </List>
                    <Divider
                      className={this.props.classes.locationOptionsDivider}
                    />
                    {/* <Divider className={this.props.classes.secondaryDivider} /> */}
                    <this.BuildNavigation
                      locationType={this.state.filterChecked}
                    />
                  </Box>
                </TableCell>
                <TableCell className={classes.gridCell}>
                  {
                    <>
                      <TabPanel value={this.state.tabValue} index={0}>
                        <SummarizedWidgetsRoot widgets="root" />
                      </TabPanel>
                      <SummarizedWidgetsRoot widgets="root" />
                      <Divider className={classes.divider} />
                      <TabPanel value={this.state.tabValue} index={1}>
                        <SummarizedWidgetsRoot
                          widgets="handheld"
                          tabLocationName={this.state.tabLocationName}
                        />
                      </TabPanel>
                      <TabPanel value={this.state.tabValue} index={2}>
                        <SummarizedWidgetsRoot
                          widgets="tablet"
                          tabLocationName={this.state.tabLocationName}
                        />
                      </TabPanel>
                      <TabPanel value={this.state.tabValue} index={3}>
                        <SummarizedWidgetsRoot widgets="bluetoothscanner" />
                      </TabPanel>
                      <TabPanel value={this.state.tabValue} index={4}>
                        <SummarizedWidgetsRoot widgets="camera" />
                      </TabPanel>
                      <TabPanel value={this.state.tabValue} index={5}>
                        <SummarizedWidgetsRoot widgets="printer" />
                      </TabPanel>
                      <TabPanel value={this.state.tabValue} index={6}>
                        <ComputerTabs
                          tabLocationName={this.state.tabLocationName}
                        />
                      </TabPanel>
                      <TabPanel value={this.state.tabValue} index={7}>
                        <SummarizedWidgetsRoot widgets="ups" />
                      </TabPanel>
                      <TabPanel value={this.state.tabValue} index={8}>
                        <SummarizedWidgetsRoot widgets="ats" />
                      </TabPanel>
                      <TabPanel value={this.state.tabValue} index={9}>
                        <SummarizedWidgetsRoot widgets="pdu" />
                      </TabPanel>
                      <TabPanel value={this.state.tabValue} index={10}>
                        <SummarizedWidgetsRoot widgets="generator" />
                      </TabPanel>
                      <TabPanel value={this.state.tabValue} index={11}>
                        <SummarizedWidgetsRoot widgets="voiceGateway" />
                      </TabPanel>
                      <TabPanel value={this.state.tabValue} index={12}>
                        <SummarizedWidgetsRoot widgets="digitalMediaPlayer" />
                      </TabPanel>

                      <TabPanel value={this.state.tabValue} index={13}>
                        <SummarizedWidgetsRoot widgets="baremetalServer" />
                      </TabPanel>
                      <TabPanel value={this.state.tabValue} index={14}>
                        <SummarizedWidgetsRoot widgets="chassis" />
                      </TabPanel>
                      <TabPanel value={this.state.tabValue} index={15}>
                        <SummarizedWidgetsRoot widgets="registerI10i" />
                      </TabPanel>

                      <TabPanel value={this.state.tabValue} index={16}>
                        <SummarizedWidgetsRoot widgets="printerI10i" />
                      </TabPanel>
                      <TabPanel value={this.state.tabValue} index={17}>
                        <SummarizedWidgetsRoot widgets="scale" />
                      </TabPanel>

                      <TabPanel value={this.state.tabValue} index={18}>
                        <SummarizedWidgetsRoot widgets="networkAccessPoint" />
                      </TabPanel>
                      <TabPanel value={this.state.tabValue} index={19}>
                        <SummarizedWidgetsRoot widgets="networkChassis" />
                      </TabPanel>
                      <TabPanel value={this.state.tabValue} index={20}>
                        <SummarizedWidgetsRoot widgets="networkGateway" />
                      </TabPanel>
                      <TabPanel value={this.state.tabValue} index={21}>
                        <SummarizedWidgetsRoot widgets="networkSwitch" />
                      </TabPanel>
                      <TabPanel value={this.state.tabValue} index={22}>
                        <SummarizedWidgetsRoot widgets="networkRouter" />
                      </TabPanel>
                      <TabPanel value={this.state.tabValue} index={23}>
                        <SummarizedWidgetsRoot widgets="networkManagement" />
                      </TabPanel>
                      <TabPanel value={this.state.tabValue} index={24}>
                        <SummarizedWidgetsRoot widgets="doorAccessControl" />
                      </TabPanel>
                      <TabPanel value={this.state.tabValue} index={25}>
                        <SummarizedWidgetsRoot widgets="rfidHandheld" />
                      </TabPanel>
                      <TabPanel value={this.state.tabValue} index={30}>
                        <SummarizedWidgetsRoot widgets="broadcast" />
                      </TabPanel>
                      <TabPanel value={this.state.tabValue} index={27}>
                        <SummarizedWidgetsRoot widgets="phone" />
                      </TabPanel>
                      <TabPanel value={this.state.tabValue} index={28}>
                        <SummarizedWidgetsRoot widgets="networkTrafficManagement" />
                      </TabPanel>
                      <TabPanel value={this.state.tabValue} index={29}>
                        <SummarizedWidgetsRoot widgets="submeteringControls" />
                      </TabPanel>
                    </>
                  }
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </>
    )
  }
}

const styles = {
  root: {
    marginTop: 5,
    background: '#fff',
    backgroundImage:
      `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='24.5' ` +
      `viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%23888888' fill-opacity='0.2' ` +
      `fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 ` +
      `11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 ` +
      `0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
  },
  tableCell: {
    paddingLeft: 0,
    paddingTop: 0,
    paddingBottom: 0,
    whiteSpace: 'nowrap',
    verticalAlign: 'top',
    borderBottom: 0,
  },
  locationOptions: {
    paddingLeft: 0,
    paddingTop: 0,
    paddingBottom: 0,
    whiteSpace: 'nowrap',
    verticalAlign: 'top',
    borderBottom: 0,
  },
  locationOptionsDivider: {
    height: '10px',
    background: '#fff',
    backgroundImage:
      `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='24.5' ` +
      `viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%23888888' fill-opacity='0.2' ` +
      `fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 ` +
      `11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 ` +
      `0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
  },
  gridCell: {
    display: 'grid',
    verticalAlign: 'top',
    maxWidth: 1235,
    minWidth: 300,
    paddingLeft: 0,
    borderBottom: 0,
  },
  secondaryDivider: {
    height: '5px',
  },
  divider: {
    height: '5px',
  },
  nested: {
    paddingLeft: 10,
    height: 35,
  },
  nestedList: {
    fontSize: 14,
  },
  disabled: {
    display: 'none',
  },
}

export default withStyles(styles, { withTheme: true })(
  withAnalytics()(withEnv()(Landing)),
)
