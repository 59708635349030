import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListSubheader,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { ExpandLess, ExpandMore, Launch, StarOutline } from '@material-ui/icons'
import { useEffect, useState } from 'react'

import React from 'react'
import Spinner from 'nicollet-react/es/components/Spinner/Spinner'
import { UserSettingsHome } from '../../components/UserSettings/UserSettingsHome'
import { redirectHandler } from '../../utilities/RedirectHandler'
import { snakeToFlat } from '../../utilities/SnakeToFlat'
import { useAuth } from '@praxis/component-auth'
import { useUserSettingsContext } from '../../contexts/UserSettingsContext'
import { withStyles } from '@material-ui/styles'

const Profile = ({ classes, isDialogOpen, setDialogOpen }) => {
  const userSettings = useUserSettingsContext()
  const { session } = useAuth()
  const [hasHome, setHasHome] = useState(false)
  const [hasRecent, setHasRecent] = useState(false)

  const [open, setOpen] = useState({
    advanced_search: false,
    location_id: false,
    search: false,
  })

  useEffect(() => {
    if (
      !userSettings.data.isLoading &&
      userSettings.data.data.home.type &&
      userSettings.data.data.home.value
    ) {
      setHasHome(true)
    } else {
      setHasHome(false)
    }

    if (
      Object.values(userSettings.recentData).filter((f) => f.length !== 0)
        .length !== 0
    ) {
      setHasRecent(true)
    } else {
      setHasRecent(false)
    }
  }, [userSettings, setHasHome, setHasRecent])

  if (!userSettings.data.isLoading) {
    return (
      <div>
        {/* HOME section */}
        <Card elevation={0}>
          <CardHeader
            className={classes.center}
            title={
              <Typography variant="h6">
                Review and edit your current MICKRA profile
              </Typography>
            }
          ></CardHeader>
          <Divider />
          <CardContent>
            {hasHome ? (
              <List dense subheader={<li />} className={classes.noPad}>
                {Object.entries(userSettings.data.data).map((item, key) => (
                  <li key={key}>
                    {item[1]['value'] && (
                      <ul className={classes.noPad}>
                        <ListSubheader color="inherit">{`${snakeToFlat(
                          item[0],
                        )}`}</ListSubheader>

                        <ListItem>
                          <ListItemAvatar>
                            <UserSettingsHome
                              homeType={item[1]['type']}
                              homeValue={item[1]['value']}
                            />
                          </ListItemAvatar>
                          <Typography variant="subtitle2">
                            {snakeToFlat(item[1].type)}: {item[1].value}
                          </Typography>
                          <Tooltip
                            arrow
                            placement="right"
                            title={'Click to go to your saved home'}
                          >
                            <IconButton
                              color="inherit"
                              onClick={() => {
                                redirectHandler(item[1].type, item[1].value)
                              }}
                            >
                              <Launch />
                            </IconButton>
                          </Tooltip>
                        </ListItem>
                      </ul>
                    )}
                  </li>
                ))}
              </List>
            ) : (
              <div className={classes.padLeft}>
                <Typography variant="subtitle2">No Home Set</Typography>
                <br />
              </div>
            )}
            {/* RECENT SECTION */}
            {hasRecent ? (
              <div>
                <List dense subheader={<li />}>
                  {Object.entries(userSettings.recentData).map((item, key) => (
                    <li key={key}>
                      {item[1].length !== 0 && (
                        <ul className={classes.noPad}>
                          <ListItem color="inherit">
                            <Typography variant="subtitle2">
                              {`Recent ${snakeToFlat(item[0])}`}
                            </Typography>

                            {open[item[0]] ? (
                              <Tooltip
                                arrow
                                placement="right"
                                title={'Click to collapse'}
                              >
                                <IconButton
                                  onClick={() => {
                                    setOpen({ [item[0]]: !open[item[0]] })
                                  }}
                                >
                                  <ExpandLess />
                                </IconButton>
                              </Tooltip>
                            ) : (
                              <Tooltip
                                arrow
                                placement="right"
                                title={'Click to expand'}
                              >
                                <IconButton
                                  onClick={() => {
                                    setOpen({ [item[0]]: !open[item[0]] })
                                  }}
                                >
                                  <ExpandMore />
                                </IconButton>
                              </Tooltip>
                            )}
                          </ListItem>
                          {item[1].map((nestedItem, nestedKey) => (
                            <Collapse
                              key={nestedKey}
                              in={open[item[0]]}
                              timeout="auto"
                              unmountOnExit
                            >
                              <ListItem>
                                <ListItemAvatar>
                                  <Tooltip
                                    style={{ display: 'none' }}
                                    arrow
                                    placement="bottom"
                                    title={'Click to save as favorite'}
                                  >
                                    <IconButton>
                                      <StarOutline />
                                    </IconButton>
                                  </Tooltip>
                                </ListItemAvatar>
                                {item[0] === 'advanced_search' ? (
                                  <>
                                    <div className={classes.div}>
                                      {/* Older stored string queries */}
                                      {typeof nestedItem !== typeof {} ? (
                                        <Card className={classes.card}>
                                          <CardContent
                                            style={{ padding: '5px' }}
                                          >
                                            <Typography variant="body2">
                                              Query:
                                            </Typography>
                                            <div>
                                              {nestedItem
                                                .split('&')
                                                .map((chipItem, chipKey) => {
                                                  return (
                                                    <ListItem
                                                      key={chipKey}
                                                      style={{ padding: '2px' }}
                                                    >
                                                      <Chip
                                                        className={
                                                          classes.chipPadding
                                                        }
                                                        clickable={false}
                                                        size="small"
                                                        key={chipKey}
                                                        label={decodeURI(
                                                          chipItem,
                                                        ).replace('=', ' = ')}
                                                      />
                                                    </ListItem>
                                                  )
                                                })}
                                            </div>
                                          </CardContent>
                                          <Tooltip
                                            arrow
                                            placement="right"
                                            title={`Click to open`}
                                          >
                                            <Button
                                              className={classes.button}
                                              variant="outlined"
                                              color="inherit"
                                              onClick={() => {
                                                let query = []

                                                nestedItem
                                                  .split('&')
                                                  .forEach(
                                                    (chipItem, chipKey) => {
                                                      const row =
                                                        decodeURI(
                                                          chipItem,
                                                        ).split('=')
                                                      if (
                                                        row[0] !==
                                                        ('advanced' ||
                                                          undefined)
                                                      ) {
                                                        query.push({
                                                          field_name: row[0],
                                                          field_value: row[1],
                                                          operator: '=',
                                                        })
                                                      }
                                                    },
                                                  )
                                                localStorage.setItem(
                                                  'advanced_search',
                                                  JSON.stringify(query),
                                                )
                                                setDialogOpen(!isDialogOpen)
                                              }}
                                            >
                                              Open Search
                                            </Button>
                                          </Tooltip>
                                          <Tooltip
                                            arrow
                                            placement="right"
                                            title={`Delete query`}
                                          >
                                            <Button
                                              variant="outlined"
                                              color="inherit"
                                              onClick={() => {
                                                userSettings.deleteRecent(
                                                  'advanced_search',
                                                  nestedItem,
                                                )
                                                // To force render the recent section only
                                                setHasRecent(Math.random())
                                              }}
                                              style={{
                                                padding: '5px',
                                                margin: '5px',
                                              }}
                                            >
                                              Delete
                                            </Button>
                                          </Tooltip>
                                        </Card>
                                      ) : (
                                        <Card className={classes.card}>
                                          <CardContent
                                            style={{ padding: '5px' }}
                                          >
                                            <Typography variant="body2">
                                              Query:
                                            </Typography>
                                            <div>
                                              {Object.entries(
                                                nestedItem.query,
                                              ).map((row, index) => {
                                                return (
                                                  <ListItem
                                                    key={index}
                                                    style={{ padding: '2px' }}
                                                  >
                                                    <Chip
                                                      className={
                                                        classes.chipPadding
                                                      }
                                                      clickable={false}
                                                      size="small"
                                                      key={row[0]}
                                                      label={`${row[1]?.field_name} ${row[1]?.operator} ${row[1]?.field_value}`}
                                                    />
                                                  </ListItem>
                                                )
                                              })}
                                            </div>
                                            <Typography
                                              variant="body2"
                                              style={{
                                                marginTop: '5px',
                                                marginBottom: '5px',
                                              }}
                                            >
                                              Timestamp:{' '}
                                              <Chip
                                                size="small"
                                                label={new Date(
                                                  nestedItem.timestamp,
                                                ).toUTCString()}
                                              />
                                            </Typography>
                                          </CardContent>
                                          <Tooltip
                                            arrow
                                            placement="right"
                                            title={`Click to open`}
                                          >
                                            <Button
                                              className={classes.button}
                                              variant="outlined"
                                              color="inherit"
                                              onClick={() => {
                                                localStorage.setItem(
                                                  'advanced_search',
                                                  JSON.stringify(
                                                    nestedItem.query,
                                                  ),
                                                )
                                                setDialogOpen(!isDialogOpen)
                                              }}
                                            >
                                              Open Search
                                            </Button>
                                          </Tooltip>
                                          <Tooltip
                                            arrow
                                            placement="right"
                                            title={`Delete query`}
                                          >
                                            <Button
                                              variant="outlined"
                                              color="inherit"
                                              onClick={() => {
                                                userSettings.deleteRecentNested(
                                                  'advanced_search',
                                                  nestedItem,
                                                )
                                                setHasRecent(Math.random())
                                              }}
                                              style={{
                                                padding: '5px',
                                                margin: '5px',
                                              }}
                                            >
                                              Delete
                                            </Button>
                                          </Tooltip>
                                        </Card>
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <Card className={classes.card}>
                                      <CardContent style={{ padding: '5px' }}>
                                        <Typography variant="body2">
                                          Location:
                                          {nestedItem
                                            .split('&')
                                            .map((chipItem, chipKey) => {
                                              return (
                                                <Chip
                                                  className={
                                                    classes.chipPadding
                                                  }
                                                  clickable={false}
                                                  size="small"
                                                  key={chipKey}
                                                  label={decodeURI(
                                                    chipItem,
                                                  ).replace('=', ': ')}
                                                ></Chip>
                                              )
                                            })}
                                        </Typography>
                                      </CardContent>
                                      <Tooltip
                                        arrow
                                        placement="right"
                                        title={`Click to visit`}
                                      >
                                        <Button
                                          variant="outlined"
                                          color="inherit"
                                          onClick={() => {
                                            redirectHandler(item[0], nestedItem)
                                          }}
                                          style={{
                                            padding: '5px',
                                            marginLeft: '10px',
                                            marginRight: '10px',
                                          }}
                                        >
                                          Visit
                                        </Button>
                                      </Tooltip>
                                      <Tooltip
                                        arrow
                                        placement="right"
                                        title={`Delete`}
                                      >
                                        <Button
                                          variant="outlined"
                                          color="inherit"
                                          onClick={() => {
                                            userSettings.deleteRecent(
                                              'location_id',
                                              nestedItem,
                                            )
                                            setHasRecent(Math.random())
                                          }}
                                          style={{
                                            padding: '5px',
                                            margin: '5px',
                                          }}
                                        >
                                          Delete
                                        </Button>
                                      </Tooltip>
                                    </Card>
                                  </>
                                )}
                              </ListItem>
                            </Collapse>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </List>
              </div>
            ) : (
              <div className={classes.padLeft}>
                <Typography variant="subtitle2">No Recent Items</Typography>
              </div>
            )}
          </CardContent>
          <Divider />
          <CardActions className={classes.center}>
            <Tooltip
              disabled={
                userSettings.data.data &&
                userSettings.data.data.home &&
                userSettings.data.data.home.type === 'profile'
                  ? true
                  : false
              }
              arrow
              placement="left"
              title={'Set this Profile page as your default home'}
            >
              <Button
                variant="outlined"
                color="inherit"
                onClick={() => {
                  userSettings.updateHome('profile', session.userInfo.lanId)
                }}
              >
                Set Profile as Home
              </Button>
            </Tooltip>
            <Tooltip
              arrow
              placement="right"
              disabled={hasRecent ? false : true}
              title={'Clear recent items lists'}
            >
              <Button
                variant="outlined"
                color="inherit"
                onClick={() => {
                  userSettings.clearRecentCache()
                }}
              >
                Delete All Recent
              </Button>
            </Tooltip>
          </CardActions>
        </Card>
      </div>
    )
  } else {
    return (
      <React.Fragment>
        <Spinner />
      </React.Fragment>
    )
  }
}

const styles = () => ({
  button: {
    padding: '5px',
    marginLeft: '10px',
    marginRight: '10px',
  },
  card: {
    borderRadius: '10px',
    width: '1000px',
    margin: '10px',
  },
  center: {
    justifyContent: 'center',
    textAlign: 'center',
  },
  div: {
    maxWidth: '100%',
  },
  noPad: {
    padding: 0,
  },
  padLeft: {
    paddingLeft: 16,
  },
  chipPadding: {
    margin: 5,
    maxWidth: '95%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
})

export default withStyles(styles, { withTheme: true })(Profile)
