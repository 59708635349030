import { Button, ListItem } from '@material-ui/core'

import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core'

const styles = (theme) => ({
  link: {
    width: '100%',
    textDecoration: 'none',
    paddingTop: 50,
  },
  label: {
    justifyContent: 'flex-start',
  },
})

const NavigationButton = (props) => {
  return (
    <ListItem>
      <Link className={props.classes.link} to={props.location}>
        <Button fullWidth classes={{ label: props.classes.label }}>
          {props.text}
        </Button>
      </Link>
    </ListItem>
  )
}

export default withStyles(styles)(NavigationButton)
