import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'

import MUIDataTable from 'mui-datatables'
import React from 'react'
import Spinner from 'nicollet-react/es/components/Spinner/Spinner'
import SvcMac from '../../../services/svcMac'
// import SvcComputers from '../../../services/svcComputers'
import SvcWindows from '../../../services/svcWindows'
import TableOptionsConfig from '../TableOptionsConfig/TableOptionsConfig'
import computercolumns from '../TableColumnsConfig/ComputerColumnConfig'
import svcI10iDevices from '../../../services/svcI10iDevices'
import { withAnalytics } from '@praxis/component-analytics'
import { withAuth } from '@praxis/component-auth'
import { withEnv } from '@praxis/component-runtime-env'
import { withStyles } from '@material-ui/styles'

class DashboardPlatformsComputer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      storeWindowsComputers: [],
      storeMacComputers: [],
      storeComputersLoading: false,
    }
    this.svcWindows = new SvcWindows()
    this.svcMac = new SvcMac()
    this.svcI10i = new svcI10iDevices()
  }

  getWindowsData = async () => {
    await this.svcWindows
      .getStoreWindowsComputers(
        this.props.locationId.padStart(4, 0),
        this.props.env,
      )
      .then((response) => {
        const eventData = {
          customMetrics: {
            metric1: 'Computer',
            metric3: response.status,
          },
          event: {
            type: `apiSuccessComputer`,
          },
        }
        this.props.trackEvent(eventData)
        this.setState({
          storeWindowsComputers: response.data,
          storeComputersLoading: false,
        })
      })
      .catch((error) => {
        const eventData = {
          customMetrics: {
            metric1: 'Computer',
            metric3: error.response.status,
          },
          event: {
            type: `apiErrorComputer`,
          },
        }
        this.props.trackEvent(eventData)
        console.log('Unable to get response for Computer data.', error)
        this.setState({ storeComputersLoading: false })
      })
      .finally(() => {
        this.setState({ storeComputersLoading: false })
      })
  }

  getMacData = async () => {
    await this.svcMac
      .getStoreMacComputers(this.props.locationId, this.props.env)
      .then((response) => {
        const eventData = {
          customMetrics: {
            metric1: 'Computer',
            metric3: response.status,
          },
          event: {
            type: `apiSuccessComputer`,
          },
        }
        this.props.trackEvent(eventData)
        this.setState({
          storeMacComputers: response.data,
          storeComputersLoading: false,
        })
      })
      .catch((error) => {
        const eventData = {
          customMetrics: {
            metric1: 'Computer',
            metric3: error.response.status,
          },
          event: {
            type: `apiErrorComputer`,
          },
        }
        this.props.trackEvent(eventData)
        console.log('Unable to get response for Computer data.', error)
        this.setState({ storeComputersLoading: false })
      })
      .finally(() => {
        this.setState({ storeComputersLoading: false })
      })
  }

  async componentDidMount() {
    this.setState({ storeComputersLoading: true })
    await this.getWindowsData()
    await this.getMacData()
  }

  render() {
    // const { classes } = this.props
    return (
      <div>
        {/* <Paper>
          <Table>
            <TableHead>
              <TableRow
                hover
                className={classes.tableRow}
                onClick={this.props.handleCollapsedRow}
              >
                {this.props.collapsedRow ? (
                  <TableCell className={classes.tableRow}>
                    <List dense>
                      <ListItem className={classes.tableRow}>
                        <Typography variant="h6" className={classes.metrics}>
                          Dashboard
                        </Typography>
                        <ExpandMoreIcon />
                      </ListItem>
                    </List>
                  </TableCell>
                ) : (
                  <TableCell className={classes.tableRow}>
                    <List dense>
                      <ListItem className={classes.tableRow}>
                        <Typography variant="h6" className={classes.metrics}>
                          Dashboard
                        </Typography>
                        <ExpandLessIcon />
                      </ListItem>
                    </List>
                  </TableCell>
                )}
                <TableCell />
                <TableCell />
                <TableCell />
              </TableRow>
            </TableHead>
          </Table>
        </Paper>
        <Collapse
          in={!this.props.collapsedRow}
          timeout="auto"
          unmountOnExit
          className={classes.honeyComb}
        >
          <TopPanelCameras
            cameraDeviceTypeIP={this.state.cameraDeviceTypeIP}
            cameraDeviceTypeAnalog={this.state.cameraDeviceTypeAnalog}
            storeCameras={this.state.storeCameras}
          />
        </Collapse> */}
        {this.state.storeComputersLoading ? (
          <Spinner />
        ) : (
          <>
            <MuiThemeProvider theme={this.getMuiTheme()}>
              <MUIDataTable
                data={[
                  ...this.state.storeWindowsComputers,
                  ...this.state.storeMacComputers,
                ]}
                title={'Computers'}
                options={TableOptionsConfig(
                  [
                    ...this.state.storeWindowsComputers,
                    ...this.state.storeMacComputers,
                  ],
                  this.props.handleFiltersState,
                  this.props.platform,
                )}
                columns={computercolumns(
                  {
                    filteredMacOSX: this.props.filterMacOSX,
                    filterMicrosoftWindows10Pro:
                      this.props.filterMicrosoftWindows10Pro,
                  },
                  this.props.customFiltersComputers,
                  this.props.platform,
                )}
              />
            </MuiThemeProvider>
          </>
        )}
      </div>
    )
  }
  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTableToolbar: {
          filterPaper: {
            width: 250,
          },
        },
      },
    })
}
const styles = (theme) => ({
  honeyComb: {
    minHeight: `calc(100vh - 75px)`,
    overflow: 'auto',
    backgroundImage:
      `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='24.5' ` +
      `viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%23888888' fill-opacity='0.50' ` +
      `fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 ` +
      `11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 ` +
      `0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
  },
  tableRow: {
    cursor: 'cursor',
    width: 'flex',
    paddingLeft: 5,
    paddingTop: 0,
    paddingBottom: 0,
    height: 30,
  },
  metrics: {
    paddingLeft: 14,
  },
  na: {
    textAlign: 'center',
  },
})

export default withStyles(styles, { withTheme: true })(
  withAnalytics()(withAuth(null)(withEnv()(DashboardPlatformsComputer))),
)
