import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import Dialog from '@material-ui/core/Dialog'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Good from '@material-ui/icons/CheckCircleTwoTone'
import IconButton from '@material-ui/core/IconButton'
import ListItem from '@material-ui/core/ListItem'
import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import Spinner from 'nicollet-react/es/components/Spinner/Spinner'
import SvcHandhelds from '../../../services/svcHandhelds'
import SvcTablets from '../../../services/svcTablets'
import Tooltip from '@material-ui/core/Tooltip'
import { withAnalytics } from '@praxis/component-analytics'
import { withEnv } from '@praxis/component-runtime-env'
import withRouter from '../../withRouter'
import { withStyles } from '@material-ui/styles'

class OfflineHandheldDevice extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      snackBarOpen: false,
      snackBarMessageInfo: '',
      actionSuccess: false,
      loading: false,
    }
    this.svcHandhelds = new SvcHandhelds()
    this.svcTablets = new SvcTablets()
    this.goBack = this.goBack.bind(this)
  }
  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value })
  }
  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    this.setState({ snackBarOpen: false })
  }
  goBack() {
    this.props.router.navigate(0)
  }
  handleSubmit() {
    const {
      rowData: { id },
    } = this.props

    this.setState({ loading: true })

    this.svcHandhelds
      .offlineHandheldDevice(id, this.props.env)
      .then((success) => {
        const eventData = {
          customMetrics: {
            metric1: 'OfflineHandheldDevice',
            metric3: success.status,
          },
          event: {
            type: 'apiSuccessOfflineHandheldDevice',
          },
        }
        this.props.trackEvent(eventData)
        if (Math.round(success.status / 100) === 2) {
          this.setState({
            snackBarMessageInfo: 'Device marked as offline',
            snackBarOpen: true,
            actionSuccess: true,
          })
        }
      })
      .catch((error) => {
        const eventData = {
          customMetrics: {
            metric1: 'OfflineHandheldDevice',
            metric3: error.response.status,
          },
          event: {
            type: 'apiErrorOfflineHandheldDevice',
          },
        }
        this.props.trackEvent(eventData)
        if (
          Math.round(error.response.status / 100) === 5 ||
          Math.round(error.response.status / 100) === 4
        ) {
          if (
            error.response.data.message !== null ||
            error.response.data.message !== ''
          ) {
            this.setState({
              snackBarOpen: true,
              snackBarMessageInfo: error.response.data.message,
            })
          } else {
            this.setState({
              snackBarOpen: true,
              snackBarMessageInfo:
                'ERROR - An error has occurred while marking the device as offline',
            })
          }
        } else {
          this.setState({
            snackBarOpen: true,
            snackBarMessageInfo:
              'ERROR - An error has occurred while marking the device as offlne',
          })
        }
      })
      .finally(() => {
        this.setState({ loading: false })
      })
  }

  render() {
    const {
      classes,
      rowData: { id, mac_address },
    } = this.props
    const { snackBarMessageInfo, actionSuccess, loading } = this.state
    return (
      <Dialog
        fullWidth
        open={this.props.open}
        onClose={this.props.onClose}
        className={classes.dialog}
      >
        <DialogTitle>Confirm Marking Device as Offline</DialogTitle>
        <DialogContentText>
          Device: "{id}" with mac address: "{mac_address}"
        </DialogContentText>
        <DialogContentText>
          This will update the device to no longer be in a "bonded" state.
        </DialogContentText>
        <IconButton
          aria-label="Close"
          className={classes.icon}
          onClick={this.props.onClose}
        >
          <CloseIcon />
        </IconButton>
        <div>
          {loading ? (
            <Spinner className={classes.spinner} />
          ) : (
            <ListItem className={classes.list}>
              {!actionSuccess ? (
                <React.Fragment>
                  <Button
                    className={classes.submitButton}
                    variant="outlined"
                    onClick={() => this.handleSubmit()}
                  >
                    Confirm
                  </Button>
                  <Button
                    className={classes.cancelButton}
                    variant="outlined"
                    onClick={this.props.onClose}
                  >
                    CANCEL
                  </Button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Button
                    className={classes.successButton}
                    variant="outlined"
                    onClick={this.goBack}
                  >
                    BACK
                  </Button>
                  <Tooltip title="Success" placement="top">
                    <Good className={classes.good} />
                  </Tooltip>
                </React.Fragment>
              )}
            </ListItem>
          )}
        </div>
        <Snackbar
          open={this.state.snackBarOpen}
          autoHideDuration={5000}
          onClose={this.handleClose}
          message={snackBarMessageInfo}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.props.onClose}
            >
              <CloseIcon className={classes.CloseIcon} />
            </IconButton>,
          ]}
        />
      </Dialog>
    )
  }
}
const styles = {
  icon: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
  submitButton: {
    float: 'left',
  },
  cancelButton: {
    float: 'left',
    marginLeft: 5,
  },
  dialog: {
    textAlign: 'center',
  },
  successButton: {
    float: 'left',
  },
  list: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  spinner: {
    marginBottom: 15,
  },
  good: {
    fontSize: 40,
    color: 'green',
    paddingLeft: 5,
  },
}

export default withStyles(styles)(
  withRouter(withAnalytics()(withEnv()(OfflineHandheldDevice))),
)
