import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'

import DeviceLastSeenFormat from '../DeviceLastSeenFormat/DeviceLastSeenFormat'
import Divider from '@material-ui/core/Divider'
import MUIDataTable from 'mui-datatables'
import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import sort from '../../../utilities/sort'

class TableDataPrinterDetailsHeader extends React.Component {
  render() {
    return (
      <>
        <MuiThemeProvider theme={this.getMuiTheme()}>
          <MUIDataTable
            data={this.props.printerDetails}
            options={{
              selectableRows: 'none',
              rowHover: false,
              search: false,
              pagination: false,
              filter: false,
              print: false,
              download: false,
              elevation: 0,
              viewColumns: false,
            }}
            columns={[
              {
                name: 'label',
                label: 'Printer Label',
                options: {
                  filter: false,
                  sortCompare: (order) => {
                    return (a, b) => {
                      return new sort().label(a, b, order)
                    }
                  },
                  customBodyRender: (value, tableMeta, updateValue) => {
                    if (value) {
                      return value
                    } else {
                      return 'Not Set'
                    }
                  },
                },
              },
              {
                name: 'hostname',
                label: 'Hostname',
              },
              {
                name: 'unique_id',
                label: 'Unique ID',
              },
              {
                name: 'tcin',
                label: 'TCIN',
              },
              {
                name: 'device_state',
                label: 'State',
              },
              {
                name: 'department',
                label: 'Device Type',
              },
              {
                name: 'role',
                label: 'Print Mode',
              },
              {
                name: 'updated_on',
                label: 'Updated On',
                options: {
                  filter: false,
                  customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                      value && (
                        <Tooltip title={value}>
                          <div>
                            <DeviceLastSeenFormat lastSeenValue={value} />
                          </div>
                        </Tooltip>
                      )
                    )
                  },
                },
              },
            ]}
          />
        </MuiThemeProvider>
        <Divider style={{ backgroundColor: 'black' }} />
      </>
    )
  }
  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTableBodyRow: {
          root: {
            backgroundColor: '#eeeeee',
            height: 10,
            textAlign: 'center',
          },
        },
        MUIDataTableHeadRow: {
          root: {
            height: 24,
          },
        },
        MUIDataTableBodyCell: {
          root: {
            padding: 0,
          },
        },
        MUIDataTableHeadCell: {
          root: {
            padding: 0,
          },
        },
      },
    })
}

export default TableDataPrinterDetailsHeader
