import 'moment-timezone'

import {
  Button,
  DialogContent,
  Grid,
  ListItem,
  Typography,
} from '@material-ui/core'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'

import CloseIcon from '@material-ui/icons/Close'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import Divider from '@material-ui/core/Divider'
import EnrollmentStatus from './EnrollmentStatus'
import IconButton from '@material-ui/core/IconButton'
import { QRCode } from 'react-qr-svg'
import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import Spinner from 'nicollet-react/es/components/Spinner/Spinner'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import ValidateStoreNumber from '../../utilities/ValidateStoreNumber'
import { fade } from '@material-ui/core/styles/colorManipulator'
import moment from 'moment'
import svcHandhelds from '../../services/svcHandhelds'
import { withAnalytics } from '@praxis/component-analytics'
import { withAuth } from '@praxis/component-auth'
import { withEnv } from '@praxis/component-runtime-env'
import { withStyles } from '@material-ui/styles'

function TabPanel(props) {
  const { children, value, index } = props
  return value === index && children
}

class EnrollDevice extends React.Component {
  _isMounted = false
  constructor(props) {
    super(props)
    this.validateStoreNumber = new ValidateStoreNumber()
    this.state = {
      snackBarOpen: false,
      snackBarMessageInfo: '',
      submitDeviceType: null,
      enrollmentJsonLoading: false,
      enrollmentJson: [],
      showBarcode: false,
      searchText: '',
      submitSearchText: '',
      tabValue: 0,
      confirmDialogOpen: false,
      deviceType: '',
      responseString: '',
      barcodeColor: '',
      osVersion: '',
      manufacturer: '',
      endpoint_param: '',
    }
    this.svcHandhelds = new svcHandhelds()
  }

  handleClick = (event) => {
    this.setState({
      searchText: event.target.value,
    })
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    this.setState({ snackBarOpen: false })
  }

  handleSubmitDeviceType = (event) => {
    this._isMounted = true
    this.setState({ enrollmentJsonLoading: true })
    this.svcHandhelds
      .postTepEnrollment(this.props, this.state)
      .then((response) => {
        this.setState({
          enrollmentJson: response.data,
          showBarcode: true,
          submitSearchText: this.state.searchText,
        })
        const eventData = {
          customMetrics: {
            metric1: 'EnrollDevice',
            metric3: this.state.searchText,
          },
          event: {
            type: `apiSuccessEnrollDevice`,
          },
        }
        this.props.trackEvent(eventData)
      })
      .catch((error) => {
        if (
          Math.round(error.response.status / 100) === 5 ||
          Math.round(error.response.status / 100) === 4
        ) {
          if (
            error.response.data.message !== null ||
            error.response.data.message !== ''
          ) {
            this.setState({
              snackBarOpen: true,
              snackBarMessageInfo: error.response.data.message,
            })
          } else {
            this.setState({
              snackBarOpen: true,
              snackBarMessageInfo: 'Error dispalying the QR Code',
            })
          }
        } else {
          this.setState({
            snackBarOpen: true,
            snackBarMessageInfo: 'Error dispalying the QR Code',
          })
        }
        const eventData = {
          customMetrics: {
            metric1: 'EnrollDevice',
            metric3: error.response.status,
          },
          event: {
            type: `apiErrorEnrollDevice`,
          },
        }

        this.props.trackEvent(eventData)
        console.log(error)
      })
      .finally(() => {
        this._isMounted &&
          this.setState({
            enrollmentJsonLoading: false,
            confirmDialogOpen: false,
          })
      })
  }

  handleChange = (event, tabValue) => {
    this.setState({ tabValue })
    let tabName = null
    if (tabValue === 0) {
      tabName = 'enroll'
    } else if (tabValue === 1) {
      tabName = 'enrollmentStatus'
    }
    const eventData = {
      customInteraction: {
        key: 'Enroll Page',
        value: tabName === null ? 'enroll' : tabName,
      },
    }
    this.props.trackEvent(eventData)
  }

  ConfirmDialog = (props) => {
    return (
      <Dialog
        fullWidth
        open={props.open}
        onClose={props.onClose}
        className={this.props.classes.dialog}
      >
        <DialogTitle>You have selected device type:</DialogTitle>
        <DialogTitle>{this.state.searchText}</DialogTitle>
        <DialogContent>
          <ListItem className={this.props.classes.list}>
            <Button
              className={this.props.classes.submitButton}
              variant="outlined"
              onClick={() => this.handleSubmitDeviceType()}
            >
              Confirm
            </Button>
            <Button
              className={this.props.classes.cancelButton}
              variant="outlined"
              onClick={props.onClose}
            >
              CANCEL
            </Button>
          </ListItem>
        </DialogContent>
      </Dialog>
    )
  }

  handleDialog = (ButtonProperties) => {
    this.setState({
      confirmDialogOpen: true,
      searchText: ButtonProperties[0],
      deviceType: ButtonProperties[1].device_type,
      barcodeColor: ButtonProperties[1].barcode_color,
      responseString: ButtonProperties[1].response_string,
      osVersion: ButtonProperties[1].os_version,
      endpoint_param: ButtonProperties[1].endpoint_param,
      manufacturer: ButtonProperties[1].manufacturer,
      showBarcode: false,
    })
  }

  handleDialogClose = () => {
    this.setState({
      confirmDialogOpen: false,
      searchText: '',
      deviceType: '',
      barcodeColor: '',
      responseString: '',
      osVersion: '',
      endpoint_param: '',
      manufacturer: '',
      showBarcode: false,
    })
  }

  handleButtonHide = (ButtonProperties) => {
    // use enrollment access groups to hide\show enrollment buttons
    // pol user, validate button is enabled for pol

    let enabledScope = null
    if (this.props.isExternalDomain) {
      // is pol user, use pol portal enabled
      enabledScope = ButtonProperties[1].pol_portal_enabled
    } else {
      // is general mickra user, use enabled
      enabledScope = ButtonProperties[1].enabled
    }

    if (enabledScope) {
      if (
        ButtonProperties[1].endpoint_param === 'elo' &&
        this.props.env.access.enrollmentEloAccessGroup.some((r) =>
          this.props.auth.session.userInfo.memberOf.includes(r.toUpperCase()),
        )
      ) {
        return true
      } else if (
        ButtonProperties[1].endpoint_param === 'zebra' &&
        this.props.env.access.enrollmentZebraAccessGroup.some((r) =>
          this.props.auth.session.userInfo.memberOf.includes(r.toUpperCase()),
        )
      ) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  render() {
    const { classes } = this.props
    const { snackBarMessageInfo } = this.state
    return (
      <>
        <MuiThemeProvider theme={this.getMuiTheme()}>
          <Tabs
            value={this.state.tabValue}
            variant="fullWidth"
            className={classes.tabs}
            onChange={this.handleChange}
            TabIndicatorProps={{ className: classes.tabsProps }}
            centered
          >
            <Tab
              label={<span className={classes.tabLabel}>TEP Enrollment</span>}
              className={classes.tab}
            />
            <Tab
              label={
                <span className={classes.tabLabel}>Enrollment Status</span>
              }
              className={classes.tab}
            />
          </Tabs>
          {
            <>
              <TabPanel value={this.state.tabValue} index={0}>
                <Grid container spacing={5}>
                  <Grid container>
                    <Grid container item style={{ maxWidth: 675 }}>
                      <Typography className={classes.messageStyle}>
                        {this.props.env.enrollmentMessage}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    style={{
                      justifyContent: 'center',
                      marginTop: 50,
                      marginBottom: 25,
                    }}
                  >
                    <Typography variant="h5">
                      {this.props.env.enrollDeviceList.search_label}
                    </Typography>
                  </Grid>
                  <Grid container style={{ justifyContent: 'center' }}>
                    {Object.entries(this.props.env.enrollDeviceList.config).map(
                      (row, index) => {
                        return (
                          <Button
                            size="medium"
                            variant="contained"
                            color="#999999"
                            key={index}
                            style={{
                              display: this.handleButtonHide(row)
                                ? null // don't hide it if authorized
                                : 'none',
                              padding: 25,
                              width: 'min-content',
                              marginLeft: 50,
                              border: '2px solid',
                              borderRadius: '8px',
                              backgroundColor:
                                this.state.barcodeColor === row[1].barcode_color
                                  ? `${fade(row[1].barcode_color, 0.1)}`
                                  : '',
                              borderColor:
                                this.state.barcodeColor === row[1].barcode_color
                                  ? row[1].barcode_color
                                  : '#666666',
                            }}
                            onClick={() => this.handleDialog(row)}
                          >
                            {row[0]}
                          </Button>
                        )
                      },
                    )}
                  </Grid>
                </Grid>
                <div style={{ textAlign: 'center', paddingTop: 25 }}>
                  {this.state.enrollmentJsonLoading ? (
                    <Spinner
                      layout="selfCentering"
                      style={{ paddingTop: '100px' }}
                    />
                  ) : (
                    this.state.showBarcode === true && (
                      <>
                        <Divider
                          style={{ marginTop: 25, height: '5px' }}
                        ></Divider>
                        <>
                          <Typography
                            variant="subtitle2"
                            className={classes.textStyle}
                          >
                            {this.state.responseString}
                          </Typography>
                          <QRCode
                            level="Q"
                            fgColor={this.state.barcodeColor}
                            style={{
                              width: 256,
                            }}
                            value={JSON.stringify(
                              this.state.enrollmentJson.qr_code_info,
                            )}
                          />
                        </>

                        <Typography
                          variant="button"
                          className={classes.expiryText}
                        >
                          Barcode expires at{' '}
                          {moment(
                            this.state.enrollmentJson.details
                              .expiry_time_for_qr_code,
                          ).format('YYYY-MM-DD HH:mm:ss')}
                          (
                          {moment
                            .tz(
                              Intl.DateTimeFormat().resolvedOptions().timeZone,
                            )
                            .zoneAbbr()}
                          )
                        </Typography>
                      </>
                    )
                  )}
                </div>
                <Snackbar
                  open={this.state.snackBarOpen}
                  autoHideDuration={3000}
                  onClose={this.handleClose}
                  message={snackBarMessageInfo}
                  action={[
                    <IconButton
                      key="close"
                      aria-label="Close"
                      color="inherit"
                      onClick={this.handleClose}
                    >
                      <CloseIcon className={classes.CloseIcon} />
                    </IconButton>,
                  ]}
                />
              </TabPanel>
              <TabPanel value={this.state.tabValue} index={1}>
                <EnrollmentStatus props={this.props}></EnrollmentStatus>
              </TabPanel>
              {this.state.confirmDialogOpen && (
                <this.ConfirmDialog
                  open={this.state.confirmDialogOpen}
                  onClose={() => {
                    this.handleDialogClose()
                  }}
                  {...this.props}
                />
              )}
            </>
          }
        </MuiThemeProvider>
      </>
    )
  }
  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MuiTab: {
          root: { '&$selected': { backgroundColor: '#fff' } },
        },
      },
    })
}

const styles = {
  dialog: {
    textAlign: 'center',
  },
  list: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  submitButton: {
    float: 'left',
  },
  cancelButton: {
    float: 'left',
    marginLeft: 5,
  },
  icon: {
    color: 'black',
  },
  dropDown: {
    marginLeft: 20,
    minWidth: 250,
    width: 250,
    marginRight: 25,
  },
  root: {
    justifyContent: 'space-around',
    marginTop: 5,
  },
  gridList: {
    width: '800px',
    height: '600px',
    paddingTop: 20,
  },
  messageStyle: {
    paddingTop: 30,
    marginLeft: 30,
    fontSize: 18,
    color: 'black',
    textAlign: 'justify',
  },
  expiryText: {
    color: '#000',
    display: 'block',
    paddingTop: '20px',
  },
  textStyle: {
    color: '#000',
    display: 'block',
    paddingTop: '20px',
    paddingBottom: '25px',
    textAlign: 'center',
    fontSize: 'large',
  },
  divStyle: {
    display: 'inlineFlex',
    justifyContent: 'center',
    paddingTop: '50px',
  },
  button: {
    marginTop: 30,
    marginBottom: 5,
    width: 75,
    height: 30,
  },
  tab: {
    borderRight: '1px solid #666666',
    borderLeft: '1px solid #666666',
    borderTop: '1px solid #666666',
    borderBottom: '1px solid #666666',
    borderRadius: '5px 5px 0px 0px',
    backgroundColor: '#999999',
  },
  tabs: {
    marginTop: 70,
  },
  tabsProps: {
    backgroundColor: `${fade('#fff', 0.1)}`,
    borderBottom: '1px solid white',
  },
  tabLabel: {
    fontSize: 18,
  },
  qrCode: {
    '.my-qr-code-cell-empty': {
      fill: 'lightgray',
    },
    '.my-qr-code-cell-filled': {
      fill: 'brown',
    },
  },
}
export default withStyles(styles)(
  withAuth(null)(withAnalytics()(withEnv()(EnrollDevice))),
)
