import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'

import ActionMenu from './ActionMenu/ActionMenu'
import AndroidPhone from '@material-ui/icons/PhoneAndroid'
import ComputerIcon from '@material-ui/icons/Computer'
import CustomDownload from './CustomDownload/CustomDownload'
import CustomDownloadAdvanced from './CustomDownloadAdvanced/CustomDownload'
import CustomDownloadI10i from './CustomDownloadI10i/CustomDownload'
import CustomSearch from './CustomSearch/CustomSearch'
import DeviceExpansionBTScanners from './DeviceExpansion/DeviceExpansionBTScanners'
// import DeviceExpansionCamera from './DeviceExpansion/DeviceExpansionCamera'
import DeviceExpansionHandheld from './DeviceExpansion/DeviceExpansionHandheld'
import DeviceExpansionI10iCore from './DeviceExpansion/DeviceExpansionI10iCore'
import DeviceExpansionMac from './DeviceExpansion/DeviceExpansionMac'
import DeviceExpansionPrinter from './DeviceExpansion/DeviceExpansionPrinter'
import DeviceExpansionRFIDHandheld from './DeviceExpansion/DeviceExpansionRFIDHandheld'
import DeviceExpansionTablet from './DeviceExpansion/DeviceExpansionTablet'
import DeviceExpansionWindows from './DeviceExpansion/DeviceExpansionWindows'
import DeviceLastSeenFormat from '../Dashboard/DeviceLastSeenFormat/DeviceLastSeenFormat'
import DevicesIcon from '@material-ui/icons/Devices'
import IconButton from '@material-ui/core/IconButton'
import Kiosk from '@material-ui/icons/TabletAndroid'
import LocationCityIcon from '@material-ui/icons/LocationCity'
import MUIDataTable from 'mui-datatables'
import OndemandVideoIcon from '@material-ui/icons/OndemandVideo'
import Paper from '@material-ui/core/Paper'
import PrintIcon from '@material-ui/icons/Print'
import React from 'react'
import RingScanners from '../../images/ringScannerLanding.svg'
import Spinner from 'nicollet-react/es/components/Spinner/Spinner'
import Svci10iDevices from '../../services/svcI10iDevices'
import TableCell from '@material-ui/core/TableCell'
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Tooltip from '@material-ui/core/Tooltip'
import TopPanelMobileHelp from '../Dashboard/TopPanel/TopPanelHelp/TopPanelMobileHelp'
// import Videocam from '@material-ui/icons/Videocam'
import { columnMap } from './I10iExports/ColumnMap'
import l3Classifications from './I10iExports/l3Classifications'
import l3categoryMap from './I10iExports/l3CategoryMap'
import l4CamelCase from './I10iExports/l4CamelCase'
import l4DeviceTypes from './I10iExports/l4DeviceTypes'
import moment from 'moment'
import { parseLinkHeader } from '@web3-storage/parse-link-header'
import queryString from 'query-string'
import { redirectHandler } from '../../utilities/RedirectHandler'
import svcDevices from '../../services/svcDevices'
import { withAnalytics } from '@praxis/component-analytics'
import { withEnv } from '@praxis/component-runtime-env'
import withRouter from '../withRouter'
import { withStyles } from '@material-ui/styles'
import withUserSettingsContext from '../../contexts/withUserSettingsContext'

class Devices extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isi10i: undefined,
      isAdvancedSearch: false,
      deviceInfo: null,
      loading: false,
      link: null,
      i10ilink: null,
      count: null,
    }
    this.loadingFlag = false
    this.svcDevices = new svcDevices()
    this.svcI10iDevices = new Svci10iDevices()
    this.submission = {}
    this.excludeList = []
    this.advancedMapping = {}
    this.page = 0
    this.perPage = 50
    this.pageCount = 0
    this.downloadConfig = null
    this.downloadSumission = null
    this.deviceIcons = {
      // camera: <Videocam style={{ width: 18, height: 18 }} />,
      computer: <ComputerIcon style={{ width: 18, height: 18 }} />,
      handheld: <AndroidPhone style={{ width: 18, height: 18 }} />,
      printer: <PrintIcon style={{ width: 18, height: 18 }} />,
      tablet: <Kiosk style={{ width: 18, height: 18 }} />,
      bluetoothscanner: <RingScanners style={{ width: 18, height: 18 }} />,
      digitalMedia: <OndemandVideoIcon style={{ width: 18, height: 18 }} />,
      allDevices: <DevicesIcon style={{ width: 18, height: 18 }} />,
    }
  }

  componentWillUnmount() {
    this.setState({ deviceInfo: [] })
  }

  componentDidMount() {
    const searchParam = new URLSearchParams(this.props.router.location.search)
    const isAdvancedSearch = searchParam.get('advanced') === 'true'
    this.setState({ isAdvancedSearch: isAdvancedSearch })

    if (isAdvancedSearch) {
      this.getAdvancedSearchData()
    } else {
      let classification_name = queryString.parse(
        this.props.router.location.search,
      ).classification_name
      if (
        classification_name in this.props.env.deviceClassificationMap ===
          false &&
        classification_name !== undefined
      ) {
        window.location = window.location.origin + `/devices?`
      }
      if (
        Object.keys(this.submission).includes('classification_name') ||
        Object.keys(this.submission).includes('device_type')
      ) {
        if (
          l3Classifications.includes(this.submission.classification_name) ||
          (l4CamelCase.includes(this.submission.device_type) &&
            !this.props.env.i10iFeaturesDisabled)
        ) {
          this.getI10iData()
        } else {
          this.getData()
        }
      } else {
        this.getData()
      }
    }
  }

  changePage = (page) => {
    this.page = page
    if (this.state.isAdvancedSearch === true) {
      this.getAdvancedSearchData(page)
    } else if (this.state.isi10i === true) {
      let newLink = this.state.i10ilink

      this.getI10iData(newLink)
    } else {
      this.getData(page)
    }
  }
  changeRow = (perPage) => {
    this.perPage = perPage
    this.page = 0
    if (this.state.isAdvancedSearch === true) {
      this.getAdvancedSearchData()
    } else if (this.state.isi10i) {
      this.getI10iData()
    } else {
      this.getData()
    }
  }

  toFriendly = (s) =>
    s
      .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase())
      .replace(/[-_]+(.)/g, (_, c) => ' ' + c.toUpperCase())

  toCamel = (s) => {
    return s.replace(/([-_][a-z])/gi, ($1) => {
      return $1.toUpperCase().replace('-', '').replace('_', '')
    })
  }

  getClassification = (property) => {
    for (const [key, value] of Object.entries(l3categoryMap)) {
      if (value.includes(property)) {
        return String(key)
      }
    }
  }

  getI10iData = (page) => {
    this.setState({ devicesLoading: true, isi10i: true })

    this.downloadConfig = this.props.env
    this.downloadSumission = Object.assign(
      queryString.parse(this.props.router.location.search),
      {
        page: page ? page : this.page,
        per_page: this.perPage,
      },
    )
    this.svcI10iDevices
      .getDevicesElasticQuery(
        this.downloadConfig,
        this.downloadSumission,
        null,
        false,
      )
      .then((response) => {
        let responseData = !response?.data?.i10i ? response.data : response.data

        if (!responseData.length > 0) {
          throw new Error('404')
        }
        const eventData = {
          customMetrics: {
            metric1: 'Devices-i10i',
            metric3: response.status,
          },
          event: {
            type: `apiSuccessDevices-i10i`,
          },
        }
        this.props.trackEvent(eventData)

        for (var k = 0; k < responseData.length; k++) {
          let obj = responseData[k]
          let property, classification

          // add both classification and device type to each entry of the response
          l4DeviceTypes.map(
            (x) =>
              obj.hasOwnProperty(x) === true &&
              ((property = x), (classification = this.getClassification(x))),
          )

          responseData[k] = Object.assign(
            {
              classification_name: classification,
              device_type: property,
            },
            obj,
          )
        }

        let totalCount = parseInt(response.headers.number_of_records)
        this.pageCount = response.headers.number_of_pages
        this.setState({
          i10ilink: response.headers.rel,
          deviceInfo: responseData,
          count: totalCount,
        })
      })
      .catch((error) => {
        const eventData = {
          customMetrics: {
            metric1: 'Devices-i10i',
            metric3: error.response?.status || 500,
          },
          event: {
            type: `apiErrorDevices-i10i`,
          },
        }

        this.setState({ count: 0, deviceInfo: [] })
        this.props.trackEvent(eventData)
      })
      .finally(() => {
        this.loadingFlag = false
        this.setState({ devicesLoading: false })
      })
  }

  getAdvancedSearchData = (page) => {
    this.setState({ devicesLoading: true })
    this.svcDevices
      .getAdvancedSearchMapping(this.props.env.auth)
      .then((mapping) => {
        this.advancedMapping = mapping.data

        let query = []
        Object.entries(
          queryString.parse(this.props.router.location.search),
        ).forEach((row) => {
          const field_name = mapping.data.find(
            (option) => option.field_name === row[0],
          )?.field_name
          if ((row[0] !== 'advanced') & (field_name !== undefined)) {
            if (
              row[0] === 'classification_name' &&
              (row[1] === '*' || row[1].indexOf('|') !== -1)
            ) {
              // To support '*' as all
            } else {
              query.push({
                field_name: field_name,
                field_value: row[1],
                operator: '=',
              })
            }
          }
        })

        localStorage.setItem('advanced_search', JSON.stringify(query))
        const eventData = {
          customInteraction: {
            key: 'AdvancedSearch',
            value: JSON.stringify(query),
          },
        }
        this.props.trackEvent(eventData)

        this.props.userSettings.updateRecent('advanced_search', {
          timestamp: moment.now(),
          query: query,
        })

        redirectHandler('advanced_search')
      })
  }

  getData = (page) => {
    this.setState({ devicesLoading: true })

    this.svcDevices
      .getDevices(
        this.props.env,
        Object.assign(queryString.parse(this.props.router.location.search), {
          page: page ? page : this.page,
          per_page: this.perPage,
        }),
      )
      .then((response) => {
        this.setState({ isi10i: false })
        const eventData = {
          customMetrics: {
            metric1: 'Devices',
            metric3: response.status,
          },
          event: {
            type: `apiSuccessDevices`,
          },
        }
        this.props.trackEvent(eventData)

        var linkHeader = parseLinkHeader(response.headers.link).last

        this.pageCount =
          linkHeader.page === '0' ? 1 : parseInt(linkHeader.page) + 1
        var totalCount =
          linkHeader.page === '0'
            ? response.data.length
            : (parseInt(linkHeader.page) + 1) * parseInt(linkHeader.per_page)

        this.setState({
          deviceInfo: response.data,
          link: response.headers.link,
          totalCount: response.headers.total,
        })

        this.state.totalCount !== totalCount &&
          this.setState({ count: totalCount })
      })

      .catch((error) => {
        const eventData = {
          customMetrics: {
            metric1: 'Devices',
            metric3: error.response.status,
          },
          event: {
            type: `apiErrorDevices`,
          },
        }

        this.props.trackEvent(eventData)

        //if the item is not found on mickra --> search i10i
        if (eventData.customMetrics.metric3 === 404) {
          // set a flag to keep loading instead of setting false in the  next block
          this.loadingFlag = true
          if (!this.props.env.i10iFeaturesDisabled) {
            this.getI10iData()
          } else {
            this.loadingFlag = false
            this.setState({ devicesLoading: false, count: 0, deviceInfo: [] })
          }
        } else {
          this.setState({ count: 0, deviceInfo: [] })
        }
      })
      .finally(() => {
        if (!this.loadingFlag) {
          this.setState({
            devicesLoading: false,
          })
        }
      })
  }

  ColumnBuilder = () => {
    let includeClassification = null
    let includeDeviceType = null

    if (Object.keys(this.submission).includes('classification_name')) {
      includeClassification = this.submission.classification_name
    } else includeClassification = 'all'

    if (Object.keys(this.submission).includes('device_type')) {
      includeDeviceType = this.submission.device_type
    }

    let includeParams = null
    let includeParamsObj = null

    includeParams = Object.entries(this.submission).filter(
      (p) => p[0] === 'products.name' || p[0] === 'products.version_name',
    )
    if (includeParams.length > 0) {
      includeParamsObj = Object.fromEntries(includeParams)
    }

    let platformColumns = []
    // only reveal classification_name column when its a non classification search
    let excluded = null
    let columns = []
    includeClassification === 'all'
      ? (excluded = true)
      : (excluded = 'excluded')

    function addAttributeInput(obj, run, objkeys) {
      Object.entries(obj).forEach((prop) => {
        if (Array.isArray(prop[1])) {
          return
        } else if (prop[1] != null && typeof prop[1] == 'object') {
          addAttributeInput(prop[1], run + prop[0] + '.', objkeys)
        } else {
          objkeys.push(run + prop[0])
        }
      })
    }

    if (
      this.state.isAdvancedSearch === true &&
      this.state.deviceInfo.length > 0
    ) {
      const allKeys = this.state.deviceInfo.reduce((keys, obj) => {
        var objkeys = []
        addAttributeInput(obj, '', objkeys)
        return keys.concat(objkeys)
      }, [])

      const uniqueColumns = [...new Set(allKeys)]
      uniqueColumns.forEach((column) => {
        if (
          ['updated_on', 'updated_at', 'insights.updated_at'].includes(column)
        ) {
          columns.push({
            name: column,
            label: column,
            options: {
              filter: false,
              customBodyRenderLite: (dataIndex) => {
                const columnNames = column.split('.')
                const value = columnNames.reduce(
                  (obj, propName) => obj && obj[propName],
                  this.state.deviceInfo[dataIndex],
                )
                if (value !== undefined && value !== null) {
                  const date = new Date(value)
                  return (
                    <div style={{ whiteSpace: 'nowrap', padding: '5px' }}>
                      {moment(date).add(23, 'hours').isAfter(/*now*/) === true
                        ? moment(date).fromNow().toString()
                        : moment(date).format('llll').toString()}
                    </div>
                  )
                } else return ''
              },
            },
          })
        } else {
          columns.push({
            name: column,
            label: column,
            options: {
              filter: false,
              customBodyRenderLite: (dataIndex) => {
                const columnNames = column.split('.')
                const value = columnNames.reduce(
                  (obj, propName) => obj && obj[propName],
                  this.state.deviceInfo[dataIndex],
                )
                if (value !== undefined && value !== null) {
                  return (
                    <div style={{ whiteSpace: 'nowrap', padding: '5px' }}>
                      {value.toString()}
                    </div>
                  )
                } else return ''
              },
            },
          })
        }
      })
      return columns
    } else if (
      this.state.isAdvancedSearch === true &&
      this.state.deviceInfo.length === 0
    ) {
      columns.push(
        'id',
        'serial_number',
        'updated_on',
        'classification_name',
        'model_name',
        'device_state',
        'mac_address',
        'location_id',
        'region_id',
        'group_id',
        'district_id',
        'manufacturer_name',
      )
      return columns
    } else {
      /*
        this is the template for columnns to be revealed in the main table
        */
      columns = [
        {
          reveal: [
            'all',
            'Handheld',
            'Tablet',
            'Camera',
            'Printer',
            'Windows',
            'Mac',
            'Bluetooth Scanner',
            'RFID',
            ...l3Classifications,
          ],
          name: 'classification_name',
          label: 'Classification',
          options: {
            display: excluded,
            filter: false,
          },
        },

        {
          reveal: l3Classifications,
          name: 'specific_object_name',
          label: 'Device Type',
          options: {
            filter: false,
            customBodyRender: (value) => {
              return value && this.toFriendly(value)
            },
          },
        },
        {
          reveal: l3Classifications,
          name: 'unique_id',
          label: 'Unique ID',
          options: {
            filter: false,
          },
        },
        {
          reveal: l3Classifications,
          name: 'tcin',
          label: 'TCIN',
          options: {
            filter: false,
          },
        },
        {
          reveal: l3Classifications,
          name: 'tcin_info',
          label: 'Model',
          options: {
            filter: false,
            customBodyRender: (value) => {
              if (value !== undefined && value !== null) {
                return <>{value.model}</>
              }
            },
          },
        },
        {
          reveal: l3Classifications,
          name: 'tcin_info',
          label: 'Manufacturer',
          options: {
            filter: false,
            customBodyRender: (value) => {
              if (value !== undefined && value !== null) {
                return <>{value.manufacturer}</>
              }
            },
          },
        },

        {
          reveal: columnMap.hostName,
          name: 'hostname',
          label: 'Host Name',
          options: {
            filter: false,
          },
        },

        {
          reveal: [
            'all',
            'Handheld',
            'Tablet',
            'Camera',
            'Printer',
            'Windows',
            'Mac',
            'Bluetooth Scanner',
            'RFID',
          ],
          name: 'id',
          label: 'ID',
          options: {
            filter: false,
          },
        },

        {
          reveal: [
            'all',
            'Handheld',
            'Tablet',
            'Printer',
            'Bluetooth Scanner',
            'RFID',
            ...l3Classifications,
          ],
          name: 'serial_number',
          label: 'Serial Number',
          options: {
            filter: false,
          },
        },
        {
          reveal: [
            'all',
            'Handheld',
            'Tablet',
            'Camera',
            'Printer',
            'Windows',
            'Mac',
          ],
          name: 'model_name',
          label: 'Model',
          options: {
            filter: false,
          },
        },
        {
          reveal: [
            'all',
            'Handheld',
            'Tablet',
            'Windows',
            'Mac',
            'Voice Gateway',
          ],
          name: 'manufacturer_name',
          label: 'Manufacturer',
        },
        {
          reveal: ['all', 'Windows', 'Mac'],
          name: 'operating_system',
          label: 'OS',
        },
        {
          reveal: ['all', 'Handheld', 'Tablet', 'Windows', 'Mac'],
          name: 'operating_system_version',
          label: 'OS Version',
        },
        {
          reveal: [
            'all',
            'Handheld',
            'Tablet',
            'Camera',
            'Printer',
            'Windows',
            'Bluetooth Scanner',
            'RFID',
            ...l3Classifications,
          ],
          name: 'location.location_id',
          label: 'Location',
          options: {
            filter: false,
            customBodyRenderLite: (dataIndex, rowIndex) => {
              let location =
                this.state.deviceInfo[dataIndex].location.location_id
              let Classification =
                this.state.deviceInfo[dataIndex].classification_name

              let deviceType
              if (l3Classifications.includes(Classification)) {
                deviceType = this.state.deviceInfo[dataIndex].device_type
                location = this.state.deviceInfo[dataIndex].tnum
                location = String(location).toUpperCase().replace('T', '')
              }

              if (
                location &&
                (location === 'unknown' || location === 'Location code not set')
              ) {
                return location
              } else if (includeDeviceType === 'networkChassis') {
                return (
                  location &&
                  location !== 'null' && (
                    <>
                      {location}
                      <Tooltip title={'No additional location info available.'}>
                        <IconButton>
                          <LocationCityIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  )
                )
              } else {
                return (
                  location &&
                  location !== 'null' && (
                    <>
                      {'T' + location}
                      <Tooltip
                        title={
                          l3Classifications.includes(Classification)
                            ? `Click to see all ${this.toFriendly(
                                deviceType,
                              )} devices at location T` + location
                            : 'Click to see ' +
                              this.props.env.deviceClassificationMapReverse[
                                Classification
                              ] +
                              's at location ' +
                              location
                        }
                      >
                        {Classification === 'handheld' ||
                        Classification === 'tablet' ? (
                          <IconButton
                            onClick={() => {
                              window.location =
                                window.location.origin +
                                `/location/${location}?platform=${this.props.env.deviceClassificationMapReverse[Classification]}`
                            }}
                          >
                            <LocationCityIcon />
                          </IconButton>
                        ) : Classification === 'rfidhandheld' ? (
                          <IconButton
                            disabled={!/^[Tt]\d{0,4}$|^\d{0,4}$/.test(location)}
                            onClick={() => {
                              window.location =
                                window.location.origin +
                                `/location/${parseInt(
                                  String(location),
                                )}?platform=rfidHandheld`
                            }}
                          >
                            <LocationCityIcon />
                          </IconButton>
                        ) : (
                          <IconButton
                            disabled={!/^[Tt]\d{0,4}$|^\d{0,4}$/.test(location)}
                            onClick={() => {
                              window.location =
                                window.location.origin +
                                `/location/${parseInt(
                                  String(location),
                                )}?platform=${
                                  l3Classifications.includes(Classification)
                                    ? this.toCamel(deviceType)
                                    : Classification
                                }`
                            }}
                          >
                            <LocationCityIcon />
                          </IconButton>
                        )}
                      </Tooltip>
                    </>
                  )
                )
              }
            },
          },
        },
        {
          reveal: [
            'all',
            'Handheld',
            'Tablet',
            'Camera',
            'Printer',
            'Bluetooth Scanner',
          ],
          name: 'location.region_id',
          label: 'Region',
          options: {
            filter: false,
            customBodyRenderLite: (dataIndex) => {
              let region = this.state.deviceInfo[dataIndex].location.region_id
              let Classification =
                this.state.deviceInfo[dataIndex].classification_name

              let redirectParams = queryString.stringify(
                Object.assign(
                  {
                    region_id: region,
                    classification_name:
                      this.props.env.deviceClassificationMapReverse[
                        Classification
                      ],
                  },
                  includeParamsObj,
                ),
                {
                  sort: false,
                  skipNull: true,
                },
              )

              return (
                region &&
                region !== 'null' &&
                region !== 'unknown' && (
                  <>
                    {region}
                    <Tooltip
                      title={`Click to see all ${this.props.env.deviceClassificationMapReverse[Classification]}s at Region ${region}`}
                    >
                      <IconButton
                        onClick={() => {
                          window.location =
                            window.location.origin +
                            `/devices?${redirectParams}`
                        }}
                      >
                        {this.deviceIcons[Classification]}
                      </IconButton>
                    </Tooltip>
                  </>
                )
              )
            },
          },
        },
        {
          reveal: [
            'all',
            'Handheld',
            'Tablet',
            'Camera',
            'Printer',
            'Bluetooth Scanner',
          ],
          name: 'location.group_id',
          label: 'Group',
          options: {
            filter: false,
            customBodyRenderLite: (dataIndex) => {
              let group = this.state.deviceInfo[dataIndex].location.group_id
              let Classification =
                this.state.deviceInfo[dataIndex].classification_name

              let redirectParams = queryString.stringify(
                Object.assign(
                  {
                    group_id: group,
                    classification_name:
                      this.props.env.deviceClassificationMapReverse[
                        Classification
                      ],
                  },
                  includeParamsObj,
                ),
                {
                  sort: false,
                  skipNull: true,
                },
              )

              return (
                group &&
                group !== 'null' && (
                  <>
                    {group}

                    <Tooltip
                      title={`Click to see all ${this.props.env.deviceClassificationMapReverse[Classification]}s at Group ${group}`}
                    >
                      <IconButton
                        onClick={() => {
                          window.location =
                            window.location.origin +
                            `/devices?${redirectParams}`
                        }}
                      >
                        {this.deviceIcons[Classification]}
                      </IconButton>
                    </Tooltip>
                  </>
                )
              )
            },
          },
        },
        {
          reveal: [
            'all',
            'Handheld',
            'Tablet',
            'Camera',
            'Printer',
            'Bluetooth Scanner',
          ],
          name: 'location.district_id',
          label: 'District',
          options: {
            filter: false,
            customBodyRenderLite: (dataIndex) => {
              let district =
                this.state.deviceInfo[dataIndex].location.district_id
              let Classification =
                this.state.deviceInfo[dataIndex].classification_name

              let redirectParams = queryString.stringify(
                Object.assign(
                  {
                    district_id: district,
                    classification_name:
                      this.props.env.deviceClassificationMapReverse[
                        Classification
                      ],
                  },
                  includeParamsObj,
                ),
                {
                  sort: false,
                  skipNull: true,
                },
              )

              return (
                district &&
                district !== 'null' && (
                  <>
                    {district}
                    <Tooltip
                      title={`Click to see all ${this.props.env.deviceClassificationMapReverse[Classification]}s at District ${district}`}
                    >
                      <IconButton
                        onClick={() => {
                          window.location =
                            window.location.origin +
                            `/devices?${redirectParams}`
                        }}
                      >
                        {this.deviceIcons[Classification]}
                      </IconButton>
                    </Tooltip>
                  </>
                )
              )
            },
          },
        },
        {
          reveal: ['all', 'Camera', 'Printer'],
          name: 'network.ip_address',
          label: 'IP Address',
          options: {
            filter: false,
            customBodyRenderLite: (dataIndex) => {
              if (this.state.deviceInfo[dataIndex].network !== null) {
                let ipAddress =
                  this.state.deviceInfo[dataIndex].network.ip_address
                return ipAddress && ipAddress !== 'null' && ipAddress
              }
            },
          },
        },
        {
          reveal: columnMap.ip,
          name: 'network.ip_address',
          label: 'Layer 3 IP Address',
          options: {
            filter: false,
            customBodyRenderLite: (dataIndex) => {
              if (this.state.deviceInfo[dataIndex].network !== null) {
                let ipAddress =
                  this.state.deviceInfo[dataIndex].network[0].layer3addr

                return ipAddress && ipAddress !== 'null' && ipAddress
              }
            },
          },
        },
        {
          reveal: columnMap.macAddress,
          name: 'network[0].layer2addr',
          label: 'Mac Address',
          options: {
            filter: false,
            customBodyRenderLite: (dataIndex) => {
              if (this.state.deviceInfo[dataIndex].network !== null) {
                let macAddress =
                  this.state.deviceInfo[dataIndex].network[0].layer2addr

                return macAddress && macAddress !== 'null' && macAddress
              }
            },
          },
        },
        {
          reveal: [
            'all',
            'Handheld',
            'Tablet',
            'Camera',
            'Printer',
            'Bluetooth Scanner',
          ],
          name: 'network.mac_address',
          label: 'Mac Address',
          options: {
            filter: false,
            customBodyRenderLite: (dataIndex) => {
              if (this.state.deviceInfo[dataIndex].network !== null) {
                let macAddress =
                  this.state.deviceInfo[dataIndex].network.mac_address
                return macAddress && macAddress !== 'null' && macAddress
              }
            },
          },
        },
        {
          reveal: ['all'],
          name: 'network.network_connection_name',
          label: 'Network Connection Name',
          options: {
            filter: false,
            customBodyRenderLite: (dataIndex) => {
              if (this.state.deviceInfo[dataIndex].network !== null) {
                let connectionName =
                  this.state.deviceInfo[dataIndex].network
                    .network_connection_name
                return (
                  connectionName && connectionName !== 'null' && connectionName
                )
              }
            },
          },
        },
        {
          reveal: ['all', 'Camera'],
          name: 'network.port_number',
          label: 'Port Number',
          options: {
            filter: false,
            customBodyRenderLite: (dataIndex) => {
              if (this.state.deviceInfo[dataIndex].network !== null) {
                let portNumber =
                  this.state.deviceInfo[dataIndex].network.port_number
                return portNumber && portNumber !== 'null' && portNumber
              }
            },
          },
        },
        {
          reveal: ['all'],
          name: 'network.ssid',
          label: 'SSID',
          options: {
            filter: false,
            customBodyRenderLite: (dataIndex) => {
              if (this.state.deviceInfo[dataIndex].network !== null) {
                let networkSsid = this.state.deviceInfo[dataIndex].network.ssid
                return networkSsid && networkSsid !== 'null' && networkSsid
              }
            },
          },
        },
        {
          reveal: [
            'all',
            'Handheld',
            'Tablet',
            'Windows',
            'Mac',
            'Bluetooth Scanner',
            'Printer',
            'RFID',
          ],
          name: 'state',
          label: 'State',
        },
        {
          reveal: [
            'all',
            'Handheld',
            'Tablet',
            'Camera',
            'Printer',
            'Windows',
            'Mac',
            'Bluetooth Scanner',
            'RFID',
          ],
          name: 'updated_on',
          label: 'Updated On',
          options: {
            filter: false,
            customBodyRender: (value, tableMeta, updateValue) => {
              return (
                value && (
                  <Tooltip title={value}>
                    <>
                      <DeviceLastSeenFormat lastSeenValue={value} />
                    </>
                  </Tooltip>
                )
              )
            },
          },
        },
        {
          reveal: ['all', 'Windows', 'Mac'],
          name: 'last_loggedin_user',
          label: 'Last Logged in User',
          options: {
            filter: false,
          },
        },
        {
          reveal: [
            'all',
            'Handheld',
            'Tablet',
            'Camera',
            'Printer',
            'Bluetooth Scanner',
          ],
          name: 'classification_name',
          label: 'Actions',
          options: {
            filter: false,
            customBodyRender: (value, tableMeta, updateValue) => {
              return <ActionMenu rowData={tableMeta.rowData} />
            },
          },
        },
      ]

      columns.forEach((row) => {
        if (
          this.state.isi10i &&
          (row.reveal.some((el) => l3Classifications.includes(el)) ||
            row.reveal.includes(`${includeDeviceType}`))
        ) {
          return platformColumns.push(row)
        } else if (
          row.hasOwnProperty('reveal') &&
          row.reveal.includes(`${includeClassification}`)
        ) {
          if (row.reveal.includes('all') && this.state.isi10i === true) {
            if (row.reveal.some((el) => l3Classifications.includes(el))) {
              return platformColumns.push(row)
            }
          } else {
            return platformColumns.push(row)
          }
        }
      })
      return platformColumns
    }
  }

  render() {
    let title = this.submission.classification_name
      ? this.submission.classification_name + ' devices'
      : 'devices'
    this.submission = queryString.parse(this.props.router.location.search, {
      sort: false,
    })
    let params = []
    delete this.submission['advanced']
    Object.entries(this.submission).forEach((row) => {
      if (this.state.isAdvancedSearch === true) {
        params.push(row[0] + ': ' + row[1])
      } else {
        params.push(
          this.props.env.chipLabelSearchItems[row[0]] +
            ': ' +
            (l4CamelCase.includes(row[1])
              ? this.props.env.deviceClassificationMapReverse[row[1]]
              : row[1]),
        )
      }
    })

    return (
      <>
        {this.state.devicesLoading ? (
          <Spinner></Spinner>
        ) : (
          <>
            {this.state.deviceInfo !== null && (
              <Paper elevation={0}>
                <MuiThemeProvider theme={this.getMuiTheme()}>
                  <MUIDataTable
                    title={title}
                    data={this.state.deviceInfo}
                    className={this.theme}
                    options={{
                      selectableRows: 'none',
                      rowHover: false,
                      filter: false,
                      filterType: 'multiselect',
                      print: false,
                      elevation: 4,
                      viewColumns: this.state.isAdvancedSearch,
                      onColumnViewChange: (changedColumns, allColumns) => {
                        if (this.excludeList.includes(changedColumns)) {
                          this.excludeList = this.excludeList.filter(
                            (column) => column !== changedColumns,
                          )
                        } else {
                          this.excludeList.push(changedColumns)
                        }
                      },
                      serverSide: true,
                      pagination: false,
                      search: !this.state.isAdvancedSearch,
                      page: this.page,
                      sort: false,
                      count: this.state.count,
                      rowsPerPageOptions: [this.state.perPage],
                      download: false,
                      expandableRows: !this.state.isAdvancedSearch, //expandDevice,
                      isRowExpandable: (dataIndex) => {
                        return true
                      },
                      serverSideFilterList: [params],
                      enableNestedDataAccess: '.',
                      downloadOptions: {
                        filterOptions: {
                          useDisplayedColumnsOnly: true,
                          useDisplayedRowsOnly: true,
                        },
                      },
                      expandableRowsHeader: false,
                      customToolbar: () => {
                        return (
                          <>
                            {this.state.isAdvancedSearch === true ? (
                              <CustomDownloadAdvanced
                                submission={this.submission}
                                totalCount={this.state.totalCount}
                                mapping={this.advancedMapping}
                                excludeList={this.excludeList}
                              />
                            ) : (
                              <>
                                {this.state.isi10i === true ? (
                                  <>
                                    {this.downloadConfig !== null &&
                                      this.downloadSumission !== null && (
                                        <CustomDownloadI10i
                                          config={this.downloadConfig}
                                          submission={this.downloadSumission}
                                          totalCount={this.state.count}
                                        />
                                      )}
                                  </>
                                ) : (
                                  <CustomDownload
                                    submission={this.submission}
                                    totalCount={this.state.totalCount}
                                  />
                                )}
                              </>
                            )}
                          </>
                        )
                      },
                      customSearchRender: (
                        searchText,
                        handleSearch,
                        hideSearch,
                        options,
                      ) => {
                        return (
                          <CustomSearch
                            searchText={searchText}
                            onSearch={handleSearch}
                            onHide={hideSearch}
                            options={options}
                            submission={this.submission}
                          />
                        )
                      },
                      setFilterChipProps: (colIndex, colName, data) => {
                        return {
                          style: {
                            maxWidth: '100%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          },
                        }
                      },
                      onFilterChipClose: (index, removedFilter, filterList) => {
                        if (this.state.isAdvancedSearch === true) {
                          delete this.submission[removedFilter.split(':')[0]]

                          let redirectParams = queryString.stringify(
                            this.submission,
                            {
                              sort: false,
                              skipNull: true,
                            },
                          )
                          window.location =
                            window.location.origin +
                            `/devices?advanced=true&${redirectParams}`
                        }
                        Object.entries(
                          this.props.env.chipLabelSearchItems,
                        ).forEach((row) => {
                          if (row[1] === removedFilter.split(':')[0]) {
                            // applications depend on name and version, removing both chips if one or the other is removed
                            if (
                              row[0] === 'products.name' ||
                              row[0] === 'products.version_name'
                            ) {
                              delete this.submission['products.name']
                              delete this.submission['products.version_name']
                            } else {
                              delete this.submission[row[0]]
                            }
                            let redirectParams = queryString.stringify(
                              this.submission,
                              {
                                sort: false,
                                skipNull: true,
                              },
                            )
                            window.location =
                              window.location.origin +
                              `/devices?${redirectParams}`
                          }
                        })
                      },
                      customFooter: (textLabels) => {
                        return (
                          <TableFooter>
                            <TableRow>
                              <TableCell>
                                {this.state.isi10i ? (
                                  <TablePagination
                                    component="div"
                                    count={this.state.count}
                                    rowsPerPage={this.perPage}
                                    page={this.page}
                                    labelRowsPerPage={textLabels.rowsPerPage}
                                    labelDisplayedRows={() => (
                                      <>
                                        Page {this.page + 1} of {this.pageCount}{' '}
                                        - Total Records: {this.state.count}{' '}
                                      </>
                                    )}
                                    backIconButtonProps={{
                                      disabled: true,
                                    }}
                                    rowsPerPageOptions={[
                                      10, 25, 50, 100, 250, 3000,
                                    ]}
                                    onPageChange={(event, page) =>
                                      this.changePage(page)
                                    }
                                    onRowsPerPageChange={(event) =>
                                      this.changeRow(event.target.value)
                                    }
                                  />
                                ) : (
                                  <TablePagination
                                    component="div"
                                    count={this.state.count}
                                    rowsPerPage={this.perPage}
                                    page={this.page}
                                    labelRowsPerPage={textLabels.rowsPerPage}
                                    labelDisplayedRows={() => (
                                      <>
                                        Page {this.page + 1} of {this.pageCount}{' '}
                                        - Devices Count: {this.state.totalCount}{' '}
                                        <TopPanelMobileHelp widgetHelperText="paginationHelp" />
                                      </>
                                    )}
                                    rowsPerPageOptions={[
                                      10, 25, 50, 100, 250, 3000,
                                    ]}
                                    onPageChange={(event, page) =>
                                      this.changePage(page)
                                    }
                                    onRowsPerPageChange={(event) =>
                                      this.changeRow(event.target.value)
                                    }
                                  />
                                )}
                              </TableCell>
                            </TableRow>
                          </TableFooter>
                        )
                      },
                      renderExpandableRow: (rowData, rowMeta) => {
                        // if (rowData[0] === 'camera') {
                        //   return <DeviceExpansionCamera rowData={rowData} />
                        // }
                        if (rowData[0] === 'mac') {
                          return <DeviceExpansionMac rowData={rowData} />
                        }
                        if (rowData[0] === 'windows') {
                          return <DeviceExpansionWindows rowData={rowData} />
                        }
                        if (rowData[0] === 'handheld') {
                          return <DeviceExpansionHandheld rowData={rowData} />
                        }
                        if (rowData[0] === 'printer') {
                          return <DeviceExpansionPrinter rowData={rowData} />
                        }
                        if (rowData[0] === 'tablet') {
                          return <DeviceExpansionTablet rowData={rowData} />
                        }
                        if (rowData[0] === 'bluetoothscanner') {
                          return <DeviceExpansionBTScanners rowData={rowData} />
                        }

                        if (
                          rowData[0] === 'rfidhandheld' ||
                          rowData[1] === 'Rfid Handheld'
                        ) {
                          return (
                            <DeviceExpansionRFIDHandheld
                              rowData={rowData}
                              env={this.props.env}
                            />
                          )
                        }

                        // i10i assets
                        if (l3Classifications.includes(rowData[0])) {
                          return (
                            <DeviceExpansionI10iCore
                              rowData={rowData}
                              rowMeta={rowMeta}
                              deviceType={rowData[1]}
                              data={this.state.deviceInfo}
                            />
                          )
                        }
                      },
                    }}
                    columns={this.ColumnBuilder(
                      queryString.parse(this.props.router.location.search),
                    )}
                  />
                </MuiThemeProvider>
              </Paper>
            )}
          </>
        )}
      </>
    )
  }
  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTableSelectCell: {
          fixedHeader: {
            // padding: '25px 0px 0px 0px',
            // width: 50,
          },
          root: {
            // padding: '0px 0px 0px 0px',
            // textAlign: 'center',
            // verticalAlign: 'middle',
            // display: 'table-cell',
          },
        },
        MUIDataTableBodyCell: {
          root: {
            backgroundColor: '#eeeeee',
            borderRight: '1px dashed white',
            padding: 10,
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 0,
            paddingBottom: 0,
            textAlign: 'center',
            // whiteSpace: 'nowrap',
          },
        },
        MUIDataTableHeadRow: {
          root: {
            height: 24,
          },
        },
        MUIDataTableHeadCell: {
          data: {
            fontSize: 14,
          },
          root: {
            textAlign: 'center',
          },
        },
        MuiTableCell: {
          root: {
            height: 10,
            paddingLeft: 4,
            paddingRight: 4,
            paddingTop: 4,
            paddingBottom: 4,
          },
        },
        MUIDataTableBodyRow: {
          root: {
            padding: 10,
            height: 50,
          },
        },
        MUIDataTableToolbar: {
          root: {
            backgroundColor: '#eeeeee',
            height: 10,
          },
          titleText: {
            fontSize: 20,
          },
          left: {
            flex: '100 0 auto',
          },
          filterPaper: {
            width: 420,
          },
          iconActive: {
            display: 'none',
          },
        },
      },
    })
}

const styles = (theme) => ({
  honeyComb: {
    minHeight: `calc(100vh - 75px)`,
    backgroundImage:
      `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='24.5' ` +
      `viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%23888888' fill-opacity='0.50' ` +
      `fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 ` +
      `11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 ` +
      `0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
  },
  cell: {
    height: 10,
    verticalAlign: 'top',
    backgroundColor: '#fff',
  },
  row: {
    height: 10,
    verticalAlign: 'top',
    backgroundColor: '#fff',
  },
  listItem: {
    verticalAlign: 'top',
    height: 10,
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 5,
    border: 'none',
  },
  root: {
    verticalAlign: 'top',
    backgroundColor: '#eeeeee',
    padding: 'dense',
  },
})

export default withStyles(styles, { withTheme: true })(
  withUserSettingsContext(withRouter(withAnalytics()(withEnv()(Devices)))),
)
