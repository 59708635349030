import { useState } from 'react'
import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import Dialog from '@material-ui/core/Dialog'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Good from '@material-ui/icons/CheckCircleTwoTone'
import IconButton from '@material-ui/core/IconButton'
import ListItem from '@material-ui/core/ListItem'
import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import Spinner from 'nicollet-react/es/components/Spinner/Spinner'
import Tooltip from '@material-ui/core/Tooltip'
import { withAnalytics } from '@praxis/component-analytics'
import { withEnv } from '@praxis/component-runtime-env'
import withRouter from '../../withRouter'
import { withStyles } from '@material-ui/styles'
import SvcHandhelds from '../../../services/svcHandhelds'

const SetTepDeviceStolen = (props) => {
  const {
    classes,
    rowData: { id, mac_address },
  } = props
  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [snackBarMessageInfo, setSnackBarMessageInfo] = useState('')
  const [actionSuccess, setActionSuccess] = useState(false)
  const [loading, setLoading] = useState(false)
  const [svcHandhelds] = useState(new SvcHandhelds())

  const handleClose = () => setSnackBarOpen(false)

  const goBack = () => props.router.navigate(0)

  const handleSubmit = () => {
    setLoading(true)
    svcHandhelds
      .markTepDeviceAsStolen(id, props.env)
      .then((success) => {
        const eventData = {
          customMetrics: {
            metric1: 'StolenTepDevice',
            metric3: success.status,
          },
          event: {
            type: 'apiSuccessStolenTepDevice',
          },
        }
        props.trackEvent(eventData)
        if (Math.abs(success.status / 100) === 2) {
          setSnackBarMessageInfo('Device marked as Stolen')
          setSnackBarOpen(true)
          setActionSuccess(true)
        }
      })
      .catch((error) => {
        const eventData = {
          customMetrics: {
            metric1: 'StolenTepDevice',
            metric3: error.response.status,
          },
          event: {
            type: 'apiErrorStolenTepDevice',
          },
        }
        props.trackEvent(eventData)
        if (
          Math.round(error.response.status / 100) === 5 ||
          Math.round(error.response.status / 100) === 4
        ) {
          if (
            error.response.data.message !== null &&
            error.response.data.message !== ''
          ) {
            setSnackBarOpen(false)
            setSnackBarMessageInfo(error.response.data.message)
          } else {
            setSnackBarOpen(true)
            setSnackBarMessageInfo(
              'ERROR - An error has occurred while marking the device as Stolen',
            )
          }
        } else {
          setSnackBarOpen(true)
          setSnackBarMessageInfo(
            'ERROR - An error has occurred while marking the device as Stolen',
          )
        }
      })
      .finally(() => {
        setLoading(false)
        setSnackBarOpen(true)
      })
  }

  return (
    <Dialog
      fullWidth
      open={props.open}
      onClose={props.onClose}
      className={classes.dialog}
    >
      <DialogTitle>Confirm Marking Device as Stolen</DialogTitle>
      <DialogContentText>
        Device: "{id}" with mac address: "{mac_address}"
      </DialogContentText>
      <IconButton
        aria-label="Close"
        className={classes.icon}
        onClick={props.onClose}
      >
        <CloseIcon />
      </IconButton>
      <div>
        {loading ? (
          <Spinner className={classes.spinner} />
        ) : (
          <ListItem className={classes.list}>
            {!actionSuccess ? (
              <React.Fragment>
                <Button
                  className={classes.submitButton}
                  variant="outlined"
                  onClick={handleSubmit}
                >
                  Confirm
                </Button>
                <Button
                  className={classes.cancelButton}
                  variant="outlined"
                  onClick={props.onClose}
                >
                  CANCEL
                </Button>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Button
                  className={classes.successButton}
                  variant="outlined"
                  onClick={goBack}
                >
                  BACK
                </Button>
                <Tooltip title="Success" placement="top">
                  <Good className={classes.good} />
                </Tooltip>
              </React.Fragment>
            )}
          </ListItem>
        )}
      </div>
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={5000}
        onClose={handleClose}
        message={snackBarMessageInfo}
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={props.onClose}
          >
            <CloseIcon className={classes.CloseIcon} />
          </IconButton>,
        ]}
      />
    </Dialog>
  )
}

const styles = {
  icon: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
  submitButton: {
    float: 'left',
  },
  cancelButton: {
    float: 'left',
    marginLeft: 5,
  },
  dialog: {
    textAlign: 'center',
  },
  successButton: {
    float: 'left',
  },
  list: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  spinner: {
    marginBottom: 15,
  },
  good: {
    fontSize: 40,
    color: 'green',
    paddingLeft: 5,
  },
}

export default withStyles(styles)(
  withRouter(withAnalytics()(withEnv()(SetTepDeviceStolen))),
)
