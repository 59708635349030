import BeepDevice from '../MenuActionItems/BeepDevice'
import DeleteHandheld from '../MenuActionItems/DeleteHandheld'
import DeviceLevelOpacAction from '../../Dashboard/MenuActionItems/DeviceLevelOpacAction'
import { Divider } from '@material-ui/core'
import EditDeviceLabel from '../MenuActionItems/EditDeviceLabel'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import OfflineHandheldDevice from '../MenuActionItems/OfflineHandheldDevice'
import QuarantineHandheld from '../MenuActionItems/QuarantineHandheld'
import React from 'react'
import RepairHandheldDevice from '../MenuActionItems/RepairHandheldDevice'
import SetDeviceLostStolen from '../MenuActionItems/SetDeviceLostStolen'
import SetTepDeviceStolen from '../MenuActionItems/SetTepDeviceStolen'
import SettingsIcon from '@material-ui/icons/Settings'
import Spinner from 'nicollet-react/es/components/Spinner/Spinner'
import Tooltip from '@material-ui/core/Tooltip'
import UpdateOrgGroup from '../MenuActionItems/UpdateOrgGroup'
import handheldDeviceTypes from '../deviceTypes/handheldDeviceTypes'
import svcCICOActions from '../../../services/svcCICOActions'
import { withAuth } from '@praxis/component-auth'
import { withEnv } from '@praxis/component-runtime-env'
import { withStyles } from '@material-ui/styles'

function MenuActionItem(props) {
  return (
    <MenuItem
      onClick={() => {
        props.onClose()
        props.onClick()
      }}
    >
      {props.label}
    </MenuItem>
  )
}

class HandheldActionMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      anchorEl: null,
      showDeviceLostStolenDialog: false,
      showDeviceLabelDialog: false,
      showBeepDeviceDialog: false,
      showDeleteDeviceDialog: false,
      showQuarantineDeviceDialog: false,
      showRepairDeviceDialog: false,
      showOfflineDeviceDialog: false,
      showOrgGroupUpdateDialog: false,
      showStolenDeviceDialog: false,
      menuDisabled: true,
      toDoAction: '',
      opacsCICO: [],
      loadingOpacs: false,
      selectedOpac: '',
    }
    this.svcCICOActions = new svcCICOActions()
  }

  handleClick = (event) => {
    this.setState({ loadingOpacs: true })
    this.svcCICOActions
      .getOpacDetails('T' + this.props.rowData.location_id, this.props.env)
      .then((response) => {
        if (Math.round(response.status / 100) === 2) {
          let opacsCICOTemp = response.data
          opacsCICOTemp.push({
            friendly_name: 'Status History',
            opac_name: 'Last five statuses',
          })
          this.setState({
            opacsCICO: opacsCICOTemp,
            loadingOpacs: false,
          })
        }
      })
      .catch((error) => {
        console.log('ERROR Opac-details', error)
        this.setState({
          loadingOpacs: false,
        })
      })
      .finally(() => {
        this.setState({ loadingOpacs: false })
      })
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }
  handleMenu = () => {
    this.setState({ menuDisabled: false })
  }
  handleDeviceLabelActionClick = (event) => {
    this.setState({ showDeviceLabelDialog: true })
  }
  handleDeviceLabelDialogClose = (event) => {
    this.setState({ showDeviceLabelDialog: false })
  }
  handleBeepDeviceActionClick = (event) => {
    this.setState({ showBeepDeviceDialog: true })
  }
  handleBeepDeviceDialogClose = (event) => {
    this.setState({ showBeepDeviceDialog: false })
  }
  handleDeviceLostStolenClick = (event) => {
    this.setState({ showDeviceLostStolenDialog: true })
  }
  handleDeviceLostStolenClose = (event) => {
    this.setState({ showDeviceLostStolenDialog: false })
  }
  handleDeleteActionClick = () => {
    this.setState({ showDeleteDeviceDialog: true })
  }
  handleDeleteDialogClose = (event) => {
    this.setState({ showDeleteDeviceDialog: false })
  }
  handleQuaratineDeviceClick = () => {
    this.setState({ showQuarantineDeviceDialog: true })
  }
  handleQuaratineDeviceDialogClose = (event) => {
    this.setState({ showQuarantineDeviceDialog: false })
  }
  handleRepairDeviceActionClick = () => {
    this.setState({ showRepairDeviceDialog: true })
  }
  handleRepairDeviceDialogClose = (event) => {
    this.setState({ showRepairDeviceDialog: false })
  }
  handleOfflineDeviceActionClick = () => {
    this.setState({ showOfflineDeviceDialog: true })
  }
  handleOfflineDeviceDialogClose = (event) => {
    this.setState({ showOfflineDeviceDialog: false })
  }
  handleOrgGroupUpdateActionClick = () => {
    this.setState({ showOrgGroupUpdateDialog: true })
  }
  handleOrgGroupUpdateDialogClose = (event) => {
    this.setState({ showOrgGroupUpdateDialog: false })
  }
  handleStolenDeviceClick = () => {
    this.setState({ showStolenDeviceDialog: true })
  }
  handleStolenDeviceDialogClose = (event) => {
    this.setState({ showStolenDeviceDialog: false })
  }

  handleCICOActionClick = (opac) => {
    this.setState({
      showCICOActionDialog: true,
      selectedOpac: opac,
    })
  }
  handleCICODialogClose = (event) => {
    this.setState({
      showCICOActionDialog: false,
    })
  }

  render() {
    const {
      rowData: { department, device_state, management_system },
    } = this.props
    const { anchorEl, menuDisabled } = this.state

    function hasAvailableAction(action) {
      return (
        handheldDeviceTypes.hasOwnProperty(department) &&
        handheldDeviceTypes[department].hasOwnProperty('availableActions') &&
        handheldDeviceTypes[department].availableActions.includes(action)
      )
    }
    return (
      <>
        {!menuDisabled ? (
          <Tooltip title="Available Actions">
            <div>
              <IconButton disabled={false} onClick={this.handleClick}>
                <SettingsIcon />
              </IconButton>
            </div>
          </Tooltip>
        ) : (
          <Tooltip title="No Available Actions">
            <div>
              <IconButton disabled={true} onClick={this.handleClick}>
                <SettingsIcon />
              </IconButton>
            </div>
          </Tooltip>
        )}
        <Menu
          style={{ paddingTop: 0, paddingBottom: 0 }}
          disableAutoFocusItem
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {this.loadingOpacs ? (
            <Spinner size="small" />
          ) : (
            <div>
              {hasAvailableAction('setLostStolen') &&
                (device_state === 'Online' ||
                  device_state === 'Offline' ||
                  device_state === 'Bonded' ||
                  device_state === 'Repair') &&
                this.props.env.access.handheldLostStolenMove.some((r) =>
                  this.props.auth.session.userInfo.memberOf.includes(
                    r.toUpperCase(),
                  ),
                ) &&
                (menuDisabled && this.handleMenu(),
                (
                  <MenuActionItem
                    onClick={() => this.handleDeviceLostStolenClick()}
                    label={
                      management_system === 'Airwatch' ? 'Lost/Stolen' : 'Lost'
                    }
                    onClose={() => {
                      this.handleClose()
                    }}
                  />
                ))}
              {hasAvailableAction('setStolen') &&
                management_system === 'TEP' &&
                (device_state === 'Online' ||
                  device_state === 'Offline' ||
                  device_state === 'Bonded' ||
                  device_state === 'Lost' ||
                  device_state === 'Repair' ||
                  device_state === 'Enrolled') &&
                this.props.env.access.tepStolenMove.some((r) =>
                  this.props.auth.session.userInfo.memberOf.includes(
                    r.toUpperCase(),
                  ),
                ) &&
                (menuDisabled && this.handleMenu(),
                (
                  <MenuActionItem
                    onClick={() => this.handleStolenDeviceClick()}
                    label={'Stolen'}
                    onClose={() => {
                      this.handleClose()
                    }}
                  />
                ))}
              {hasAvailableAction('findDevice') &&
                management_system === 'Airwatch' &&
                device_state === 'Online' &&
                (menuDisabled && this.handleMenu(),
                (
                  <MenuActionItem
                    onClick={() => this.handleBeepDeviceActionClick()}
                    label={'Ring Device'}
                    onClose={() => {
                      this.handleClose()
                    }}
                  />
                ))}
              {hasAvailableAction('editDeviceLabel') &&
                (device_state === 'Online' ||
                  device_state === 'Offline' ||
                  device_state === 'Lost') &&
                (this.props.env.access.editDeviceLabelByJobCodeId.includes(
                  this.props.auth.session.identity.jobtitlecode,
                ) ||
                  this.props.env.access.labelGroup.some((r) =>
                    this.props.auth.session.userInfo.memberOf.includes(
                      r.toUpperCase(),
                    ),
                  )) &&
                (menuDisabled && this.handleMenu(),
                (
                  <MenuActionItem
                    onClick={this.handleDeviceLabelActionClick}
                    label={'Edit Device Label'}
                    onClose={() => {
                      this.handleClose()
                    }}
                  />
                ))}
              {hasAvailableAction('deleteHandheld') &&
                management_system === 'Airwatch' &&
                (device_state === 'Lost' ||
                  device_state === 'Converted' ||
                  device_state === 'Repair') &&
                this.props.env.access.deleteGroup.some((r) =>
                  this.props.auth.session.userInfo.memberOf.includes(
                    r.toUpperCase(),
                  ),
                ) &&
                (menuDisabled && this.handleMenu(),
                (
                  <MenuActionItem
                    onClick={() => this.handleDeleteActionClick()}
                    label={'Delete Device'}
                    onClose={() => {
                      this.handleClose()
                    }}
                  />
                ))}
              {hasAvailableAction('markDeviceQuarantine') &&
                management_system === 'TEP' &&
                (device_state === 'Online' ||
                  device_state === 'Offline' ||
                  device_state === 'Bonded' ||
                  device_state === 'Lost' ||
                  device_state === 'Repair' ||
                  device_state === 'Enrolled') &&
                this.props.env.access.quarantineDeviceGroup.some((r) =>
                  this.props.auth.session.userInfo.memberOf.includes(
                    r.toUpperCase(),
                  ),
                ) &&
                (menuDisabled && this.handleMenu(),
                (
                  <MenuActionItem
                    onClick={() => this.handleQuaratineDeviceClick()}
                    label={'Quarantine Device'}
                    onClose={() => {
                      this.handleClose()
                    }}
                  />
                ))}
              {hasAvailableAction('deleteRepairDevice') &&
                (device_state === 'Online' ||
                  device_state === 'Offline' ||
                  device_state === 'Bonded' ||
                  device_state === 'Lost' ||
                  device_state === 'Quarantined' ||
                  device_state === 'Stolen') &&
                this.props.env.access.repairDevice.some((r) =>
                  this.props.auth.session.userInfo.memberOf.includes(
                    r.toUpperCase(),
                  ),
                ) &&
                (menuDisabled && this.handleMenu(),
                (
                  <MenuActionItem
                    onClick={() => this.handleRepairDeviceActionClick()}
                    label={'Repair Device'}
                    onClose={() => {
                      this.handleClose()
                    }}
                  />
                ))}
              {hasAvailableAction('markOfflineHandheldDevice') &&
                device_state === 'Bonded' &&
                this.props.env.access.offlineDeviceStateUpdateUserGroup.some(
                  (r) =>
                    this.props.auth.session.userInfo.memberOf.includes(
                      r.toUpperCase(),
                    ),
                ) &&
                (menuDisabled && this.handleMenu(),
                (
                  <MenuActionItem
                    onClick={() => this.handleOfflineDeviceActionClick()}
                    label={'Offline Device'}
                    onClose={() => {
                      this.handleClose()
                    }}
                  />
                ))}
              {hasAvailableAction('updateOrgGroup') &&
                management_system === 'Airwatch' &&
                this.props.env.access.eloActionGroup.some((r) =>
                  this.props.auth.session.userInfo.memberOf.includes(
                    r.toUpperCase(),
                  ),
                ) &&
                (menuDisabled && this.handleMenu(),
                (
                  <MenuActionItem
                    onClick={() => this.handleOrgGroupUpdateActionClick()}
                    label={'Update Organization Group'}
                    onClose={() => {
                      this.handleClose()
                    }}
                  />
                ))}
              <Divider />
              {hasAvailableAction('tepOpacs') &&
                management_system === 'TEP' &&
                device_state === 'Online' &&
                (menuDisabled && this.handleMenu(),
                this.state.loadingOpacs === true ? (
                  <Spinner />
                ) : (
                  this.state.opacsCICO &&
                  this.state.opacsCICO.length !== 0 &&
                  this.state.opacsCICO
                    .filter(
                      (opac) =>
                        opac.friendly_name === 'Status History' ||
                        (opac['applicable_devices'] &&
                          opac['applicable_devices'].includes(
                            this.props.env.opacsApplicableDevicesMap[
                              department
                            ],
                          )),
                    )
                    .map((opac) => {
                      return (
                        <MenuActionItem
                          key={opac.opac_name}
                          onClick={() => this.handleCICOActionClick(opac)}
                          label={
                            opac.friendly_name
                              ? opac.friendly_name
                              : opac.opac_name
                          }
                          onClose={() => {
                            this.handleClose()
                          }}
                        />
                      )
                    })
                ))}
            </div>
          )}
        </Menu>
        {this.state.showDeviceLostStolenDialog && (
          <SetDeviceLostStolen
            open={this.state.showDeviceLostStolenDialog}
            onClose={() => {
              this.handleDeviceLostStolenClose()
            }}
            {...this.props}
          />
        )}
        {this.state.showBeepDeviceDialog && (
          <BeepDevice
            open={this.state.showBeepDeviceDialog}
            onClose={() => {
              this.handleBeepDeviceDialogClose()
            }}
            {...this.props}
          />
        )}
        {this.state.showDeviceLabelDialog && (
          <EditDeviceLabel
            open={this.state.showDeviceLabelDialog}
            onClose={() => {
              this.handleDeviceLabelDialogClose()
            }}
            {...this.props}
          />
        )}
        {this.state.showDeleteDeviceDialog && (
          <DeleteHandheld
            open={this.state.showDeleteDeviceDialog}
            onClose={() => {
              this.handleDeleteDialogClose()
            }}
            {...this.props}
          />
        )}
        {this.state.showQuarantineDeviceDialog && (
          <QuarantineHandheld
            open={this.state.showQuarantineDeviceDialog}
            onClose={() => {
              this.handleQuaratineDeviceDialogClose()
            }}
            {...this.props}
          />
        )}
        {this.state.showStolenDeviceDialog && (
          <SetTepDeviceStolen
            open={this.state.showStolenDeviceDialog}
            onClose={() => {
              this.handleStolenDeviceDialogClose()
            }}
            {...this.props}
          />
        )}
        {this.state.showRepairDeviceDialog && (
          <RepairHandheldDevice
            open={this.state.showRepairDeviceDialog}
            onClose={() => {
              this.handleRepairDeviceDialogClose()
            }}
            {...this.props}
          />
        )}
        {this.state.showOfflineDeviceDialog && (
          <OfflineHandheldDevice
            open={this.state.showOfflineDeviceDialog}
            onClose={() => {
              this.handleOfflineDeviceDialogClose()
            }}
            {...this.props}
          />
        )}
        {this.state.showOrgGroupUpdateDialog && (
          <UpdateOrgGroup
            open={this.state.showOrgGroupUpdateDialog}
            onClose={() => {
              this.handleOrgGroupUpdateDialogClose()
            }}
            {...this.props}
          />
        )}
        {this.state.showCICOActionDialog && (
          <DeviceLevelOpacAction
            open={this.state.showCICOActionDialog}
            onClose={() => {
              this.handleCICODialogClose()
            }}
            {...this.props}
            data={this.state.selectedOpac}
          />
        )}
      </>
    )
  }
}

const styles = {
  spinner: {
    marginLeft: 50,
    marginRight: 50,
    margin: 10,
  },
}

export default withStyles(styles, { withTheme: true })(
  withAuth(null)(withEnv()(HandheldActionMenu)),
)
