import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import CloseIcon from '@material-ui/icons/Close'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { FileCopy } from '@material-ui/icons'
import Good from '@material-ui/icons/CheckCircleTwoTone'
import IconButton from '@material-ui/core/IconButton'
import ListItem from '@material-ui/core/ListItem'
import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import Spinner from 'nicollet-react/es/components/Spinner/Spinner'
import SvcAdvancedDownload from '../../../services/svcAdvancedDownload'
import Tooltip from '@material-ui/core/Tooltip'
import { Typography } from '@material-ui/core'
import { withAnalytics } from '@praxis/component-analytics'
import { withEnv } from '@praxis/component-runtime-env'
import { withStyles } from '@material-ui/core/styles'

class ShareTokenDialog extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      actionSuccess: false,
      loading: false,
      snackBarMessageInfo: '',
      snackBarOpen: false,
      token: '',
    }
    this.svcAdvancedDownload = new SvcAdvancedDownload()
  }

  handleDialogClose() {
    this.setState({
      actionSuccess: false,
      loading: false,
      snackBarOpen: false,
    })
    this.props.onClose()
  }

  handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    this.setState({ snackBarOpen: false })
  }

  handleSubmit(submission) {
    this.setState({ loading: true })
    this.svcAdvancedDownload
      .getToken(this.props.env, submission)
      .then((response) => {
        const eventData = {
          customMetrics: {
            metric1: 'Generate Token',
            metric3: response.status,
          },
          event: {
            type: `apiSuccessGenerateToken`,
          },
        }
        this.props.trackEvent(eventData)

        this.setState({
          token: response.data.token,
          actionSuccess: true,
          loading: false,
        })
      })
      .catch((error) => {
        console.log(error)
        const eventData = {
          customMetrics: {
            metric1: 'Generate Token',
            metric3: error.response.status,
          },
          event: {
            type: `apiErrorGenerateToken`,
          },
        }
        this.props.trackEvent(eventData)
        this.setState({
          actionSuccess: false,
          loading: false,
          snackBarMessageInfo: 'Link generation Failed',
          snackBarOpen: true,
        })
      })
  }

  copyToClipboard = (url) => {
    navigator.clipboard.writeText(url).then(
      () =>
        this.setState({ snackBarOpen: true, snackBarMessageInfo: 'Copied!' }),
      () =>
        this.setState({
          snackBarOpen: true,
          snackBarMessageInfo: 'Failed to copy!',
        }),
    )
  }

  render() {
    const { classes } = this.props
    let params = []

    Object.entries(this.props.submission)?.forEach((row) => {
      params.push(
        row[1].field_name + ' ' + row[1].operator + ' ' + row[1].field_value,
      )
    })
    return (
      <Dialog
        fullWidth
        maxWidth="sm"
        open={this.props.open}
        onClose={this.props.onClose}
      >
        <DialogTitle>Share link with Search Criteria</DialogTitle>

        <IconButton
          aria-label="Close"
          className={classes.icon}
          onClick={() => this.handleDialogClose()}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <DialogContentText>
            Generate URL for search criteria:
          </DialogContentText>
          {params.map((data, index) => (
            <ListItem key={index}>
              <Chip label={data} className={classes.chip} />
            </ListItem>
          ))}
        </DialogContent>
        <div>
          {this.state.loading ? (
            <>
              <Spinner className={classes.spinner} />
            </>
          ) : (
            <ListItem className={classes.list}>
              {!this.state.actionSuccess ? (
                <React.Fragment>
                  <Button
                    className={classes.submitButton}
                    variant="outlined"
                    onClick={() => this.handleSubmit(this.props.submission)}
                  >
                    Generate
                  </Button>
                  <Button
                    className={classes.cancelButton}
                    variant="outlined"
                    onClick={() => this.handleDialogClose()}
                  >
                    CANCEL
                  </Button>
                </React.Fragment>
              ) : (
                <div>
                  <DialogContentText>Copy Link: </DialogContentText>
                  <div className={classes.shareContainer}>
                    <Typography variant="body2" className={classes.shareInput}>
                      {`${window.location.origin}/search?token=${this.state.token}`}
                    </Typography>
                    <IconButton
                      aria-label="Copy"
                      className={classes.copy}
                      onClick={() =>
                        this.copyToClipboard(
                          `${window.location.origin}/search?token=${this.state.token}`,
                        )
                      }
                    >
                      <FileCopy />
                    </IconButton>
                  </div>
                  <div className={classes.closeButton}>
                    <Button
                      className={classes.successButton}
                      variant="outlined"
                      onClick={() => this.handleDialogClose()}
                    >
                      CLOSE
                    </Button>
                    <Tooltip title="Success" placement="top">
                      <Good className={classes.good} />
                    </Tooltip>
                  </div>
                </div>
              )}
            </ListItem>
          )}
        </div>
        <Snackbar
          open={this.state.snackBarOpen}
          autoHideDuration={5000}
          onClose={this.handleSnackBarClose}
          message={this.state.snackBarMessageInfo}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleSnackBarClose}
            >
              <CloseIcon className={classes.CloseIcon} />
            </IconButton>,
          ]}
        />
      </Dialog>
    )
  }
}

const styles = {
  icon: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
  copy: {
    paddingTop: '10px',
    marginRight: '0px',
    marginLeft: 'auto',
    paddingRight: '0px',
  },
  chip: {
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  searchTextbar: {
    minWidth: 200,
    width: '350px',
    paddingRight: '5px',
  },
  submitButton: {
    float: 'left',
  },
  cancelButton: {
    float: 'left',
    marginLeft: 5,
  },
  resetButton: {
    float: 'left',
    marginLeft: 5,
  },
  closeIcon: {
    fontSize: 20,
  },
  list: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  spinner: {
    marginBottom: 15,
  },
  good: {
    fontSize: 40,
    color: 'green',
    paddingLeft: 5,
  },
  successButton: {
    textAlign: 'center',
  },
  shareContainer: {
    display: 'flex',
    padding: '10px',
    fontSize: '14px',
    borderRadius: '8px',
    height: '20px',
    marginBottom: '10px',
    minWidth: '525px',
    maxWidth: '570px',
    backgroundColor: '#E0E0E0',
  },
  shareInput: {
    marginRight: '20px',
  },
  closeButton: {
    alignItems: 'center',
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'center',
  },
}

export default withStyles(styles)(withAnalytics()(withEnv()(ShareTokenDialog)))
