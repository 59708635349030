import Button from '@material-ui/core/Button'
import CloseIcon from '@material-ui/icons/Close'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import Good from '@material-ui/icons/CheckCircleTwoTone'
import IconButton from '@material-ui/core/IconButton'
import ListItem from '@material-ui/core/ListItem'
import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import Spinner from 'nicollet-react/es/components/Spinner/Spinner'
import SvcHandhelds from '../../../services/svcHandhelds'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import ValidateDeviceLabel from '../../../utilities/ValidateDeviceLabel'
import { withAnalytics } from '@praxis/component-analytics'
import { withEnv } from '@praxis/component-runtime-env'
import withRouter from '../../withRouter'
import { withStyles } from '@material-ui/styles'

class EditDeviceLabel extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      deviceLabel: '',
      snackBarOpen: false,
      snackBarMessageInfo: '',
      actionSuccess: false,
      loading: false,
    }
    this.validateDeviceLabel = new ValidateDeviceLabel()
    this.svcHandhelds = new SvcHandhelds()
    this.goBack = this.goBack.bind(this)
  }
  componentDidMount() {}
  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value })
  }
  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    this.setState({ snackBarOpen: false })
  }
  goBack() {
    this.props.router.navigate(0)
  }
  handleSubmit() {
    let validDeviceLabel = this.validateDeviceLabel.ValidateDeviceNumber(
      this.state.deviceLabel,
    )
    if (validDeviceLabel) {
      if (this.props.rowData.label === this.state.deviceLabel) {
        this.setState({
          snackBarMessageInfo: 'WARNING - Duplicated the existing device name',
          snackBarOpen: true,
          deviceLabel: ' ',
        })
      } else {
        this.setState({ loading: true })
        if (this.state.deviceLabel.length === 0) {
          var tempLabel = ''
        } else {
          tempLabel = this.state.deviceLabel
        }
        this.svcHandhelds
          .putHandhheldLabel(this.props.rowData.id, tempLabel, this.props.env)
          .then((success) => {
            const eventData = {
              customMetrics: {
                metric1: 'EditDeviceLabel',
                metric3: success.status,
              },
              event: {
                type: 'apiSuccessEditDeviceLabel',
              },
            }
            this.props.trackEvent(eventData)
            if (success.status === 200) {
              this.setState({
                snackBarMessageInfo: 'Device label changed',
                snackBarOpen: true,
                deviceLabel: '',
                actionSuccess: true,
              })
            }
          })
          .catch((error) => {
            const eventData = {
              customMetrics: {
                metric1: 'EditDeviceLabel',
                metric3: error.response.status,
              },
              event: {
                type: 'apiErrorEditDeviceLabel',
              },
            }
            this.props.trackEvent(eventData)
            if (
              Math.round(error.response.status / 100) === 5 ||
              Math.round(error.response.status / 100) === 4
            ) {
              if (
                error.response.data.message !== null ||
                error.response.data.message !== ''
              ) {
                this.setState({
                  snackBarOpen: true,
                  snackBarMessageInfo: error.response.data.message,
                })
              } else {
                this.setState({
                  snackBarOpen: true,
                  snackBarMessageInfo:
                    'ERROR - An error has occurred setting the device label',
                })
              }
            } else {
              this.setState({
                snackBarOpen: true,
                snackBarMessageInfo:
                  'ERROR - An error has occurred setting the device label',
              })
            }
          })
          .finally(() => {
            this.setState({ loading: false })
          })
      }
    } else {
      this.setState({
        snackBarMessageInfo: 'WARNING -  Entered Device Label Is Invalid',
        snackBarOpen: true,
        deviceLabel: '',
      })
    }
  }

  getLabel(deviceLabel) {
    if (deviceLabel === null || deviceLabel === '') {
      return 'Not Set'
    } else {
      return deviceLabel
    }
  }

  render() {
    const { classes } = this.props
    const { snackBarMessageInfo, actionSuccess, loading } = this.state
    return (
      <Dialog fullWidth open={this.props.open} onClose={this.props.onClose}>
        <DialogTitle>
          Edit Current Device Label: "{this.getLabel(this.props.rowData.label)}"
        </DialogTitle>
        <IconButton
          aria-label="Close"
          className={classes.icon}
          onClick={this.props.onClose}
        >
          <CloseIcon />
        </IconButton>
        <div>
          {loading ? (
            <Spinner className={classes.spinner} />
          ) : (
            <ListItem className={classes.list}>
              {!actionSuccess ? (
                <React.Fragment>
                  <TextField
                    id="enter-a-new-device-label"
                    label="Enter a new device label"
                    value={this.state.deviceLabel}
                    onChange={this.handleChange('deviceLabel')}
                    className={classes.searchTextbar}
                    margin="dense"
                    variant="filled"
                    onKeyPress={(ev) => {
                      if (ev.key === 'Enter') {
                        this.handleSubmit()
                        ev.preventDefault()
                      }
                    }}
                  />
                  <Button
                    className={classes.submitButton}
                    variant="outlined"
                    onClick={() => this.handleSubmit()}
                  >
                    Confirm
                  </Button>
                  <Tooltip placement="top" title="Reset Label to Not Set">
                    <Button
                      className={classes.resetButton}
                      variant="outlined"
                      onClick={() => this.handleSubmit()}
                    >
                      Reset
                    </Button>
                  </Tooltip>
                  <Button
                    className={classes.cancelButton}
                    variant="outlined"
                    onClick={this.props.onClose}
                  >
                    CANCEL
                  </Button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Button
                    className={classes.successButton}
                    variant="outlined"
                    onClick={this.goBack}
                  >
                    BACK
                  </Button>
                  <Tooltip title="Success" placement="top">
                    <Good className={classes.good} />
                  </Tooltip>
                </React.Fragment>
              )}
            </ListItem>
          )}
        </div>
        <Snackbar
          open={this.state.snackBarOpen}
          autoHideDuration={3000}
          onClose={this.handleClose}
          message={snackBarMessageInfo}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.props.onClose}
            >
              <CloseIcon className={classes.CloseIcon} />
            </IconButton>,
          ]}
        />
      </Dialog>
    )
  }
}

const styles = {
  icon: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
  searchTextbar: {
    minWidth: 200,
    width: '350px',
    paddingRight: '5px',
  },
  submitButton: {
    float: 'left',
  },
  cancelButton: {
    float: 'left',
    marginLeft: 5,
  },
  resetButton: {
    float: 'left',
    marginLeft: 5,
  },
  closeIcon: {
    fontSize: 20,
  },
  list: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  spinner: {
    marginBottom: 15,
  },
  good: {
    fontSize: 40,
    color: 'green',
    paddingLeft: 5,
  },
}

export default withStyles(styles)(
  withRouter(withAnalytics()(withEnv()(EditDeviceLabel))),
)
