const l4CamelCase = [
  'ups',
  'ats',
  'pdu',
  'generator',
  'digitalMediaPlayer',
  'baremetalServer',
  'chassis',
  'doorAccessControl',
  'register',
  'broadcast',
  'scale',
  'phone',
  'printer',
  // 'networkAccessControlAndVisibility',
  'networkAccessPoint',
  'networkChassis',
  'networkManagement',
  'networkGateway',
  'networkSwitch',
  'networkRouter',
  'voiceGateway',
  'rfidHandheld',
  'networkTrafficManagement',
  'submeteringControls',
]
export default l4CamelCase
