export const l3categoryMap = {
  Power: ['ups', 'ats', 'pdu', 'generator'],
  'Voice and Collaboration': [
    'digital_media_player',
    'voice_gateway',
    'broadcast',
    'phone',
  ],
  Compute: ['baremetal_server', 'chassis'],
  'EndUser/EndPoint': ['scale', 'printer', 'register', 'rfid_handheld'],
  'Camera and Access Control': ['door_access_control'],
  Network: [
    'network_access_control_and_visibility',
    'network_access_point',
    'network_chassis',
    'network_gateway',
    'network_management',
    'network_switch',
    'network_router',
    'network_traffic_management',
  ],
  'Property Management': ['submetering_controls'],
}
export default l3categoryMap
