import { IconButton, Modal, Tooltip } from '@material-ui/core'

import HelpIcon from '@material-ui/icons/Help'
import React from 'react'
import { withEnv } from '@praxis/component-runtime-env'
import { withStyles } from '@material-ui/styles'

class HandheldStateInfoModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openModal: false,
    }
  }

  handleOpenModal = (e) => {
    e.stopPropagation()
    this.setState({
      openModal: !this.state.openModal,
    })
  }
  render() {
    const { classes, env } = this.props
    return (
      <div style={{ display: 'inline' }}>
        <Tooltip title={`Click to see device states information`}>
          <IconButton onClick={this.handleOpenModal}>
            <HelpIcon />
          </IconButton>
        </Tooltip>
        <Modal
          className={classes.modal}
          open={this.state.openModal}
          onClose={this.handleOpenModal}
        >
          <div className={classes.paper}>
            {env.StatesAndItsDefinitions.map((states, index) => (
              <React.Fragment key={index}>
                <b>{states.state + ' : '}</b>
                {states.definition} <br />{' '}
              </React.Fragment>
            ))}
          </div>
        </Modal>
      </div>
    )
  }
}

const styles = (theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    lineHeight: 1.8,
    wordSpacing: '1.5px',
    padding: '5px',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
  },
})

export default withStyles(styles)(withEnv()(HandheldStateInfoModal))
