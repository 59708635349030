import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'

import Apps from '@material-ui/icons/Apps'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import HandheldLocations from './HandheldLocation/HandheldLocations'
import HandheldNeptuneLocation from './HandheldNeptuneLocation/HandheldNeptuneLocation'
import Map from '@material-ui/icons/Map'
import React from 'react'
import SettingsIcon from '@material-ui/icons/Settings'
import Tab from '@material-ui/core/Tab'
import TableCell from '@material-ui/core/TableCell'
import TableDataHandheldApplications from './TableDataHandheldApplications'
import TableDataHandheldDetails from './TableDataHandheldDetails'
import TableDataTabletApplications from './TableDataTabletApplications'
import TableRow from '@material-ui/core/TableRow'
import Tabs from '@material-ui/core/Tabs'
import { fade } from '@material-ui/core/styles/colorManipulator'
import { withEnv } from '@praxis/component-runtime-env'
import { withStyles } from '@material-ui/styles'

class TableDataHandheld extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tabValue: 0,
      tabName: 'details',
    }
  }

  handleChange = (event, tabValue) => {
    const {
      results: { results },
      rowMeta,
    } = this.props
    this.setState({ tabValue })
    let tab = null
    if (tabValue === 0) {
      tab = 'details'
    } else if (tabValue === 1) {
      tab = 'applications'
    } else if (tabValue === 2) {
      tab =
        results[rowMeta.dataIndex].management_system === 'Airwatch'
          ? 'street_map'
          : 'store_map'
    } else if (tabValue === 3) {
      tab = 'street_map'
    }
    this.setState({ tabName: tab })
  }

  render() {
    const colSpan = this.props.rowData.length + 1
    const {
      classes,
      results: { results },
      rowMeta,
      locationFullDetails,
    } = this.props
    return (
      <TableRow className={classes.honeyComb}>
        <TableCell colSpan={colSpan}>
          <Card>
            <CardContent>
              {typeof results[rowMeta.dataIndex] !== 'undefined' ? (
                <div>
                  {
                    <MuiThemeProvider theme={this.getMuiTheme()}>
                      <Tabs
                        value={this.state.tabValue}
                        onChange={this.handleChange}
                        variant="fullWidth"
                        className={classes.tabs}
                        TabIndicatorProps={{ className: classes.tabsProps }}
                      >
                        <Tab
                          icon={<SettingsIcon />}
                          label="Device Details"
                          className={classes.tab}
                        />
                        <Tab
                          icon={<Apps />}
                          label="Applications"
                          className={classes.tab}
                        />
                        {results[rowMeta.dataIndex].management_system ===
                          'TEP' && (
                          <Tab
                            icon={<Map />}
                            label="Store Map"
                            className={classes.tab}
                          />
                        )}
                        {(this.props.env.department_handheld_tablet_map.handhelds.includes(
                          results[rowMeta.dataIndex].department,
                        ) ||
                          this.props.env.department_handheld_tablet_map.tablets.includes(
                            results[rowMeta.dataIndex].department,
                          )) &&
                          results[rowMeta.dataIndex].management_system.match(
                            /^TEP|Airwatch$/,
                          ) && (
                            <Tab
                              icon={<Map />}
                              label="Street Map"
                              className={classes.tab}
                            />
                          )}
                      </Tabs>
                    </MuiThemeProvider>
                  }
                  {this.state.tabName === 'details' && (
                    <TableDataHandheldDetails
                      handheldDetails={results[rowMeta.dataIndex]}
                    />
                  )}
                  {this.state.tabName === 'applications' &&
                    (this.props.env.department_handheld_tablet_map.handhelds.includes(
                      results[rowMeta.dataIndex].department,
                    ) ? (
                      <TableDataHandheldApplications
                        // handle tablet or handheld call
                        id={results[rowMeta.dataIndex].id}
                        type="applications"
                      />
                    ) : (
                      this.props.env.department_handheld_tablet_map.tablets.includes(
                        results[rowMeta.dataIndex].department,
                      ) && (
                        <TableDataTabletApplications
                          // handle tablet or handheld call
                          id={results[rowMeta.dataIndex].id}
                          type="applications"
                        />
                      )
                    ))}
                  {this.state.tabName === 'store_map' && (
                    <HandheldNeptuneLocation
                      last_event={results[rowMeta.dataIndex].last_event}
                      last_user={results[rowMeta.dataIndex].last_user}
                      department={results[rowMeta.dataIndex].department}
                      id={results[rowMeta.dataIndex].id}
                      locationFullDetails={locationFullDetails}
                      handheldDetails={results[rowMeta.dataIndex]}
                    />
                  )}
                  {this.state.tabName === 'street_map' &&
                    (this.props.env.department_handheld_tablet_map.handhelds.includes(
                      results[rowMeta.dataIndex].department,
                    ) ||
                      this.props.env.department_handheld_tablet_map.tablets.includes(
                        results[rowMeta.dataIndex].department,
                      )) && (
                      <HandheldLocations
                        // handle tablet or handheld call
                        last_event={results[rowMeta.dataIndex].last_event}
                        last_user={results[rowMeta.dataIndex].last_user}
                        department={results[rowMeta.dataIndex].department}
                        id={results[rowMeta.dataIndex].id}
                        locationFullDetails={locationFullDetails}
                        type="geo_positions"
                        managementSystem={
                          results[rowMeta.dataIndex].management_system
                        }
                      />
                    )}
                </div>
              ) : (
                <div>
                  Note, additional details are not available for this device.
                </div>
              )}
            </CardContent>
          </Card>
        </TableCell>
      </TableRow>
    )
  }
  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MuiTab: {
          root: { '&$selected': { backgroundColor: '#fff' } },
        },
        MUIDataTableBodyCell: {
          root: {
            backgroundColor: '#eeeeee',
            height: 10,
          },
        },
        MUIDataTableHeadRow: {
          root: {
            height: 10,
          },
        },
        MUIDataTableBodyRow: {
          root: {
            backgroundColor: '#eeeeee',
            height: 10,
          },
        },
        MUIDataTableToolbar: {
          root: {
            backgroundColor: '#eeeeee',
            height: 10,
          },
          titleText: {
            fontSize: 14,
          },
          left: {
            flex: '100 0 auto',
          },
        },
        MuiPaper: {
          root: {
            minWidth: '400',
            paddingLeft: '0',
          },
        },
      },
    })
}

const styles = (theme) => ({
  honeyComb: {
    backgroundImage:
      `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='24.5' ` +
      `viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%23888888' fill-opacity='0.50' ` +
      `fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 ` +
      `11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 ` +
      `0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
  },
  tab: {
    borderRight: '1px solid #666666',
    borderLeft: '1px solid #666666',
    borderTop: '1px solid #666666',
    borderBottom: '1px solid #666666',
    borderRadius: '5px 5px 0px 0px',
  },
  tabs: {
    backgroundColor: '#999999',
  },
  tabsProps: {
    backgroundColor: `${fade('#fff', 0.1)}`,
    borderBottom: '1px solid white',
  },
})

export default withStyles(styles, { withTheme: true })(
  withEnv()(TableDataHandheld),
)
