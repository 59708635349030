import React from 'react'
import axios from 'axios'
import queryString from 'query-string'

class SvcWindows extends React.Component {
  getWindows(config, submission) {
    const queryParameters = {
      windows: {
        location_id: null,
        computer_name: null,
        operating_system: null,
        operating_system_version: null,
        logged_in_user: null,
        id: null,
      },
    }
    let queryParametersVar = queryParameters
    Object.entries(submission).forEach((param) => {
      if (queryParametersVar.windows.hasOwnProperty(param[0])) {
        queryParametersVar.windows[`${param[0]}`] = param[1]
      }
    })

    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey
    const endpointParams = queryString.stringify(queryParametersVar.windows, {
      sort: false,
      skipNull: true,
    })
    const apiUri = apiUriBase + `/windows?` + endpointParams + `&key=` + apiKey
    return axios.get(apiUri)
  }
  getStoreWindowsComputers(storeNumber, config) {
    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey
    const apiUri = encodeURI(
      apiUriBase + `/windows?location_id=` + storeNumber + `&key=` + apiKey,
    )
    return axios.get(apiUri)
  }
}
export default SvcWindows
