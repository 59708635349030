// case sensitive device mapping, dashboardmain appends 'filter' to this name, this is used as the callback name in the right nav. spaces are removed if they exist.
export const deviceFilterMap = {
  filteredMyDevice: 'myDevice',
  filteredMyCheckout: 'myCheckout',
  filteredMyKiosk: 'My Kiosk',
  filteredPaymentKiosk: 'Payment Kiosk',
  filteredServiceKiosk: 'Service Kiosk',
  filteredGiftRegistry: 'Gift Registry',
  filteredGiftRegistryScanner: 'Gift Registry Scanner',
  filteredGiftRegistryKiosk: 'Gift Registry Kiosk',
  filteredPML: 'PML',
  filteredTimeclock: 'Timeclock',
  filteredVisualMerchandiser: 'Visual Merchandiser',
  filteredDirectStoreDeliveryKiosk: 'Direct Store Delivery Kiosk',
  filteredSFSPrinter: 'SFS',
  filteredScangun: 'Scangun',
  filteredDCHRTablet: 'DCHR Tablet',
  filteredAPTablet: 'AP Tablet',
  filteredICQATablet: 'ICQA Tablet',
  filteredYardTruckTablet: 'Yard Truck Tablet',
  filteredDCFKTablet: 'FourKites Tablet',
  filteredDCOLETablet: 'OLE Tablet',
  filteredADWTablet: 'Gladiator',
}
export default deviceFilterMap
