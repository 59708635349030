import HandheldNeptuneMap from './HandheldNeptuneMap'
import React from 'react'
import Spinner from 'nicollet-react/es/components/Spinner/Spinner'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import { fade } from '@material-ui/core/styles/colorManipulator'
import moment from 'moment'
import svcHandhelds from '../../../../services/svcHandhelds'
import { withAnalytics } from '@praxis/component-analytics'
import { withEnv } from '@praxis/component-runtime-env'
import { withStyles } from '@material-ui/styles'

class HandheldNeptuneLocation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      acornPositions: null,
      acornPositionsLoading: false,
    }
    this.svcHandhelds = new svcHandhelds()
  }

  getAcornPositions() {
    var metric1
    if (
      this.props.handheldDetails &&
      this.props.handheldDetails.device_state &&
      (this.props.handheldDetails.device_state === 'Offline' ||
        this.props.handheldDetails.device_state === 'Lost')
    ) {
      metric1 = 'NeptuneOffLineLostLocationDetails'
    } else {
      metric1 = 'NeptuneLocationDetails'
    }
    this.setState({ acornPositionsLoading: true })
    this.svcHandhelds
      .getHandheldAcornPositions(this.props.env, this.props.id)
      .then((response) => {
        const eventData = {
          customMetrics: {
            metric1: metric1,
            metric3: response.status,
          },
          event: {
            type: `apiNeptuneLocationDetails`,
          },
        }
        this.props.trackEvent(eventData)
        this.setState({
          acornPositions: response.data.acorn_positions,
        })
      })
      .catch((error) => {
        const eventData = {
          customMetrics: {
            metric1: metric1,
            metric3: error.response.status,
          },
          event: {
            type: `apiNeptuneLocationDetails`,
          },
        }
        this.props.trackEvent(eventData)
        console.log(error)
      })
      .finally(() => {
        this.setState({ acornPositionsLoading: false })
      })
  }

  componentDidMount() {
    this.getAcornPositions()
  }

  render() {
    const { classes } = this.props
    const { acornPositionsLoading, acornPositions } = this.state

    return (
      <>
        {acornPositionsLoading ? (
          <Spinner className={classes.spinner} />
        ) : // if unable to retrieve position data, do not render or attempt load neptune map
        acornPositions &&
          acornPositions.length !== 0 &&
          acornPositions[0].xcoordinate !== null &&
          acornPositions[0].ycoordinate !== null &&
          acornPositions[0].physical_location_id !== null &&
          acornPositions[0].floor_id !== null &&
          acornPositions[0].timestamp !== null &&
          acornPositions[0].acorn_position_enabled ? (
          this.props.handheldDetails.location_id ===
          acornPositions[0].physical_location_id ? (
            <div>
              <Table className={classes.root}>
                <TableHead>
                  <TableRow className={classes.cell}>
                    <TableCell className={classes.cell}>Last User</TableCell>
                    <TableCell className={classes.cell}>
                      Last User Type
                    </TableCell>
                    <TableCell className={classes.cell}>
                      Last User Event
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow className={classes.cell}>
                    <TableCell className={classes.cell}>
                      {this.props.last_user.employee_id
                        ? this.props.last_user.lan_id
                          ? this.props.last_user.first_name +
                            ' ' +
                            this.props.last_user.last_name +
                            ' - ' +
                            this.props.last_user.lan_id +
                            ' (' +
                            this.props.last_user.employee_id +
                            ')'
                          : this.props.last_user.employee_id
                        : 'No Existing Record'}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {this.props.last_user.employee_type
                        ? this.props.last_user.employee_type +
                          ', ' +
                          this.props.last_user.company
                        : 'No Existing Record'}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {this.props.last_event
                        ? this.props.last_event
                        : 'No Existing Record'}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              {/* // lets add another set of rows here */}
              <Typography className={classes.messageText} variant="body2">
                Location: {acornPositions[0].physical_location_id} <br /> Floor:{' '}
                {acornPositions[0].floor_id} <br /> Timestamp:{' '}
                {moment(acornPositions[0].timestamp).format('llll')}
              </Typography>
              <div className={classes.neptune}>
                <HandheldNeptuneMap
                  acornPositions={this.state.acornPositions[0]}
                />
              </div>
            </div>
          ) : (
            <Typography className={classes.messageText} variant="h6">
              {this.props.env.neptuneMessage}
            </Typography>
          )
        ) : (
          <Typography className={classes.messageText} variant="h6">
            No Store Map Position Data Available For Device
          </Typography>
        )}
      </>
    )
  }
}

const styles = {
  root: {
    backgroundColor: '#eeeeee',
    padding: 'dense',
    fontSize: '40pt',
    verticalAlign: 'top',
  },
  cell: {
    padding: 0,
  },
  slider: {
    zIndex: 500,
    marginTop: 27,
    marginLeft: 265,
    position: 'absolute',
    border: '2px solid #D6D6D6',
    width: 150,
  },
  focused: {},
  activated: {},
  jumped: {},
  track: {
    backgroundColor: '#333',
    height: 8,
  },
  thumb: {
    backgroundColor: '#333',
    height: 14,
    width: 14,
    '&$focused, &$activated, &$jumped, &:hover': {
      boxShadow: `0px 0px 0px ${8 * 1.5}px ${fade('#333', 0.16)}`,
    },
  },
  sliderText: {
    zIndex: 500,
    marginTop: 1,
    marginLeft: 265,
    position: 'absolute',
    backgroundColor: 'white',
  },
  svgContainer: {
    display: 'inlineBlock',
    position: 'relative',
    width: '100%',
    verticalAlign: 'top',
    overflow: 'hidden',
  },
  locationMap: {
    display: 'inlineBlock',
    position: 'absolute',
    top: 60,
    left: 0,
  },
  spinner: {
    marginBottom: 15,
  },
  messageText: {
    textAlign: 'center',
    margin: 'auto',
    paddingTop: 25,
  },
  hide: {
    display: 'none',
  },
  neptune: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}

export default withStyles(styles)(
  withEnv()(withAnalytics()(HandheldNeptuneLocation)),
)
