import { Divider } from '@material-ui/core'
import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/styles'

class TableDataLexmarkPrinterDetails extends React.Component {
  render() {
    const { classes, printerDetails } = this.props
    return typeof printerDetails !== 'undefined' ? (
      <>
        <Typography variant="h6" id="tableTitle">
          General
        </Typography>
        <Table className={classes.root}>
          <TableHead>
            <TableRow className={classes.cell}>
              {/* <TableCell className={classes.cell}>Firmware Version</TableCell>
              <TableCell className={classes.cell}>Mac Address</TableCell>
              <TableCell className={classes.cell}>Label X Move</TableCell>
              <TableCell className={classes.cell}>Label Y Move</TableCell>
              <TableCell className={classes.cell}>Media Sense Mode</TableCell>
              <TableCell className={classes.cell}>Media TOF</TableCell>
              <TableCell className={classes.cell}>Media Type</TableCell>
              <TableCell className={classes.cell}>Power Health</TableCell>
              <TableCell className={classes.cell}>Power Percent</TableCell>
              <TableCell className={classes.cell}>
                Power Serial Number
              </TableCell>
              <TableCell className={classes.cell}>ZPL Timeout</TableCell> */}
              <TableCell className={classes.cell}>Primary Status</TableCell>
              <TableCell className={classes.cell}>Secondary Status</TableCell>
              <TableCell className={classes.cell}>Paper Jam</TableCell>
              <TableCell className={classes.cell}>Service Required</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={classes.cell}>
              {/* <TableCell className={classes.cell}>
                {printerDetails.firmware}
              </TableCell>
              <TableCell className={classes.cell}>
                {printerDetails.mac_address}
              </TableCell>
              <TableCell className={classes.cell}>
                {printerDetails.label_x_move}
              </TableCell>
              <TableCell className={classes.cell}>
                {printerDetails.label_y_move}
              </TableCell>
              <TableCell className={classes.cell}>
                {printerDetails.media_sense_mode}
              </TableCell>
              <TableCell className={classes.cell}>
                {printerDetails.media_tof}
              </TableCell>
              <TableCell className={classes.cell}>
                {printerDetails.media_type}
              </TableCell>
              <TableCell className={classes.cell}>
                {printerDetails.power_health}
              </TableCell>
              <TableCell className={classes.cell}>
                {printerDetails.power_percent_full}
              </TableCell>
              <TableCell className={classes.cell}>
                {printerDetails.power_serial_number}
              </TableCell>
              <TableCell className={classes.cell}>
                {printerDetails.zpl_data_timeout_in_seconds}
              </TableCell> */}
              <TableCell className={classes.cell}>
                {printerDetails.primary_status && printerDetails.primary_status}
              </TableCell>
              <TableCell className={classes.cell}>
                {printerDetails.secondary_status &&
                  printerDetails.secondary_status}
              </TableCell>
              <TableCell className={classes.cell}>
                {typeof printerDetails.paper_jam === 'boolean' &&
                  printerDetails.paper_jam.toString()}
              </TableCell>
              <TableCell className={classes.cell}>
                {typeof printerDetails.service_required === 'boolean' &&
                  printerDetails.service_required.toString()}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Divider style={{ backgroundColor: 'black' }} />
        <Typography variant="h6" id="tableTitle">
          Consumables
        </Typography>
        <Table className={classes.root}>
          <TableHead>
            <TableRow className={classes.cell}>
              <TableCell className={classes.cell}>Description</TableCell>
              <TableCell className={classes.cell}>Capacity</TableCell>
              <TableCell className={classes.cell}>Percentage Left</TableCell>
              <TableCell className={classes.cell}>Name</TableCell>
              <TableCell className={classes.cell}>Current Value</TableCell>
            </TableRow>
          </TableHead>
          {printerDetails.consumables &&
            printerDetails.consumables.map((row, index) => (
              <TableBody key={index}>
                <TableRow className={classes.cell}>
                  <TableCell className={classes.cell}>
                    {row.description && row.description}
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {row.capacity && row.capacity}
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {row.percentage_left && row.percentage_left}
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {row.name && row.name}
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {row.current_value && row.current_value}
                  </TableCell>
                </TableRow>
              </TableBody>
            ))}
        </Table>
      </>
    ) : (
      <div>Note, additional details are not available for this device.</div>
    )
  }
}

const styles = (theme) => ({
  root: {
    backgroundColor: '#eeeeee',
    padding: 'dense',
    fontSize: '40pt',
    verticalAlign: 'top',
  },
  cell: {
    padding: 0,
    verticalAlign: 'top',
  },
  honeyComb: {
    minHeight: `calc(100vh - 75px)`,
    backgroundImage:
      `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='24.5' ` +
      `viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%23888888' fill-opacity='0.50' ` +
      `fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 ` +
      `11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 ` +
      `0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
  },
  listItem: {
    verticalAlign: 'top',
    height: 10,
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 5,
    border: 'none',
  },
})

export default withStyles(styles)(TableDataLexmarkPrinterDetails)
