import { DialogContent, DialogContentText, Typography } from '@material-ui/core'

import Button from '@material-ui/core/Button'
import CheckCircleTwoTone from '@material-ui/icons/CheckCircleTwoTone'
import CloseIcon from '@material-ui/icons/Close'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import Good from '@material-ui/icons/CheckCircleTwoTone'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import ListItem from '@material-ui/core/ListItem'
import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import Spinner from 'nicollet-react/es/components/Spinner/Spinner'
import SvcPrinters from '../../../services/svcPrinters'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import ValidateHostname from '../../../utilities/ValidateHostname'
import { withAnalytics } from '@praxis/component-analytics'
import { withEnv } from '@praxis/component-runtime-env'
import withRouter from '../../withRouter'
import { withStyles } from '@material-ui/styles'

class EditPrinterHostname extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      deviceHostname: '',
      error: true,
      errorLabel: 'enter a valid printer name',
      label: 'valid printer name',
      snackBarOpen: false,
      snackBarMessageInfo: '',
      actionSuccess: false,
      loading: false,
    }
    this.validateHostname = new ValidateHostname()
    this.svcPrinters = new SvcPrinters()
    this.goBack = this.goBack.bind(this)
  }
  handleChange = (name, location_id) => (event) => {
    this.setState({ [name]: event.target.value.toUpperCase() })
    this.setState({
      error: this.validateHostname.ValidateHostnamePattern(
        event.target.value,
        location_id,
      ),
      // ,
      // errorLabel: this.props.env.devicesSearchItems[this.state.selected]
      //   .error_label,
    })
  }
  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    this.setState({ snackBarOpen: false })
  }
  goBack() {
    this.props.router.navigate(0)
  }
  handleSubmit() {
    const {
      toDoAction,
      rowData: { ip_address, hostname, mac_address, location_id }, //, serial_number
    } = this.props
    let validHostname = this.validateHostname.ValidateDuplicateHostname(
      this.state.deviceHostname,
    )
    if (validHostname) {
      if (hostname.toUpperCase() === this.state.deviceHostname.toUpperCase()) {
        this.setState({
          snackBarMessageInfo: 'WARNING - Duplicated the existing printer name',
          snackBarOpen: true,
          deviceHostname: '',
        })
      } else {
        this.setState({ loading: true })
        this.svcPrinters
          .putPrinterUpdates(
            ip_address,
            hostname,
            toDoAction,
            mac_address,
            this.state.deviceHostname,
            location_id,
            this.props.env,
          )
          .then((success) => {
            const eventData = {
              customMetrics: {
                metric1: 'EditPrinterHostName',
                metric2: toDoAction,
                metric3: success.status,
              },
              event: {
                type: 'apiSuccessEditPrinterHostName',
              },
            }
            this.props.trackEvent(eventData)
            if (success.status === 200) {
              this.setState({
                snackBarMessageInfo: 'Printer Name Changed',
                snackBarOpen: true,
                actionSuccess: true,
              })
            }
          })
          .catch((error) => {
            const eventData = {
              customMetrics: {
                metric1: 'EditPrinterHostName',
                metric2: toDoAction,
                metric3: error.response.status,
              },
              event: {
                type: 'apiErrorEditPrinterHostName',
              },
            }
            this.props.trackEvent(eventData)
            if (
              Math.round(error.response.status / 100) === 5 ||
              Math.round(error.response.status / 100) === 4
            ) {
              if (
                error.response.data.message !== null ||
                error.response.data.message !== ''
              ) {
                this.setState({
                  snackBarOpen: true,
                  snackBarMessageInfo: error.response.data.message,
                })
              } else {
                this.setState({
                  snackBarOpen: true,
                  snackBarMessageInfo:
                    'ERROR - An error has occurred setting the new printer name',
                })
              }
            } else {
              this.setState({
                snackBarOpen: true,
                snackBarMessageInfo:
                  'ERROR - An error has occurred setting the new printer name',
              })
            }
          })
          .finally(() => {
            this.setState({ loading: false })
          })
      }
    } else {
      this.setState({
        snackBarMessageInfo: 'WARNING -  Entered Printer Name Is Invalid',
        snackBarOpen: true,
        deviceHostname: '',
      })
    }
  }

  render() {
    const {
      classes,
      rowData: { hostname, location_id },
    } = this.props
    const { snackBarMessageInfo, actionSuccess, loading } = this.state
    return (
      <Dialog
        fullWidth
        maxWidth="sm"
        open={this.props.open}
        onClose={this.props.onClose}
      >
        <DialogTitle>Edit Current Printer Name: "{hostname}"</DialogTitle>
        <DialogContent>
          <DialogContentText>
            SFS printer naming pattern "T{location_id.padStart(4, 0)}
            PRT050[1-9]"
          </DialogContentText>
        </DialogContent>
        <IconButton
          aria-label="Close"
          className={classes.icon}
          onClick={this.props.onClose}
        >
          <CloseIcon />
        </IconButton>
        <div>
          {loading ? (
            <Spinner className={classes.spinner} />
          ) : (
            <ListItem className={classes.list}>
              {!actionSuccess ? (
                <React.Fragment>
                  <TextField
                    id="enter-a-new-printer-name"
                    label={
                      this.state.error ? (
                        <Typography variant="button" style={{ color: '#666' }}>
                          {this.state.errorLabel}
                        </Typography>
                      ) : (
                        <Typography variant="button" style={{ color: '#666' }}>
                          {this.state.label}
                        </Typography>
                      )
                    }
                    value={this.state.deviceHostname}
                    onChange={this.handleChange(
                      'deviceHostname',
                      location_id.padStart(4, 0),
                    )}
                    InputProps={{
                      disableUnderline: true,
                      endAdornment: this.state.error ? (
                        <InputAdornment position="end">
                          <Tooltip title="Enter a valid printer name">
                            <ErrorOutlineIcon style={{ color: '#b92c09' }} />
                          </Tooltip>
                        </InputAdornment>
                      ) : (
                        <InputAdornment position="end">
                          <CheckCircleTwoTone style={{ color: '#008300' }} />
                        </InputAdornment>
                      ),
                    }}
                    className={classes.searchTextbar}
                    margin="dense"
                    variant="filled"
                    onKeyPress={(ev) => {
                      if (ev.key === 'Enter') {
                        this.handleSubmit()
                        ev.preventDefault()
                      }
                    }}
                  />
                  <Button
                    className={classes.submitButton}
                    variant="outlined"
                    disabled={this.state.error}
                    onClick={() => this.handleSubmit()}
                  >
                    Confirm
                  </Button>
                  <Button
                    className={classes.cancelButton}
                    variant="outlined"
                    onClick={this.props.onClose}
                  >
                    CANCEL
                  </Button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Button
                    className={classes.successButton}
                    variant="outlined"
                    onClick={this.goBack}
                  >
                    BACK
                  </Button>
                  <Tooltip title="Success" placement="top">
                    <Good className={classes.good} />
                  </Tooltip>
                </React.Fragment>
              )}
            </ListItem>
          )}
        </div>
        <Snackbar
          open={this.state.snackBarOpen}
          autoHideDuration={3000}
          onClose={this.handleClose}
          message={snackBarMessageInfo}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.props.onClose}
            >
              <CloseIcon className={classes.CloseIcon} />
            </IconButton>,
          ]}
        />
      </Dialog>
    )
  }
}

const styles = {
  icon: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
  searchTextbar: {
    minWidth: 200,
    width: '350px',
    paddingRight: '5px',
  },
  submitButton: {
    float: 'left',
  },
  cancelButton: {
    float: 'left',
    marginLeft: 5,
  },
  closeIcon: {
    fontSize: 20,
  },
  list: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  spinner: {
    marginBottom: 15,
  },
  good: {
    fontSize: 40,
    color: 'green',
    paddingLeft: 5,
  },
}

export default withStyles(styles)(
  withRouter(withAnalytics()(withEnv()(EditPrinterHostname))),
)
