import Button from '@material-ui/core/Button'
import React from 'react'
// import Slider from '@material-ui/lab/Slider'
import Spinner from 'nicollet-react/es/components/Spinner/Spinner'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TabletLocationMap from './TabletLocationMap'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { fade } from '@material-ui/core/styles/colorManipulator'
import svcLocationDetails from '../../../../services/svcLocationDetails'
import svcTablets from '../../../../services/svcTablets'
import { withAnalytics } from '@praxis/component-analytics'
import { withEnv } from '@praxis/component-runtime-env'
import { withStyles } from '@material-ui/styles'

class TabletLocations extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mapCenter: null,
      deviceLocations: [],
      locationsToCheck: {},
      lostStolenDeviceLocationInfo: [],
      locationsCount: 20,
      locationsSelected: 5,
      loading: false,
      tabletDetails: null,
    }
    this.centerOnNextDeviceLocation = this.centerOnNextDeviceLocation.bind(this)
    this.svcTablets = new svcTablets()
    this.svcLocationDetails = new svcLocationDetails()
  }

  handleChange = (event, locationsSelected) => {
    this.setState({ locationsSelected })
  }

  getStoreGeocoords(storeid) {
    if (this.state.latitude && this.state.longitude) {
      return {
        latitude: this.state.latitude,
        longitude: this.state.longitude,
      }
    } else if (this.props.locationFullDetails.length !== 0) {
      return {
        latitude: this.props.locationFullDetails.latitude,
        longitude: this.props.locationFullDetails.longitude,
      }
    } else {
      return {
        // 1375 def loc for no loc
        latitude: 44.975017,
        longitude: -93.274104,
      }
    }
  }

  getDeviceLocationDetails() {
    this.setState({ tabletDetailsLoading: true })
    if (this.props.location_id) {
      this.svcLocationDetails
        .getLocationDetails(this.props.location_id, this.props.env)
        .then((response) => {
          const eventData = {
            customMetrics: {
              metric1: 'LocationDetails-' + response.data[0].type,
              metric3: response.status,
            },
            event: {
              type: `apiSuccessLocationDetails`,
            },
          }
          this.props.trackEvent(eventData)
          this.setState({
            latitude: response.data[0].latitude,
            longitude: response.data[0].longitude,
          })
        })
        .catch((error) => {
          const eventData = {
            customMetrics: {
              metric1: 'LocationDetails',
              metric3: error.response.status,
            },
            event: {
              type: `apiErrorLocationDetails`,
            },
          }
          this.props.trackEvent(eventData)
          console.log(error)
        })
    }
    const submission = {
      id: this.props.id,
      type: this.props.type,
    }
    this.svcTablets
      .getTabletDetail(this.props.env, submission)
      .then((response) => {
        this.setState({
          tabletDetails: response.data,
        })
        this.setupDeviceLocations(response.data)
      })
      .catch((error) => {
        console.log(error)
      })
      .finally(() => {
        this.setState({ tabletDetailsLoading: false })
      })
  }

  componentDidMount() {
    this.getDeviceLocationDetails()
  }

  render() {
    const { classes, storeId } = this.props
    const { locationsSelected, tabletDetailsLoading, tabletDetails } =
      this.state
    if (!this.state.mapCenter && Number(storeId)) {
      this.setState({ mapCenter: this.getStoreGeocoords(storeId) })
    }
    if (locationsSelected > this.state.deviceLocations.length) {
      var locationsCount = this.state.deviceLocations.length
    } else locationsCount = locationsSelected

    return (
      <>
        <Table className={classes.root}>
          <TableHead>
            <TableRow className={classes.cell}>
              <TableCell className={classes.cell}>Last User</TableCell>
              <TableCell className={classes.cell}>Last User Type</TableCell>
              <TableCell className={classes.cell}>Last User Event</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={classes.cell}>
              <TableCell className={classes.cell}>
                {this.props.last_user.employee_id
                  ? this.props.last_user.lan_id
                    ? this.props.last_user.first_name +
                      ' ' +
                      this.props.last_user.last_name +
                      ' - ' +
                      this.props.last_user.lan_id +
                      ' (' +
                      this.props.last_user.employee_id +
                      ')'
                    : this.props.last_user.employee_id
                  : 'No Existing Record'}
              </TableCell>
              <TableCell className={classes.cell}>
                {this.props.last_user.employee_type
                  ? this.props.last_user.employee_type +
                    ', ' +
                    this.props.last_user.company
                  : 'No Existing Record'}
              </TableCell>
              <TableCell className={classes.cell}>
                {this.props.last_event}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        {tabletDetailsLoading ? (
          <Spinner className={classes.spinner} />
        ) : tabletDetails && tabletDetails.gps_coordinates.length !== 0 ? (
          <>
            <Tooltip title="Locate Last Known Location">
              <Button
                style={{
                  zIndex: 500,
                  marginTop: 10,
                  marginLeft: 50,
                  position: 'absolute',
                  backgroundColor: 'white',
                }}
                variant="outlined"
                onClick={this.centerOnNextDeviceLocation}
              >
                go to last location
              </Button>
            </Tooltip>
            <Typography className={classes.sliderText} variant="body2">
              Show Last {locationsCount} Locations of maximum of{' '}
              {this.state.deviceLocations.length} available locations
            </Typography>
            {/* <Slider
              classes={{
                disabled: classes.hide,
                thumb: classes.thumb,
                root: classes.slider,
                track: classes.track,
                focused: classes.focused,
                activated: classes.activated,
                jumped: classes.jumped,
              }}
              disabled={
                (locationsSelected > this.state.deviceLocations.length &&
                  this.state.deviceLocations.length >= 5) ||
                this.state.deviceLocations.length <= 5
              }
              value={locationsSelected}
              min={5}
              max={this.state.deviceLocations.length}
              step={1}
              onChange={this.handleChange}
            /> */}
            <TabletLocationMap
              mapCenter={
                // Use existing map center if available (e.g. if a device is in focus), otherwise default to the store's geocoordinates
                this.state.mapCenter != null
                  ? this.state.mapCenter
                  : this.getStoreGeocoords(storeId)
              }
              deviceLocations={this.state.deviceLocations.slice(
                0,
                locationsSelected,
              )}
              id="leafletMap"
            />
          </>
        ) : (
          <Typography className={classes.noLocationText} variant="h6">
            No Location Data Available
          </Typography>
        )}
      </>
    )
  }

  setupDeviceLocations(deviceLocationInfo) {
    const newLocs = []
    const newLocsMap = new Map()
    if (deviceLocationInfo.gps_coordinates !== undefined) {
      for (const location of deviceLocationInfo.gps_coordinates) {
        const newLoc = new DeviceLocation(location)
        newLocs.push(newLoc)
        newLocsMap.set(newLoc.timestamp, newLoc)
      }
      // newLocs.sort((a, b) => (a.timestamp > b.timestamp ? 1 : -1))
      newLocs[0].isFocus = true
    }
    this.setState({ deviceLocations: newLocs, locationsToCheck: newLocsMap })
  }

  clearDeviceLocations() {
    this.setState({
      mapCenter: this.getStoreGeocoords(Number(this.props.storeid)),
      deviceLocations: [],
      locationsToCheck: new Map(),
    })
  }

  centerOnNextDeviceLocation() {
    const deviceLoc = this.state.locationsToCheck.values().next().value
    deviceLoc.isFocus = true
    this.setState({
      mapCenter: deviceLoc,
    })
  }
}

export class DeviceLocation {
  constructor(props) {
    if (props.managementSystem === 'Airwatch') {
      this.latitude = props.latitude
      this.longitude = props.longitude
      this.timestamp = props.sample_time
    } else {
      this.latitude = props.lat
      this.longitude = props.lon
      this.timestamp = props.timestamp
    }
    this.wasChecked = false
    this.isFocus = false
  }
}

const styles = {
  root: {
    backgroundColor: '#eeeeee',
    padding: 'dense',
    fontSize: '40pt',
    verticalAlign: 'top',
  },
  cell: {
    padding: 0,
  },
  slider: {
    zIndex: 500,
    marginTop: 27,
    marginLeft: 265,
    position: 'absolute',
    border: '2px solid #D6D6D6',
    width: 150,
  },
  focused: {},
  activated: {},
  jumped: {},
  track: {
    backgroundColor: '#333',
    height: 8,
  },
  thumb: {
    backgroundColor: '#333',
    height: 14,
    width: 14,
    '&$focused, &$activated, &$jumped, &:hover': {
      boxShadow: `0px 0px 0px ${8 * 1.5}px ${fade('#333', 0.16)}`,
    },
  },
  sliderText: {
    zIndex: 500,
    marginTop: 1,
    marginLeft: 265,
    position: 'absolute',
    backgroundColor: 'white',
  },
  svgContainer: {
    display: 'inlineBlock',
    position: 'relative',
    width: '100%',
    verticalAlign: 'top',
    overflow: 'hidden',
  },
  locationMap: {
    display: 'inlineBlock',
    position: 'absolute',
    top: 60,
    left: 0,
  },
  spinner: {
    marginBottom: 15,
  },
  noLocationText: {
    textAlign: 'center',
    margin: 'auto',
    paddingTop: 25,
  },
  hide: {
    display: 'none',
  },
}

export default withStyles(styles)(withEnv()(withAnalytics()(TabletLocations)))
