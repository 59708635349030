import React from 'react'
import axios from 'axios'

class SvcEnrollmentStatus extends React.Component {
  getEnrollmentStatusData(config) {
    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey
    return axios.get(apiUriBase + `/enrollmentstatus?key=${apiKey}`)
  }
}
export default SvcEnrollmentStatus
