class ValidateDeviceLabel {
  ValidateDeviceNumber = (deviceLabel) => {
    if (/^[\w\s]*[\w]$/i.test(deviceLabel)) {
      return true
    } else if (deviceLabel.length === 0) {
      return true
    } else {
      return false
    }
  }
}
export default ValidateDeviceLabel
