import Acorn from '../../../images/acorn_logo.svg'
import Bad from '@material-ui/icons/RemoveCircleOutlineTwoTone'
import Badge from '@material-ui/core/Badge'
import Divider from '@material-ui/core/Divider'
import Good from '@material-ui/icons/CheckCircleTwoTone'
import { IconButton } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import React from 'react'
import SettingsRemoteIcon from '@material-ui/icons/SettingsRemote'
import SvcDevices from '../../../services/svcDevices'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'
import { withStyles } from '@material-ui/styles'

class TableDataHandheldDetails extends React.Component {
  constructor(props) {
    super(props)
    this.svcDevices = new SvcDevices()
  }
  render() {
    const { classes, handheldDetails } = this.props
    const acorn_link = this.svcDevices.generateAcornLogLink(
      handheldDetails.serial_number,
    )
    const BatteryPluggedStateEnum = {
      0: 'BATTERY_PLUGGED_NONE',
      1: 'BATTERY_PLUGGED_AC',
      2: 'BATTERY_PLUGGED_USB',
      4: 'BATTERY_PLUGGED_WIRELESS',
    }
    var bluetooth_myCheckout = null
    if (
      handheldDetails.preserved_bluetooth_connections !== null &&
      handheldDetails.preserved_bluetooth_connections.preserved_connections !==
        null &&
      handheldDetails.preserved_bluetooth_connections.preserved_connections !==
        'null'
    ) {
      var str =
        handheldDetails.preserved_bluetooth_connections.preserved_connections.replace(
          /\\/g,
          '',
        )
      JSON.parse(str).map((row, index) => {
        bluetooth_myCheckout = row
        return null
      })
    }
    return typeof handheldDetails !== 'undefined' ? (
      <>
        <Typography variant="h6" id="tableTitle">
          General
        </Typography>
        <Table className={classes.root}>
          <TableHead>
            <TableRow className={classes.cell}>
              <TableCell className={classes.cell}>Unique ID</TableCell>
              <TableCell className={classes.cell}>TCIN</TableCell>
              <TableCell className={classes.cell}>Mac Address</TableCell>
              <TableCell className={classes.cell}>IP Address</TableCell>
              <TableCell className={classes.cell}>OS Version</TableCell>
              <TableCell className={classes.cell}>Cert Expiration</TableCell>
              <TableCell className={classes.cell}>Platform</TableCell>
              <TableCell className={classes.cell}>Agent Version</TableCell>
              <TableCell className={classes.cell}>Build Number</TableCell>
              <TableCell className={classes.cell}>Logs</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={classes.cell}>
              <TableCell className={classes.cell}>
                {handheldDetails.unique_id && handheldDetails.unique_id}
              </TableCell>
              <TableCell className={classes.cell}>
                {handheldDetails.tcin && handheldDetails.tcin}
              </TableCell>
              <TableCell className={classes.cell}>
                {handheldDetails.mac_address && handheldDetails.mac_address}
              </TableCell>
              <TableCell className={classes.cell}>
                {handheldDetails.wifi_ip_address
                  ? handheldDetails.wifi_ip_address
                  : 'Not Available'}
              </TableCell>
              <TableCell className={classes.cell}>
                {handheldDetails.operating_system_version &&
                  handheldDetails.operating_system_version}
              </TableCell>
              <TableCell className={classes.cell}>
                {handheldDetails.certificate_expiration &&
                  handheldDetails.certificate_expiration}
              </TableCell>
              <TableCell className={classes.cell}>
                {handheldDetails.platform && handheldDetails.platform}
              </TableCell>
              <TableCell className={classes.cell}>
                {handheldDetails.agent_version && handheldDetails.agent_version}
              </TableCell>
              <TableCell className={classes.cell}>
                {handheldDetails.build_number && handheldDetails.build_number}
              </TableCell>
              <TableCell className={classes.cell}>
                <a href={acorn_link} target="_blank" rel="noreferrer">
                  <Acorn />
                </a>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Divider style={{ backgroundColor: 'black' }} />
        {handheldDetails.manufacturer_name &&
          handheldDetails.manufacturer_name !== 'Elo Touch Solutions' && (
            <>
              <div>
                <Typography variant="h6" id="tableTitle">
                  Last Event User Details
                </Typography>
                <Table className={classes.root}>
                  <TableHead>
                    <TableRow className={classes.cell}>
                      <TableCell className={classes.cell}>
                        Last Event User
                        {handheldDetails.device_event_state ===
                          'No Check-Out' && (
                          <Tooltip
                            title={
                              <Typography variant="subtitle2">
                                {'Last Checked-In User : ' +
                                  (handheldDetails.last_user.lan_id
                                    ? handheldDetails.last_user.first_name +
                                      ' ' +
                                      handheldDetails.last_user.last_name
                                    : 'No Record')}
                              </Typography>
                            }
                          >
                            <IconButton>
                              <InfoIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        Check-Out Status
                      </TableCell>
                      {handheldDetails.device_event_state == null && (
                        <TableCell className={classes.cell}>
                          Last User Event
                        </TableCell>
                      )}
                      <TableCell className={classes.cell}>
                        Last Event Timestamp
                      </TableCell>
                      <TableCell className={classes.cell}>
                        Last User Type
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow className={classes.cell}>
                      <TableCell className={classes.cell}>
                        {handheldDetails.device_event_state === 'No Check-Out'
                          ? 'No User'
                          : handheldDetails.last_user.employee_id
                            ? handheldDetails.last_user.lan_id
                              ? handheldDetails.last_user.first_name +
                                ' ' +
                                handheldDetails.last_user.last_name +
                                ' - ' +
                                handheldDetails.last_user.lan_id +
                                ' (' +
                                handheldDetails.last_user.employee_id +
                                ')'
                              : handheldDetails.last_user.employee_id
                            : 'No Existing Record'}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {handheldDetails.device_event_state
                          ? handheldDetails.device_event_state
                          : 'Not Enabled'}
                      </TableCell>
                      {handheldDetails.device_event_state == null && (
                        <TableCell className={classes.cell}>
                          {handheldDetails.last_event === null
                            ? 'No Existing Record'
                            : handheldDetails.last_event}
                        </TableCell>
                      )}
                      <TableCell className={classes.cell}>
                        {moment(
                          handheldDetails.last_event_timestamp,
                        ).format() !==
                        moment('0000-00-00 00:00:00.000').format()
                          ? moment(
                              handheldDetails.last_event_timestamp,
                            ).format() < moment().subtract(24, 'hours').format()
                            ? moment(
                                moment(
                                  handheldDetails.last_event_timestamp,
                                ).format(),
                              ).format('llll')
                            : moment
                                .utc(handheldDetails.last_event_timestamp)
                                .fromNow()
                          : 'No Existing Record'}
                      </TableCell>
                      <TableCell className={classes.cell}>
                        {handheldDetails.last_user.employee_type
                          ? handheldDetails.device_event_state ===
                            'No Check-Out'
                            ? 'No Existing Record'
                            : handheldDetails.last_user.employee_type +
                              ', ' +
                              handheldDetails.last_user.company
                          : 'No Existing Record'}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>

              <Divider style={{ backgroundColor: 'black' }} />
            </>
          )}
        {handheldDetails.device_state === 'Lost' && (
          <>
            <div>
              <Typography variant="h6" id="tableTitle">
                Lost Event Details
              </Typography>
              <Table className={classes.root}>
                <TableHead>
                  <TableRow className={classes.cell}>
                    <TableCell className={classes.cell}>
                      Lost Event User
                    </TableCell>
                    <TableCell className={classes.cell}>
                      Lost Event Timestamp
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow className={classes.cell}>
                    <TableCell className={classes.cell}>
                      {handheldDetails.lost_user &&
                        (handheldDetails.lost_user.employee_id
                          ? handheldDetails.lost_user.lan_id
                            ? handheldDetails.lost_user.first_name +
                              ' ' +
                              handheldDetails.lost_user.last_name +
                              ' - ' +
                              handheldDetails.lost_user.lan_id +
                              ' (' +
                              handheldDetails.lost_user.employee_id +
                              ')'
                            : handheldDetails.lost_user.employee_id
                          : 'No Existing Record')}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {moment(handheldDetails.lost_event_timestamp).format() !==
                      moment('0000-00-00 00:00:00.000').format()
                        ? moment(
                            handheldDetails.lost_event_timestamp,
                          ).format() < moment().subtract(24, 'hours').format()
                          ? moment(
                              moment(
                                handheldDetails.lost_event_timestamp,
                              ).format(),
                            ).format('llll')
                          : moment
                              .utc(handheldDetails.lost_event_timestamp)
                              .fromNow()
                        : 'No Existing Record'}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
            <Divider style={{ backgroundColor: 'black' }} />
          </>
        )}
        {handheldDetails.manufacturer_name &&
          handheldDetails.manufacturer_name !== 'Elo Touch Solutions' && (
            <>
              <Typography variant="h6" id="tableTitle">
                Battery
              </Typography>
              <Table className={classes.root}>
                <TableHead>
                  <TableRow className={classes.cell}>
                    <TableCell className={classes.cell}>
                      Battery Level
                    </TableCell>
                    <TableCell className={classes.cell}>
                      Battery Health
                    </TableCell>
                    <TableCell className={classes.cell}>
                      Present\Rated Capacity
                    </TableCell>
                    <TableCell className={classes.cell}>Plugged</TableCell>
                    <TableCell className={classes.cell}>
                      Serial Number
                    </TableCell>
                    <TableCell className={classes.cell}>Part Number</TableCell>
                    <TableCell className={classes.cell}>
                      Manufactured Date
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow className={classes.cell}>
                    <TableCell className={classes.cell}>
                      {handheldDetails.battery_status &&
                      handheldDetails.battery_status.level &&
                      handheldDetails.device_state !== 'Lost'
                        ? handheldDetails.battery_status.level
                        : 'BATTERY_LEVEL_UNKNOWN'}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {handheldDetails.battery_status &&
                      handheldDetails.battery_status.battery_health_string &&
                      handheldDetails.device_state !== 'Lost'
                        ? handheldDetails.battery_status.battery_health_string
                        : 'BATTERY_HEALTH_UNKNOWN'}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {handheldDetails.battery_status &&
                        handheldDetails.battery_status.present_capacity &&
                        handheldDetails.battery_status.present_capacity +
                          ' \\ ' +
                          handheldDetails.battery_status.rated_capacity}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {handheldDetails.battery_status &&
                        handheldDetails.battery_status.plugged &&
                        BatteryPluggedStateEnum[
                          handheldDetails.battery_status.plugged
                        ]}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {handheldDetails.battery_status &&
                        handheldDetails.battery_status.serial_number &&
                        handheldDetails.battery_status.serial_number}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {handheldDetails.battery_status &&
                        handheldDetails.battery_status.part_number &&
                        handheldDetails.battery_status.part_number}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {handheldDetails.battery_status &&
                        handheldDetails.battery_status.mfd &&
                        moment(handheldDetails.battery_status.mfd).format('LL')}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <Divider style={{ backgroundColor: 'black' }} />
            </>
          )}
        <Typography variant="h6" id="tableTitle">
          Bluetooth
        </Typography>
        <Table className={classes.root}>
          <TableHead>
            <TableRow className={classes.cell}>
              <TableCell className={classes.cell}>Is Discovering</TableCell>
              <TableCell className={classes.cell}>Is Enabled</TableCell>
              <TableCell className={classes.cell}>Mac Address</TableCell>
              <TableCell className={classes.cell}>Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={classes.cell}>
              <TableCell className={classes.cell}>
                {handheldDetails.bluetooth_status &&
                  handheldDetails.bluetooth_status.is_discovering}
              </TableCell>
              <TableCell className={classes.cell}>
                {handheldDetails.bluetooth_status &&
                  handheldDetails.bluetooth_status.is_enabled}
              </TableCell>
              <TableCell className={classes.cell}>
                {handheldDetails.bluetooth_status &&
                  handheldDetails.bluetooth_status.mac_address}
              </TableCell>
              <TableCell className={classes.cell}>
                {handheldDetails.bluetooth_status &&
                  handheldDetails.bluetooth_status.name}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Divider style={{ backgroundColor: 'black' }} />
        {handheldDetails.department !== 'myCheckout' &&
          handheldDetails.manufacturer_name &&
          handheldDetails.manufacturer_name !== 'Elo Touch Solutions' && (
            <>
              <div>
                <Typography variant="h6" id="tableTitle">
                  Bluetooth Pairing
                </Typography>
                <Table className={classes.root}>
                  <TableHead>
                    <TableRow className={classes.cell}>
                      <TableCell className={classes.cell}>
                        Paired Device Name
                      </TableCell>
                      <TableCell className={classes.cell}>
                        Paired Device Mac Address
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {handheldDetails.bluetooth_status &&
                      handheldDetails.bluetooth_status.paired_devices &&
                      handheldDetails.bluetooth_status.paired_devices.map(
                        (row, index) => {
                          return (
                            <TableRow className={classes.cell} key={index}>
                              <TableCell className={classes.listItem}>
                                {row.name && row.name}
                              </TableCell>
                              <TableCell className={classes.listItem}>
                                {row.mac_address && row.mac_address}
                              </TableCell>
                            </TableRow>
                          )
                        },
                      )}
                  </TableBody>
                </Table>
              </div>
            </>
          )}
        {handheldDetails.department === 'myCheckout' && (
          <div>
            <Divider style={{ backgroundColor: 'black' }} />
            <Typography variant="h6" id="tableTitle">
              Bluetooth Pairing
            </Typography>
            <Table className={classes.root}>
              <TableHead>
                <TableRow className={classes.cell}>
                  <TableCell className={classes.cell}>
                    Paired Device Name
                  </TableCell>
                  <TableCell className={classes.cell}>
                    Paired Device Mac Address
                  </TableCell>
                  <TableCell className={classes.listItem}>Paired</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bluetooth_myCheckout && (
                  <TableRow className={classes.cell}>
                    <TableCell className={classes.listItem}>
                      {bluetooth_myCheckout.name && bluetooth_myCheckout.name}
                    </TableCell>
                    <TableCell className={classes.listItem}>
                      {bluetooth_myCheckout.macAddress &&
                        bluetooth_myCheckout.macAddress}
                    </TableCell>
                    <TableCell className={classes.listItem}>
                      {bluetooth_myCheckout.paired.toString()}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              <TableBody>
                {handheldDetails.bluetooth_status &&
                  handheldDetails.bluetooth_status.paired_devices &&
                  handheldDetails.bluetooth_status.paired_devices.map(
                    (row, index) => {
                      if (bluetooth_myCheckout != null) {
                        if (
                          row.name &&
                          row.name !== bluetooth_myCheckout.name
                        ) {
                          return (
                            <TableRow className={classes.cell} key={index}>
                              <TableCell className={classes.listItem}>
                                {row.name && row.name}
                              </TableCell>
                              <TableCell className={classes.listItem}>
                                {row.mac_address && row.mac_address}
                              </TableCell>
                            </TableRow>
                          )
                        } else {
                          return null
                        }
                      } else {
                        return (
                          <TableRow className={classes.cell} key={index}>
                            <TableCell className={classes.listItem}>
                              {row.name && row.name}
                            </TableCell>
                            <TableCell className={classes.listItem}>
                              {row.mac_address && row.mac_address}
                            </TableCell>
                          </TableRow>
                        )
                      }
                    },
                  )}
              </TableBody>
            </Table>
          </div>
        )}
        {handheldDetails.management_system === 'TEP' &&
          handheldDetails.department === 'myCheckout' && (
            <div>
              <Divider style={{ backgroundColor: 'black' }} />
              <Typography variant="h6" id="tableTitle">
                E355 Verifone Payment Device
                {handheldDetails.e355_pinpad_information &&
                  handheldDetails.e355_pinpad_information.mptc_enabled &&
                  (handheldDetails.e355_pinpad_information.connection_status.toUpperCase() ===
                  'CONNECTED' ? (
                    <Tooltip title={'MPTC Enabled'}>
                      <Badge
                        badgeContent={<Good className={classes.good} />}
                        className={classes.badge}
                      >
                        <SettingsRemoteIcon className={classes.icon} />
                      </Badge>
                    </Tooltip>
                  ) : (
                    <Tooltip title={'MPTC Disabled'}>
                      <Badge
                        badgeContent={<Bad className={classes.bad} />}
                        className={classes.badge}
                      >
                        <SettingsRemoteIcon className={classes.icon} />
                      </Badge>
                    </Tooltip>
                  ))}
              </Typography>
              <Table className={classes.root}>
                <TableHead>
                  <TableRow className={classes.cell}>
                    <TableCell className={classes.cell}>Host Name</TableCell>
                    <TableCell className={classes.cell}>e355 Serial</TableCell>
                    <TableCell className={classes.listItem}>
                      e355 Part Number
                    </TableCell>
                    <TableCell className={classes.listItem}>
                      OS Version
                    </TableCell>
                    <TableCell className={classes.listItem}>
                      Build Version
                    </TableCell>
                    <TableCell className={classes.listItem}>
                      XPI Version
                    </TableCell>
                    <TableCell className={classes.listItem}>
                      Cam Version
                    </TableCell>
                    <TableCell className={classes.listItem}>PIN KSN</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow className={classes.cell}>
                    <TableCell className={classes.listItem}>
                      {handheldDetails.e355_pinpad_information &&
                        handheldDetails.e355_pinpad_information.android_host}
                    </TableCell>
                    <TableCell className={classes.listItem}>
                      {handheldDetails.e355_pinpad_information &&
                        handheldDetails.e355_pinpad_information
                          .pinpad_serial_number}
                    </TableCell>
                    <TableCell className={classes.listItem}>
                      {handheldDetails.e355_pinpad_information &&
                        handheldDetails.e355_pinpad_information
                          .svc_info_part_number}
                    </TableCell>
                    <TableCell className={classes.listItem}>
                      {handheldDetails.e355_pinpad_information &&
                        handheldDetails.e355_pinpad_information.os_version}
                    </TableCell>
                    <TableCell className={classes.listItem}>
                      {handheldDetails.e355_pinpad_information &&
                        handheldDetails.e355_pinpad_information
                          .tgt_build_number}
                    </TableCell>
                    <TableCell className={classes.listItem}>
                      {handheldDetails.e355_pinpad_information &&
                        handheldDetails.e355_pinpad_information.xpi_version}
                    </TableCell>
                    <TableCell className={classes.listItem}>
                      {handheldDetails.e355_pinpad_information &&
                        handheldDetails.e355_pinpad_information.cam_version}
                    </TableCell>
                    <TableCell className={classes.listItem}>
                      {handheldDetails.e355_pinpad_information &&
                        handheldDetails.e355_pinpad_information.debit_ksn}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              {handheldDetails.e355_pinpad_information &&
              handheldDetails.e355_pinpad_information.mptc_enabled ? (
                <Table className={classes.root}>
                  <TableHead>
                    <TableRow className={classes.cell}>
                      <TableCell className={classes.listItem}>
                        Battery Level
                      </TableCell>
                      <TableCell className={classes.listItem}>SRED</TableCell>
                      <TableCell className={classes.listItem}>
                        Connection Status
                      </TableCell>
                      {handheldDetails.e355_pinpad_information &&
                        handheldDetails.e355_pinpad_information.connection_status.toUpperCase() ===
                          'DISCONNECTED' && (
                          <TableCell className={classes.listItem}>
                            Last Connected Time
                          </TableCell>
                        )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow className={classes.cell}>
                      <TableCell className={classes.listItem}>
                        {handheldDetails.e355_pinpad_information &&
                          handheldDetails.e355_pinpad_information.battery_level}
                      </TableCell>
                      <TableCell className={classes.listItem}>
                        {handheldDetails.e355_pinpad_information &&
                          handheldDetails.e355_pinpad_information.sred !==
                            null &&
                          handheldDetails.e355_pinpad_information.sred !==
                            undefined &&
                          handheldDetails.e355_pinpad_information.sred.toString()}
                      </TableCell>
                      <TableCell className={classes.listItem}>
                        {handheldDetails.e355_pinpad_information &&
                          handheldDetails.e355_pinpad_information
                            .connection_status}
                      </TableCell>
                      {handheldDetails.e355_pinpad_information &&
                        handheldDetails.e355_pinpad_information.connection_status.toUpperCase() ===
                          'DISCONNECTED' && (
                          <TableCell className={classes.listItem}>
                            {handheldDetails.e355_pinpad_information &&
                            handheldDetails.e355_pinpad_information
                              .last_connected_time !== null
                              ? handheldDetails.e355_pinpad_information
                                  .last_connected_time
                              : handheldDetails.e355_pinpad_information
                                  .timestamp}
                          </TableCell>
                        )}
                    </TableRow>
                  </TableBody>
                </Table>
              ) : (
                ''
              )}
              <Divider style={{ backgroundColor: 'black' }} />
              <Typography variant="h6" id="tableTitle">
                SIM Information
              </Typography>
              <Table className={classes.root}>
                <TableHead>
                  <TableRow className={classes.cell}>
                    <TableCell className={classes.cell}>
                      Sim Serial Number
                    </TableCell>
                    <TableCell className={classes.cell}>
                      ICC Card Status
                    </TableCell>
                    <TableCell className={classes.listItem}>
                      Sim Operator
                    </TableCell>
                    <TableCell className={classes.listItem}>IMEI</TableCell>
                    <TableCell className={classes.listItem}>
                      Line1 Number
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow className={classes.cell}>
                    <TableCell className={classes.listItem}>
                      {handheldDetails.sim_info &&
                        handheldDetails.sim_info.sim_serial_number}
                    </TableCell>
                    <TableCell className={classes.listItem}>
                      {handheldDetails.sim_info &&
                        handheldDetails.sim_info.has_icc_card}
                    </TableCell>
                    <TableCell className={classes.listItem}>
                      {handheldDetails.sim_info &&
                        handheldDetails.sim_info.sim_operator}
                    </TableCell>
                    <TableCell className={classes.listItem}>
                      {handheldDetails.sim_info &&
                        handheldDetails.sim_info.imei}
                    </TableCell>
                    <TableCell className={classes.listItem}>
                      {handheldDetails.sim_info &&
                        handheldDetails.sim_info.line1_number}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          )}
      </>
    ) : (
      <div>Note, additional details are not available for this device.</div>
    )
  }
}

const styles = (theme) => ({
  root: {
    backgroundColor: '#eeeeee',
    padding: 'dense',
    fontSize: '40pt',
    align: 'top',
  },
  cell: {
    padding: 0,
    size: 'small',
  },
  good: {
    fontSize: 16,
    color: 'green',
  },
  bad: {
    fontSize: 16,
    color: 'red',
  },
  icon: {
    color: '#757575',
    fontSize: 24,
  },
  honeyComb: {
    minHeight: `calc(100vh - 75px)`,
    backgroundImage:
      `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='24.5' ` +
      `viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%23888888' fill-opacity='0.50' ` +
      `fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 ` +
      `11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 ` +
      `0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
  },
  listItem: {
    padding: 0,
  },
})

export default withStyles(styles, { withTheme: true })(TableDataHandheldDetails)
