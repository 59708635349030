export const redirectHandler = (redirectType, params) => {
  switch (redirectType) {
    case 'advanced_search':
      //... go to devices route w/ params
      window.location = window.location.origin + `/search`
      break
    case 'enrollment':
      //... go to enroll route
      break
    case 'profile':
      window.location = window.location.origin + `/profile`
      break
    case 'location_id':
      window.location = window.location.origin + `/location/${parseInt(params)}`
      break
    default:
      //... if nothing exists don't do that
      break
  }
}
