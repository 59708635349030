import axios from 'axios'
class SvcStoreMapInfo {
  getStoreMapInfo(locationId, floorId, config) {
    // fetch neptune map by location id and floor id
    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey
    const apiUri = encodeURI(
      apiUriBase +
        `/store_map_info?location_id=` +
        locationId +
        `&floor_id=` +
        floorId +
        `&key=` +
        apiKey,
    )
    return axios.get(apiUri)
  }
}
export default SvcStoreMapInfo
