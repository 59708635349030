import {
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  makeStyles,
} from '@material-ui/core'

import AddIcon from '@material-ui/icons/Add'
import { useState } from 'react'

const FlipCard = ({ onSubmit, aggregatableFields }) => {
  const classes = useStyles()
  const [isFlipped, setIsFlipped] = useState(false)
  const [selectedOption, setSelectedOption] = useState('')
  const [enabled, setEnabled] = useState(false)
  const handleFlip = (event) => {
    event.stopPropagation()
    setIsFlipped(true)
  }
  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value)
    setEnabled(true)
  }
  const handleSubmit = (event) => {
    event.stopPropagation()
    setIsFlipped(false)
    setSelectedOption('')
    onSubmit(selectedOption)
    setEnabled(false)
  }
  return (
    <div className={classes.container} onClick={handleFlip}>
      <div className={`${classes.inner} ${isFlipped ? classes.flipped : ''}`}>
        <div className={`${classes.face} ${classes.front}`}>
          <Card style={{ width: '100%' }}>
            <CardContent
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <AddIcon style={{ fontSize: 50 }} />
            </CardContent>
          </Card>
        </div>
        <div className={`${classes.face} ${classes.back}`}>
          <Card
            style={{ width: '100%' }}
            onClick={(event) => event.stopPropagation()}
          >
            <CardContent>
              <FormControl fullWidth>
                <InputLabel id="select-label">Group By</InputLabel>
                <Select
                  labelId="select-label"
                  value={selectedOption}
                  onChange={handleSelectChange}
                >
                  {aggregatableFields &&
                    aggregatableFields.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option.toString()}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <Button
                variant="contained"
                disabled={!enabled}
                color="primary"
                style={{ marginTop: '1rem' }}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: {
    perspective: '1000px',
    width: '18rem',
    margin: '1rem',
  },
  inner: {
    position: 'relative',
    width: '100%',
    height: '100%',
    transition: 'transform 0.6s',
    transformStyle: 'preserve-3d',
    '&$flipped': {
      transform: 'rotateY(180deg)',
    },
  },
  flipped: {},
  face: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backfaceVisibility: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  front: {},
  back: {
    transform: 'rotateY(180deg)',
  },
}))

export default FlipCard
