import DeletePrinter from '../MenuActionItems/DeletePrinter'
import DisableNotification from '../MenuActionItems/DisableNotification'
import Divider from '@material-ui/core/Divider'
import EditPrinterHostname from '../MenuActionItems/EditPrinterHostname'
import EditPrinterLabel from '../MenuActionItems/EditPrinterLabel'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import PrinterLostStolen from '../MenuActionItems/PrinterLostStolen'
import PrinterTools from '../MenuActionItems/PrinterTools'
import React from 'react'
import RingDevicePrinter from '../MenuActionItems/RingDevicePrinter'
import SetPrinterMode from '../MenuActionItems/SetPrinterMode'
import SettingsIcon from '@material-ui/icons/Settings'
import Tooltip from '@material-ui/core/Tooltip'
import { Typography } from '@material-ui/core'
import printerDeviceTypes from '../deviceTypes/printerDeviceTypes'
import { withAuth } from '@praxis/component-auth'

function MenuActionItem(props) {
  return (
    <MenuItem
      onClick={() => {
        props.onClose()
        props.onClick()
      }}
    >
      {props.label}
    </MenuItem>
  )
}

class PrinterActionsMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      anchorEl: null,
      showPrinterDialog: false,
      showPrinterDeleteDialog: false,
      showPrinterToolsDialog: false,
      showPrinterHostnameDialog: false,
      showPrinterLabelDialog: false,
      showRingDeviceDialog: false,
      showDisableNotification: false,
      showPrinterLostStolen: false,
      menuDisabled: true,
      toDoAction: '',
      toDoActionValue: '',
    }
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget })
  }
  handlePrinterModeActionClick = (toDoAction) => {
    this.setState({ showPrinterModeDialog: true, toDoAction: toDoAction })
  }
  handlePrinterModeDialogClose = (event) => {
    this.setState({ showPrinterModeDialog: false })
  }
  handlePrinterToolsActionClick = (toDoAction, toDoActionValue) => {
    this.setState({
      showPrinterToolsDialog: true,
      toDoAction: toDoAction,
      toDoActionValue: toDoActionValue,
    })
  }
  handlePrinterLabelActionClick = (toDoAction, toDoActionValue) => {
    this.setState({
      showPrinterLabelDialog: true,
      toDoAction: toDoAction,
      toDoActionValue: toDoActionValue,
    })
  }

  handlePrinterToolsDialogClose = (event) => {
    this.setState({ showPrinterToolsDialog: false })
  }
  handleClose = () => {
    this.setState({ anchorEl: null })
  }
  handleMenu = () => {
    this.setState({ menuDisabled: false })
  }
  handlePrinterHostnameActionClick = (toDoAction) => {
    this.setState({ showPrinterHostnameDialog: true, toDoAction: toDoAction })
  }
  handlePrinterDeleteActionClick = (toDoAction) => {
    this.setState({ showPrinterDeleteDialog: true, toDoAction: toDoAction })
  }
  handleRingDeviceActionClick = (toDoAction, toDoActionValue) => {
    this.setState({
      showRingDeviceDialog: true,
      toDoAction: toDoAction,
      toDoActionValue: toDoActionValue,
    })
  }
  handleDisableNotificationActionClick = () => {
    this.setState({ showDisableNotification: true })
  }
  handlePrinterLostStolenActionClick = () => {
    this.setState({ showPrinterLostStolen: true })
  }
  handlePrinterHostnameDialogClose = (event) => {
    this.setState({ showPrinterHostnameDialog: false })
  }
  handlePrinterDeleteDialogClose = (event) => {
    this.setState({ showPrinterDeleteDialog: false })
  }
  handlePrinterLabelDialogClose = (event) => {
    this.setState({ showPrinterLabelDialog: false })
  }
  handleRingDeviceDialogClose = (event) => {
    this.setState({ showRingDeviceDialog: false })
  }
  handleDisableNotificationDialogClose = () => {
    this.setState({ showDisableNotification: false })
  }
  handlePrinterLostStolenDialogClose = () => {
    this.setState({ showPrinterLostStolen: false })
  }

  render() {
    const {
      rowData: { department, ip_address, hostname, device_state, is_renamed },
    } = this.props
    const { anchorEl, menuDisabled } = this.state
    function hasAvailableAction(action) {
      return (
        printerDeviceTypes.hasOwnProperty(department) &&
        printerDeviceTypes[department].hasOwnProperty('availableActions') &&
        printerDeviceTypes[department].availableActions.includes(action)
      )
    }
    return (
      <>
        {!menuDisabled ? (
          <Tooltip title="Available Actions">
            <div>
              <IconButton disabled={false} onClick={this.handleClick}>
                <SettingsIcon />
              </IconButton>
            </div>
          </Tooltip>
        ) : (
          <Tooltip title="No Available Actions">
            <div>
              <IconButton disabled={true} onClick={this.handleClick}>
                <SettingsIcon />
              </IconButton>
            </div>
          </Tooltip>
        )}
        <Menu
          style={{ paddingTop: 0, paddingBottom: 0 }}
          disableAutoFocusItem
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {hasAvailableAction('setPrinterMode') &&
            this.props.access.printerModeGroup.some((r) =>
              this.props.auth.session.userInfo.memberOf.includes(
                r.toUpperCase(),
              ),
            ) &&
            device_state === 'Online' &&
            typeof ip_address === 'string' &&
            (menuDisabled && this.handleMenu(),
            (
              <div>
                <Typography
                  style={{
                    textAlign: 'Center',
                    backgroundColor: '#F7DA5E',
                    textTransform: 'uppercase',
                  }}
                  variant="subtitle2"
                >
                  Printer Mode
                </Typography>
                <Divider />
                <MenuActionItem
                  onClick={() => this.handlePrinterModeActionClick('zplstar')}
                  label={'Set to ZPL *'}
                  onClose={() => {
                    this.handleClose()
                  }}
                />
                <MenuActionItem
                  onClick={() => this.handlePrinterModeActionClick('pdf')}
                  label={'Set to PDF'}
                  onClose={() => {
                    this.handleClose()
                  }}
                />
                {/* <MenuActionItem
                  onClick={() => this.handlePrinterModeActionClick('ezprint')}
                  label={'Set to EZPRINT'}
                  onClose={() => {
                    this.handleClose()
                  }}
                />
                <MenuActionItem
                  onClick={() => this.handlePrinterModeActionClick('zpl')}
                  label={'Set to ZPL'}
                  onClose={() => {
                    this.handleClose()
                  }}
                /> */}
              </div>
            ))}
          {hasAvailableAction('printerTools') &&
            device_state === 'Online' &&
            this.props.access.printerToolsActionGroup.some((r) =>
              this.props.auth.session.userInfo.memberOf.includes(
                r.toUpperCase(),
              ),
            ) &&
            typeof ip_address === 'string' &&
            (menuDisabled && this.handleMenu(),
            (
              <div>
                <Typography
                  style={{
                    textAlign: 'Center',
                    backgroundColor: '#F7DA5E',
                    textTransform: 'uppercase',
                  }}
                  variant="subtitle2"
                >
                  Printer Tools
                </Typography>
                <Divider />
                {hasAvailableAction('printerTOF') && (
                  <MenuActionItem
                    // TOF36plus
                    onClick={() =>
                      this.handlePrinterToolsActionClick('TOF36plus')
                    }
                    label={'Set Printer TOF X0 Y0'}
                    onClose={() => {
                      this.handleClose()
                    }}
                  />
                )}
                {/* {hasAvailableAction('printerTest') && (
                  <MenuActionItem
                    // testPrintID
                    onClick={() =>
                      this.handlePrinterToolsActionClick('printLabel', hostname)
                    }
                    label={'Reprint Barcode Label'}
                    onClose={() => {
                      this.handleClose()
                    }}
                  />
                )} */}
                {hasAvailableAction('printerTest') && (
                  <MenuActionItem
                    // testPrintID
                    onClick={() =>
                      this.handlePrinterToolsActionClick(
                        'printLabel',
                        'Hello MICKRA',
                      )
                    }
                    label={'Send Test Print'}
                    onClose={() => {
                      this.handleClose()
                    }}
                  />
                )}
                {/* {hasAvailableAction(`printer${model_name}Reprint`) && (
                  <MenuActionItem
                    // testPrintID
                    onClick={() =>
                      this.handlePrinterToolsActionClick(
                        `printLabelBorder${model_name}`,
                        hostname
                      )
                    }
                    label={'Reprint Barcode Label'}
                    onClose={() => {
                      this.handleClose()
                    }}
                  />
                )} */}
                {hasAvailableAction(`printerZT410Test`) && (
                  <MenuActionItem
                    // testPrintID
                    onClick={() =>
                      this.handlePrinterToolsActionClick(
                        `printZTPrintheadTest`,
                        'Hello MICKRA',
                      )
                    }
                    label={'Send Test Print'}
                    onClose={() => {
                      this.handleClose()
                    }}
                  />
                )}
                {hasAvailableAction('printerRebase') &&
                  this.props.access.printerRebaseGroup.some((r) =>
                    this.props.auth.session.userInfo.memberOf.includes(
                      r.toUpperCase(),
                    ),
                  ) && (
                    <MenuActionItem
                      // idealSettings
                      onClick={() =>
                        this.handlePrinterToolsActionClick('idealSettings')
                      }
                      label={'Set Printer Base Configuration'}
                      onClose={() => {
                        this.handleClose()
                      }}
                    />
                  )}
                {hasAvailableAction(`printerZT410Rebase`) &&
                  this.props.access.printerRebaseGroup.some((r) =>
                    this.props.auth.session.userInfo.memberOf.includes(
                      r.toUpperCase(),
                    ),
                  ) && (
                    <MenuActionItem
                      // idealSettings
                      onClick={() =>
                        this.handlePrinterToolsActionClick(`idealSettingsZT410`)
                      }
                      label={'Set Printer Base Configuration'}
                      onClose={() => {
                        this.handleClose()
                      }}
                    />
                  )}
                {hasAvailableAction('printerReboot') && (
                  <MenuActionItem
                    // reboot
                    onClick={() => this.handlePrinterToolsActionClick('reboot')}
                    label={'Reboot Printer'}
                    onClose={() => {
                      this.handleClose()
                    }}
                  />
                )}
              </div>
            ))}
          {/* add action item here */}
          {hasAvailableAction('editPrinterHostname') &&
            this.props.access.printerRenameGroup.some((r) =>
              this.props.auth.session.userInfo.memberOf.includes(
                r.toUpperCase(),
              ),
            ) &&
            device_state === 'Online' &&
            typeof ip_address === 'string' &&
            (menuDisabled && this.handleMenu(),
            (
              <div>
                <Typography
                  style={{
                    textAlign: 'Center',
                    backgroundColor: '#F7DA5E',
                    textTransform: 'uppercase',
                  }}
                  variant="subtitle2"
                >
                  Printer Rename
                </Typography>
                <Divider />
                <MenuActionItem
                  onClick={() =>
                    this.handlePrinterHostnameActionClick(
                      'device.friendly_name',
                    )
                  }
                  label={'Printer Rename'}
                  onClose={() => {
                    this.handleClose()
                  }}
                />
              </div>
            ))}
          {/* add action item here */}
          {hasAvailableAction('printerDelete') &&
            this.props.access.printerDeleteGroup.some((r) =>
              this.props.auth.session.userInfo.memberOf.includes(
                r.toUpperCase(),
              ),
            ) &&
            (menuDisabled && this.handleMenu(),
            (
              <div>
                <Typography
                  style={{
                    textAlign: 'Center',
                    backgroundColor: '#F7DA5E',
                    textTransform: 'uppercase',
                  }}
                  variant="subtitle2"
                >
                  Printer Delete
                </Typography>
                <Divider />
                <MenuActionItem
                  onClick={() =>
                    this.handlePrinterDeleteActionClick('device.friendly_name')
                  }
                  label={'Printer Delete'}
                  onClose={() => {
                    this.handleClose()
                  }}
                />
              </div>
            ))}
          {hasAvailableAction('editPrinterLabel') &&
            (this.props.access.editDeviceLabelByJobCodeId.includes(
              this.props.auth.session.identity.jobtitlecode,
            ) ||
              this.props.access.printerLabelGroup.some((r) =>
                this.props.auth.session.userInfo.memberOf.includes(
                  r.toUpperCase(),
                ),
              )) &&
            (menuDisabled && this.handleMenu(),
            (
              <div>
                <Typography
                  style={{
                    textAlign: 'Center',
                    backgroundColor: '#F7DA5E',
                    textTransform: 'uppercase',
                  }}
                  variant="subtitle2"
                >
                  Edit Printer Label
                </Typography>
                <Divider />
                <MenuActionItem
                  onClick={() => this.handlePrinterLabelActionClick()}
                  label={'Edit Printer Label'}
                  onClose={() => {
                    this.handleClose()
                  }}
                />
              </div>
            ))}
          {hasAvailableAction('ringDevice') &&
            (this.props.access.editDeviceLabelByJobCodeId.includes(
              this.props.auth.session.identity.jobtitlecode,
            ) ||
              this.props.access.ringDeviceGroup.some((r) =>
                this.props.auth.session.userInfo.memberOf.includes(
                  r.toUpperCase(),
                ),
              )) &&
            device_state === 'Online' &&
            (menuDisabled && this.handleMenu(),
            (
              <div>
                <Typography
                  style={{
                    textAlign: 'Center',
                    backgroundColor: '#F7DA5E',
                    textTransform: 'uppercase',
                  }}
                  variant="subtitle2"
                >
                  Ring Printer
                </Typography>
                <Divider />
                <MenuActionItem
                  onClick={() =>
                    this.handleRingDeviceActionClick('beep', '240')
                  }
                  label={'Ring Printer'}
                  onClose={() => {
                    this.handleClose()
                  }}
                />
              </div>
            ))}
          {hasAvailableAction('disableNotification') &&
            is_renamed === true &&
            (menuDisabled && this.handleMenu(),
            (
              <div>
                <Typography
                  style={{
                    textAlign: 'Center',
                    backgroundColor: '#F7DA5E',
                    textTransform: 'uppercase',
                  }}
                  variant="subtitle2"
                >
                  Clear Rename Notification
                </Typography>
                <Divider />
                <MenuActionItem
                  onClick={() => this.handleDisableNotificationActionClick()}
                  label={'Clear Rename Notification'}
                  onClose={() => {
                    this.handleClose()
                  }}
                />
              </div>
            ))}
          {hasAvailableAction('launchWebPortal') &&
            this.props.access.printerLexmarkLaunchWebGroup.some((r) =>
              this.props.auth.session.userInfo.memberOf.includes(
                r.toUpperCase(),
              ),
            ) &&
            (menuDisabled && this.handleMenu(),
            (
              <div>
                <Typography
                  style={{
                    textAlign: 'Center',
                    backgroundColor: '#F7DA5E',
                    textTransform: 'uppercase',
                  }}
                  variant="subtitle2"
                >
                  Launch Admin Web Portal
                </Typography>
                <Divider />
                <MenuActionItem
                  onClick={() => {
                    hostname.match(/^[T]\d{4}[P][R][T][2][0][0][1]$/gi)
                      ? window.open(`http://${hostname}.kiosk.target.com`)
                      : window.open(`http://${hostname}.stores.target.com`)
                  }}
                  label={'Launch Admin Web Portal'}
                  onClose={() => {
                    this.handleClose()
                  }}
                />
              </div>
            ))}
          {hasAvailableAction('printerLostStolen') &&
            (device_state === 'Online' || device_state === 'Offline') &&
            this.props.access.printerLostGroup.some((r) =>
              this.props.auth.session.userInfo.memberOf.includes(
                r.toUpperCase(),
              ),
            ) &&
            (menuDisabled && this.handleMenu(),
            (
              <div>
                <Typography
                  style={{
                    textAlign: 'Center',
                    backgroundColor: '#F7DA5E',
                    textTransform: 'uppercase',
                  }}
                  variant="subtitle2"
                >
                  Printer Lost/Stolen
                </Typography>
                <Divider />
                <MenuActionItem
                  onClick={() => this.handlePrinterLostStolenActionClick()}
                  label={'Printer Lost/Stolen'}
                  onClose={() => {
                    this.handleClose()
                  }}
                />
              </div>
            ))}
          {hasAvailableAction('printerZT410LostStolen') &&
            (device_state === 'Online' || device_state === 'Offline') &&
            this.props.access.printerZT410LostGroup.some((r) =>
              this.props.auth.session.userInfo.memberOf.includes(
                r.toUpperCase(),
              ),
            ) &&
            (menuDisabled && this.handleMenu(),
            (
              <div>
                <Typography
                  style={{
                    textAlign: 'Center',
                    backgroundColor: '#F7DA5E',
                    textTransform: 'uppercase',
                  }}
                  variant="subtitle2"
                >
                  Printer Lost/Stolen
                </Typography>
                <Divider />
                <MenuActionItem
                  onClick={() => this.handlePrinterLostStolenActionClick()}
                  label={'Printer Lost/Stolen'}
                  onClose={() => {
                    this.handleClose()
                  }}
                />
              </div>
            ))}
        </Menu>
        {this.state.showPrinterModeDialog && (
          <SetPrinterMode
            toDoAction={this.state.toDoAction}
            open={this.state.showPrinterModeDialog}
            onClose={() => {
              this.handlePrinterModeDialogClose()
            }}
            {...this.props}
          />
        )}
        {this.state.showPrinterToolsDialog && (
          <PrinterTools
            toDoAction={this.state.toDoAction}
            toDoActionValue={this.state.toDoActionValue}
            open={this.state.showPrinterToolsDialog}
            onClose={() => {
              this.handlePrinterToolsDialogClose()
            }}
            {...this.props}
          />
        )}
        {this.state.showPrinterHostnameDialog && (
          <EditPrinterHostname
            toDoAction={this.state.toDoAction}
            open={this.state.showPrinterHostnameDialog}
            onClose={() => {
              this.handlePrinterHostnameDialogClose()
            }}
            {...this.props}
          />
        )}
        {this.state.showPrinterDeleteDialog && (
          <DeletePrinter
            toDoAction={this.state.toDoAction}
            open={this.state.showPrinterDeleteDialog}
            onClose={() => {
              this.handlePrinterDeleteDialogClose()
            }}
            {...this.props}
          />
        )}
        {this.state.showPrinterLabelDialog && (
          <EditPrinterLabel
            toDoAction={this.state.toDoAction}
            open={this.state.showPrinterLabelDialog}
            onClose={() => {
              this.handlePrinterLabelDialogClose()
            }}
            {...this.props}
          />
        )}
        {this.state.showRingDeviceDialog && (
          <RingDevicePrinter
            toDoAction={this.state.toDoAction}
            toDoActionValue={this.state.toDoActionValue}
            open={this.state.showRingDeviceDialog}
            onClose={() => {
              this.handleRingDeviceDialogClose()
            }}
            {...this.props}
          />
        )}
        {this.state.showDisableNotification && (
          <DisableNotification
            open={this.state.showDisableNotification}
            onClose={() => {
              this.handleDisableNotificationDialogClose()
            }}
            {...this.props}
          />
        )}
        {this.state.showPrinterLostStolen && (
          <PrinterLostStolen
            open={this.state.showPrinterLostStolen}
            onClose={() => {
              this.handlePrinterLostStolenDialogClose()
            }}
            {...this.props}
          />
        )}
      </>
    )
  }
}

export default withAuth(null)(PrinterActionsMenu)
