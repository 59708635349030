import Bad from '@material-ui/icons/RemoveCircleOutlineTwoTone'
import Badge from '@material-ui/core/Badge'
import BatteryCharging20Icon from '@material-ui/icons/BatteryCharging20Outlined'
import BatteryGoodIcon from '@material-ui/icons/BatteryFullOutlined'
import BatteryNullIcon from '@material-ui/icons/BatteryUnknownOutlined'
import BluetoothIcon from '@material-ui/icons/Bluetooth'
import Good from '@material-ui/icons/CheckCircleTwoTone'
import Help from '@material-ui/icons/HelpOutline'
import ListItem from '@material-ui/core/ListItem'
import Policy from '../../../images/policy.svg'
import React from 'react'
import SyncProblemIcon from '@material-ui/icons/SyncProblem'
import Tooltip from '@material-ui/core/Tooltip'
import handheldDeviceTypes from '../deviceTypes/handheldDeviceTypes'
import { withEnv } from '@praxis/component-runtime-env'
import { withStyles } from '@material-ui/styles'

class DeviceHealthTep extends React.Component {
  render() {
    const {
      classes,
      rowData: {
        battery_status: { level },
        product_compliance,
        network_compliant,
        policy_compliant,
        management_system,
        department,
        preserved_bluetooth_connections,
        direct_boot_mode,
        device_state,
      },
    } = this.props

    return (
      <ListItem className={classes.listItem}>
        {(handheldDeviceTypes.hasOwnProperty(management_system) &&
          handheldDeviceTypes[management_system].hasOwnProperty(
            'availableHeathItems',
          ) &&
          handheldDeviceTypes[management_system].availableHeathItems.includes(
            'batteryHealth',
          ) &&
          this.props.env.department_handheld_tablet_map.handhelds.includes(
            department,
          ) &&
          level === null) ||
          (device_state === 'Lost' && (
            <Tooltip title={'Battery Health - Level: Unknown'}>
              <Badge
                badgeContent={<Help className={classes.batteryNull} />}
                className={classes.badge}
              >
                <BatteryNullIcon className={classes.icon} />
              </Badge>
            </Tooltip>
          ))}
        {handheldDeviceTypes.hasOwnProperty(management_system) &&
          handheldDeviceTypes[management_system].hasOwnProperty(
            'availableHeathItems',
          ) &&
          handheldDeviceTypes[management_system].availableHeathItems.includes(
            'batteryHealth',
          ) &&
          this.props.env.department_handheld_tablet_map.handhelds.includes(
            department,
          ) &&
          level !== null &&
          level < 20 &&
          device_state !== 'Lost' && (
            <Tooltip title={'Battery Health - Level: ' + level + '%'}>
              <Badge
                badgeContent={<Bad className={classes.bad} />}
                className={classes.badge}
              >
                <BatteryCharging20Icon className={classes.icon} />
              </Badge>
            </Tooltip>
          )}
        {handheldDeviceTypes.hasOwnProperty(management_system) &&
          handheldDeviceTypes[management_system].hasOwnProperty(
            'availableHeathItems',
          ) &&
          handheldDeviceTypes[management_system].availableHeathItems.includes(
            'batteryHealth',
          ) &&
          this.props.env.department_handheld_tablet_map.handhelds.includes(
            department,
          ) &&
          level !== null &&
          level >= 20 &&
          device_state !== 'Lost' && (
            <Tooltip title={'Battery Health - Level: ' + level + '%'}>
              <Badge
                badgeContent={<Good className={classes.good} />}
                className={classes.badge}
              >
                <BatteryGoodIcon className={classes.icon} />
              </Badge>
            </Tooltip>
          )}
        {/* add action item here */}
        {handheldDeviceTypes.hasOwnProperty(management_system) &&
        handheldDeviceTypes[management_system].hasOwnProperty(
          'availableHeathItems',
        ) &&
        handheldDeviceTypes[management_system].availableHeathItems.includes(
          'complianceHealth',
        ) &&
        product_compliance &&
        network_compliant &&
        policy_compliant ? (
          <Tooltip
            title={
              'Compliance Health - Policies, Products, Network - Compliant'
            }
          >
            <Badge
              badgeContent={<Good className={classes.good} />}
              className={classes.badge}
            >
              <Policy className={classes.icon} />
            </Badge>
          </Tooltip>
        ) : (
          <Tooltip
            title={
              <div>
                Compliance Health: <br />
                Policies: {`${policy_compliant}`} <br />
                Products: {`${product_compliance}`} <br />
                Network: {`${network_compliant}`}
              </div>
            }
          >
            <Badge
              badgeContent={<Bad className={classes.bad} />}
              className={classes.badge}
            >
              <Policy className={classes.icon} />
            </Badge>
          </Tooltip>
        )}
        {department === 'myCheckout' ? (
          handheldDeviceTypes.hasOwnProperty(management_system) &&
          handheldDeviceTypes[management_system].hasOwnProperty(
            'availableHeathItems',
          ) &&
          handheldDeviceTypes[management_system].availableHeathItems.includes(
            'bluetoothCompliance',
          ) &&
          preserved_bluetooth_connections &&
          preserved_bluetooth_connections.preserved_bluetooth_connections_compliance ? (
            <Tooltip title={'Bluetooth Pairing Compliance'}>
              <Badge
                badgeContent={<Good className={classes.good} />}
                className={classes.badge}
              >
                <BluetoothIcon className={classes.icon} />
              </Badge>
            </Tooltip>
          ) : (
            <Tooltip title={'Bluetooth Compliance'}>
              <Badge
                badgeContent={<Bad className={classes.bad} />}
                className={classes.badge}
              >
                <BluetoothIcon className={classes.icon} />
              </Badge>
            </Tooltip>
          )
        ) : (
          ''
        )}
        {handheldDeviceTypes.hasOwnProperty(management_system) &&
          direct_boot_mode === true && (
            <Tooltip title={'Device is in Direct Boot Mode'}>
              <SyncProblemIcon style={{ color: 'red' }}></SyncProblemIcon>
            </Tooltip>
          )}
      </ListItem>
    )
  }
}

const styles = (theme) => ({
  margin: {
    margin: theme.spacing(2),
  },
  error: {
    fontSize: 16,
    color: 'red',
  },
  good: {
    fontSize: 16,
    color: 'green',
  },
  batteryNull: {
    fontSize: 16,
    color: 'grey',
  },
  utilizationNull: {
    fontSize: 16,
    color: 'grey',
  },
  bad: {
    fontSize: 16,
    color: 'red',
  },
  warning: {
    fontSize: 16,
    fill: 'yellow',
    stroke: 'black',
    strokeWidth: '1',
  },
  badge: {
    margin: 4,
  },
  icon: {
    color: '#757575',
    fontSize: 16,
  },
  listItem: {
    paddingLeft: 0,
    paddingRight: 0,
  },
})

export default withStyles(styles, { withTheme: true })(
  withEnv()(DeviceHealthTep),
)
