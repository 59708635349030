import * as L from 'leaflet'
import * as d3 from 'd3'

import { useEffect, useRef, useState } from 'react'

import moment from 'moment'

const LostStolenMap = (props) => {
  const { mapCenter } = props
  const [mapRandom] = useState('map' + Math.floor(Math.random() * 1000) + 1)
  const [mapInstance, setMapInstance] = useState(null)
  const mapRef = useRef(null)
  const [g, setG] = useState(null)

  /**
   * Build the map layer and center it on the starting location (likely a StoreLocation).
   */
  useEffect(() => {
    if (mapInstance) return
    mapRef.current = L.map(mapRandom, {
      bounceAtZoomLimits: false,
      boxZoom: false,
    })

    mapRef.current.setView([mapCenter.latitude, mapCenter.longitude], 18)

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png').addTo(
      mapRef.current,
    )

    /* Initialize the SVG layer */
    L.svg().addTo(mapRef.current)
    setMapInstance(mapRef.current)

    const svg = d3
      .select('#' + mapRandom)
      .select('svg')
      .attr('pointer-events', null)
    const g = svg.append('g')
    setG(g)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!mapInstance) return

    /**
     * 1. Moves the map to center of a StoreLocation or DeviceLocation.
     * 2. Draws circles representing each location.
     * 3. If the map center is a DeviceLocation (i.e. that device is in focus),
     *    bring it to the front and make it pulse so that it's obvious which location
     *    should be checked.
     */
    const updateMap = () => {
      let lat = mapCenter.latitude
      let lng = mapCenter.longitude
      const data = props.deviceLocations
      mapInstance.flyTo([lat, lng], 18)

      // Add a LatLng object to each item in the dataset
      for (const d of data) {
        if (!d.latLong) {
          d.latLong = new L.LatLng(d.latitude, d.longitude)
        }
      }
      // clears existing circles when changing the slider state up and down, otherwise we end up with many dots
      g.selectAll('circle').remove()

      // Draw the location circles on the map
      g.selectAll('circle')
        .data(data)
        .enter()
        .append('circle')
        .style('stroke', 'darkred')
        .style('stroke-width', '2')
        .style('fill', 'red')
        .attr('r', (d, i) => Math.max(10 - i * 2, 4))

      // Get all the location circles
      const lastKnownLocations = g.selectAll('circle')

      // Update location circle placement on map
      function drawAndUpdateCircles() {
        lastKnownLocations.attr('transform', function (d) {
          let layerPoint = mapInstance.latLngToLayerPoint(d.latLong)
          return 'translate(' + layerPoint.x + ',' + layerPoint.y + ')'
        })
      }
      drawAndUpdateCircles()

      // Pulse the in-focus location
      function pulse() {
        lastKnownLocations
          .transition()
          .filter((d) => d.isFocus)
          .duration(1000)
          .ease(d3.easeElasticOut)
          .attr('r', 13)
          .transition()
          .duration(300)
          .ease(d3.easeBackIn)
          .attr('r', 10)
          .on('end', pulse)
      }
      // Bring the in-focus location to the front
      lastKnownLocations
        .filter((d) => d.isFocus)
        .each(function () {
          this.parentNode.appendChild(this)
        })

      function onMouseOver(event) {
        const point = mapInstance.mouseEventToContainerPoint(event)
        d3.select('.leaflet-overlay-pane')
          .append('div')
          .attr('class', 'tooltip')
          .style('pointer-events', 'none')
          .style('position', 'absolute')
          .style('padding', '4px 8px')
          .style('background', '#666666')
          .style('font-weight', 'medium')
          .style('font-size', '10px')
          .style('border-radius', '5px')
          .style('color', '#F7F7F7')
          .style('white-space', 'nowrap')
          .style('left', point.x + 'px')
          .style('top', point.y + 'px')
        const tooltip = d3.select('div.tooltip').node()
        getTooltip(tooltip, event.timestamp)
      }

      function onMouseOut(event) {
        d3.select(mapInstance.getContainer()).select('.tooltip').remove()
      }

      function getTooltip(node, value) {
        d3.select(node)
          .append('g')
          .call(function (s) {
            s.append('text').text(moment(value).format('llll'))
          })
      }

      lastKnownLocations.on('mouseover', onMouseOver).on('mouseout', onMouseOut)

      // Stop any existing transitions for locations not in focus
      lastKnownLocations.filter((d) => !d.isFocus).interrupt()
      pulse()

      mapInstance.on('moveend', drawAndUpdateCircles)
    }
    updateMap()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapInstance, mapCenter, props.deviceLocations])

  return <div id={mapRandom} style={{ width: '1515px', height: '600px' }} />
}

export default LostStolenMap
