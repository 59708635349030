import Home from '@material-ui/icons/Home'
import HomeOutlined from '@material-ui/icons/HomeOutlined'
import { IconButton } from '@material-ui/core'
import React from 'react'
import Spinner from 'nicollet-react/es/components/Spinner/Spinner'
import Tooltip from '@material-ui/core/Tooltip'
import { useUserSettingsContext } from '../../contexts/UserSettingsContext'

export const UserSettingsHome = ({ homeType, homeValue }) => {
  const userSettings = useUserSettingsContext()

  if (!userSettings.data.isLoading) {
    return (
      <>
        {homeValue !== userSettings.data.data.home.value ? (
          <Tooltip title={'SET HOME'}>
            <IconButton
              color="inherit"
              onClick={() => {
                userSettings.updateHome(homeType, homeValue)
              }}
            >
              <HomeOutlined />
            </IconButton>
          </Tooltip>
        ) : (
          <>
            <Tooltip title={'REMOVE HOME'}>
              <IconButton
                color="inherit"
                onClick={() => {
                  userSettings.updateHome(null, null)
                }}
              >
                <Home />
              </IconButton>
            </Tooltip>
          </>
        )}
      </>
    )
  } else {
    return (
      <React.Fragment>
        <IconButton disabled>
          <Spinner
            style={{
              width: 24,
              height: 24,
            }}
          />
        </IconButton>
      </React.Fragment>
    )
  }
}
