import DeviceLastSeenFormat from '../DeviceLastSeenFormat/DeviceLastSeenFormat'
import Divider from '@material-ui/core/Divider'
import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/styles'

class TableDataWindowsInsights extends React.Component {
  render() {
    const { classes, computerDetails } = this.props
    return typeof computerDetails !== 'undefined' ? (
      <>
        <Typography variant="h6" id="tableTitle">
          General
        </Typography>
        <Table className={classes.root}>
          <TableHead>
            <TableRow className={classes.cell}>
              <TableCell className={classes.cell}>Computer Name</TableCell>
              <TableCell className={classes.cell}>Os platform</TableCell>
              <TableCell className={classes.cell}>Operating system</TableCell>
              <TableCell className={classes.cell}>Location Type</TableCell>
              <TableCell className={classes.cell}>Unique Id</TableCell>
              <TableCell className={classes.cell}>Location Number</TableCell>
              <TableCell className={classes.cell}>Manufacturer</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={classes.cell}>
              <TableCell className={classes.cell}>
                {computerDetails.computer_name && computerDetails.computer_name}
              </TableCell>
              <TableCell className={classes.cell}>
                {computerDetails.os_platform && computerDetails.os_platform}
              </TableCell>
              <TableCell className={classes.cell}>
                {computerDetails.operating_system &&
                  computerDetails.operating_system}
              </TableCell>
              <TableCell className={classes.cell}>
                {computerDetails.location_type && computerDetails.location_type}
              </TableCell>
              <TableCell className={classes.cell}>
                {computerDetails.unique_id && computerDetails.unique_id}
              </TableCell>
              <TableCell className={classes.cell}>
                {computerDetails.location_number &&
                  computerDetails.location_number}
              </TableCell>
              <TableCell className={classes.cell}>
                {computerDetails.manufacturer_name &&
                  computerDetails.manufacturer_name}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Divider style={{ backgroundColor: 'black' }} />
        <Table className={classes.root}>
          <TableHead>
            <TableRow className={classes.cell}>
              <TableCell className={classes.cell}>Tanium Id</TableCell>

              <TableCell className={classes.cell}>Domain Name</TableCell>
              <TableCell className={classes.cell}>purchase date</TableCell>
              <TableCell className={classes.cell}>Bios Version</TableCell>
              <TableCell className={classes.cell}>Created At</TableCell>
              <TableCell className={classes.cell}>Updated at</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow className={classes.cell}>
              <TableCell className={classes.cell}>
                {computerDetails.tanium_id && computerDetails.tanium_id}
              </TableCell>

              <TableCell className={classes.cell}>
                {computerDetails.domain_name && computerDetails.domain_name}
              </TableCell>
              <TableCell className={classes.cell}>
                {computerDetails.purchase_date && computerDetails.purchase_date}
              </TableCell>
              <TableCell className={classes.cell}>
                {computerDetails.bios_version && computerDetails.bios_version}
              </TableCell>
              <TableCell className={classes.cell}>
                <DeviceLastSeenFormat
                  lastSeenValue={
                    computerDetails.created_at && computerDetails.created_at
                  }
                />
              </TableCell>
              <TableCell className={classes.cell}>
                <DeviceLastSeenFormat
                  lastSeenValue={
                    computerDetails.updated_at && computerDetails.updated_at
                  }
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Divider style={{ backgroundColor: 'black' }} />
        <Divider style={{ backgroundColor: 'black' }} />
        <Typography variant="h6" id="tableTitle">
          CPU Information
        </Typography>
        <Table className={classes.root}>
          <TableHead>
            <TableRow className={classes.cell}>
              <TableCell className={classes.cell}>Chasis type</TableCell>
              <TableCell className={classes.cell}>Cpu Processor</TableCell>
              <TableCell className={classes.cell}>Disk Total Space</TableCell>
              <TableCell className={classes.cell}>Disk Free Space</TableCell>
              <TableCell className={classes.cell}>Cpu manufacturer</TableCell>
              <TableCell className={classes.cell}>Cpu speed</TableCell>
              <TableCell className={classes.cell}>Cpu core</TableCell>
              <TableCell className={classes.cell}>RAM</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={classes.cell}>
              <TableCell className={classes.cell}>
                {computerDetails.chasis_type && computerDetails.chasis_type}
              </TableCell>
              <TableCell className={classes.cell}>
                {computerDetails.cpu_processor && computerDetails.cpu_processor}
              </TableCell>
              <TableCell className={classes.cell}>
                {computerDetails.disk_total_space &&
                  computerDetails.disk_total_space}
              </TableCell>
              <TableCell className={classes.cell}>
                {computerDetails.disk_free_space &&
                  `${computerDetails.disk_free_space} GB`}
              </TableCell>
              <TableCell className={classes.cell}>
                {computerDetails.cpu_manufacturer &&
                  computerDetails.cpu_manufacturer}
              </TableCell>
              <TableCell className={classes.cell}>
                {computerDetails.cpu_speed && computerDetails.cpu_speed}
              </TableCell>
              <TableCell className={classes.cell}>
                {computerDetails.cpu_core && computerDetails.cpu_core}
              </TableCell>
              <TableCell className={classes.cell}>
                {computerDetails.ram && computerDetails.ram}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Divider style={{ backgroundColor: 'black' }} />
        {/* <div> */}
        <Typography variant="h6" id="tableTitle">
          User Information
        </Typography>
        <Table className={classes.root}>
          <TableHead>
            <TableRow className={classes.cell}>
              <TableCell className={classes.cell}>Logged in User</TableCell>
              <TableCell className={classes.cell}>
                Ad organizational unit
              </TableCell>

              <TableCell className={classes.cell}>Target Top User</TableCell>
              <TableCell className={classes.cell}>Last seen at</TableCell>
              <TableCell className={classes.cell}>VPN</TableCell>
              <TableCell className={classes.cell}>Name</TableCell>
              <TableCell className={classes.cell}>Last loggedin user</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow className={classes.cell}>
              <TableCell className={classes.cell}>
                {computerDetails.logged_in_user &&
                  computerDetails.logged_in_user}
              </TableCell>
              <TableCell className={classes.cell}>
                {computerDetails.ad_organizational_unit &&
                  computerDetails.ad_organizational_unit}
              </TableCell>
              <TableCell className={classes.cell}>
                {computerDetails.target_top_user &&
                  computerDetails.target_top_user}
              </TableCell>
              <TableCell className={classes.cell}>
                <DeviceLastSeenFormat
                  lastSeenValue={
                    computerDetails.last_seen_at && computerDetails.last_seen_at
                  }
                />
              </TableCell>

              <TableCell className={classes.cell}>
                {computerDetails.vpn && computerDetails.vpn}
              </TableCell>
              <TableCell className={classes.cell}>
                {computerDetails.name && computerDetails.name}
              </TableCell>
              <TableCell className={classes.cell}>
                {computerDetails.last_loggedin_user &&
                  computerDetails.last_loggedin_user}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Divider style={{ backgroundColor: 'black' }} />
        {/* <div> */}
        <Typography variant="h6" id="tableTitle">
          Logical Disk Details
        </Typography>
        <Table className={classes.root}>
          <TableHead>
            <TableRow className={classes.cell}>
              <TableCell className={classes.cell}>ID</TableCell>
              <TableCell className={classes.cell}>Name</TableCell>
              <TableCell className={classes.cell}>Free Space</TableCell>
            </TableRow>
          </TableHead>
          <>
            {computerDetails.ci_logical_disk &&
              computerDetails.ci_logical_disk.flatMap((row, index) => (
                <TableBody key={index}>
                  <TableRow className={classes.cell}>
                    <TableCell className={classes.cell}>{row.id}</TableCell>
                    <TableCell className={classes.cell}>
                      {row.name ? row.name : 'NA'}
                    </TableCell>
                    <TableCell className={classes.cell}>
                      {Math.round(row.free_space / 1024 / 1024 / 1024)} GB
                    </TableCell>
                  </TableRow>
                </TableBody>
              ))}
          </>
        </Table>
        <Divider style={{ backgroundColor: 'black' }} />
        {/* <div> */}
        <Typography variant="h6" id="tableTitle">
          Network Adapter Details
        </Typography>
        <Table className={classes.root}>
          <TableHead>
            <TableRow className={classes.cell}>
              <TableCell className={classes.cell}>Id</TableCell>
              <TableCell className={classes.cell}>Name</TableCell>
              <TableCell className={classes.cell}>IPv4 Address</TableCell>
              <TableCell className={classes.cell}>IPv6 Address</TableCell>
              <TableCell className={classes.cell}>Mac Address</TableCell>
            </TableRow>
          </TableHead>

          {computerDetails.ci_network_adapter &&
            computerDetails.ci_network_adapter.flatMap((row, index) => (
              <TableBody key={index}>
                <TableRow className={classes.cell}>
                  <TableCell className={classes.cell}>{row.id}</TableCell>
                  <TableCell className={classes.cell}>{row.name}</TableCell>
                  <TableCell className={classes.cell}>
                    {row.ipv4_address}
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {row.ipv6_address}
                  </TableCell>
                  <TableCell className={classes.cell}>
                    {row.mac_address}
                  </TableCell>
                </TableRow>
              </TableBody>
            ))}
        </Table>
      </>
    ) : (
      <div>Note, additional details are not available for this device.</div>
    )
  }
}

const styles = (theme) => ({
  root: {
    backgroundColor: '#eeeeee',
    padding: 'dense',
    fontSize: '40pt',
    verticalAlign: 'top',
  },
  cell: {
    padding: 0,
    height: 10,
    verticalAlign: 'top',
  },
  honeyComb: {
    minHeight: `calc(100vh - 75px)`,
    backgroundImage:
      `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='24.5' ` +
      `viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%23888888' fill-opacity='0.50' ` +
      `fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 ` +
      `11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 ` +
      `0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
  },
  listItem: {
    verticalAlign: 'top',
    height: 10,
    paddingTop: 0,
    paddingLeft: 0,
    paddingRight: 5,
    border: 'none',
  },
})

export default withStyles(styles, { withTheme: true })(TableDataWindowsInsights)
