import React from 'react'
import axios from 'axios'
import queryString from 'query-string'

class SvcPrinters extends React.Component {
  getPrinters(config, submission) {
    const queryParameters = {
      printers: {
        location_id: null,
        serial_number: null,
        mac_address: null,
        model: null,
        id: null,
        hostname: null,
        device_class: null,
      },
    }
    let queryParametersVar = queryParameters
    Object.entries(submission).forEach((param) => {
      if (queryParametersVar.printers.hasOwnProperty(param[0])) {
        queryParametersVar.printers[`${param[0]}`] = param[1]
      }
    })

    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey
    const endpointParams = queryString.stringify(queryParametersVar.printers, {
      sort: false,
      skipNull: true,
    })
    const apiUri = encodeURI(
      apiUriBase + `/printers?` + endpointParams + `&key=` + apiKey,
    )
    return axios.get(apiUri)
  }
  getStorePrinters(storeNumber, config) {
    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey
    const apiUri = encodeURI(
      apiUriBase + `/printers?location_id=` + storeNumber + `&key=` + apiKey,
    )
    return axios.get(apiUri)
  }
  // printer label
  putPrinterLabel(mac_address, label, config) {
    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey
    const apiUri = encodeURI(
      apiUriBase + `/printers/` + mac_address + '/labels?key=' + apiKey,
    )
    return axios.put(
      apiUri,
      {
        label: label,
      },
      { 'content-type': 'application/json' },
    )
  }
  putPrinterUpdates(
    ipAddress,
    hostname,
    toDoAction,
    macAddress,
    value,
    locationId,
    config,
  ) {
    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey
    const apiUri = encodeURI(
      apiUriBase + `/printers/` + macAddress + `?key=` + apiKey,
    )
    return axios.put(
      apiUri,
      {
        hostname: hostname,
        to_do: toDoAction,
        ip_address: ipAddress,
        value: value,
        store_id: locationId,
      },
      { 'content-type': 'application/json' },
    )
  }
  deletePrinter(macAddress, config) {
    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey
    const apiUri = encodeURI(
      apiUriBase + `/printers/` + macAddress + `?key=` + apiKey,
    )
    return axios.delete(apiUri)
  }
  disableNotification(macAddress, config) {
    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey
    const apiUri = encodeURI(
      apiUriBase +
        `/printers/` +
        macAddress +
        `/disable_notifications?key=` +
        apiKey,
    )
    return axios.post(apiUri)
  }
  postPrinterLostStolenMoves(macAddress, config) {
    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey
    const apiUri = encodeURI(
      `${apiUriBase}/printers/${macAddress}/lost_stolen_moves?key=${apiKey}`,
    )
    return axios.post(apiUri)
  }
}
export default SvcPrinters
