class ValidateStoreNumber {
  validateStoreNumber = (store) => {
    if (/^t?\d{1,4}$/i.test(store)) {
      let storeStripped = store.replace(/^t?/i, '')
      return parseInt(storeStripped) === 0 ? '0' : parseInt(storeStripped)
    } else {
      return ''
    }
  }
}
export default ValidateStoreNumber
