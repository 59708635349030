import axios from 'axios'

class SvcLocationDetails {
  getLocationDetails(locationId, config) {
    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey
    const apiUri = encodeURI(
      apiUriBase +
        `/location_details?location_id=` +
        locationId +
        `&key=` +
        apiKey,
    )
    return axios.get(apiUri)
  }
  getRGDDetails(config) {
    const url = config.auth.apiUriBase + '/field_suggestions'
    const apiUrl = `${url}?key=${config.auth.apiKey}&field=region_id,group_id,district_id,location_id`
    return axios.get(apiUrl)
  }
}
export default SvcLocationDetails
