import { createMuiTheme } from '@material-ui/core/styles'
import DeviceLastSeenFormat from '../DeviceLastSeenFormat/DeviceLastSeenFormat'
import Divider from '@material-ui/core/Divider'
import React from 'react'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

class TableDataRFIDScannersDetailsHeader extends React.Component {
  render() {
    const { classes } = this.props
    return (
      <>
        <Typography variant="h6" id="tableTitle">
          General Details
        </Typography>
        <Table className={classes.root}>
          <TableHead>
            <TableRow className={classes.cell}>
              <TableCell className={classes.cell}>Name</TableCell>
              <TableCell className={classes.cell}>Unique ID</TableCell>
              <TableCell className={classes.cell}>TCIN</TableCell>
              <TableCell className={classes.cell}>Status</TableCell>
              <TableCell className={classes.cell}>Device State</TableCell>
              <TableCell className={classes.cell}>Updated On</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={classes.cell}>
              <TableCell className={classes.cell}>
                {this.props.rfidscannerDetails.name}
              </TableCell>
              <TableCell className={classes.cell}>
                {this.props.rfidscannerDetails.unique_id}
              </TableCell>
              <TableCell className={classes.cell}>
                {this.props.rfidscannerDetails.tcin}
              </TableCell>
              <TableCell className={classes.cell}>
                {this.props.rfidscannerDetails.status}
              </TableCell>
              <TableCell className={classes.cell}>
                {this.props.rfidscannerDetails.device_state}
              </TableCell>
              <TableCell className={classes.cell}>
                {this.props.rfidscannerDetails.updated_on && (
                  <DeviceLastSeenFormat
                    lastSeenValue={this.props.rfidscannerDetails.updated_on}
                  />
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Divider style={{ backgroundColor: 'black' }} />
      </>
    )
  }
  getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTableBodyRow: {
          root: {
            backgroundColor: '#eeeeee',
            textAlign: 'center',
          },
        },
        MUIDataTableHeadRow: {
          root: {
            height: 24,
          },
        },
        MUIDataTableBodyCell: {
          root: {
            padding: 0,
          },
        },
        MUIDataTableHeadCell: {
          root: {
            padding: 0,
          },
        },
      },
    })
}

export default TableDataRFIDScannersDetailsHeader
