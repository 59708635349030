class ValidateHostname {
  ValidateDuplicateHostname = (deviceHostname) => {
    if (/^\S[\w\s]*\S$/i.test(deviceHostname)) {
      return true
    } else {
      return false
    }
  }

  ValidateHostnamePattern = (deviceHostname, locationId) => {
    var regexString = new RegExp('(T' + locationId + 'PRT050)[1-9]$', 'g')
    if (regexString.test(deviceHostname)) {
      return false
    } else {
      return true
    }
  }
}
export default ValidateHostname
