import DeviceActionMenu from '../../Devices/ActionMenu/DeviceActionMenu'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import React from 'react'
import SettingsIcon from '@material-ui/icons/Settings'
import svcBTScanners from '../../../services/svcBTScanners'
import svcHandhelds from '../../../services/svcHandhelds'
import svcPrinters from '../../../services/svcPrinters'
import svcTablets from '../../../services/svcTablets'
import { withAnalytics } from '@praxis/component-analytics'
import { withEnv } from '@praxis/component-runtime-env'

class ActionMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      details: [],
      handheldDetails: [],
      printerDetails: [],
      executed: false,
      anchorEl: null,
    }
    this.svcHandhelds = new svcHandhelds()
    this.svcPrinters = new svcPrinters()
    this.svcTablets = new svcTablets()
    this.svcBTScanners = new svcBTScanners()
  }

  loadData = (event) => {
    this.setState({ loading: true })
    this.setState({ anchorEl: event.currentTarget })
    //rowData[0] is classification
    //rowData[1] is unique id
    const submission = {
      id: this.props.rowData[1],
    }
    // we need to call the appropriate API endpoint based on the classification_name in rowdata[0]
    // right now this is hardcoded to handhelds.
    if (this.props.rowData[0] === 'handheld') {
      this.svcHandhelds
        .getHandhelds(this.props.env, submission)
        .then((response) => {
          const eventData = {
            customMetrics: {
              metric1: 'Handheld',
              metric3: response.status,
            },
            event: {
              type: `apiSuccessHandheld`,
            },
          }
          this.props.trackEvent(eventData)
          this.setState({
            details: response.data[0],
          })
        })
        .catch((error) => {
          const eventData = {
            customMetrics: {
              metric1: 'Handheld',
              metric3: error.response.status,
            },
            event: {
              type: `apiErrorHandheld`,
            },
          }
          this.props.trackEvent(eventData)
          console.log(error)
        })
        .finally(() => {
          this.setState({ loading: false, executed: true })
        })
    } else if (this.props.rowData[0] === 'printer') {
      this.svcPrinters
        .getPrinters(this.props.env, submission)
        .then((response) => {
          const eventData = {
            customMetrics: {
              metric1: 'Printer',
              metric3: response.status,
            },
            event: {
              type: `apiSuccessPrinter`,
            },
          }
          this.props.trackEvent(eventData)
          this.setState({
            details: response.data[0],
          })
        })
        .catch((error) => {
          const eventData = {
            customMetrics: {
              metric1: 'Printer',
              metric3: error.response.status,
            },
            event: {
              type: `apiErrorPrinter`,
            },
          }
          this.props.trackEvent(eventData)
          console.log(error)
        })
        .finally(() => {
          this.setState({ loading: false, executed: true })
        })
    } else if (this.props.rowData[0] === 'tablet') {
      this.svcTablets
        .getTablets(this.props.env, submission)
        .then((response) => {
          const eventData = {
            customMetrics: {
              metric1: 'Tablet',
              metric3: response.status,
            },
            event: {
              type: `apiSuccessTablet`,
            },
          }
          this.props.trackEvent(eventData)
          this.setState({
            details: response.data[0],
          })
        })
        .catch((error) => {
          const eventData = {
            customMetrics: {
              metric1: 'Tablet',
              metric3: error.response.status,
            },
            event: {
              type: `apiErrorTablet`,
            },
          }
          this.props.trackEvent(eventData)
          console.log(error)
        })
        .finally(() => {
          this.setState({ loading: false, executed: true })
        })
    } else if (this.props.rowData[0] === 'bluetoothscanner') {
      this.svcBTScanners
        .getBTScannersId(this.props.env, submission)
        .then((response) => {
          const eventData = {
            customMetrics: {
              metric1: 'Bluetooth Scanner',
              metric3: response.status,
            },
            event: {
              type: `apiSuccessBluetoothScanner`,
            },
          }
          this.props.trackEvent(eventData)
          this.setState({
            details: response.data[0],
          })
        })
        .catch((error) => {
          const eventData = {
            customMetrics: {
              metric1: 'Bluetooth Scanner',
              metric3: error.response.status,
            },
            event: {
              type: `apiErrorBluetoothScanner`,
            },
          }
          this.props.trackEvent(eventData)
          console.log(error)
        })
        .finally(() => {
          this.setState({ loading: false, executed: true })
        })
    }
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  render() {
    const platform = this.props.rowData[0]
    return (
      <>
        <IconButton onClick={(event) => this.loadData(event)}>
          <SettingsIcon />
        </IconButton>
        <Menu
          style={{ paddingTop: 0, paddingBottom: 0 }}
          disableAutoFocusItem
          anchorEl={this.state.anchorEl}
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleClose}
          anchorOrigin={{ vertical: 0, horizontal: -175 }}
        >
          <DeviceActionMenu
            rowData={this.state.details}
            platform={platform}
            location={this.props.rowData[7]}
          />
        </Menu>
      </>
    )
  }
}

export default withEnv()(withAnalytics()(ActionMenu))
